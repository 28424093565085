import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'valueSet'
})

export class ValueSetPipe implements PipeTransform {
  constructor(private sharedService: SharedDataService) {

  }
  transform = (value):any =>{
    let result;
 // console.log(value);
    this.sharedService.getValidValuesDetails(value).subscribe(async res => {
      if (res) {
        result = await res.data.attributes.name;
     // console.log(result);
        return result;
      }
    });
  }
  
}
