<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Add Attributes</span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <!-- <div>
                <button mat-raised-button (click)="saveDraft()" class='buttonSuccess'>Save as Draft</button>
            </div>
            <div>
                <button mat-raised-button (click)="cancel()" class="buttonCancel">Cancel</button>
            </div> -->
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" *ngIf="!loading">
            <div fxFlex="50">
                <ul style="margin-top:10px">
                    <li *ngFor="let item of list">
                        {{item.name}}
                    </li>
                </ul>
            </div>
        </mat-card>    
    </div>
</div>
  