import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  loading = true;
  files = [];
  uploadForm;
  constructor(
    private snackBar:MatSnackBar,
    private formBuilder: FormBuilder,
    private iconRegistry:MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.uploadForm = this.formBuilder.group({
      attachment: ['']
    });
  }
  href='https://taxonomy.demo.datax.ai/api/taxonomies/export';

  ngOnInit(): void {
    this.loading = false;
  }

  download = () => {
    window.open(this.href,"_self");
  } 
  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  submitName = () => {
    this.saveDraft('name');
  }

  submitDesc = () => {
    this.saveDraft('description');
  }

  saveDraft =(field)=>{
    this.uploadForm = this.formBuilder.group({
      attachment: ['']
    });
    if(this.files.length==0){
   // console.log(1);
      this.snackBar.open(`Please fill the values properly.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    } else {
      let data = {
        edit_type: "category_field_edit",
      }
   // console.log(data);
      // this.nodeService.nodeEdit(data).subscribe({
      //   next: (resp:any) => {
      //  // console.log(resp);
      //     if(resp.data.id>0){
      //       this.formSubmitted = false;
      //       this.draftId = resp.data.id;
      //    // console.log(this.draftId);
      //       if(this.draftId > 0 && this.files.length > 0){
      //         setTimeout(() => {
      //           let file={
      //             attachments:this.files
      //           }
      //           var form = new FormData();
      //           this.files.forEach(e => {
      //             form.append("attachments", e);
      //           });
      //        // console.log(form);
      //           this.nodeService.uploadFile(this.draftId,form).subscribe({
      //             next: resp => {
      //            // console.log('resp');
      //               this.snackBar.open(`Edit Request Submitted Successfully.`, 'OK', {
      //                 duration: 4000,
      //                 panelClass: ['error-snackbar']
      //               });
      //             }
      //           })  
      //         }, 2000);
      //       } else {
      //         this.snackBar.open(`Edit Request Submitted Successfully.`, 'OK', {
      //           duration: 4000,
      //           panelClass: ['error-snackbar']
      //         });
      //       }

      //     }      
      //   },
      //   error: (HttpResponse: HttpErrorResponse) => {
      //     this.formSubmitted = false;
      //     this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
      //       duration: 4000,
      //       panelClass: ['error-snackbar']
      //     });
      //   }
      // });
   // console.log(this.files); 
    }
  }

  getIcon = (name) => {
    let arr = name.split(".");
    // console.log(arr);
    if(arr[1]=='csv'){
      return 'csv';
    } else if(arr[1]=='zip'){
      return 'zip';
    } else if(arr[1]=='pdf'||arr[1]=='docx'){
      return 'pdf';
    } else {
      return 'zip';
    }
    
  }

}
