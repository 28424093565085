import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { VersionService } from 'src/app/services/version.service';
import { HierarchyInfoComponent } from '../_shared/hierarchy-info/hierarchy-info.component';

@Component({
  selector: 'app-edit-relation',
  templateUrl: './edit-relation.component.html',
  styleUrls: ['./edit-relation.component.scss']
})
export class EditRelationComponent implements OnInit {

  constructor(
    private nodeService: NodeService,
    private infoService: InfoService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private homeService: HomeService,
    private dialog: MatDialog,
    private versionService: VersionService,
    private errorHandler: ErrorHandlerService
  ) {

    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      )
    );
  }
  formGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = true;
  formSubmitted = false;
  draftId = 0;
  selectedNodeId: any = null;
  selectedNodeData: any = {
    attributes : null
  };
  categoriesList: any = null;
  isLeaf = false;
  targetNode: any = null;
  nodeMovedTo: any = null;
  searchNodeSelected;
  searchKey;
  selectedLevel;
  options;
  searchResult;
  searchedNode;
  ticketID = '';
  selectedHierarchy = null;
  selectedVersion = this.versionService.checkSetVersion();
  ngOnInit(): void {
    this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    this.selectedNode = this.nodeService.selectedNode;
    this.selectedNodeId = this.infoService.nodeId;
    this.initForm();
    if (this.selectedNode !== ''){
   // console.log(this.selectedNode);
   // console.log(this.selectedNodeId);
      this.nodeInfo(this.selectedNodeId);
      this.nodeList('');
      } else {
      this.router.navigate(['/home']);
    }
  }

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.loading = false;
      }
    });
  }

  nodeList = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.categoriesList = res.results.data;
        this.loading = false;
      }
    });
  }

  viewHierarchy = (category) => {
 // console.log(category);
    let categoryName = null;
    if (category){
      categoryName = category.attributes.category.data.attributes.name;
    }
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-hierarchy';
    dialogConfig.width = '60vw';
    dialogConfig.data = {categoryId: category.attributes.category_id, categoryName};
    const modalDialog = this.dialog.open(
      HierarchyInfoComponent, dialogConfig
      );
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save = () => {
    this.loading = true;
    const data = {
      edit_type: 'category_relation_edit',
      hierarchy_id: this.selectedHierarchy.id,
      version_id: this.selectedVersion.id,
      // original_value: this.selectedNodeData.attributes.path,
      node_to_move: this.selectedNodeId,
      target_node: this.targetNode,
      note: this.notes,
      internal_ticket_id: this.ticketID
    };
 // console.log(data);
    this.nodeService.addCategory(this.selectedId, data).subscribe({
      next: (resp: any) => {
     // console.log(resp);
        this.draftId = resp.data.id;
        if (this.draftId > 0 && this.files.length > 0){
          setTimeout(() => {
            const file = {
              attachments: this.files
            };
            const form = new FormData();
            this.files.forEach(e => {
              form.append('attachments', e);
            });
         // console.log(form);
            this.nodeService.uploadFile(this.draftId, form).subscribe({
              next: resp => {
                this.formSubmitted = false;
                this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
                  duration: 4000,
                  panelClass: ['error-snackbar']
                });
              },
              error: (HttpResponse: HttpErrorResponse) => {
                this.formSubmitted = false;
                this.loading = false;
                this.errorHandler.pass(HttpResponse.error);
              }
            });
          }, 2000);
        } else {
          this.loading = false;
          this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
     // console.log(HttpResponse.error. detail);
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  initForm() {
    this.formGroup = this.fb.group({
      myControl : ['']
    });
    this.formGroup.get('myControl').valueChanges.pipe(debounceTime(1000),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKey);
      if (this.formGroup.get('myControl').value != ''){
        this.searchKey = resp;
        this.getSearchSuggestions(resp);
      } else {
        // console.log('no searchkey');
        // this.getTreeData();
      }
    });
  }

  enterSearch = (e) => {
    // console.log(e);
    if (e.keyCode === 13){
      const input = this.formGroup.get('myControl').value;
      // console.log(input);
      // if(input){
      //   this.matAutocomplete.closePanel();
      // }
    }
  }

  getSearchSuggestions = (searchKey: any) => {
    if (this.searchKey = !0){
      this.searchKey = searchKey;
      this.homeService.searchNode(searchKey, this.selectedLevel, false).subscribe(res => {
        if (res) {
       // console.log(res);
          this.options = res.results.data;
        }
      });
    }
  }

  selectOption = (node: any) => {
 // console.log('Got an ID');
 // console.log(node);
    this.searchedNode = node;
    this.targetNode = this.searchedNode.attributes.category_id;
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }


}
