<div class="value" fxLayout="row" style="width: 100%;">
    <div *ngIf="data.editField=='Name'" class="editPanel" fxFlex="100">
      <mat-card fxLayout="row" class="editDialog">
        <div fxFlex="50">
            <div>
                <mat-label>Name</mat-label>
                <!-- <button style="" mat-icon-button matTooltip="View Hierarchy" matTooltipPosition="above" (click)="viewHierarchy()" ><mat-icon>account_tree</mat-icon></button> -->
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="editedValue">
                </mat-form-field>
            </div>
            <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
            </div>
            <div class="textArea">
                <mat-label>Notes</mat-label>
                <mat-form-field appearance="outline">
                    <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="50">
            <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                <ngx-dropzone-label>
                    <mat-icon svgIcon="upload"></mat-icon>
                    <p>Drag or Drop your files here</p>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    
                    <ngx-dropzone-label fxLayout="row">
                        <div fxFlex="50">
                            <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                            </mat-icon>    
                        </div>
                        <div fxFlex="50">
                            <span style="font-size: 10px;">{{ f.name }}</span>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
      </mat-card>
    <div style="padding: 20px 0px">
        <span style="width:100%;display:block;word-wrap:break-word;"><i>This change will reflect in all the hierarchies where <b>{{nodeData.attributes.category.data.attributes.name}} ({{nodeData.attributes.category.data.attributes.code}})</b> is present.</i></span>
    </div>
      <div mat-dialog-actions style="float: right;">
        <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitName()" >SAVE</button>
        <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
      </div>      
    </div>


    <div *ngIf="data.editField=='Node Description'" class="editPanel" fxFlex="100">
        <mat-card fxLayout="row" class="editDialog">
          <div fxFlex="50">
              <div>
                  <mat-label>Description</mat-label>
                  <!-- <button style="" mat-icon-button matTooltip="View Hierarchy" matTooltipPosition="above" (click)="viewHierarchy()" ><mat-icon>account_tree</mat-icon></button> -->
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="8" [(ngModel)]="editedValue"></textarea>
                  </mat-form-field>
              </div>
              <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
              </div>
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex="50">
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      
                      <ngx-dropzone-label fxLayout="row">
                          <div fxFlex="50">
                              <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                              </mat-icon>    
                          </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div style="padding: 20px 0px">
            <span style="width:100%;display:block;word-wrap:break-word;"><i>This change will reflect in all the hierarchies where <b>{{nodeData.attributes.category.data.attributes.name}} ({{nodeData.attributes.category.data.attributes.code}})</b> is present.</i></span>
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitDesc()" >SAVE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
          
      </div>
  </div>