import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { ENDPOINTS } from '../_globals/endpoints';
import { Globals } from '../_globals/endpoints.global';
import { HierarchyService } from './hierarchy.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  private draftState = new BehaviorSubject({});
  draftChangeStatus = this.draftState.asObservable();
  currentVersion: any  = 0;
  currentVersionName: any = null;
  selectedDraft: any = null;
  selectedAttributeList: any = [];
  selectedFilterAttributeList: any = [];
  subscription: Subscription;
  subscriptionHeirarchy: Subscription;
  currentHierarchy: any = null;
  selectedNode = '';
  attributeId = '';

  private reviewStatus = new BehaviorSubject({});
  reviewChangeStatus = this.reviewStatus.asObservable();

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private hierarchyService: HierarchyService,
    private versionService: VersionService
    ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      if (this.currentVersion === 0 || this.currentVersion == null) {
     // console.log(this.currentVersion);
        this.currentVersion = JSON.parse(localStorage.getItem('version'));
      } else {
       this.currentVersionName = this.currentVersion.id;
      }
    });
    this.subscriptionHeirarchy = this.hierarchyService.getUpdatedHierarchy().subscribe(hierarchy => {
      this.currentHierarchy = hierarchy;
      if (this.currentHierarchy === 0 || this.currentHierarchy == null) {
     // console.log(this.currentHierarchy);
        this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
      } else {
     // console.log(this.currentHierarchy);
      }
    });

  }
  getProperties = (nodeId) => {
    let endPoint = this.globals.urlJoin('node_properties', 'properties');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint + nodeId, {
      headers: this.httpOptions,
      params: {
        node_id!: nodeId,
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  changeDraftStatus = (value: any) => {
    this.draftState.next(value);
  }

  changeReviewStatus = (value: any) => {
    this.reviewStatus.next(value);
  }

  nodeEdit = (data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http
      .post(endPoint, data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  uploadFile = (draftId, data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http
      .post(endPoint + draftId + '/files/', data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  filterDraftList = (option) => {
    const endPoint = this.globals.urlJoin('draft', 'list');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        state!: option,
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getDraftCount = (state: string) => {
    const endPoint = this.globals.urlJoin('draft', 'list');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        state
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getDraftList = (page: any, pageSize: any, status: any, creatorId?: any ) => {
    if(pageSize === 0){
      pageSize = 50;
    }
    const endPoint = this.globals.urlJoin('draft', 'list');
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('page_size', pageSize);
    if (page === 0){
      page++;
    }
 // console.log(creatorId);
    if(creatorId !== undefined && creatorId !== '' && creatorId !== 'undefined'){
      params = params.append('creator_id', creatorId);
    }
 // console.log(status);
    if (status !== ''){
      params = params.append('state', status);
    } else {
    }
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );


  }

  getDraft = (id: any) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.get<any>(endPoint + id + '/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getDraftFiles = (id: any) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.get<any>(endPoint + id + '/files/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  searchAttribute = (searchKey: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'search');
    // this.versionService.getVersion().subscribe(version => this.currentVersion = version.attributes.name);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersion);

    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        q: searchKey,
      }
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getCategoriesData = (list: any) => {
    let endPoint = this.globals.urlJoin('data', 'categories');
    // this.versionService.getVersion().subscribe(version => this.currentVersion = version.attributes.name);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersion);
    return this.http.post<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        category_id_list: list,
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributesData = (list: any) => {
    let endPoint = this.globals.urlJoin('data', 'attributes');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.post<any>(endPoint, list, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  postToReview = (nodeId) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.post<any>(endPoint + nodeId + '/submit/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  postToApprove = (nodeId) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.post<any>(endPoint + nodeId + '/review/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributeList = () => {
    let endPoint = this.globals.urlJoin('attribute', 'list');
 // console.log(this.currentVersionName);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    })
    .pipe(
      tap(resp => {
          return resp;
      }),
      catchError(error => throwError(error))
    );
  }

  getAttributeDetails = (id) => {
    let endPoint = this.globals.urlJoin('attribute', 'list');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);

    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions,
    })
    .pipe(
      tap(resp => {
          return resp;
      }),
      catchError(error => throwError(error))
    );
  }

  postToFinalSubmit = (nodeId) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.post<any>(endPoint + nodeId + '/approve/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  publish = () => {
    const endPoint = this.globals.urlJoin('draft', 'publish');
    return this.http.post<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  attributeDetails = (nodeId, attributeId) => {
 // console.log(nodeId);
 // console.log(attributeId);
    let endPoint = this.globals.urlJoin('attribute', 'details');
 // console.log(this.currentVersionName);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);

    return this.http.get<any>(endPoint + nodeId + '/attributes/' + attributeId + '/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  deleteDraft = (editId: any) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.delete<any>(endPoint + editId + '/', {
      headers: this.httpOptions,
      // params: {
      //   page: '2',
      //   page_size: '2'
      // }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  rejectDraft = (editId: any) => {
    const endPoint = this.globals.urlJoin('node', 'edit');
    return this.http.post<any>(endPoint + editId + '/reject/', {
      headers: this.httpOptions,
      // params: {
      //   page: '2',
      //   page_size: '2'
      // }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  addCategory = (id: any, data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('node', 'add');
    return this.http
      .post(endPoint, data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  delCategory = (id: any, data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('node', 'add');
    return this.http
      .post(endPoint, data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  addComment = (id: any, data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('draft', 'comment');
    return this.http
      .post(endPoint + id + '/comments/', data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  viewComments = (id: any) => {
    const endPoint = this.globals.urlJoin('draft', 'comment');
    return this.http
      .get(endPoint + id + '/comments/', {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  getImpact = (id: any) => {
    const endPoint = this.globals.urlJoin('draft', 'list');
    return this.http
      .get(endPoint + id + '/impact_skus/', {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  acceptReview = (id) => {
    const endPoint = this.globals.urlJoin('node_properties', 'accept');
    return this.http.post<any>(endPoint + id, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  undoAcceptReview = (id) => {
    const endPoint = this.globals.urlJoin('node_properties', 'accept');
    return this.http.put<any>(endPoint + id, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  reviewSubmitStatus = () => {
    const endPoint = this.globals.urlJoin('node_properties', 'submitStatus');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  freeze = () => {
    const endPoint = this.globals.urlJoin('draft', 'freeze');
    return this.http.post<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  unFreeze = () => {
    const endPoint = this.globals.urlJoin('draft', 'unfreeze');
    return this.http.post<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  publishStatus = () => {
    const endPoint = this.globals.urlJoin('draft', 'status');
    return this.http
      .get(endPoint, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  uploadUoM = (data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('file', 'uomUpload');
    return this.http
      .post(endPoint, data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  uploadCodeset = (data: any) => {
 // console.log(data);
    const endPoint = this.globals.urlJoin('file', 'codesetUpload');
    return this.http
      .post(endPoint, data, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  exportUoM = () => {
    const endPoint = this.globals.urlJoin('file', 'exportUoM');
    window.location.href = endPoint;
  }
  exportValidValues = () => {
    const endPoint = this.globals.urlJoin('file', 'exportValidValues');
    window.location.href = endPoint;
    // return this.http
    //   .get(endPoint, {
    //     headers: this.httpOptions,
    //   })
    //   .pipe(
    //     map((response) => {
    //       return response;
    //     }),
    //     catchError((error) => throwError(error))
    //   );
  }

}
