import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-ticket-reset',
  templateUrl: './ticket-reset.component.html',
  styleUrls: ['./ticket-reset.component.scss']
})
export class TicketResetComponent implements OnInit {

  constructor(
    private snackBar:MatSnackBar,
    private homeService: HomeService,
    private errorHandler: ErrorHandlerService,
    public dialogRef: MatDialogRef<TicketResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  submit = () => {
    this.homeService.resetDraft().subscribe({
      next: res => {
        this.snackBar.open('Draft tickets are cleared.', 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
        this.dialogRef.close(true);
        if (res) {
          console.log(res);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  cancel = () => {
    this.dialogRef.close(false);
  }

}
