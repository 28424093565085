import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../_globals/endpoints';
import { Globals } from '../_globals/endpoints.global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  constructor(private globals: Globals, private http: HttpClient) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
  /**
   * POST dialog text field from login page
   */
  fromLogin = (dialogData,origin): Observable<any> => {
    if(origin == 'login'){
      var loginEndpoint = this.globals.urlJoin('users', 'need_help');
    } else if (origin == 'app'){
      var loginEndpoint = this.globals.urlJoin('users', 'contact_us');
    }
    return this.http
    .post(loginEndpoint, {
      body: dialogData,
    })
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  
  };

}
