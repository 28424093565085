<!-- <p>login works!</p> -->
<div class="row">
  <div class="column-left">
    <!-- Login page left column -->
    <div class="content-left">
      <div class="logo" fxLayoutAlign="center center">
        <img src="../../../assets/images/datax.svg" class="svg_icon" />
      </div>

      <!-- <h1>Welcome Back to dataX</h1>
      <p>Log in with your credentials to proceed.</p> -->
      <!-- login form -->
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="input-fields">
          <!-- Username -->
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input
              matInput
              type="text"
              formControlName="username"
              autocomplete="off"
            /> </mat-form-field
          ><br />
          <!-- password -->
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              autocomplete="off"
            />
            <p
              class="password-peek"
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </p>
          </mat-form-field>
          <!-- <br> -->
        </div>
        <mat-hint fxLayout="row" fxLayoutAlign="start start" >
          <!-- <input
            type="checkbox"
            formControlName="rememberme"
            class="remember-me"
          />
          <p class="remember-title">Remember me</p> -->

          <!-- <p class="forgot-password" (click)="navigateTo()">Forgot Password?</p> -->
        </mat-hint>
        <!-- login button -->
        <button
          mat-raised-button
          class="login_button"
          [disabled]="loginForm.invalid"
          type="submit"
        >
          Log In
          <mat-icon class="btn-spinner" *ngIf="loading"
            ><mat-spinner color="accent" diameter="20"> </mat-spinner
          ></mat-icon>
        </button>

        <p
          class="need-help"
          (click)="openDialog()"
          style="left: 29%; padding: 10px 0; position: relative"
        >
          Need Help?
        </p>

        <!-- remember me / forgot password -->
      </form>
    </div>

    <!-- Copyrights -->
    <p class="login__copyright">
      &copy; {{ currentYear }}
      <a href="https://www.crowdanalytix.com/" target="_blank"
        ><span style="color: #fff; font-weight: 400" class="crowd">Crowd</span
        ><span class="analytix">ANALYTIX</span></a
      >
    </p>
  </div>

  <!-- Login page Right column Info -->
  <div class="column-right">
    <app-login-info-content></app-login-info-content>
  </div>
</div>
