<div class="dialog" fxLayout="column">
    <div class="editPanel" fxFlex="100">
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div *ngIf="nodeData" style="margin: 20px 0px;">
                    <mat-label>Path</mat-label>
                    <mat-label style="margin-left:20px;">{{nodeData.attributes.path | deleteCategory: nodeData.attributes.category.data.attributes.name}} <span style="color:red; font-weight: 600;">{{nodeData.attributes.category.data.attributes.name}}</span></mat-label>
                    <!-- <button mat-icon-button matTooltip="View Hierarchy" matTooltipPosition="above" (click)="viewHierarchy(nodeData)" ><mat-icon>account_tree</mat-icon></button> -->
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submit()" >DELETE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
    </div>  
</div>
    