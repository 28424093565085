<div class="row">
  <div class="column-left">
    <!-- Login page left column -->
    <div class="content-left">
      <div class="logo" fxLayoutAlign="center center">
        <img src="assets/images/datax_logo.svg" class="svg_icon" />
      </div>

      <h1>Reset Password</h1>

      <!-- Reset password form -->
      <div class="input-fields">
        <form
          novalidate
          class="form"
          fxLayout="column"
          [formGroup]="resetPasswordForm"
          #form
        >
          <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput type="password" formControlName="password" />
            <mat-error
              *ngIf="
                f.password.touched &&
                f.password.invalid &&
                f.password.errors.required &&
                resetPasswordForm.controls.password.hasError('pattern')
              "
            >
              Password is required.
            </mat-error>
            <mat-error
              *ngIf="resetPasswordForm.controls.password.hasError('pattern')"
            >
              Password must contain a lowercase, uppercase, special character
              and a number
            </mat-error> </mat-form-field
          ><br />
          <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="confirm_password"
            />

            <mat-error
              *ngIf="
                f.confirm_password.touched &&
                f.confirm_password.invalid &&
                f.confirm_password.errors.required
              "
            >
              Password is required.
            </mat-error>

            <mat-error
              *ngIf="
                f.confirm_password.touched &&
                f.confirm_password.invalid &&
                f.confirm_password.errors.confirmedValidator
              "
            >
              Your password and confirmation password do not match.
            </mat-error>
          </mat-form-field>
        </form>

        <button
          mat-raised-button
          class="return-login-button"
          (click)="resetComplete()"
          [disabled]="!resetPasswordForm.valid"
        >
          Save
        </button>
      </div>
    </div>
    <!-- Copyrights -->
    <p class="login__copyright">&copy; 2021 Crowd<a href="#">ANALYTIX</a></p>
  </div>

  <!-- Login page Right column Info -->
  <div class="column-right">
    <app-login-info-content></app-login-info-content>
  </div>
</div>
