<!--Header Starts-->
<div class="top-nav" fxLayoutAlign="center center">
  <div class="nav-bar" layout="row" fxFlex="100" fxLayoutAlign.xs="space-between center"
    fxLayoutAlign="space-between center">
    <!--Logo -->
    <div class="top-nav-logo">
      <a class="top-nav-logo-link">
        <img src="assets/images/datax.svg" class="svg_icon" routerLink='/home'>
      </a>
    </div>
    <div class="status" style="width:100%; color: #fff;">
      <a>
        <div fxLayout="column" fxLayoutAlign='start center' style="float:left; border-left: 2px solid #fff; margin-left: 10px; height: 35px;">
          <div style="margin: 10px; font-size: 16px; font-weight: 500;">Content Governance Platform (CGP)</div>
        </div>
      </a>
    </div>

    <div class="status" style="width:100%;color: #fff;" *ngIf="frozen">
      <a>
        <div fxLayout="column" fxLayoutAlign='end end' style="float: right;">
          Taxonomy is Frozen
        </div>
      </a>
    </div>
    <!-- topnav actions -->
    <div class="top-nav__menu">
      <div fxLayout="row">
        <!-- User display picture -->
        <a class="top-nav__menu-link">
          <div fxLayout="column" fxLayoutAlign='center center'>
            <img [src]="userDisplayPicture" class="avatar">
          </div>
        </a>
        <!-- User role -->
        <a class="top-nav__menu-user">
          <div fxLayout="column" fxLayoutAlign='left'>
            <p>{{userDisplayName | uppercase}}</p>
            <p class="role" *ngFor="let role of userRole">{{role.name|uppercase}}</p>
          </div>
        </a>
        <!-- Dropdown action buttons -->
        <div class="menu-drop">
          <mat-icon mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            arrow_drop_down
          </mat-icon>
          <mat-menu #menu="matMenu" class="">
            <button mat-menu-item (click)="resetTicket()" *ngIf="isAdmin">
              <mat-icon>undo</mat-icon>
              <span class="menu-item">Reset Tickets</span>
            </button>
            <button mat-menu-item (click)="logOut()">
              <mat-icon>power_settings_new</mat-icon>
              <span class="menu-item">Log Out</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="bottomSheet">
  <div fxLayout="column">
    {{currentVersion|json}}
  </div>
</div> -->