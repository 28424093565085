<div fxLayout="column" class="tabContainer">
    <div fxLayout="row">
        <div fxFlex="50">
            <div>
                <mat-label>Attribute Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="name">
                </mat-form-field>
            </div>
            <div>
                <mat-label>Description</mat-label>
                <mat-form-field appearance="outline">
                    <textarea matInput placeholder="" rows="4" [(ngModel)]="description"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <div>
            <button mat-raised-button (click)="save()" class='buttonSuccess'>Add</button>
        </div>
    </div>
</div>
  