<div class="mainContainer" fxLayout="column" *ngIf="selectedNodeData.attributes">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Edit Relation ({{selectedNodeData.attributes.category.data.attributes.name}})</span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button (click)="save()" class='buttonSuccess'>Save as Draft</button>
            </div>
            <div>
                <button mat-raised-button (click)="cancel()" class="buttonCancel">Cancel</button>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" *ngIf="!loading">
            <div fxFlex="50">
                <div>
                    <mat-label>Current Node Name</mat-label>
                    <!-- <button mat-icon-button matTooltip="View Hierarchy" matTooltipPosition="above" (click)="viewHierarchy(selectedNodeData)" ><mat-icon>account_tree</mat-icon></button> -->
                    <mat-form-field appearance="outline">
                        <input matInput value="{{selectedNodeData.attributes.category.data.attributes.name}} (Level {{selectedNodeData.attributes.level}})" readonly>
                    </mat-form-field>
                </div>
                <div>
                    <div>
                        <mat-label>Parent Node Level</mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select [(ngModel)]="selectedLevel">
                              <mat-option>Select Level</mat-option>
                              <mat-option value="1">Level 1</mat-option>
                              <mat-option value="2">Level 2</mat-option>
                              <mat-option value="3">Level 3</mat-option>
                              <mat-option value="4">Level 4</mat-option>
                              <mat-option value="5">Level 5</mat-option>
                              <mat-option value="6">Level 6</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <form class="example-form" [formGroup]="formGroup" style="width: 100%;">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <mat-icon matPrefix>search</mat-icon>
                          <input type="text"
                                id="searchKey"
                                placeholder="Search"
                                matInput
                                formControlName="myControl"
                                [matAutocomplete]="auto" (keydown)="enterSearch($event)">        
                          <mat-autocomplete  #auto="matAutocomplete" style="width: 90%;">
                            <mat-option (click)="selectOption(option)" style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of options" [value]="option.name">
                              {{option.attributes.name}}
                              <button *ngIf="option.attributes.category_code" button style="background: #E6E8F0; border: none; height: 20px;border-radius: 4px;">{{option.attributes.category_code}}</button>
                              <button style="background: #52BD94; border: none; height: 20px;border-radius: 4px;" *ngIf="option.type=='categories'">(Level {{option.attributes.level}})</button>
                              <br />
                              <span button style="background: transparent; border: none; height: 20px;border-radius: 4px;">{{option.attributes.path}}</span>
                            </mat-option>
                            <!-- <mat-option *ngIf="options.length==0">No data present.</mat-option> -->
                          </mat-autocomplete>
                        </mat-form-field>
                      </form>
                </div>
                <div *ngIf="searchedNode">
                    <mat-label>Seleted New Node</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput value="{{searchedNode.attributes.category.data.attributes.name}} (Level {{searchedNode.attributes.level}})" readonly>
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketID">
                    </mat-form-field>
                </div>
                
            </div>
            <div fxFlex="50">
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
    </div>
</div>
  