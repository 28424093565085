import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VersionService } from 'src/app/services/version.service';
import { HierarchyInfoComponent } from '../../_shared/hierarchy-info/hierarchy-info.component';
import { TreeDialogComponent } from '../../_shared/tree-dialog/tree-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-draft-view',
  templateUrl: './draft-view.component.html',
  styleUrls: ['./draft-view.component.scss']
})
export class DraftViewComponent implements OnInit {
  // ngOnInit(): void {
  //   this.id = this.route.snapshot.paramMap.get('id');
  //// console.log(this.id);
  // }

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private infoService: InfoService,
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    private sharedDataService: SharedDataService,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private sharedService: SharedDataService,
    private errorHandler: ErrorHandlerService
  ) {

    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'doc',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/doc.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'send',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/send.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'export',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/export.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );

  }
  // tslint:disable-next-line: variable-name
  subscription: Subscription;
  formGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = true;
  formSubmitted = false;
  draftId = 0;
  editId = 0;
  userData: any = null;
  userDisplayName: any = null;
  userDisplayPicture: any = 'assets/images/user.jpg';
  suggestions;
  data;
  page = 0;
  pageSize = 100;
  totalItems = 0;
  editedNode;
  attachment;
  currentUserDisplayName = '';
  allDraftList = [];
  draftList = [];
  draftData;
  nodeCheckList: any = [];
  nodeCheckCount = 0;
  parentNodeData: any = [];
  previousParentNodeData: any = {
    attributes: null
  };
  attributeData: any = [];
  requestType: any = null;
  fileList: any = [];
  commentList: any = [];
  comment: any = null;
  userId = 1;
  commentLoader = true;
  addedAttributeList: any = [];
  addedAttributeListNew: any = [];
  addedAttributeListOld: any = [];
  currentVersion;
  frozen = false;
  previous = false;
  ticketId: any = null;
  redirectedFromList = true;
  attributeName: any = null;
  childNodesData = [];
  taskState = null;
  selectedHierarchy = null;
  impact = 0;
  totalDraftCount = 0;
  uomName = '';
  codesetName = '';
  hierarchy = null;
  public selectedDraftIds: any;
  public selectedDraftId: any;
  basepath = null;
  draftVersion = null;
  draftHierarchy = null;

  ngOnInit(): void {
    this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.selectedDraftIds = this.route.snapshot.paramMap.get('id').split('&');
    this.selectedDraftId = this.selectedDraftIds[0];
    this.draftList = this.selectedDraftIds;
 // console.log(this.userData);
    this.userId = this.userData.id;
    if (this.userData.data){
      this.currentUserDisplayName = this.userData.username;
   // console.log(this.currentUserDisplayName);
    }
    if (this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
      this.currentVersion = this.currentVersion.attributes.name;
    }
 // console.log(this.selectedDraftId);
    this.taskState = this.sharedDataService.draftNodeStatus;
    this.getOneTicket(this.selectedDraftId);
    this.totalItems = this.selectedDraftIds.length;
    this.totalDraftCount = this.selectedDraftIds.length;
    this.sharedDataService.draftListRedirection = false;
  }

  refresh = () => {
 // console.log('reload');
    window.location.reload();
  }

  moveToDraftList = () => {
 // console.log('Going to Draft');
    this.sharedDataService.draftListSource = 'draft';
    this.router.navigate(['/draft-list']);
  }

  reloadAllDraft = () => {
    this.selectedDraftId = this.nodeService.selectedDraft;
    this.nodeService.selectedDraft = null;
    this.allDraftList.forEach(e => {
        if (e.id === this.selectedDraftId){
       // console.log('Id matched');
        } else {
       // console.log('Id not matched');
        }
    });
  }

  getDraftCount = () => {
    this.nodeService.getDraftCount('draft').subscribe({
      next: res => {
     // console.log(res);
        this.totalItems = res.total_count;
      }
    });
  }

  viewHierarchy = (category) => {
 // console.log(category);
    let categoryName = null;
    if (category){
      categoryName = category.attributes.category.data.attributes.name;
    }
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-hierarchy';
    dialogConfig.width = '60vw';
    dialogConfig.data = {categoryId: category.attributes.category_id, categoryName};
    const modalDialog = this.dialog.open(
      HierarchyInfoComponent, dialogConfig
      );
  }

  getOneTicket = (id) => {
    this.getInfo(id);
  }

  getDraftList = () => {
    this.draftList = [];
    this.allDraftList = [];
 // console.log(this.draftList);
 // console.log(this.allDraftList);
    this.totalItems = 0;
    this.page = 0;
    this.nodeService.getDraftList(this.page, this.pageSize, 'draft').subscribe({
      next: res => {
        if (res) {
          this.allDraftList = [];
          this.allDraftList = res.results.data;
       // console.log(this.allDraftList);
          this.draftList = [];
          this.allDraftList.forEach(e => {
            if (e.attributes.workflow_state === 'draft')
            {
              this.draftList.push(e);
           // console.log(e.attributes.workflow_state);
            }
          });
       // console.log(this.draftList);
          if (this.redirectedFromList && this.totalItems > 0){
            this.totalItems = 1;
          } else{
            this.totalItems = this.draftList.length;
          }
          if (this.draftList.length > 0){
            if (this.selectedDraftId){
              this.getInfo(this.selectedDraftId);
            } else {
              this.getInfo(this.draftList[0].id);
            }
          } else {
            this.loading = false;
          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  getFiles = (id: any) => {
    this.nodeService.getDraftFiles(id).subscribe({next: res => {
      if (res) {
     // console.log(res);
        this.fileList = res.data;
      }
    },
    error: (HttpResponse: HttpErrorResponse) => {
      this.loading = false;
   // console.log(HttpResponse.error.detail);
      const error = HttpResponse.error.detail;
      const errorList = [];
      // tslint:disable-next-line: forin
      for (const p in error) {
     // console.log (p, error[p]);
        const x = {
          title: p,
          value: error[p]
        };
        errorList.push(x);
      }
   // console.log(errorList);
      errorList.forEach(element => {
        this.snackBar.open(element.title.toUpperCase() + ' - ' + element.value, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });

      });
      }
    });
  }
  
  getInfo = (id: any) => {
    this.getFiles(id);
 // console.log(id);
    this.nodeService.getDraft(id).subscribe({
      next: res => {
        if (res) {
          this.codesetName = '';
          this.uomName = '';
          this.draftData = res.data;
       // console.log(this.draftData);
          this.draftId = this.draftData.id;
          if (this.draftData && this.draftData.attributes.hasOwnProperty('note')){
            this.notes = this.draftData.attributes.note;
          } else {
            this.notes = '';
          }
          this.ticketId = this.draftData.attributes.internal_ticket_id;
       // console.log(this.draftData.attributes.context_data.edit_type);
          this.requestType = this.draftData.attributes.context_data.edit_type;
          this.taskState = this.draftData.attributes.workflow_state;
          this.draftVersion = this.draftData.attributes.context_data.version_id;
          this.draftHierarchy = this.draftData.attributes.context_data.hierarchy_id;
       // console.log(this.taskState);
          if (this.requestType === 'category_addition'){
            // Case 1 - Add Category
         // console.log(this.draftData.attributes.context_data.target_node);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.target_node).subscribe(async res => {
              if (res) {
             // console.log(res);
                this.nodeData = res.data;
                this.hierarchy = this.nodeData.attributes.hierarchy.data.attributes.name;
                this.addedAttributeList = this.draftData.attributes.context_data.attributes;
                this.convertToName(this.addedAttributeList, 2);
             // console.log(this.addedAttributeList);
              }
            });
          } else if (this.requestType === 'category_field_edit'){
            // Case 2 - Edit Category
         // console.log(this.draftData.attributes.context_data.category_id);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.category_id).subscribe(async res => {
              if (res) {
                this.nodeData = res.data;
             // console.log(this.nodeData);
                this.hierarchy = this.nodeData.attributes.hierarchy.data.attributes.name;
              }
            });
          } else if (this.requestType === 'attribute_addition'){
            // Case 3 - Add Attribute
            if (this.draftData.attributes.context_data.attribute_id){
              this.infoService.getAttributeInfo(this.draftData.attributes.context_data.attribute_id).subscribe(async res => {
                if (res) {
               // console.log(res);
                  this.attributeName = res.data.attributes.name;
                }
              });
            }
            if (this.draftData.attributes.context_data.attribute_name === ''){
              this.infoService.getAttributeInfo(this.draftData.attributes.context_data.attribute_id).subscribe(async res => {
                if (res) {
               // console.log(res);
                  this.attributeName = res.data.attributes.name;
                }
              });
            } else {
              this.attributeName = this.draftData.attributes.context_data.attribute_name;
            }
         // console.log(this.draftData.attributes.context_data.category_id);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.category_id).subscribe(async res => {
              if (res) {
                this.parentNodeData = res.data;
             // console.log(this.data);
                this.hierarchy = this.parentNodeData.attributes.hierarchy.data.attributes.name;
              }
            });
         // console.log(this.parentNodeData);
          } else if (this.requestType === 'attribute_field_edit'){
            // Case 4 - Edit Attribute
            if (this.draftData.attributes.context_data.attribute_id){
              this.infoService.getAttributeInfo(this.draftData.attributes.context_data.attribute_id).subscribe(async res => {
                if (res) {
               // console.log(res);
                  this.attributeName = res.data.attributes.name;
                }
              });
            }
         // console.log(this.draftData.attributes.context_data.category_id);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.category_id).subscribe(async res => {
              if (res) {
             // console.log(res);
                this.parentNodeData = res.data;
             // console.log(this.parentNodeData);
                this.hierarchy = this.parentNodeData.attributes.hierarchy.data.attributes.name;
              }
            });
            if (this.draftData.attributes.context_data.field_name === 'value_codeset'){
              if (this.draftData.attributes.context_data.new_value === null){
                this.codesetName = 'None';
              } else {
                this.sharedService.getValidValuesDetails(this.draftData.attributes.context_data.new_value).subscribe(res => {
                  if (res) {
                 // console.log(res.data);
                    this.codesetName = res.data.attributes.name;
                  }
                });
              }
              if (this.draftData.attributes.context_data.original_value === null){
                this.draftData.codesetName = 'None';
              } else {
                this.sharedService.getValidValuesDetails(this.draftData.attributes.context_data.original_value).subscribe(res => {
                  if (res) {
                 // console.log(res.data);
                    this.draftData.codesetName = res.data.attributes.name;
                  }
                });
              }
            } else if (this.draftData.attributes.context_data.field_name === 'uom_codeset'){
              if (this.draftData.attributes.context_data.new_value == null){
                this.uomName = 'None';
              } else {
                this.sharedService.getUomDetails(this.draftData.attributes.context_data.new_value).subscribe(res => {
                  if (res) {
                 // console.log(res.data);
                    this.uomName = res.data.attributes.name;
                  }
                });
              }
              if (this.draftData.attributes.context_data.original_value == null){
                this.uomName = 'None';
              } else {
                this.sharedService.getUomDetails(this.draftData.attributes.context_data.original_value).subscribe(res => {
                  if (res) {
                 // console.log(res.data);
                    this.draftData.uomName = res.data.attributes.name;
                  }
                });
              }
            }
         // console.log(this.draftData);
          } else if (this.requestType === 'category_relation_add'){
            // Case 5 - Add Relation
            this.childNodesData = [];
         // console.log('Category Addition Multiple');
         // console.log(this.draftData);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.target_node).subscribe(res => {
              if (res) {
                this.parentNodeData = res.data;
                this.hierarchy = this.parentNodeData.attributes.hierarchy.data.attributes.name;

             // console.log(this.parentNodeData);
                // this.basepath = res.data.attribtues.path;
              }
            });
            let childIDList = [];
            this.draftData.attributes.context_data.relation_to_add.forEach( e => {
              const item = {
                id: e.child_id,
                name: '',
                level: e.level,
                parent: e.parent_id
              };
              childIDList.push(e.child_id);
              this.childNodesData.push(item);
            });
         // console.log(childIDList);
         // console.log(this.childNodesData);
            const ids = {
              category_ids: childIDList
            };
            this.hierarchyService.getNodeData(ids).subscribe(res => {
              if (res) {
             // console.log(res);
                res.data.forEach(e => {
                  const index = this.childNodesData.findIndex(x => x.id === e.id);
               // console.log(e);
               // console.log('Matching Index ' + index);
                  if (index >= 0){
                    this.childNodesData[index].name = e.attributes.name;
                  }
                });
              }
            });
          }  else if (this.requestType === 'category_relation_edit'){
            // Case 6 - Edit Relation
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.node_to_move).subscribe(async res => {
              if (res) {
                this.previousParentNodeData = res.data;
                this.hierarchy = this.nodeData.attributes.hierarchy.data.attributes.name;
             // console.log(this.previousParentNodeData);
              }
            });
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.target_node).subscribe(async res => {
              if (res) {
                this.parentNodeData = res.data;
             // console.log(this.parentNodeData);
              }
            });
          } else if (this.requestType === 'category_detach'){
            // Case 7 - Edit Relation
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.node_to_detach).subscribe(async res => {
              if (res) {
                this.nodeData = res.data;
                this.hierarchy = this.nodeData.attributes.hierarchy.data.attributes.name;
             // console.log(this.nodeData);
              }
            });
          } else if (this.requestType === 'attribute_sequence_edit'){
            // Case 7 - Edit Relation
            if (this.draftData.attributes.context_data.attribute_id){
              this.infoService.getAttributeInfo(this.draftData.attributes.context_data.attribute_id).subscribe(async res => {
                if (res) {
               // console.log(res);
                  this.attributeName = res.data.attributes.name;
                }
              });
            }
         // console.log(this.draftData.attributes.context_data.category_id);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.draftData.attributes.context_data.category_id).subscribe(async res => {
              if (res) {
                this.nodeData = res.data;
                this.hierarchy = this.nodeData.attributes.hierarchy.data.attributes.name;
             // console.log(this.nodeData);
                this.addedAttributeListNew = this.draftData.attributes.context_data.new_value;
                this.addedAttributeListOld = this.draftData.attributes.context_data.original_value;
                this.convertToName(this.addedAttributeListNew, 1);
                this.convertToName(this.addedAttributeListOld, 0);
              }
            });
          } else {
            this.nodeData = null;
          }
          // setTimeout(function(){
          //   this.loading = false;
          // },2000);
        }
        this.loading = false;
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  resolveAfter2Seconds = (x) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, 2000);
    });
  }

  compareObject = (oldAttributeList, newAttributeList) => {
    newAttributeList.forEach(e => {
      // console.log(e.name);
      e.different = [];
    });
    oldAttributeList.forEach(e => {
      // console.log(e.name);
      e.different = [];
    });
    // console.log(oldAttributeList);
    oldAttributeList.forEach((_old, index) => {
      newAttributeList.forEach((_new, index) => {
        if (_old.attribute_id === _new.attribute_id && _old.filter_sequence !== _new.filter_sequence){
          _new.different.push('reordered');
        }
      });
    });

    // To check deleted Attributes
    const deleted = oldAttributeList.filter(o1 => !newAttributeList.some(o2 => o1.attribute_id === o2.attribute_id));
    // console.log(deleted);
    deleted.forEach(diff => {
      // console.log(diff);
      const index = oldAttributeList.indexOf(diff);
      // console.log(index);
      if (index > -1){
        oldAttributeList[index].different.push('deleted');
      }
    });
    // To check newly added Attributes
    const newAdded = newAttributeList.filter(o1 => !oldAttributeList.some(o2 => o1.attribute_id === o2.attribute_id));
    // console.log(newAdded);
    newAdded.forEach(diff1 => {
      // console.log(diff1);
      const index = newAttributeList.indexOf(diff1);
      // console.log(index);
      if (index > -1){
        newAttributeList[index].different.push('new');
      }
    });
    let referenceOld = [];
    let referenceNew = [];
    // Compare Remaining Keys
    newAttributeList.forEach((e, i) => {
      if (!e.different.includes('new') && !e.different.includes('deleted')) {
        referenceNew.push(e);
      }
    });
    oldAttributeList.forEach((e, i) => {
      if (!e.different.includes('new') && !e.different.includes('deleted')) {
        referenceOld.push(e);
      }
    });

    oldAttributeList.forEach((_old, index) => {
      newAttributeList.forEach((_new, index) => {
        if (_old.attribute_id === _new.attribute_id && _old.attribute_sequence !== _new.attribute_sequence){
          _new.different.push('seq_reordered');
        }
      });
    });
    for (let i = 0; i < referenceOld.length; i++){
      for (let j = 0; j < referenceNew.length; j++){
        if (referenceOld[i].attribute_id === referenceNew[j].attribute_id){
          console.log(referenceOld[i].attribute_id);
          console.log(referenceNew[j].attribute_id);
          console.log('Checking is active');
          console.log(referenceOld[i].is_active);
          console.log(referenceNew[j].is_active);
          console.log(referenceOld[i].is_active === referenceNew[j].is_active);
          // console.log(referenceNew[j].different);
          if (referenceOld[i].is_active !== referenceNew[j].is_active){
            {
              newAttributeList[j].different.push('is_active');
            }
          }
          if (referenceOld[i].is_closed !== referenceNew[j].is_closed){
            {
              newAttributeList[j].different.push('is_closed');
            }
          }
          if (referenceOld[i].is_differentiator !== referenceNew[j].is_differentiator){
            {
              newAttributeList[j].different.push('is_differentiator');
            }
          }
          if (referenceOld[i].is_filter !== referenceNew[j].is_filter){
            {
              newAttributeList[j].different.push('is_filter');
            }
          }
          if (referenceOld[i].is_multi !== referenceNew[j].is_multi){
            {
              newAttributeList[j].different.push('is_multi');
            }
          }
          if (referenceOld[i].is_required !== referenceNew[j].is_required){
            {
              newAttributeList[j].different.push('is_required');
            }
          }
          // For minimum Value
          if (referenceOld[i].min_value === null || referenceOld[i].min_value.length === 0){
            referenceOld[i].min_value = '';
          }
          if (referenceNew[j].min_value === null || referenceNew[j].min_value.length === 0){
            referenceNew[j].min_value = '';
          }
          // console.log(referenceOld[i].attribute_id);
          // console.log(referenceNew[j].attribute_id);
          // console.log(referenceOld[i].min_value === referenceNew[j].min_value);
          // console.log(referenceOld[i].min_value !== referenceNew[j].min_value);
          if (referenceOld[i].min_value !== referenceNew[j].min_value){
            {
              newAttributeList[j].different.push('min_value');
            }
          }
          if (referenceOld[i].uom_codeset_id !== referenceNew[j].uom_codeset_id){
            {
              newAttributeList[j].different.push('uom_codeset_id');
            }
          }
          if (referenceOld[i].value_codeset_id !== referenceNew[j].value_codeset_id){
            {
              newAttributeList[j].different.push('value_codeset_id');
            }
          }
        }
      }
    }

  }

  convertToName = (list: any, listType: number) => {
    let nameList = [];
    const attributeList = [];
    if (list.length > 0){
      list.forEach(e => {
        attributeList.push(e.attribute_id);
      });
      const reqParam = {
        attribute_ids: attributeList
      };
   // console.log(reqParam);
      if (list.length > 0) {
        this.hierarchyService.getAttributeData(reqParam).subscribe(async res => {
          if (res) {
            // await res;
         // console.log(res);
            nameList = res.data;
            this.loading = false;
            await this.pushNames(nameList, listType);
          }
        });
      }
    }
    this.compareObject(this.addedAttributeListOld, this.addedAttributeListNew);
  }

  getAttributeInfo = (nodeId: any, attributeId: any) => {
    this.nodeService.attributeDetails(nodeId, attributeId).subscribe(res => {
      if (res) {
     // console.log(res);
        return res;
      }
    });
  }

  pushNames = (nameList, listType) => {
    if (listType === 1){
   // console.log('New attribute list');
      nameList.forEach((e) => {
     // console.log(e);
        const index = this.addedAttributeListNew.findIndex(x => x.attribute_id === e.id);
     // console.log(this.addedAttributeListNew[index]);
        if(this.addedAttributeListNew[index].uom_codeset_id){
          this.sharedService.getUomDetails(this.addedAttributeListNew[index].uom_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListNew[index].uomName = res.data.attributes.name;
            }
          });
        } else{
          this.addedAttributeListNew[index].uomName = null;
        }
        if(this.addedAttributeListNew[index].value_codeset_id){
          this.sharedService.getValidValuesDetails(this.addedAttributeListNew[index].value_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListNew[index].validValueName = res.data.attributes.name;
            }
          });
        } else{
          this.addedAttributeListNew[index].validValueName = null;
        }
        this.addedAttributeListNew[index].name = e.attributes.name;
      });
    } else if (listType === 0){
   // console.log('Old attribute list');
      nameList.forEach((e, n) => {
        const index = this.addedAttributeListOld.findIndex(x => x.attribute_id === e.id);
     // console.log(this.addedAttributeListOld[index]);
        if(this.addedAttributeListOld[index].uom_codeset_id){
          this.sharedService.getUomDetails(this.addedAttributeListOld[index].uom_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListOld[index].uomName = res.data.attributes.name;
            }
          });
        } else {
          this.addedAttributeListOld[index].uomName = null;
        }
        if(this.addedAttributeListOld[index].value_codeset_id){
          this.sharedService.getValidValuesDetails(this.addedAttributeListOld[index].value_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListOld[index].validValueName = res.data.attributes.name;
            }
          });
        } else {
          this.addedAttributeListOld[index].validValueName = null;
        }
     // console.log(this.addedAttributeListOld[0]);
        this.addedAttributeListOld[index].name = e.attributes.name;
      });
    } else if (listType === 2){
   // console.log('Newly added attribute list');
      nameList.forEach((e, n) => {
        const index = this.addedAttributeList.findIndex(x => x.attribute_id === e.id);
     // console.log(index);
     // console.log(e);
     // console.log(this.addedAttributeList[0]);
        this.addedAttributeList[index].name = e.attributes.name;
      });
    }
 // console.log(this.addedAttributeList);
  }

  nodeCheck = () => {
    const id = null;
    // this.infoService.getNodeData(id).subscribe(res => {
    //   if (res) {
    //     if (res.data){
    //       this.nodeCheckList = res.data.result;
    //     }
    //   }
    // });
  }

  getNodeInfo = (nodeId: any) => {
    this.nodeCheckCount = 1;
    this.nodeCheckList.forEach(e => {
      if (e.id === nodeId){
        this.nodeCheckCount++;
      }
    });
 // console.log('Node Check - ' + this.nodeCheckCount);
  }

  getImpact = (draftId: any) => {
    this.nodeService.getImpact(draftId).subscribe({
      next: (res: any) => {
        if (res) {
       // console.log(res);
          this.impact = res.skus_impacted;
          if(this.impact === null) {
            this.snackBar.open('No SKUs impacted.', 'OK', {
              duration: 4000,
              panelClass: ['error-snackbar']
            });
          }
          return res;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.formSubmitted = false;
     // console.log(HttpResponse);
        this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  changePage = (dir) => {
    this.impact = 0;
 // console.log(dir);
    this.loading = true;
    this.commentLoader = true;
    if (dir === 'n' && this.page < this.totalItems){
      this.page++;
   // console.log(this.page);
      const newId = this.draftList[this.page];
   // console.log('New ID' + newId);
      this.draftId = newId;
      this.getInfo(newId);
    } else if (dir === 'p' && this.page > 0){
      this.page--;
   // console.log(this.page);
      const newId = this.draftList[this.page];
   // console.log('Previous ID' + newId);
      this.draftId = newId;
      this.getInfo(newId);
    }
 // console.log('Previous ID' + this.draftId);
  }

  getIcon = (name) => {
    const arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'doc' || arr[1] === 'docx'){
      return 'doc';
    } else if (arr[1] === 'pdf'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }

  goToNode = (id) => {
 // console.log(id);
    // this.homeService.selectedNodeId = id;
    // this.homeService.nodeSelection = 1;
    // this.sharedDataService.changeSideNavStatus(1);
    // this.router.navigate(['/home']);
  }

  download = (url) => {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  openAttributeOrder(id): void {
 // console.log(id);
    const reqType = this.requestType;
    const newList = this.addedAttributeListNew;
    const oldList = this.addedAttributeListOld;
    const newlyAdded = this.addedAttributeList;
    this.infoService.nodeId = id;
    // let attributeList
    if (this.requestType === 'attribute_sequence_edit'){
      const dialogRef = this.dialog.open(DialogDraftViewSingle, {
        width: '98vw',
        height: '80vh',
        data: {id, reqType, newList, oldList, newlyAdded}
      });
      dialogRef.afterClosed().subscribe(result => {
     // console.log(result);
        // if (result)
        // {
        //   this.snackBar.open('Marked Successfully', '', {
        //     duration: 2000,
        //   });
        // } else {
        // }
      });
    } else{
      const dialogRef = this.dialog.open(DialogDraftViewSingle, {
        width: '98vw',
        height: '80vh',
        data: {id, reqType, newList, oldList, newlyAdded}
      });
      dialogRef.afterClosed().subscribe(result => {
     // console.log(result);
        // if (result)
        // {
        //   this.snackBar.open('Marked Successfully', '', {
        //     duration: 2000,
        //   });
        // } else {
        // }
      });
    }
  }

  viewRelations = () => {
 // console.log(this.parentNodeData);
 // console.log(this.draftData);
 // console.log(this.childNodesData);
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '90vh';
    dialogConfig.width = '95vw';
    dialogConfig.data = {parentData : this.parentNodeData, draftData : this.draftData, childData: this.childNodesData};
    const modalDialog = this.dialog.open(
      TreeDialogComponent, dialogConfig
      );
  }

  openDialog(id): void {
 // console.log(id);
    this.infoService.nodeId = id;
    const dialogRef = this.dialog.open(DialogNodeInfoDraftView, {
      width: '98vw',
      data: {id, version : this.draftVersion, hierarchy: this.draftHierarchy}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
    });
  }
}

// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './nodeInfo.html',
  styleUrls: ['./draft-view.component.scss']
})
  export class DialogNodeInfoDraftView {
  constructor(
    public dialog: MatDialog,
    private infoService: InfoService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    public dialogRef: MatDialogRef<DialogNodeInfoDraftView>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'filters',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/filters.svg'
      )
    );
   }
  tab1Data: any = null;
  attribute: any = null;
  productType: any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  isLeaf = false;
  leafCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  // Edit ------
  ngOnInit(): void {
    this.selectedNodeId = this.infoService.nodeId;
 // console.log(this.selectedNodeId);
    if (this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    }
  }

  close = () => {
    this.dialogRef.close({data: null});
  }


  getLeafNodeAttributes = (i: any) => {
    this.homeService.getLeafNodeAttributes(i).subscribe({
      next: res => {
        if (res) {
          this.attributes = res.results.data;
          this.tabLoader = false;
       // console.log(this.attributes);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  nodeInfo = (id) => {
    this.infoService.getDraftNodeData(this.data.version, this.data.hierarchy, id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.tab1Data = res.data;
        this.isLeaf = this.tab1Data.attributes.is_leaf;
     // console.log('IS LEAF ' + this.isLeaf);
        this.loading = false;
        this.tabLoader = false;
      }
    });
  }

  getLeafNodeList = (id) => {
    this.homeService.getLeafNodes(id, this.leafCounter.page, this.leafCounter.pageSize).subscribe(res => {
      if (res) {
        this.leafNodes = res;
     // console.log(res);

        this.tabLoader = false;
      }
    });
  }

  tabChanged = (e) => {
    this.tabLoader = true;
    this.tabIndex = e.index;
 // console.log(this.tabIndex);
    if (this.tabIndex === 0){
      this.nodeInfo(this.selectedNodeId);
    } else if (this.tabIndex === 1){
      this.getLeafNodeAttributes(this.selectedNodeId);
      // this.getLeafNodeList(this.selectedNodeId);
    } else if (this.tabIndex === 2){
      this.getLeafNodeAttributes(this.selectedNodeId);
    }
  }
}

// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './attribute.html',
  styleUrls: ['./draft-view.component.scss']
  })
  export class DialogDraftViewSingle {

  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<DialogDraftViewSingle>,
    private fb: FormBuilder,
    private sharedDataService: SharedDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.iconRegistry.addSvgIcon(
        'edit',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'filter',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/icon.svg'
        )
      );
    }
    selectedNodeId = 0;
    loading = true;
    nodeData;
    tabLoader;
    attributes;
    leafNodes;
    tabIndex = 0;
    page = 0;
    pageSize = 10;
    totalItems = 0;
    editedNode;
    attachment;
    selectedAttributes: any = [];
    formGroupAttribute: FormGroup;
    searchedAttribute;
    attributeList: any = [];
    filteringAttributeList: any = [];
    activeTabIndex = 0;
    postAttributeObject: any = [];
    postAttributeFilterObject: any = [];
    addedAttributeList: any = [];
    type = null;
    requestType = this.data.reqType;
    newAttributeList: any = [];
    oldAttributeList: any = [];
    namedList: any = [];
    localLoading = false;
    // Edit ------
    ngOnInit(): void {
      this.selectedAttributes = [];
      this.attributeList = [];
      this.filteringAttributeList = [];
      this.postAttributeObject = [];
      this.postAttributeFilterObject = [];
      this.requestType = this.data.reqType;
   // console.log(this.data);
      if (this.data.reqType === 'attribute_sequence_edit' ){
     // console.log('Editing');
        this.oldAttributeList = this.data.oldList;
        this.newAttributeList = this.data.newList;
        this.localLoading = true;
      } else if (this.data.reqType === 'category_addition' ){
     // console.log('Addition');
        this.newAttributeList = this.data.newlyAdded;
      } else {
     // console.log('Addition');
      }
      // this.nodeInfo();
      // this.initForm();
    }

    nodeInfo = () => {
      this.nodeService.getAttributeList().subscribe(res => {
        if (res) {
          // console.log(res);
          this.attributeList = res.results.data;
          // console.log(this.attributeList);
          this.tabLoader = false;
        }
      });
    }

    close = () => {
      this.dialogRef.close({data: null});
    }
}
