
<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/draft-list'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Review</span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button [disabled]="totalItems==0 || page == 0" (click)="changePage('p')" class="buttonCancel"><mat-icon>west</mat-icon><span style="margin-left: 10px;">Prev</span></button>
            </div>
            <div *ngIf="totalItems > 0">
                <button mat-icon-button >{{page+1}}/{{totalItems}}</button>
            </div>
            <div>
                <button mat-raised-button [disabled]="totalItems==0 || page == totalItems-1" (click)="changePage('n')" class="buttonCancel"><span style="margin-right: 10px;">Next</span><mat-icon>east</mat-icon></button>
            </div>
            <div>
                <button mat-raised-button (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div>
                <button mat-raised-button [disabled]="totalItems==0 || taskState != 'waiting for approval'" (click)="postToReview()" class='buttonSuccess'>Approve Request</button>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="tabContainer" *ngIf="previous">
        <span>
            You have selected an older Version.
        </span>
    </div>
    <div class="tabContainer" *ngIf="!previous">
        <app-loader *ngIf="loading"></app-loader>
        <div fxLayout="row">
            <div fxFlex="50" fxLayoutAlign="start center"><h3 *ngIf="!loading">You have {{totalItems}} unfinished requests to complete</h3></div>
            <div fxFlex="40" fxLayout="row" fxLayoutAlign="end start" style="width:100%">
                <div *ngIf="impact>0" style="margin:0px 10px;padding: 10px; background: lightgray"><span>{{impact}} SKUs Impacted</span></div>
                <div *ngIf="totalItems > 0" fxLayoutAlign="end start">
                    <button mat-raised-button class='buttonCancel' (click)="getImpact(draftId)">Impact Analysis</button>
                </div>
            </div>
            <div fxFlex="10" fxLayoutAlign="start start" style="margin-left:10px">
                <button mat-raised-button class='buttonCancel' (click)="moveToDraftList()">
                    Draft List <mat-icon svgIcon="draft-list"></mat-icon>
                </button>
            </div>
        </div>
        <div fxLayout="row" *ngIf="nodeData && !loading  && requestType == 'category_addition'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Add Category</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(nodeData.attributes.category_id)" [matTooltip]="nodeData.attributes.path">{{nodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{nodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(nodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>

                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="nodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing </div>
                            <div class="name">
                                <div>
                                    {{nodeData.attributes.path}}
                                </div>
                            </div>        
                        </div>
                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div class="name">
                                        {{nodeData.attributes.path}} / {{draftData.attributes.context_data.category_name}}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>
        <div fxLayout="row" *ngIf="nodeData && !loading  && requestType == 'category_field_edit'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Edit Request for node</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(nodeData.attributes.category_id)" [matTooltip]="nodeData.attributes.path">{{nodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{nodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(nodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing {{draftData.attributes.context_data.column_name|titlecase}} </div>
                            <div class="name">
                                <span *ngIf="draftData.attributes.context_data">{{draftData.attributes.context_data.original_value}}</span>
                                <span *ngIf="!draftData.attributes.context_data">N/A</span>
                            </div>        
                        </div>
                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div class="name">
                                        {{draftData.attributes.context_data.new_value}}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>

        <div fxLayout="row" *ngIf="previousParentNodeData.attributes && !loading  && requestType == 'category_relation_edit'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Relation Edit Request for</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(previousParentNodeData.attributes.category_id)" [matTooltip]="previousParentNodeData.attributes.path">{{previousParentNodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{previousParentNodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(previousParentNodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="previousParentNodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing Position</div>
                            <div class="name">
                                <span *ngIf="draftData">{{draftData.attributes.context_data.original_value}}</span>
                            </div>        
                        </div>
                        <!-- <div class="items">
                            <div class="title" style="color: #FFA600;">Existing {{draftData.attributes.context_data.column_name|titlecase}} </div>
                            <div class="name">
                                <span *ngIf="previousParentNodeData.attributes.category.data.attributes.name">{{previousParentNodeData.attributes.category.data.attributes.name}}</span>
                                <span *ngIf="!previousParentNodeData.attributes.category.data.attributes.name">N/A</span>
                            </div>        
                        </div> -->

                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div class="name">
                                        <span *ngIf="draftData">{{draftData.attributes.context_data.suggested_value}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>

        <div fxLayout="row" *ngIf="parentNodeData.attributes && !loading  && requestType == 'attribute_addition'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Adding attribute to Node</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(parentNodeData.attributes.category_id)" [matTooltip]="parentNodeData.attributes.path">{{parentNodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{parentNodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(parentNodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="parentNodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Attribte Added to Node</div>
                            <div class="name">
                                <div>
                                    {{parentNodeData.attributes.path}}
                                </div>
                            </div>        
                        </div>
                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div class="name">
                                        {{attributeName}}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>
        <div fxLayout="row" *ngIf="parentNodeData.attributes && !loading  && requestType == 'category_relation_add'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Added relation to Node </div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(parentNodeData.attributes.category_id)" [matTooltip]="parentNodeData.attributes.path">{{parentNodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{parentNodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button class="openInNew" (click)="openDialog(parentNodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>

                    </div>

                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="parentNodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing Path </div>
                            <div class="name">
                                <span *ngIf="draftData">{{draftData.attributes.context_data.original_value}}</span>
                            </div>        
                        </div>
                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                            <div fxLayout="row">
                                <div fxFlex="60" class="title" style="color: #52BD94;">Suggestions</div>
                                <div fxFlex="40" fxLayoutAlign="end end">
                                    <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                </div>
                            </div>
                            <div fxLayout="column">
                                <div class="name">
                                    <button mat-raised-button style="cursor: pointer;" (click)="viewRelations()"><span>View Relation</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>

        <div fxLayout="row" *ngIf="nodeData && !loading  && requestType == 'category_detach'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Category Delete request</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(nodeData.attributes.category_id)" [matTooltip]="nodeData.attributes.path">{{nodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{nodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(nodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="parentNodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing </div>
                            <div class="name">
                                <span *ngIf="draftData">{{draftData.attributes.context_data.original_value}}</span>
                            </div>        
                        </div>
                        <!-- <div class="items">
                            <div class="title" style="color: #FFA600;">Existing {{draftData.attributes.context_data.column_name|titlecase}} </div>
                            <div class="name">
                                <span *ngIf="previousParentNodeData.attributes.category.data.attributes.name">{{previousParentNodeData.attributes.category.data.attributes.name}}</span>
                                <span *ngIf="!previousParentNodeData.attributes.category.data.attributes.name">N/A</span>
                            </div>        
                        </div> -->

                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div class="name">
                                        <span *ngIf="draftData">{{draftData.attributes.context_data.suggested_value}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>

        <div fxLayout="row" *ngIf="parentNodeData.attributes && !loading  && requestType == 'attribute_field_edit'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Attribute Field Edit request for</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(parentNodeData.attributes.category_id)" [matTooltip]="parentNodeData.attributes.path">{{parentNodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{parentNodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button (click)="openDialog(parentNodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                        <div>
                            <span>Attribute Name : <b>{{attributeName}}</b></span>
                        </div>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="parentNodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <div class="items">
                            <div class="title" style="color: #FFA600;">Existing {{draftData.attributes.context_data.field_name|stringClearner}} </div>
                            <div class="name">
                                <span *ngIf="draftData.attributes.context_data.field_name === 'value_codeset'">
                                    <span *ngIf="draftData.attributes.context_data.original_value">{{draftData.codesetName}}</span>
                                    <span *ngIf="!draftData.attributes.context_data.original_value">None</span>
                                </span>
                                <span *ngIf="draftData.attributes.context_data.field_name === 'uom_codeset'">
                                    <span *ngIf="draftData.attributes.context_data.original_value">{{draftData.uomName}}</span>
                                    <span *ngIf="!draftData.attributes.context_data.original_value">None</span>
                                </span>
                                <span *ngIf="draftData.attributes.context_data.field_name === 'is_multi'">
                                    <span *ngIf="draftData.attributes.context_data.original_value == true">False</span>
                                    <span *ngIf="draftData.attributes.context_data.original_value == null">N/A</span>
                                    <span *ngIf="draftData.attributes.context_data.original_value == false">True</span>
                                </span>
                                <span *ngIf="draftData.attributes.context_data.field_name != 'uom_codeset' && draftData.attributes.context_data.field_name != 'value_codeset' && draftData.attributes.context_data.field_name !== 'is_multi'">
                                    <span *ngIf="draftData.attributes.context_data.original_value">{{(draftData.attributes.context_data.original_value).toString() | titlecase}}
                                    </span>
                                    <span *ngIf="draftData.attributes.context_data.original_value == null || draftData.attributes.context_data.original_value.length == 0">N/A</span>
                                    <span *ngIf="draftData.attributes.context_data.original_value == false && draftData.attributes.context_data.original_value !== ''">False</span>
                                </span>
                            </div>  
                                 
                        </div>

                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                
                                <div fxLayout="column">
                                    <div class="name">
                                        <span *ngIf="draftData.attributes.context_data.field_name === 'value_codeset'">
                                            <span *ngIf="codesetName !== ''">{{codesetName}}</span>
                                            <span *ngIf="codesetName === ''">{{codesetName}}</span>
                                        </span>
                                        <span *ngIf="draftData.attributes.context_data.field_name === 'uom_codeset'">
                                            <span *ngIf="uomName == ''">None</span>
                                            <span *ngIf="uomName != ''">{{uomName}}</span>
                                        </span>
                                        <span *ngIf="draftData.attributes.context_data.field_name === 'is_multi'">
                                            {{(!draftData.attributes.context_data.new_value).toString() | titlecase}}
                                        </span>
                                        <span *ngIf="draftData.attributes.context_data.field_name != 'uom_codeset' && draftData.attributes.context_data.field_name != 'value_codeset' && draftData.attributes.context_data.field_name != 'is_multi'">
                                            {{(draftData.attributes.context_data.new_value).toString() | titlecase}}
                                        </span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>
        <div fxLayout="row" *ngIf="nodeData && !loading  && requestType == 'attribute_sequence_edit'">
            <mat-card fxFlex="33">
                <div class="header">Entity Type & Actions</div>
                <div class="content" fxLayout="column">
                    <div>
                        <div>Edit Attribute Order</div>
                        <span style="color: #3366FF;text-decoration: none;" (click)="goToNode(nodeData.attributes.category_id)" [matTooltip]="nodeData.attributes.path">{{nodeData.attributes.category.data.attributes.name}}</span>
                        <span>&nbsp;(Level {{nodeData.attributes.level}})</span>
                        
                        <span style="color: #3366FF;">
                            <button class="openInNew" (click)="openDialog(nodeData.attributes.category_id)" mat-icon-button><mat-icon svgIcon="newTab"></mat-icon></button>                            
                        </span>
                    </div>
                    <app-hierarchy-name [draftData]="draftData"></app-hierarchy-name>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Ticket ID</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId != null">
                            {{ticketId}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="ticketId == null">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;">
                        <h4 style="margin-bottom: 4px;">Notes</h4>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="notes">
                            {{notes}}
                        </span>
                        <span style="font-size: 12px; color:#3B3E48;" *ngIf="!notes">
                            N/A
                        </span>
                    </div>
                    <div style="margin-top: 10px;" *ngIf="fileList">
                        <h4 style="margin-bottom: 4px;">Attachments</h4>
                        <div fxLayout="row" style="overflow: auto;">
                            <div style="font-size: 12px; color:#3B3E48;" *ngIf="fileList.length==0">N/A</div>
                            <div *ngFor="let item of fileList" style="padding: 10px;
                            border: 1px solid #EDEFF5; margin: 4px;cursor: pointer;" (click)="download(item.attributes.file_url)">
                                <div style="width: 60px; text-align: center;">
                                    <mat-icon style="width: 40px;height: 40px;" [svgIcon]="getIcon(item.attributes.file_name)"></mat-icon>
                                    <p style="word-break: break-all;">{{item.attributes.file_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33" *ngIf="nodeData">
                <div class="header">Suggestions</div>
                <div class="content">
                    <div style="border: 1px solid #C1C4D6; border-radius: 4px;padding: 10px 20px;">
                        <div class="items">
                            <div class="title" >By {{draftData.attributes.creator.username}}</div>
                        </div>
                        <!-- <div class="items">
                            <div class="title" style="color: #FFA600;">Existing </div>
                            <div class="name">
                                <div>
                                    {{nodeData.attributes.path}}
                                </div>
                            </div>        
                        </div> -->
                        <hr style="border: 1px solid #E6E8F0;" />
                        <div  class="items" fxLayout="column">
                                <div fxLayout="row">
                                    <div fxFlex="60" class="title" style="color: #52BD94;">Suggestion</div>
                                    <div fxFlex="40" fxLayoutAlign="end end">
                                        <button *ngIf="taskState =='waiting for approval'" (click)="deleteDraft(draftId)" class="process-button-red" mat-icon-button>Reject <mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <div fxLayout="column">
                                    <div>
                                        <button mat-raised-button (click)="openAttributeOrder(draftId)">Attributes</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card fxFlex="33">
                <div class="header">Comments</div>
                <app-comments *ngIf="draftId" [selectedDraftId]="draftId"></app-comments>
            </mat-card>
        </div>
    </div>   
</div>
  