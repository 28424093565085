import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-label-dialog',
  templateUrl: './label-dialog.component.html',
  styleUrls: ['./label-dialog.component.scss']
})
export class LabelDialogComponent implements OnInit {
  labelList;
  checked = false;
  select:boolean;
  activeChip;
  module_slug;
  batch_id;
  chipControl = new FormControl(new Set());
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matSnackbar: MatSnackBar,
    public homeService: HomeService,
  ) { }

  ngOnInit(): void {
    this.labelList = this.data.str;
    this.module_slug = this.data.module_slug
    this.batch_id = this.data.batch_id
  }

  toggleChip = (chip: any, addremove_label) => {
    {
      this.chip.add(chip)
      this.addLabellist(this.module_slug,this.batch_id, addremove_label)
    }
  }

  addLabellist(module_slug, batch_id, addremove_label) {
    const keyword = 'dialog'
  //  this.homeService.AddorRemove(module_slug, batch_id, keyword, addremove_label).subscribe({
  //    next: (resp) => {
  //      this.matSnackbar.open(resp.detail, 'OK', {
  //        duration: 4000,
  //      });
   
      
  //    },
  //    error: (HttpResponse: HttpErrorResponse) => {
  //      this.matSnackbar.open(`${HttpResponse.statusText}`, 'OK', {
  //        duration: 4000,
  //      });
  //    },
  //  });
 }

  get chip() { return this.chipControl.value; }

  onSubmit(val){
    // console.log(val)
  }
}
