import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Globals } from '../_globals/endpoints.global';
import { async, Observable, Subject, Subscription, throwError } from 'rxjs';
import { map, catchError, tap, takeUntil } from 'rxjs/operators';
import { BatchTableList } from '../_models/home-table';
import { ENDPOINTS } from '../_globals/endpoints';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VersionService } from './version.service';
import { HierarchyService } from './hierarchy.service';

@Injectable({
  providedIn: 'root',
})

export class HomeService{
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  unsubscribe$: Subject<boolean> = new Subject();
  selectedNodeId = 0;
  nodeSelection = 0;
  currentVersion: any = JSON.parse(localStorage.getItem('currentVersion'));
  selectedVersion = null;
  currentVersionName: any = null;
  versionList: any = [];
  frozen = false;
  subscription: Subscription;
  currentHierarchy: any = null;
  subscriptionHeirarchy: Subscription;
  //
  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private versionService: VersionService,
    private hierarchyService: HierarchyService
    ) {
      this.httpOptions = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.versionService.getUpdatedVersion().subscribe({
        next: version => {
          this.currentVersion = version;
          if (this.currentVersion){
            this.currentVersionName = this.currentVersion.id;
            this.frozen = this.currentVersion.attributes.is_frozen;
          }
        }
      });
      this.hierarchyService.getUpdatedHierarchy().subscribe({
        next: hierarchy => {
        this.currentHierarchy = hierarchy;
     // console.log('Change in H Main');
        }
      });
  }

  getTreeData = (nodeID: any, page?, pageSize?) => {
 // console.log(page);
    let endPoint = this.globals.urlJoin('home', 'tree_data');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
 // console.log('1');
    if (nodeID){
      return this.http.get<any>(endPoint + nodeID, {
        headers: this.httpOptions,
      }).pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    } else {
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          page,
          page_size: pageSize
        }
      })
        .pipe(
          tap(resp => {
            return resp;
          }),
          catchError(error => throwError(error))
        );
    }
  }

  getNodeDataDraftList = (nodeID: any, hID?) => {
 // console.log(hID);
    let endPoint = this.globals.urlJoin('home', 'tree_data');
    if(hID == undefined){
      endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    } else {
      endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + hID);
    }
 // console.log('1');
    if (nodeID){
      return this.http.get<any>(endPoint + nodeID, {
        headers: this.httpOptions,
      }).pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    }
  }

  resetDraft = () => {
    const endPoint = this.globals.urlJoin('draft', 'clear');
    return this.http.delete<any>(endPoint,{
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getNodeData = (nodeId: any, leafId, page, pageSize) => {
 // console.log(page + '-' + pageSize);
    let endPoint = this.globals.urlJoin('home', 'node_details');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    if (leafId > 0){
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          node_id!: nodeId,
          leaf_id!: leafId,
          page: page,
          page_size: pageSize
        }
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
        );
    } else {
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          node_id!: nodeId,
          page: page,
          page_size: pageSize
        }
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
        );
    }
  }

  getAllAttributeList = () => {
    let endPoint = this.globals.urlJoin('attribute', 'list');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    this.currentVersionName = this.currentVersion.attributes.name;

    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    })
    .pipe(
      tap(resp => {
          return resp;
      }),
      catchError(error => throwError(error))
    );
  }

  getAttributeList = (id: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'selectiveList');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    return this.http.get<any>(endPoint + id + '/attributes/', {
      headers: this.httpOptions,
    })
    .pipe(
      tap(resp => {
          return resp;
      }),
      catchError(error => throwError(error))
    );
  }

  getSearchSuggestions = (searchKey: any, depths: any, attribute: any) => {
    let endPoint = this.globals.urlJoin('home', 'search_suggestion');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    var x;
    if (depths.length > 0) {
      depths.forEach(e => {
        x = 'depth:' + e;
      });
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          search: searchKey,
          is_attribute: attribute,
          depth: depths
        }
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
        );
    } else if (depths.length == 0){
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          search: searchKey,
          is_attribute: attribute,
        }
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
        );
    }

  }
  getSearchResults = (searchKey: any, depth: any) => {
 // console.log(searchKey);
    
    let endPoint = this.globals.urlJoin('home', 'search_result');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    if (depth.length > 0){
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          search: searchKey,
          depth: depth
        }
      })
      .pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    } else if (depth.length == 0){
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          search: searchKey,
        }
      })
      .pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    }

  }
  // ?search_string=abrasive&depth=2

  getNodeAttributes = (nodeId: any, leafId: any) => {
    let endPoint = this.globals.urlJoin('home', 'node_details');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);

    if (leafId > 0){
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
        params: {
          node_id: nodeId,
        }
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
      );
    }
  }

  getLeafNodes = (id: any, page, pageSize) => {
    let endPoint = this.globals.urlJoin('home', 'tree_data');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    return this.http.get<any>(endPoint + id + '/leaves/', {
      headers: this.httpOptions,
      params: {
        page: page,
        page_size: pageSize
      }
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getLeafNodeAttributes = (id: any) => {
    if (id == null){

    } else {
      let endPoint = this.globals.urlJoin('home', 'tree_data');
      endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
      return this.http.get<any>(endPoint + id + '/attributes/', {
        headers: this.httpOptions,
      })
        .pipe(
          tap(resp => {
              return resp;
          }),
          catchError(error => throwError(error))
        );
    }
  }

  getAttributes = (id: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'list');
    // this.versionService.getVersion().subscribe(version => this.currentVersion = version);
    this.currentVersionName = this.currentVersion.attributes.name;
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getCodeset = (searchKey: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'uom');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        q: searchKey,
      }
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getValidValues = (searchKey: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'validValue');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        q: searchKey,
      }
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  searchNode = (key: any, levels: any, page: any) => {
 // console.log(levels);
    let options = new HttpParams().set('q', key);
    options = options.append('page', page);
    if (levels.length > 0){
      if (levels.length == 1){
          options = options.append('level', levels);
      } else{
        levels.forEach(e => {
          options = options.append('level', e);
        });

      }
    }
    let requestOptions = {
      params: options,
    };
 // console.log(options);
    let UsageEndpoint = this.globals.urlJoin('home', 'search');
    UsageEndpoint = UsageEndpoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    // UsageEndpoint = UsageEndpoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get(UsageEndpoint, requestOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  }


  logoutUser = () => {
    let userLogoutEndpoint = this.globals.urlJoin('user', 'logout');
    this.http.get(userLogoutEndpoint, {})
      .subscribe({
        next: (r) => this.router.navigate(['/login'])
      });
  }
}
