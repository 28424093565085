<div class="container">
    <div class="automation-wrapper" fxFlex="100">
        <mat-card class="automation-card">
            <div fxLayout class="auto-head-section">
                <img fxLayout src="assets/images/page-back.svg" id="pointer-icon" routerLink="/home">
                <p class="automation-head">Automation</p>
            </div>
            <!-- progress spinner -->
            <div fxLayout class="auto-progress-section">
                <circle-progress class="circle" style="display: block;" [percent]="automationPercentage" [radius]="40"
                    [outerStrokeWidth]="4" [innerStrokeWidth]="0" [outerStrokeColor]="'#7BB79B'"
                    [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300" [titleFontSize]="20"
                    [titleFontWeight]="800" [showSubtitle]="false" [unitsFontSize]="20" [unitsFontWeight]="800">
                </circle-progress>
                <!-- automation header -->
                <div fxLayout="column" class="auto-info-section">
                    <h1>Automation</h1>
                    <span>Last Updated: {{lastUpdated|date:'medium'}}</span>
                </div>
            </div>
        </mat-card>
        <!-- Automation graph -->
        <div class="graph-tab">
            <mat-tab-group (selectedTabChange)="myTabSelectedTabChange($event)">
                <mat-tab label={{item.title}} *ngFor="let item of automationChartIntervals; let i=index">
                    <mat-card class="graph-wrapper">
                        <div class="graph">

                            <!-- loading spinner -->
                            <app-loadingspinner *ngIf="pageload"></app-loadingspinner>
                            <!-- error message for error handling -->
                            <app-error-message *ngIf="errorOccurs"></app-error-message>
                            <!-- line chart -->
                            <canvas height="20" id="{{item.id}}">{{ chart }}</canvas>

                        </div>
                    </mat-card>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>