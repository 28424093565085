import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AutomationComponent } from './components/automation/automation.component';
import { ReviewComponent } from './components/review/review.component';
import { AuthGuard } from './_guards/auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DownloadComponent } from './components/download/download.component';
import { InfoComponent } from './components/info/info.component';
import { AttributeInfoComponent } from './components/attribute-info/attribute-info.component';
import { EditRelationComponent } from './components/edit-relation/edit-relation.component';
import { DraftComponent } from './components/draft/draft.component';
import { AttributeSuggestionComponent } from './_shared/attribute-suggestion/attribute-suggestion.component';
import { DraftListComponent } from './components/draft-list/draft-list.component';
import { AddAttributeComponent } from './components/add-attribute/add-attribute.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { ApproveComponent } from './components/approve/approve.component';
import { MappedValuesComponent } from './components/mapped-values/mapped-values.component';
import { UploadComponent } from './components/upload/upload.component';
import { AddCategoryMultiComponent } from './components/category/add-category-multi/add-category-multi.component';
import { SearchCategoryComponent } from './components/category/search-category/search-category.component';
import { AddRelationMultiComponent } from './components/category/add-relation-multi/add-relation-multi.component';
import { UomConflictComponent } from './components/uom-conflict/uom-conflict.component';
import { AbandondNodesComponent } from './components/_shared/abandond-nodes/abandond-nodes.component';
import { DraftViewComponent } from './components/_shared/draft-view/draft-view.component';

const routes: Routes = [
  // {path: '**', redirectTo: 'home'},
  { path: '', component: LoginComponent, data: { title: 'Login' } },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { title: 'Home' },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' },
  },
  {
    path: 'automation',
    component: AutomationComponent,
    canActivate: [AuthGuard],
    data: { title: 'Automation' },
  },
  {
    path: 'download',
    component: DownloadComponent,
    canActivate: [AuthGuard],
    data: { title: 'Download' },
  },
  {
    path: 'info',
    component: InfoComponent,
    canActivate: [AuthGuard],
    data: { title: 'Information' },
  },
  {
    path: 'attribute-info',
    component: AttributeInfoComponent,
    canActivate: [AuthGuard],
    data: { title: 'Attribute Information' },
  },
  {
    path: 'edit-relation',
    component: EditRelationComponent,
    canActivate: [AuthGuard],
    data: { title: 'Edit Relation' },
  },
  {
    path: 'attribute-suggestion',
    component: AttributeSuggestionComponent,
    canActivate: [AuthGuard],
    data: { title: 'Attribute Suggestion' },
  },

  {
    path: 'add-attribute',
    component: AddAttributeComponent,
    canActivate: [AuthGuard],
    data: { title: 'Add Attribute' },
  },

  {
    path: 'add-category',
    component: AddCategoryComponent,
    canActivate: [AuthGuard],
    data: { title: 'Add Category' },
  },

  {
    path: 'category',
    component: AddCategoryMultiComponent,
    canActivate: [AuthGuard],
    data: { title: 'Add Category' },
  },

  {
    path: 'category-list',
    component: SearchCategoryComponent,
    canActivate: [AuthGuard],
    data: { title: 'Search Category' },
  },

  {
    path: 'draft',
    component: DraftComponent,
    canActivate: [AuthGuard],
    data: { title: 'Draft' },
  },
  {
    path: 'draft-list',
    component: DraftListComponent,
    canActivate: [AuthGuard],
    data: { title: 'Draft List' },
  },
  {
    path: 'review',
    component: ReviewComponent,
    canActivate: [AuthGuard],
    data: { title: 'Review' },
  },

  {
    path: 'approve',
    component: ApproveComponent,
    canActivate: [AuthGuard],
    data: { title: 'Approve' },
  },
  {
    path: 'mapping',
    component: MappedValuesComponent,
    canActivate: [AuthGuard],
    data: { title: 'Valid Values' },
  },
  {
    path: 'upload',
    component: UploadComponent,
    canActivate: [AuthGuard],
    data: { title: 'Upload / Download' },
  },
  {
    path: 'add-relation',
    component: AddRelationMultiComponent,
    canActivate: [AuthGuard],
    data: { title: 'Add Relation' },
  },
  {
    path: 'uom-conflist',
    component: UomConflictComponent,
    canActivate: [AuthGuard],
    data: { title: 'UOM Conflict' },
  },
  {
    path: 'empty-nodes',
    component: AbandondNodesComponent,
    canActivate: [AuthGuard],
    data: { title: 'Empty Nodes' },
  },
  {
    path: 'draft-view/:id',
    component: DraftViewComponent,
    data: { title: 'Conflicting Ticket' },
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
