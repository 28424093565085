import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Globals } from '../_globals/endpoints.global';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENDPOINTS } from '../_globals/endpoints';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MultiCategoryService {
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  getCategoryList = (page: number, page_size: number, is_leaf: boolean, searchKey: string, is_free: boolean) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'categories');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        page: page.toString(),
        page_size: page_size.toString(),
        is_leaf: is_leaf.toString(),
        is_free: is_free.toString(),
        q: searchKey
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getCategoryDetail = (id) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'categoryDetails');
    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributes = (page: number, page_size: number, searchKey: string) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'attributes');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        page: page.toString(),
        page_size: page_size.toString(),
        q: searchKey
      },
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  addAttribute = (data: any) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'attributes');
    return this.http.post<any>(endPoint, data, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  addCategory = (data) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'categories');
    return this.http.post<any>(endPoint, data, {
      headers: this.httpOptions}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributeValidValue = (id) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'attributes');
    return this.http.get<any>(endPoint + id + '/value_codesets/', {
      headers: this.httpOptions}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributeUOM = (id) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'attributes');
    return this.http.get<any>(endPoint + id + '/uom_codesets/', {
      headers: this.httpOptions}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  validateCategoryName = (name) => {
    const endPoint = this.globals.urlJoin('validate', 'category_name');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        q: name
      }}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  validateCategoryCode = (code) => {
    const endPoint = this.globals.urlJoin('validate', 'category_code');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        q: code
      }}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  editCategory = (id,data) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'categoryDetails');
    return this.http.put<any>(endPoint + id, data, {
      headers: this.httpOptions}).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

}
