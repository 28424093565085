import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../_globals/endpoints.global';
import { Observable, Subscription, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ENDPOINTS } from '../_globals/endpoints';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VersionService } from './version.service';
import { HierarchyService } from './hierarchy.service';


@Injectable({
  providedIn: 'root'
})
export class InfoService {
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  currentVersion: any = 0;
  subscription: Subscription;
  currentVersionName: any = 0;
  subscriptionHeirarchy: Subscription;
  currentHierarchy: any = null;
  nodeId = 0;
  selectedCategory: any = null;
  attributeId = 0;
  attribute: any = null;

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private versionService: VersionService,
    private hierarchyService: HierarchyService
    ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      if (this.currentVersion === 0 || this.currentVersion == null) {
     // console.log(this.currentVersion);
        this.currentVersion = JSON.parse(localStorage.getItem('version'));
      } else {
       this.currentVersionName = this.currentVersion.id;
      }
    });
    this.subscriptionHeirarchy = this.hierarchyService.getUpdatedHierarchy().subscribe(hierarchy => {
      this.currentHierarchy = hierarchy;
      if (this.currentHierarchy === 0 || this.currentHierarchy == null) {
     // console.log(this.currentHierarchy);
        this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
      } else {
     // console.log(this.currentHierarchy);
      }
    });
  }

  getNodeInfo = (nodeId) => {
 // console.log(this.currentVersionName);
    let endPoint = this.globals.urlJoin('node_info', 'all_info');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        node_id!: nodeId,
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getNodeHierarchyInfo = (nodeId) => {
    const endPoint = this.globals.urlJoin('node', 'hierarchy');
    return this.http.get<any>(endPoint + nodeId + '/hierarchies/', {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributeInfo = (id) => {
    let endPoint = this.globals.urlJoin('attribute', 'list');
    // this.versionService.getVersion().subscribe(version => this.currentVersion = version.attributes.name);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );

  }

  export = () => {
    let endPoint = this.globals.urlJoin('taxonomy', 'export');
    // this.versionService.getVersion().subscribe(version => this.currentVersion = version.attributes.name);
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );

  }

  getUOMConflict = (page, page_size) => {
    const endPoint = this.globals.urlJoin('home', 'uom_conflict');
    endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        page,
        page_size
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAbandonedNodes = (page, page_size) => {
    const endPoint = this.globals.urlJoin('home', 'abandoned_nodes');
    endPoint.replace('api', 'api/versions/');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
      params: {
        page,
        page_size
      }
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getNodeData = (nodeID: any) => {
 // console.log(this.currentVersionName);
 // console.log(this.currentHierarchy);
    let endPoint = this.globals.urlJoin('home', 'tree_data');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName + '/hierarchies/' + this.currentHierarchy.id);
    if (nodeID){
      return this.http.get<any>(endPoint + nodeID, {
        headers: this.httpOptions,
      }).pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    } else {
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
      })
        .pipe(
          tap(resp => {
            // console.log(resp);
            return resp;
          }),
          catchError(error => throwError(error))
        );
    }
  }

  getDraftNodeData = (versionId:string, hierarchyId:string, nodeID: string) => {
    let endPoint = this.globals.urlJoin('home', 'tree_data');
    endPoint = endPoint.replace('api', 'api/versions/' + versionId + '/hierarchies/' + hierarchyId);
    if (nodeID){
      return this.http.get<any>(endPoint + nodeID, {
        headers: this.httpOptions,
      }).pipe(
        tap(resp => {
       // console.log(resp);
          return resp;
        }),
        catchError(error => throwError(error))
      );
    } else {
      return this.http.get<any>(endPoint, {
        headers: this.httpOptions,
      })
        .pipe(
          tap(resp => {
            // console.log(resp);
            return resp;
          }),
          catchError(error => throwError(error))
        );
    }
  }
}
