<div class="legend-dialog">
  <div fxLayout="row">
    <h2 fxFlex="98">Keyboard Shortcuts</h2>
    <mat-icon (click)="close()" style="cursor: pointer;">close</mat-icon>
  </div>

  <div class="container">
    <div class="nav-column">
      <h4>Navigation</h4>
      <table>
        <tr *ngFor="let nav of navigation; let i = index">
          <td class="short-cut-keys">{{ nav.shortcut_key }}</td>
          <td>{{ nav.name }}</td>
        </tr>
      </table>
    </div>
    <div class="action-column">
      <h4>Actions</h4>
      <table>
        <tr *ngFor="let nav of actions; let i = index">
          <td  class="short-cut-keys">{{ nav.shortcut_key }}</td>
          <td>{{ nav.name }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
