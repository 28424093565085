import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';

@Component({
  selector: 'app-uom-conflict',
  templateUrl: './uom-conflict.component.html',
  styleUrls: ['./uom-conflict.component.scss']
})
export class UomConflictComponent implements OnInit {
  selectedNode = null;
  conflistList = [];
  loading = false;
  counter = {
    page : 1,
    pageSize : 50,
    totalPages : 0,
    totalValues : 0
  };
  constructor(
    private infoService: InfoService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlerService,
    private multiCategoryService: MultiCategoryService,
  ) { }

  ngOnInit(): void {
    this.getConflictList();
  }

  getConflictList = () => {
    this.infoService.getUOMConflict(this.counter.page, this.counter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.counter.totalPages = res.total_pages;
          this.conflistList = res.results.data;
          this.selectedNode = this.conflistList[0];
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  getInfo = (item: any) => {
    this.selectedNode = item;
  }

  onCategoryScroll = (event: any) => {
    if (this.counter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight-10)) {
     // console.log('scrolling category');
        if (this.counter.page < this.counter.totalPages){
          this.counter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.counter.page);
        }else{
       // console.log('Cant Load' + this.counter.page);
        }
      }
    }
  }

  patchCategoryList = (i) => {
    this.infoService.getUOMConflict(this.counter.page, this.counter.pageSize).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.conflistList = this.conflistList.concat(res.results.data);
            this.counter.totalPages = res.total_pages;
            this.counter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }

}
