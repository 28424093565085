<div class="container slider">
  <div>
    <div *ngFor="let slide of imgsArr; let i = index">
      <div class="tag slide" *ngIf="isCurrentSlideIndex(i) && slide">
        {{ slide.tag }}
      </div>
       <pinch-zoom *ngIf="isCurrentSlideIndex(i)">
        <img src={{slide.value}} />
      </pinch-zoom>

     
    </div>
  </div>

  <button
    class="arrow prev"
    (click)="nextSlide()"
    fxLayoutAlign="center center"
    *ngIf="imgsArr.length > 1"
  >
    <mat-icon svgIcon="chevronLeft"></mat-icon>
  </button>
  <button
    class="arrow next"
    (click)="prevSlide()"
    fxLayoutAlign="center center"
    *ngIf="imgsArr.length > 1"
  >
    <mat-icon svgIcon="chevronRight"></mat-icon>
  </button>
</div>
