<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
      <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
        <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Valid Values/UoM Mapping</span>
      </div>
      <div fxFlex="44" fxLayoutAlign="end center">
        <button  *ngIf="isAdmin" color="primary" mat-raised-button (click)="uploadDialog()">
            Upload Codeset File <mat-icon>upload</mat-icon>
        </button>
      </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-tab-group class="table-tab" #tabGroup (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Attribute Valid Values">
                <div fxLayout="row">
                    <div fxLayout="column" fxLayoutAlign="center start" style="margin-top: 20px;width: 100%;">
                        <div fxLayout="row" style="width: 100%;">
                            <form class="example-form" [formGroup]="formGroupValidValues" style="width: 90%;">
                                <!-- <mat-label>Valid Values</mat-label> -->
                                <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matPrefix>search</mat-icon>
                                <input type="text"
                                        id="searchKey"
                                        placeholder="Value Codeset"
                                        matInput
                                        formControlName="validValue"
                                        [matAutocomplete]="validValuesAutoComplete">        
                                <mat-autocomplete #validValuesAutoComplete="matAutocomplete" style="width: 90%;">
                                    <mat-option (click)="selectValidValue(values)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of validValueList" [value]="values.attributes.name">
                                    {{values.attributes.name}}
                                    </mat-option>
                                    <!-- <mat-option *ngIf="validValueList.length==0">No Valid Values are found.</mat-option> -->
                                </mat-autocomplete>
                                </mat-form-field>
                            </form>
                            <!-- <div fxLayout="row" *ngIf="isAdmin">
                                <div>
                                    <button mat-raised-button class="download" (click)="downloadValidValue()">
                                        <mat-icon>download</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div fxLayout="row" *ngIf="isAdmin">
                                <div>
                                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/18lmFFHWMxikf6_d88w12E9vGllHP1KbarebcdjoCBPk/edit?usp=sharing">
                                        <button mat-raised-button class="download">
                                            Sample File
                                        </button>
                                    </a>
                                </div>
                            </div> -->
                        </div>

                        <div fxLayout="column" class="dataView" *ngIf="validValues.length>0">
                            <!-- <div>{{uomValueData|json}}</div> -->
                            <div>
                                <!-- {{uomCodeSet|json}} -->
                                <div class="mappedValues" fxLayout="row wrap">
                                    <div *ngFor="let item of validValues;let isLast=last">{{item.attributes.name}}{{isLast ? '' : ' , '}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar>   -->
            </mat-tab>
            <mat-tab label="UoM Codeset">
                <!-- <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar> -->
                <div fxLayout="row">
                    <div fxLayout="column" fxLayoutAlign="center start" style="margin-top: 20px;width: 100%;">
                        <div fxLayout="row" style="width: 100%;">
                            <form class="example-form" [formGroup]="formGroupUoM" style="width: 90%;">
                                <!-- <mat-label>UoM Codeset</mat-label> -->
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-icon matPrefix>search</mat-icon>
                                    <input type="text"
                                          id="searchKey"
                                          placeholder="UOM codeset"
                                          matInput
                                          formControlName="uom"
                                          [matAutocomplete]="uomAutoComplete">        
                                    <mat-autocomplete #uomAutoComplete="matAutocomplete" style="width: 90%;">
                                      <mat-option (click)="selectUoMValue(option)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of uomValueList" [value]="option.attributes.name">
                                        {{option.attributes.name}}
                                      </mat-option>
                                      <!-- <mat-option *ngIf="validValueList.length==0">No data present.</mat-option> -->
                                    </mat-autocomplete>
                                  </mat-form-field>
                              </form>
                              <!-- <div fxLayout="row" *ngIf="isAdmin">
                                <div>
                                    <button mat-raised-button class="download" (click)="downloadUoM()">
                                        <mat-icon>download</mat-icon>
                                    </button>
                                </div>
                              </div> -->
                              <!-- <div fxLayout="row" *ngIf="isAdmin">
                                <div>
                                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1s-m-KDV3bsJNbEOCVkUb1LHC80Y-6tamQ_MfPdH1seA/edit?usp=sharing">
                                        <button mat-raised-button class="download">
                                            Sample File
                                        </button>
                                    </a>
                                </div>
                            </div> -->
                        </div>
                        <div fxLayout="column" class="dataView" *ngIf="uomCodeSet.length >0">
                            <!-- <div>{{uomValueData|json}}</div> -->
                            <div>
                                <!-- {{uomCodeSet|json}} -->
                                <div class="mappedValues">
                                    <div *ngFor="let item of uomCodeSet;let isLast=last">{{item.attributes.name}}{{isLast ? '' : ' , '}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="UoM Conflict">
                <app-uom-conflict></app-uom-conflict>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
  

