import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss'],
})
export class LegendsComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LegendsComponent, any>
  ) {}
  navigation = [
    { shortcut_key: '<Ctrl+ Shift + h>', name: 'Go to home' },
    { shortcut_key: '<Ctrl+ Shift + r>', name: 'Go to Review' },
    { shortcut_key: '<Ctrl+ Shift + p>', name: 'Go to Products' },
    { shortcut_key: '<Ctrl + q>', name: 'Logout' },
  ];

  actions = [
    { shortcut_key: '<Shift + u>', name: 'Upload' },
    { shortcut_key: '<Esc>', name: 'Close upload' },
    { shortcut_key: '<Ctrl + />', name: 'Search' },
    { shortcut_key: '<Shift + n>', name: 'Next' },
    { shortcut_key: '<Shift + p>', name: 'Previous' },
    { shortcut_key: '<Shift + r>', name: 'Reset' },
  ];
  ngOnInit(): void {}

  close = () => {
    this.dialogRef.close();
  };
}
