import { Component, Directive, Inject, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Params, Router } from '@angular/router';
import { data } from 'jquery';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { NodeService } from 'src/app/services/node.service';
import { VersionService } from 'src/app/services/version.service';
import { HierarchyInfoComponent } from '../_shared/hierarchy-info/hierarchy-info.component';
import { ActivatedRoute } from '@angular/router';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private infoService: InfoService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private nodeService: NodeService,
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private route: ActivatedRoute,
  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icon.svg'
      )
    );
   }
  subscription: Subscription;
  subscriptionHeirarchy: Subscription;
  tab1Data: any = null;
  attributes: any = null;
  leafNodes: any = null;
  selectedNodeId = null;
  properties: any = null;
  loading = true;
  propertyKeys: any = null;
  templateKeys: any = null;
  templates: any = null;
  nameEdit = false;
  descEdit = false;
  uploadForm;
  attributeCounter = {
    page : 1,
    pageSize : 25,
    totalPages : 0,
    totalValues: 0
  };
  tabIndex = 0;
  tabLoader = true;
  isLeaf = false;
  leafCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  currentVersion: any = null;
  frozen = false;
  versionList: any = [];
  selectedVersion: any = null;

  attributeLocalLoading = false;
  i = 1;
  scroll = false;
  userData;
  userDisplayName;
  userGroup = null;
  userType = null;
  currentHierarchy: any = null;
  currentHierarchyName: any = null;
  disableEdit = false;

  // Edit ------
  ngOnInit(): void {
  //   this.route.params.subscribe(
  //     (params: Params) => {
  //       //  this. = params['id'];
  //     // console.log(params.id);
  //        this.selectedNodeId = params.id;
  //     }
  //  );
    this.getVersionInfo();
    this.selectedNodeId = this.infoService.nodeId;
    this.currentHierarchy = this.hierarchyService.checkSetHierarchy();
   // console.log(this.currentHierarchy);
    if (this.currentHierarchy){
      this.currentHierarchyName = this.currentHierarchy.attributes.name;
    }
    // this.selectedNodeId = 'c067bbc3-f39b-4709-8d03-3bbc53ac6f4f';
    if (this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    } else {
      this.router.navigate(['/home']);
    }
    this.uploadForm = this.formBuilder.group({
      attachment: ['']
    });
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.userDisplayName = this.userData.username;
    this.userGroup = this.userData.groups;
 // console.log(this.userGroup);
    this.userGroup.forEach(e => {
      if (e.name === 'administrator'){
        this.userType = 'administrator';
      } else if (e.name === 'reviewer'){
        this.userType = 'reviewer';
      } else if (e.name === 'editor'){
        this.userType = 'editor';
      }
    });
  }

  getVersionInfo = () => {
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    if (this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
    }
    // this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
    //// console.log(version);
    //   this.currentVersion = version;
    // this.frozen = this.currentVersion.attributes.is_frozen;
    // });
  }
  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.tab1Data = res.data;
        this.loading = false;
        this.tabLoader = false;
        this.isLeaf = this.tab1Data.attributes.is_leaf;
        this.disableEdit = this.isLeaf && (this.currentHierarchyName.indexOf('Master') === -1);
     // console.log(this.disableEdit);
        if (this.isLeaf){
          this.getAttributes(this.tab1Data.attributes.category_id);
        } else {
          this.attributes = [];
        }
      }
    });
  }

  getAttributes = (id) => {
    this.attributes = [];
    this.homeService.getLeafNodeAttributes(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributes = res.results.data;
        this.tabLoader = false;
        // this.attributesListCount = this.attributes.length;
      }
    });
  }


  tabChanged = (e) => {
    this.tabLoader = true;
    this.tabIndex = e.index;
 // console.log(this.tabIndex);
    if (this.tabIndex === 0){
      this.nodeInfo(this.selectedNodeId);
    } else if (this.tabIndex === 1){
      this.getAttributes(this.selectedNodeId);
    } else if (this.tabIndex === 2){
      // this.getLeafNodeList(this.selectedNodeId);
    }
  }

  checkColor = () => {
    Object.values(this.templates).forEach(value => {
   // console.log(value);
    });
  }
// Edit --
  editPanel = (column, value) => {
 // console.log(value);
    this.openDialog(column, this.selectedNodeId, value);
    if (column === 'Name'){
      if (this.nameEdit){
        this.nameEdit = false;
      } else {
        this.nameEdit = true;
      }
    } else{
      if (this.descEdit){
        this.descEdit = false;
      } else {
        this.descEdit = true;
      }
    }
  }

  openDialog(editField, id, value): void {
    const isLeaf = this.isLeaf;
    const dialogRef = this.dialog.open(DialogEdit, {
      width: '80vw',
      height : '75vh',
      data: {editField, id, value, isLeaf, category: this.tab1Data}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      if (result)
      {
        // this.snackBar.open('Marked Successfully', '', {
        //   duration: 2000,
        // });
      } else {
      }
    });
  }

  onAttributeScroll = (event: any) => {
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
   // console.log(this.attributeCounter.totalPages);

      if (this.i < this.attributeCounter.totalPages){
        this.i = this.i + 1;
     // console.log('Can Load' + this.i);
        this.attributeLocalLoading = true;
        // this.patchAttributeValues(this.i);
      }else{
        // this.i = this.attributeCounter.totalPages;
     // console.log('Cant Load' + this.i);
      }
   // console.log('End');
    } else if (event.target.scrollTop === 0) {
   // console.log('Start');
    }
  }

  editAttributes = () => {
    const id = this.selectedNodeId;
    const attributes = JSON.parse(JSON.stringify(this.attributes));
 // console.log('Attribute Edit');
    const dialogRef = this.dialog.open(DialogNodeAttributesOrder, {
      width: '80vw',
      height : '75vh',
      data: {id, attributes}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        // this.snackBar.open('Edited Successfully', '', {
        //   duration: 2000,
        // });
      } else {
      }
    });
  }

}

//  -------------------------------------- Edit Category Data  --------------------------------------

@Component({
  selector: 'dialog-confirm-final',
  templateUrl: './edit.html',
  styleUrls: ['./info.component.scss'],
})
export class DialogEdit {
  selectedNode;
  selectedId = this.data.id;
  files: File[] = [];
  nodeData = this.data.category;
  name = '';
  description = '';
  notes = '';
  formSubmitted = false;
  draftId = 0;
  editedValue = this.data.value;
  value = this.data.value;
  isLeaf = this.data.isLeaf;
  uploadForm;
  ticketId;
  selectedVersion = this.versionService.checkSetVersion();
  selectedHierarchy = this.hierarchyService.checkSetHierarchy();
  constructor(
    private snackBar: MatSnackBar,
    private nodeService: NodeService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private hierarchyService: HierarchyService,
    private versionService: VersionService,
    public dialogRef: MatDialogRef<DialogEdit>,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandlerService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.iconRegistry.addSvgIcon(
        'upload',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/addFolder.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'zip',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/zip.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'csv',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/csv.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'pdf',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/pdf.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'hierarchy',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/hierarchy.svg'
        ),
      );
    }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  viewHierarchy = () => {
 // console.log(this.selectedNode);
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-hierarchy';
    dialogConfig.width = '60vw';
    dialogConfig.data = {categoryId: this.selectedId};
    const modalDialog = this.dialog.open(
      HierarchyInfoComponent, dialogConfig
      );
  }

  submitName = () => {
    this.saveDraft('name');
  }

  submitDesc = () => {
    this.saveDraft('description');
  }

  saveDraft = (field) => {
    this.isLeaf = this.data.isLeaf;
 // console.log(this.data);
    this.uploadForm = this.formBuilder.group({
      attachment: ['']
    });
    if (this.editedValue === ''){
      this.snackBar.open(`Please fill the values properly.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }
    if (this.editedValue === this.data.value){
      this.snackBar.open(`Existing and previous vlaues can't be same.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });

    } else {
      if (this.data.value === undefined){
        this.data.value = '';
      }
      const data = {
        edit_type: 'category_field_edit',
        category_id: this.selectedId,
        field_name: field,
        new_value: this.editedValue,
        original_value: this.data.value,
        note: this.notes,
        is_leaf: this.isLeaf,
        internal_ticket_id: this.ticketId,
        hierarchy_id: this.selectedHierarchy.id,
        version_id: String(this.selectedVersion.id)
      };
   // console.log(data);
      this.nodeService.nodeEdit(data).subscribe({
        next: (resp: any) => {
       // console.log(resp);
          if (resp.data.id > 0){
            this.formSubmitted = false;
            this.draftId = resp.data.id;
         // console.log(this.draftId);
            if (this.draftId > 0 && this.files.length > 0){
              setTimeout(() => {
                const file = {
                  attachments: this.files
                };
                const form = new FormData();
                this.files.forEach(e => {
                  form.append('attachments', e);
                });
             // console.log(form);
                this.nodeService.uploadFile(this.draftId, form).subscribe({
                  next: resp => {
                 // console.log('resp');
                    this.snackBar.open(`Draft ticket for category field edit created Successfully.`, 'OK', {
                      duration: 4000,
                      panelClass: ['error-snackbar']
                    });
                  }
                });
              }, 2000);
            } else {
              this.snackBar.open(`Draft ticket for category field edit created Successfully.`, 'OK', {
                duration: 4000,
                panelClass: ['error-snackbar']
              });
            }

          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.formSubmitted = false;
       // console.log(HttpResponse);
          this.errorHandler.pass(HttpResponse.error);
        }
      });
      this.dialogRef.close(null);
    }
  }

  getIcon = (name) => {
    const arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
      return 'pdf';
    } else {
      return 'zip';
    }

  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}



// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './attributeOrder.html',
  styleUrls: ['./info.component.scss']
  })
  export class DialogNodeAttributesOrder {
    constructor(
      public dialog: MatDialog,
      private iconRegistry: MatIconRegistry,
      private sanitizer: DomSanitizer,
      private nodeService: NodeService,
      public dialogRef: MatDialogRef<DialogNodeAttributesOrder>,
      private fb: FormBuilder,
      private multiCategoryService: MultiCategoryService,
      private homeService: HomeService,
      private hierarchyService: HierarchyService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private errorHandler: ErrorHandlerService,
      private snackBar: MatSnackBar,
      private versionService: VersionService
    ) {
      this.iconRegistry.addSvgIcon(
        'edit',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit.svg'
        )
      );
    }
  @ViewChild(MatAutocompleteTrigger) attributeValuesAutoComplete: MatAutocompleteTrigger;
    isRequiredList = [
      {
        value : null,
        view: 'NULL'
      },
      {
        value : false,
        view: 'False'
      },
      {
        value : true,
        view: 'True'
      }
    ];

    tab1Data: any = null;
    attribute: any = null;
    productType: any = null;
    selectedNodeId = 0;
    loading = true;
    nodeData;
    tabLoader;
    attributes;
    leafNodes;
    tabIndex = 0;
    page = 0;
    pageSize = 10;
    totalItems = 0;
    editedNode;
    attachment;
    selectedAttributes: any = [];
    formGroupAttribute: FormGroup;
    searchedAttribute;
    attributeList: any = [];
    filteringAttributeList: any = [];
    activeTabIndex = 0;
    postAttributeObject: any = [];
    postAttributeFilterObject: any = [];
    attributeCounter = {
      page : 1,
      pageSize : 200,
      totalPages: 0
    };
    postFilterCounter: any = 0;
    rawFilterCounter: any = 0;
    isLeaf = false;
    isSingle = true;
    isActive = false;
    isRequired = null;
    minValue = '';
    isDifferentiator = false;
    formGroupUoM: FormGroup;
    formGroupValidValues: FormGroup;
    searchKeyUoM = '';
    searchKeyValidValues = '';
    searchKey = '';
    selectedValidValue: any = null;
    selecteduom: any = null;
    uomValueList = [];
    validValueList = [];
    previousAttributes = [];
    previousAttributesUnset = [];
    selectedTabIndex = 0;
    selectedHierarchy = null;
    files = [];
    ticketId = '';
    notes = '';
    isClosed = true;
    currentHierarchy = this.hierarchyService.checkSetHierarchy();
    selectedVersion = this.versionService.checkSetVersion();
    draftId = 0;

    ngOnInit(): void {
      this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
   // console.log(this.data);
      this.initFormValidValue();
      this.initFormUoM();
      this.selectedAttributes = [];
      this.attributeList = [];
      this.filteringAttributeList = [];
      this.postAttributeObject = [];
      this.postAttributeFilterObject = [];
      // if(this.nodeService.selectedAttributeList.length>0){
      //   // this.selectedAttributes = this.nodeService.selectedAttributeList;
      //   this.nodeService.selectedAttributeList.forEach(e=>{
      //     this.selectAttribute(e);
      //   });
      // }
      this.formGroupAttribute = this.fb.group({
        attribute : ['']
      });
      this.nodeInfo();
      this.initForm();
      this.mapAttributeNames();
    }

    next = () => {
      this.selectedTabIndex++;
    }

    onTabChanged = (e: any) => {
   // console.log(e);
      this.selectedTabIndex = e.index;
    }

    removeAttribute = (item, i) => {
   // console.log(item);
      const index2 = this.previousAttributesUnset.findIndex(i => i.attributes.attribute_id === item.attributes.attribute_id);
      const index3 = this.postAttributeObject.findIndex(i => i.attribute_id === item.attributes.attribute_id);
      const index4 = this.postAttributeFilterObject.findIndex(i => i.attribute_id === item.attributes.attribute_id);
      const index5 = this.selectedAttributes.findIndex(i => i.attributes.attribute_id === item.attributes.attribute_id);
      const index6 = this.filteringAttributeList.findIndex(i => i.attributes.attribute_id === item.attributes.attribute_id);
      if (index2 > -1){
        this.previousAttributesUnset.splice(index2, 1);
      }
      if (index3 > -1){
        this.postAttributeObject.splice(index3, 1);
      }
      if (index4 > -1){
        this.postAttributeFilterObject.splice(index4, 1);
        this.postFilterCounter --;
      }
      if (index5 > -1){
        this.selectedAttributes.splice(index5, 1);
      }
      if (index6 > -1){
        this.filteringAttributeList.splice(index6, 1);
        this.rawFilterCounter --;
      }
      this.mergeAttribute(this.postAttributeObject);
      this.mergeFilterAttribute();
      this.mergeFilterSequence();
    }

    mapAttributeNames = () => {
      this.previousAttributesUnset = this.data.attributes;
      this.mapPreviousValues();
      // this.selectedAttributes = this.previousAttributes;
   // console.log(this.selectedAttributes);
      let i = 0;
      this.previousAttributesUnset.forEach(e => {
        this.selectAttribute(e);
        if (e.attributes.is_filter === true){
       // console.log(e);
       // console.log('I am a filter.');
          this.selectFilterOld(e, i);
        }
        i++;
      });
    }

    mapPreviousValues = () => {
      this.previousAttributesUnset.forEach(e => {
        let uomName = null;
        let uomId = null;
        let validValueName = null;
        let validValueId = null;
        if (e.attributes.uom_codeset !== null){
          uomName = e.attributes.uom_codeset.data.attributes.name;
          uomId = e.attributes.uom_codeset.data.id;
        }
        if (e.attributes.value_codeset !== null){
          validValueName = e.attributes.value_codeset.data.attributes.name;
          validValueId = e.attributes.value_codeset.data.id;
        }
     // console.log(e);
        const params = {
          attribute_id: e.attributes.attribute_id,
          attribute_sequence: e.attributes.attribute_sequence,
          filter_sequence: e.attributes.filter_sequence,
          is_multi:  e.attributes.is_multi,
          is_active: e.attributes.is_active,
          is_required: e.attributes.is_required,
          is_closed: e.attributes.is_closed,
          is_filter: e.attributes.is_filter,
          min_value: e.attributes.min_value,
          is_differentiator: e.attributes.is_differentiator,
          value_codeset_id: validValueId,
          uom_codeset_id: uomId,
        };
        this.previousAttributes.push(params);
      });
   // console.log(this.previousAttributes);
   // console.log(this.postAttributeObject);
    }

    nodeInfo = () => {
      this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey).subscribe(res => {
        if (res) {
          this.attributeList = res.results.data;
          this.attributeCounter.totalPages = res.total_pages;
          this.attributeCounter.page = res.page;
          this.tabLoader = false;
        }
      });
    }


    initForm = () => {
      this.formGroupAttribute = this.fb.group({
        attribute : ['']
      });
      this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).
      subscribe(resp => {
        // console.log(resp);
        // console.log(this.searchedAttribute);
        if (this.formGroupAttribute.get('attribute').value != ''){
          this.searchKey = resp;
          this.searchAttribute(resp);
        } else {
          this.searchKey = resp;
          this.searchAttribute('');
        }
      });
    }

    searchAttribute = (searchKey) => {
      this.attributeCounter.page = 1;
      this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, searchKey).subscribe(res => {
        if (res) {
          this.attributeList = res.results.data;
          this.attributeCounter.totalPages = res.total_pages;
          this.attributeCounter.page = res.page;
          this.loading = false;
        }
      });
    }

    checkForValidValues = (id) => {
      this.multiCategoryService.getAttributeValidValue(id).subscribe({
        next: res => {
          if (res) {
            if (res.data.length === 1){
           // console.log('Valid Values');
           // console.log(res.data[0]);
              return res.data[0];
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
      });
    }

    checkForUOM = (id) => {
      this.multiCategoryService.getAttributeUOM(id).subscribe({
        next: res => {
          if (res) {
            if (res.data.length === 1){
           // console.log('UOM');
           // console.log(res.data[0]);
              return res.data[0];
            } else {
              return null;
            }
          } else {
            return null;
          }
        }
      });
    }

    selectAttribute = (item, e?) => {
   // console.log(item);
      if (e === undefined || e.checked === true){
     // console.log(this.selectedAttributes[0]);
        if (this.selectedAttributes.some(e => e.attributes.attribute_id === item.id)){
       // console.log('Already Selected');
          // const i = this.selectedAttributes.map(
          //   function(x) {
          //  // console.log(x);
          //     return x.attributes.attribute_id;
          //   }).indexOf(item.id);
          // if (i > -1) {
          //   this.selectedAttributes.splice(i, 1);
          //   this.postAttributeObject.splice(i, 1);
          // }
        } else {
       // console.log('Not Selected');
          let uomName = null;
          let uomId = null;
          let validValueName = null;
          let validValueId = null;
       // console.log(item);
       // console.log(item.attributes.uom_codeset !== null);
       // console.log(item.attributes.value_codeset !== null);
          if (item.attributes.uom_codeset !== null && item.attributes.uom_codeset !== undefined){
         // console.log(item.attributes.uom_codeset);
            uomName = item.attributes.uom_codeset.data.attributes.name;
            uomId = item.attributes.uom_codeset.data.id;
         // console.log('UOM is present' + uomName);
          }
          if (item.attributes.value_codeset !== null && item.attributes.value_codeset !== undefined){
         // console.log(item.attributes.value_codeset);
            validValueName = item.attributes.value_codeset.data.attributes.name;
            validValueId = item.attributes.value_codeset.data.id;
         // console.log('Valid Value is present' + validValueName);
          }
          let sampleAttribute: any = null;
          let params: any = null;
       // console.log(item);
          if (item.attributes.version_name === undefined){
         // console.log('Step 1');
            params = {
              name: item.attributes.name,
              attribute_id: item.id,
              value_codeset_id: validValueId,
              uom_codeset_id: uomId,
              validValueName,
              uomName,
              attribute_sequence: null,
              filter_sequence: null,
              is_multi:  false,
              is_active: true,
              is_required: null,
              is_filter: false,
              is_closed: false,
              min_value: '',
              is_differentiator: false,
            };
            sampleAttribute = {
              type: 'attributes',
              attributes: {
                  attribute_id: item.id,
                  attribute: {
                      data: {
                          type: 'attributes',
                          id: item.id,
                          attributes: {
                              name: item.attributes.name
                          }
                      }
                  },
                  attribute_sequence: null,
                  filter_sequence: null,
                  is_multi:  false,
                  is_active: true,
                  is_required: null,
                  is_filter: false,
                  is_closed: false,
                  min_value: '',
                  is_differentiator: false,
                  value_codeset_id: null,
                  uom_codeset_id: null,
              }
            };
          } else {
         // console.log('Step 2');
         // console.log(item);
            params = {
              name: item.attributes.attribute.data.attributes.name,
              attribute_id: item.attributes.attribute_id,
              is_filter: item.attributes.is_filter,
              attribute_sequence: item.attributes.attribute_sequence,
              filter_sequence: item.attributes.filter_sequence,
              is_multi: item.attributes.is_multi,
              is_active: item.attributes.is_active,
              is_required: item.attributes.is_required,
              min_value: item.attributes.min_value,
              is_differentiator: item.attributes.is_differentiator,
              is_closed: item.attributes.is_closed,
              value_codeset_id: validValueId,
              uom_codeset_id: uomId,
              validValueName,
              uomName,
            };

            sampleAttribute = {
              type: 'attributes',
              attributes: {
                name: item.attributes.attribute.data.attributes.name,
                  attribute_id: item.attributes.attribute_id,
                  attribute: {
                      data: {
                          type: 'attributes',
                          id: item.attributes.attribute_id,
                          attributes: {
                              name: item.attributes.attribute.data.attributes.name
                          }
                      }
                  },
                  is_closed: item.attributes.is_closed,
                  is_multi: item.attributes.is_multi,
                  is_filter: item.attributes.is_filter,
                  attribute_sequence: item.attributes.attribute_sequence,
                  filter_sequence: item.attributes.filter_sequence,
                  is_active: item.attributes.is_active,
                  is_required: item.attributes.is_required,
                  min_value: item.attributes.min_value,
                  is_differentiator: item.attributes.is_differentiator
              }
            };
          }
       // console.log(sampleAttribute);
       // console.log(params);
          const validValue = null;
          const uom = null;
          this.selectedAttributes.push(sampleAttribute);
       // console.log(this.selectedAttributes.length);
          const index = this.selectedAttributes.length;
       // console.log(uom);
       // console.log(validValue);
          this.postAttributeObject.push(params);
       // console.log(this.postAttributeObject);
        }
      }
    }

    selectFilterOld = (item, i) => {
   // console.log(this.selectedAttributes[i]);
   // console.log(this.postAttributeObject[i].is_filter);
      if (this.postAttributeObject[i].is_filter === true){
     // console.log(this.postAttributeObject[i]);
     // console.log('Here');
        this.postAttributeObject[i].is_filter = true;
        this.postAttributeObject[i].filter_sequence = this.selectedAttributes[i].attributes.filter_sequence;
     // console.log(this.postAttributeObject[i]);
        this.postAttributeFilterObject.push(this.postAttributeObject[i]);
        this.postFilterCounter ++;
     // console.log(this.postAttributeObject);
     // console.log(this.postAttributeFilterObject);
        } else {
     // console.log(this.postAttributeFilterObject);
        this.postAttributeObject[i].is_filter = false;
        const i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
        this.postAttributeObject[i].filter_sequence = '';
        if (i1 > -1) {
          this.postAttributeFilterObject.splice(i, 1);
          this.postFilterCounter --;
        }
      }
      if (this.filteringAttributeList.some(e => e === item)){
        const i = this.filteringAttributeList.indexOf(item);
        if (i > -1) {
          this.filteringAttributeList.splice(i, 1);
          this.rawFilterCounter --;
        }
      } else {
        this.filteringAttributeList.push(item);
        this.rawFilterCounter++;
      }
   // console.log(this.filteringAttributeList);
      this.filteringAttributeList.sort(function(a, b) {
        let keyA = new Date(a.attributes.filter_sequence),
        keyB = new Date(b.attributes.filter_sequence);
        // Compare the 2 dates
        if (keyA < keyB) { return -1; }
        if (keyA > keyB) { return 1; }
        return 0;
      });
   // console.log(this.filteringAttributeList[0]);
      this.filteringAttributeList.sort((a,b) => a.attributes.filter_sequence - b.attributes.filter_sequence);
      this.postAttributeFilterObject.sort((a,b) => a.filter_sequence - b.filter_sequence);
   // console.log(this.filteringAttributeList);
   // console.log(this.postAttributeFilterObject);
    }

    selectFilter = (item, i, e?: any) => {
   // console.log(e);
   // console.log(item);
   // console.log(this.filteringAttributeList);
      if (e.checked === true) {
     // console.log(this.postAttributeObject[i].is_filter);
        if (this.postAttributeObject[i].is_filter === false){
       // console.log('Here');
          this.rawFilterCounter++;
          this.postFilterCounter++;
       // console.log('Adding to location ' + this.postFilterCounter);
          this.postAttributeObject[i].is_filter = true;
          this.postAttributeObject[i].filter_sequence = this.postFilterCounter;
          this.postAttributeFilterObject.push(this.postAttributeObject[i]);
          item.attributes.filter_sequence = this.rawFilterCounter;
          this.filteringAttributeList.push(item);
       // console.log(this.postAttributeObject[i]);
        //  console.log(this.postAttributeFilterObject);
          }
      } else {
     // console.log(this.postAttributeObject[i].is_filter);
     // console.log(this.postAttributeFilterObject);
        this.postAttributeObject[i].is_filter = false;
        const i1 = this.postAttributeFilterObject.map(
          function(x) {
            return x.attribute_id;
          }).indexOf(item.attributes.attribute_id);
        if (i1 > -1) {
       // console.log(i1);
       // console.log('Found Here');
          this.postAttributeFilterObject.splice(i1, 1);
          this.postFilterCounter--;
        }
        const i2 = this.filteringAttributeList.map(
          function(x) {
         // console.log(x);
            return x.attributes.attribute_id;
          }).indexOf(item.attributes.attribute_id);
        if (i2 > -1) {
       // console.log(i2);
       // console.log('Found Here');
          this.filteringAttributeList.splice(i2, 1);
          this.rawFilterCounter--;
        }
      }
      this.mergeAttribute(this.postAttributeObject);
      this.mergeFilterAttribute();
      this.mergeFilterSequence();
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
   // console.log(this.filteringAttributeList);
    }

    drop = (event: CdkDragDrop<string[]>) => {
   // console.log(this.selectedAttributes);
      moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
      moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
      this.mergeAttribute(this.postAttributeObject);
      this.mergeFilterAttribute();
      this.mergeFilterSequence();
    }

    mergeAttribute = (obj) => {
   // console.log(obj);
   // console.log(this.postAttributeObject);
      this.postAttributeObject.forEach((e, i) => {
     // console.log(e.attribute_sequence);
        e.attribute_sequence = i + 1;
      });
   // console.log(this.postAttributeObject);
    }

    dropFilter = (event: CdkDragDrop<string[]>) => {
   // console.log(this.postAttributeFilterObject);
   // console.log(this.filteringAttributeList);
      moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
      moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
   // console.log(this.postAttributeFilterObject);
   // console.log(this.filteringAttributeList);
      this.mergeFilterAttribute();
      this.mergeFilterSequence();
    }

    mergeFilterAttribute = () => {
   // console.log(this.postAttributeFilterObject);
      if (this.postAttributeFilterObject.length > 0){
        for (let i = 0; i < this.postAttributeFilterObject.length; i++){
       // console.log(this.postAttributeFilterObject[i]);
          this.postAttributeFilterObject[i].filter_sequence = i + 1;
        }
      }
   // console.log(this.postAttributeFilterObject);
    }

    mergeFilterSequence = () => {
   // console.log('Calling Merge filter');
   // console.log(this.postAttributeFilterObject);
      this.postAttributeFilterObject.forEach((e, i) => {
        const index = this.postAttributeObject.findIndex(newAttribute => newAttribute.attribute_id === e.attribute_id);
     // console.log(index + ' - Matching');
        this.postAttributeObject[index];
        if (index > 0){
          this.postAttributeObject[index].filter_sequence = e.filter_sequence;
        }
      });
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
    submit = () => {
      this.mergeAttribute(this.postAttributeObject);
      this.mergeFilterAttribute();
      this.mergeFilterSequence();
    }

    close = () => {
      this.dialogRef.close({data: null});
    }

    initFormUoM = () => {
      this.formGroupUoM = this.fb.group({
        uom : ['']
      });
      this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).
      subscribe(resp => {
     // console.log(resp);
     // console.log(this.searchKeyUoM);
        if (this.formGroupUoM.get('uom').value != ''){
          this.searchKeyUoM = resp;
          this.searchUoM(resp);
        } else {
         this.searchKeyUoM = '';
         this.searchUoM('');
        }
      });
    }

    searchUoM = (searchKey: any) => {
      this.homeService.getCodeset(searchKey).subscribe(res => {
        if (res) {
       // console.log(res.results.data);
          this.uomValueList = res.results.data;
          this.loading = false;
        }
      });
    }
    selectUoMValue = (e: any, i: any) => {
   // console.log(e);
      this.selecteduom = e;
      if (e !== null){
        this.postAttributeObject[i].uom_codeset_id = e.id;
        this.postAttributeObject[i].uomName = e.attributes.name;
      } else {
        this.postAttributeObject[i].uom_codeset_id = null;
        this.postAttributeObject[i].uomName = null;
      }
      this.uomValueList = [];
      this.formGroupUoM.reset();
    }

    initFormValidValue = () => {
      this.formGroupValidValues = this.fb.group({
        validValue : ['']
      });
      this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).
      subscribe(resp => {
     // console.log(resp);
        if (this.formGroupValidValues.get('validValue').value !== ''){
          this.searchKeyValidValues = resp;
       // console.log(this.searchKeyValidValues);
          this.searchValidValue(this.searchKeyValidValues);
        } else {
         this.searchKeyValidValues = '';
         this.searchValidValue('');
        }
      });
    }

    searchValidValue = (searchKey: any) => {
      this.homeService.getValidValues(searchKey).subscribe(res => {
        if (res) {
       // console.log(res.results.data);
          this.validValueList = res.results.data;
        }
      });
    }
    selectValidValue = (e: any, i: any) => {
      this.selectedValidValue = e;
      if (e !== null){
        this.postAttributeObject[i].value_codeset_id = e.id;
        this.postAttributeObject[i].validValueName = e.attributes.name;
        this.postAttributeObject[i].is_closed = true;
      } else {
        this.postAttributeObject[i].value_codeset_id = null;
        this.postAttributeObject[i].validValueName = null;
        this.postAttributeObject[i].is_closed = false;
      }
      this.validValueList = [];
      this.formGroupValidValues.reset();
    }

    singleSelection = (e, i) => {
      if (e.checked === true){
     // console.log(e);
        this.postAttributeObject[i].is_multi = false;
      } else {
     // console.log(e);
        this.postAttributeObject[i].is_multi = true;
      }
    }

  activeSelection = (e, i) => {
 // console.log(this.postAttributeObject[i].name);
    if (e.checked === true){
        this.postAttributeObject[i].is_active = true;
      } else {
        this.postAttributeObject[i].is_active = false;
      }
  }

  requiredSelection = (e, i) => {
 // console.log(this.postAttributeObject[i].name);
    if (e.checked === true){
      this.postAttributeObject[i].is_required = true;
    }else {
      this.postAttributeObject[i].is_required = false;
    }
  }

  diffSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
   // console.log(this.postAttributeObject[i].name);
      this.postAttributeObject[i].is_differentiator = true;
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_differentiator = false;
    }
  }

  setMinValue = (i, value) => {
    this.postAttributeObject[i].min_value = value;
  }

  getIcon = (name) => {
    const arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  saveDraft = () => {
    this.submit();
 // console.log(this.previousAttributes);
 // console.log(this.postAttributeObject);
    if (this.postAttributeObject === ''){
      this.snackBar.open(`Please fill the values properly.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    } else {
      this.postAttributeObject.forEach(element => {
        delete element.name;
        delete element.validValueName;
        delete element.uomName;
      });
      const data = {
        edit_type: 'attribute_sequence_edit',
        category_id: this.data.id,
        new_value: this.postAttributeObject,
        original_value: this.previousAttributes,
        note: this.notes,
        hierarchy_id: this.selectedHierarchy.id,
        version_id: this.selectedVersion.id,
        internal_ticket_id: this.ticketId
      };
   // console.log(data);
      this.nodeService.nodeEdit(data).subscribe({
        next: (resp: any) => {
       // console.log(resp);
          if (resp.data.id > 0){
            // this.formSubmitted = false;
            this.draftId = resp.data.id;
         // console.log(this.draftId);
            if (this.draftId > 0 && this.files.length > 0){
              setTimeout(() => {
                let file = {
                  attachments: this.files
                };
                let form = new FormData();
                this.files.forEach(e => {
                  form.append('attachments', e);
                });
             // console.log(form);
                this.nodeService.uploadFile(this.draftId, form).subscribe({
                  next: resp => {
                 // console.log('resp');
                    this.snackBar.open(`Draft ticket for attribute sequence edit created Successfully.`, 'OK', {
                      duration: 4000,
                      panelClass: ['error-snackbar']
                    });
                    this.dialogRef.close({data: this.postAttributeObject});
                  }
                });
              }, 2000);
            } else {
              this.snackBar.open(`Draft ticket for attribute sequence edit created Successfully.`, 'OK', {
                duration: 4000,
                panelClass: ['error-snackbar']
              });
              this.dialogRef.close({data: this.postAttributeObject});
            }

          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
       // console.log(HttpResponse.error. detail);
          this.errorHandler.pass(HttpResponse.error);
        }
      });
   // console.log(this.files);
      this.dialogRef.close(null);
    }
  }
  onScroll = (event: any) => {
    if (this.attributeCounter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10) - 1) {
     // console.log('scrolling category');
        if (this.attributeCounter.page < this.attributeCounter.totalPages){
          this.attributeCounter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.attributeCounter.page);
        }else{
       // console.log('Cant Load' + this.attributeCounter.page);
        }
      }
    }
  }

  patchCategoryList = (i) => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey)
    .subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.attributeList = this.attributeList.concat(res.results.data);
            this.attributeCounter.totalPages = res.total_pages;
            this.attributeCounter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }

}
