import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-table',
  templateUrl: './home-table.component.html',
  styleUrls: ['./home-table.component.scss']
})
export class HomeTableComponent implements OnInit {

  list = [1,2,3,4,5,6,7,8];
  constructor() { }
  ngOnInit(): void {
  }

}
