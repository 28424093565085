<div class="tabContainer">
    <app-loader *ngIf="loading"></app-loader>
    <mat-card fxLayout="row" *ngIf="!loading">
        <div fxFlex="50">
            <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketID">
                </mat-form-field>
            </div>
            <div class="textArea">
                <mat-label>Notes</mat-label>
                <mat-form-field appearance="outline">
                    <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                </mat-form-field>
            </div>
            
        </div>
        <div fxFlex="50">
            <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                <ngx-dropzone-label>
                    <mat-icon svgIcon="upload"></mat-icon>
                    <p>Drag or Drop your files here</p>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    
                    <ngx-dropzone-label fxLayout="row">
                        <div fxFlex="50">
                            <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                            </mat-icon>    
                        </div>
                        <div fxFlex="50">
                            <span style="font-size: 10px;">{{ f.name }}</span>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
    </mat-card>
    <div mat-dialog-actions style="float: right;">
        <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submit()" >SAVE</button>
        <button mat-raised-button class="close-btn-style" (click)="cancel()" >CLOSE</button>
    </div>
</div>