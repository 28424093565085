import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HomeService } from 'src/app/services/home.service';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NodeService } from 'src/app/services/node.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-mapped-values',
  templateUrl: './mapped-values.component.html',
  styleUrls: ['./mapped-values.component.scss']
})
export class MappedValuesComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) validValuesAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) uomAutoComplete: MatAutocompleteTrigger;

  tabLoader = true;
  tabIndex = 0;
  loading = true;
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  searchKeyValidValues = '';
  searchKeyUoM = '';
  uomValueList:any = [];
  validValueList:any = [];
  selectedCodeSet;
  selectedValidValue;
  validValueData:any = {};
  uomValueData:any = {};
  uomCodeSet:any = [];
  valueCodeSet:any = [];
  validValues:any = [];
  files:any = [];
  userData:any = null;
  userType:any = null;
  isAdmin = false;
  constructor(
    private fb:FormBuilder,
    private homeService: HomeService,
    private sharedService: SharedDataService,
    private iconRegistry:MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog:MatDialog,
    private snackBar: MatSnackBar,
    private nodeService: NodeService
  ) {
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
  }

  ngOnInit(): void {
    this.formGroupValidValues = this.fb.group({
      'validValue' :['']
    });
    this.formGroupUoM = this.fb.group({
      'uom' :['']
    });
    this.initFormUoM();
    this.initFormValidValue();
    this.loading = false;
    this.checkGroup();
  }

  checkGroup = () => {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
  // console.log(this.userData);
    this.userType = this.userData.groups;
    this.userType.forEach(e => {
      if(e.name == 'administrator'){
        this.isAdmin = true;
      }
    });

  }

  tabChanged = (e) => {
    this.tabLoader = true;
    this.tabIndex = e.index;
 // console.log(this.tabIndex);    
  }

  getValidValues = (id) => {
    
  }

  // Get Data
  initFormUoM() {
    this.formGroupUoM = this.fb.group({
      'uom' :['']
    })
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);      
      if(this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {
       
      }
    })
  }

  searchUoM = (searchKey) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }

  selectUoMValue = (e:any) => {
 // console.log(e);
    this.getUomDetails(e.id);
  }

  initFormValidValue() {
    this.formGroupValidValues = this.fb.group({
      'validValue' :['']
    })
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if(this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);      
        this.searchValidValue(this.searchKeyValidValues);
      } else {
       
      }
    })
  }

  searchValidValue = (searchKey) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
        // console.log(res.results.data);
        this.validValueList = res.results.data;
      }
    });
  }

  downloadUoM = () => {
    this.nodeService.exportUoM();
  }

  downloadValidValue = () => {
    this.nodeService.exportValidValues();
  }

  selectValidValue = (e:any) => {
 // console.log(e);
    this.getValidValueDetails(e.id);
    // this.validValueList = [];
  }

  getUomDetails = (id) => {
    this.sharedService.getUomDetails(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.uomValueData = res.data;
        this.uomCodeSet = this.uomValueData.attributes.units.data;

      }
    });
  }

  getValidValueDetails = (id) => {
    this.sharedService.getValidValuesDetails(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.validValueData = res.data;
        this.validValues = this.validValueData.attributes.values.data;
      }
    });
  }

  uploadDialog(): void {
    const dialogRef = this.dialog.open(DialogUploadUOM, {
      width: '50vw',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      // if (result)
      // {
      //   this.snackBar.open('Uploaded Successfully', '', {
      //     duration: 2000,
      //   });
      // } else {
      // }
    }); 
  }

  

}



/*---------- Upload Dialog Box */


// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './upload.html',
  styleUrls: ['./mapped-values.component.scss']
  })
  export class DialogUploadUOM {
  
  uomFiles: File | null = null;
  validValueFiles: File | null = null;
  constructor(
    public dialog: MatDialog,
    private iconRegistry:MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<DialogUploadUOM>,
    private fb:FormBuilder,
    private snackBar:MatSnackBar,
    private errorHandler: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.iconRegistry.addSvgIcon(
        'upload',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/addFolder.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'zip',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/zip.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'xls',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/xls.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'pdf',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/pdf.svg'
        ),
      );
    }
    
    // Edit ------
    ngOnInit(): void {

    }
    
    submitUpload =()=>{
   // console.log(this.uomFiles);
      var form = new FormData();
      form.append("file", this.uomFiles[0]);
      this.nodeService.uploadCodeset(form).subscribe({
      next: (resp:any) => {
     // console.log(resp);
        this.snackBar.open('Files uploaded Successfully', '', {
          duration: 5000,
        });
        this.dialogRef.close();
        },
        error: (HttpResponse: HttpErrorResponse) => {
          // this.formSubmitted = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
      
    }

    close = () => {
      this.dialogRef.close();    
    } 

    getIcon = (name) => {
      let arr = name.split(".");
      // console.log(arr);
      if(arr[1]=='xlsx'){
        return 'xls';
      } else if(arr[1]=='zip'){
        return 'zip';
      } else if(arr[1]=='pdf'||arr[1]=='docx'){
        return 'pdf';
      } else {
        return 'zip';
      }
      
    }

    onSelectUOM(event) {
   // console.log(event);
      this.uomFiles=event.addedFiles;
    }
    
    onRemoveUOM(event) {
   // console.log(event);
      this.uomFiles=null;
    }
        
  }
  