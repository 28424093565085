<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Add Node</span>
        </div>
        <div fxFlex="30" fxLayout="column" fxLayoutAlign="end center" *ngIf="selectedNodeData && selectedNodeData.attributes.is_leaf" class="warning">
            <span style="font-style: normal;font-size: 12px;line-height: 14px;color: #3B3E48;">
                Adding a category under a leaf node will convert {{selectedNodeData.attributes.category.data.attributes.name}} into non-leafnode.
            </span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button (click)="save()" class='buttonSuccess'>Proceed</button>
            </div>
            <div>
                <button mat-raised-button (click)="cancel()" class="buttonCancel">Cancel</button>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" *ngIf="!loading && selectedNodeData && selectedNodeData.attributes">
            <div fxFlex="50">
                <div>
                    <mat-label>New Category Code</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="newCategoryId">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>New Category Name</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="categoryName">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Description</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="description"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" style="width: 100%;height: 30px;">
                    <section class="example-section" fxFlex="30">
                        <mat-checkbox class="example-margin" [(ngModel)]="isLeaf" >Is Leaf Node</mat-checkbox>
                    </section>
                    <div fxFlex="70" fxLayout="column" fxLayoutAlign="center end"><button *ngIf="isLeaf" (click)="openDialog()" mat-raised-button>Select Attributes</button></div>
                </div>
                
                <div style="margin-top: 20px;">
                    <mat-label>Connect to (Level)</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput readonly value="{{selectedNodeData.attributes.category.data.attributes.name}} (Level {{selectedNodeData.attributes.level}})">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
    </div>
</div>
