import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { DialogNodeInfoDraft, SnackBarUndo } from '../draft/draft.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VersionService } from 'src/app/services/version.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { TreeDialogComponent } from '../_shared/tree-dialog/tree-dialog.component';
import { HierarchyInfoComponent } from '../_shared/hierarchy-info/hierarchy-info.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  subscription: Subscription;
  @ViewChild('_snackBar', { static: false }) _snackBar: SnackBarUndo;
  constructor(
    public dialog: MatDialog,
    private infoService: InfoService,
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    private sharedDataService: SharedDataService,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private sharedService: SharedDataService,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'send',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/send.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'export',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/export.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'draft-list',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/draft-icon.svg'
      )
    );
  }
  formGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = true;
  formSubmitted = false;
  draftId = 0;
  editId = 0;
  userData: any = null;
  userDisplayName: any = null;
  userDisplayPicture: any = 'assets/images/user.jpg';
  suggestions;
  data;
  page = 0;
  pageSize = 100;
  totalItems = 0;
  editedNode;
  currentUserDisplayName = '';
  allDraftList = [];
  draftList = [];
  draftData: any = [];
  nodeCheckList: any = [];
  nodeCheckCount = 1;
  parentNodeData: any = [];
  previousParentNodeData: any = {
    attributes: null
  };
  attributeData: any = [];
  requestType: any = null;
  selectedDraftId;
  fileList: any = [];
  draftDataRaw: any = null;
  taskState = null;
  selectedDraftObj: any = null;
  multipleRequests = false;
  commentList: any = [];
  comment: any = null;
  userId = 2;
  addedAttributeListNew: any = [];
  addedAttributeListOld: any = [];
  addedAttributeList: any = [];
  currentVersion: any = null;
  frozen = false;
  previous = false;
  ticketId: any = null;
  redirectedFromList = false;
  attributeName: any = null;
  childNodesData = [];
  codesetName = '';
  uomName = '';
  draftVersion = null;
  draftHierarchy = null;
  ngOnInit(): void {
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    if(this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
      this.previous = !(this.currentVersion.attributes.is_current);
    }
    this.redirectedFromList = this.sharedDataService.draftListRedirection;
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.userData.id;
    if (this.userData.attributes){
      this.currentUserDisplayName = this.userData.username;
   // console.log(this.currentUserDisplayName);
    }
    this.redirectedFromList = this.sharedDataService.draftListRedirection;
 // console.log(this.redirectedFromList);
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
 // console.log(this.userData);
    this.userId = this.userData.id;
    if (this.userData.data){
      this.currentUserDisplayName = this.userData.username;
   // console.log(this.currentUserDisplayName);
    }
    if (this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
      this.currentVersion = this.currentVersion.attributes.name;
    }
    if(!this.redirectedFromList){
      this.getDraftCount();
      // this.getDraftList();
    } else {
      this.selectedDraftId = this.nodeService.selectedDraft;
      this.taskState = this.sharedDataService.draftNodeStatus;
      this.getOneTicket(this.selectedDraftId);
      this.totalItems = 1;
      this.sharedDataService.draftListRedirection = false;
    }
  }

  getOneTicket = (id) => {
    this.getInfo(id);
  }

  moveToDraftList = () => {
 // console.log('Going to Draft');
    this.sharedDataService.draftListSource = 'review';
    this.router.navigate(['/draft-list']);
  }

  reloadAllDraft = () => {
    this.nodeService.selectedDraft = null;
    this.allDraftList.forEach(e => {
        if (e.id === this.selectedDraftId){
       // console.log('Id matched');
        } else {
       // console.log('Id not matched');
        }
    });
  }
  impact = 0;
  getImpact = (draftId: any) => {
    this.nodeService.getImpact(draftId).subscribe({
      next: (res: any) => {
        if (res) {
       // console.log(res);
          this.impact = res.skus_impacted;
          if(this.impact === null) {
            this.snackBar.open('No SKUs impacted.', 'OK', {
              duration: 4000,
              panelClass: ['error-snackbar']
            });
          }
          return res;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.formSubmitted = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  refresh = () => {
 // console.log('reload');
    window.location.reload();
  }

  getDraftCount = () => {
    this.nodeService.getDraftCount('review in progress').subscribe({
      next: res => {
     // console.log(res);
        this.totalItems = res.total_count;
        this.pageSize = this.totalItems;
        this.getDraftList();
      }
    });
  }

  getDraftList = () => {
    this.draftList = [];
    this.allDraftList = [];
    this.totalItems = 0;
    this.page = 0;
 // console.log(this.draftList);
 // console.log(this.allDraftList);

    this.nodeService.getDraftList(this.page, this.pageSize, 'review in progress').subscribe(async res => {
      if (res) {
        this.allDraftList = res.results.data;
        this.totalItems = this.allDraftList.length;
     // console.log(this.allDraftList);
        await this.allDraftList.forEach(e => {
          if (e.attributes.workflow_state === 'review in progress')
          {
            this.draftList.push(e);
         // console.log(e.attributes.workflow_state);
           }
        });
     // console.log(this.draftList);
        this.draftList = this.draftList.filter(function (value, index, array) {
          return array.indexOf(value) === index;
        });
     // console.log(this.draftList);
        if (this.redirectedFromList && this.totalItems > 0){
          this.totalItems = 1;
        } else{
          this.totalItems = this.draftList.length;
        }
        if (this.draftList.length > 0){
          if (this.selectedDraftId){
            this.getInfo(this.selectedDraftId);
          } else {
            this.getInfo(this.draftList[0].id);
          }
        } else {
          this.loading = false;
        }
      }
    });
  }

  getFiles = (id: any) => {
    this.nodeService.getDraftFiles(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.fileList = res.data;
      }
    });
  }

  getInfo = (id: any) => {
    this.selectedDraftId = null;
    this.selectedDraftObj = null;
    this.draftData = [];
    this.draftDataRaw = null;
    this.multipleRequests = false;
    this.selectedDraftId = id;
    this.draftId = id;
 // console.log(id);
    this.getFiles(id);
    this.getNodeInfo(id);
  }

  getNodeInfo = (id: any) => {
    this.nodeService.getDraft(id).subscribe(res => {
      if (res) {
        this.draftDataRaw = res.data;
     // console.log(this.draftDataRaw);
        this.notes = '';
        this.multipleRequests = Array.isArray(this.draftDataRaw);
     // console.log(this.multipleRequests);
        if (!this.multipleRequests){
          this.draftData[0] = this.draftDataRaw;
        } else {
          this.draftData = this.draftDataRaw;
        }
     // console.log(this.draftData);
     // console.log(this.selectedDraftObj);
        if (this.selectedDraftObj === null){
          this.selectedDraftObj = this.draftData[0];
          this.selectedDraftId = this.selectedDraftObj.id;
        } else {

        }
        if (this.selectedDraftObj.attributes.note) {
          this.notes = this.selectedDraftObj.attributes.note;
        }
        this.ticketId = this.selectedDraftObj.attributes.internal_ticket_id;
     // console.log(this.notes);
     // console.log(this.selectedDraftObj.attributes.context_data.edit_type);
        this.requestType = this.selectedDraftObj.attributes.context_data.edit_type;
        this.taskState = this.selectedDraftObj.attributes.workflow_state;
        this.draftVersion = this.selectedDraftObj.attributes.context_data.version_id;
        this.draftHierarchy = this.selectedDraftObj.attributes.context_data.hierarchy_id;
        if (this.requestType === "category_addition"){
          // Case 1 - Add Category
       // console.log(this.selectedDraftObj.attributes.context_data.target_node);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.target_node).subscribe(res => {
          if (res) {
            this.nodeData = res.data;
         // console.log(this.nodeData);
            this.addedAttributeList = this.draftData.attributes.context_data.attributes;
            this.convertToName(this.addedAttributeList, 2);
          }
          });
        } else if (this.requestType === 'category_field_edit'){
          // Case 2 - Edit Category
       // console.log(this.selectedDraftObj.attributes.context_data.category_id);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.category_id).subscribe(res => {
            if (res) {
              this.nodeData = res.data;
           // console.log(this.data);
            }
          });
        } else if (this.requestType === 'attribute_addition'){
          // Case 3 - Add Attribute
       // console.log(this.selectedDraftObj.attributes.context_data.category_id);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.category_id).subscribe(res => {
            if (res) {
              this.parentNodeData = res.data;
           // console.log(this.data);
            }
          });
       // console.log(this.parentNodeData);
        } else if (this.requestType === 'attribute_field_edit'){
          // Case 4 - Edit Attribute
          if (this.selectedDraftObj.attributes.context_data.attribute_id){
            this.infoService.getAttributeInfo(this.selectedDraftObj.attributes.context_data.attribute_id).subscribe(async res => {
              if (res) {
             // console.log(res);
                this.attributeName = res.data.attributes.name;
              }
            });
          }
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.category_id).subscribe(res => {
            if (res) {
           // console.log(res);
              this.parentNodeData = res.data;
           // console.log(this.parentNodeData);
            }
          });
       // console.log(this.draftData);
          this.draftData.forEach(e => {
         // console.log(e);
            e.uomName = null;
            e.codesetName = null;
            if(e.attributes.context_data.field_name === 'value_codeset'){
                if(e.attributes.context_data.new_value){
                  this.sharedService.getValidValuesDetails(e.attributes.context_data.new_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.codesetName = res.data.attributes.name;
                    }
                  });
                } else {
                  e.codesetName = '';
                }
                if(e.attributes.context_data.original_value){
                  this.sharedService.getValidValuesDetails(e.attributes.context_data.original_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.oldcodesetName = res.data.attributes.name;
                    }
                  });
                } else {
                  e.oldcodesetName = '';
                }
              } else if(e.attributes.context_data.field_name === 'uom_codeset'){
                if(e.attributes.context_data.new_value){
                  this.sharedService.getUomDetails(e.attributes.context_data.new_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.uomName = res.data.attributes.name;
                    }
                  });
                } else {
                  e.uomName = '';
                }
                if(e.attributes.context_data.original_value){
                  this.sharedService.getUomDetails(e.attributes.context_data.original_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.olduomName = res.data.attributes.name;
                    }
                  });
                } else {
                  e.olduomName = '';
                }
              }
          });
        } else if (this.requestType === 'category_relation_add'){
            this.childNodesData = [];
         // console.log('Category Addition Multiple');
         // console.log(this.selectedDraftObj);
            this.infoService.getDraftNodeData
            (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.target_node).subscribe(res => {
              if (res) {
                this.parentNodeData = res.data;
             // console.log(this.parentNodeData);
                // this.basepath = res.data.attribtues.path;
              }
            });
            let childIDList = [];
            this.selectedDraftObj.attributes.context_data.relation_to_add.forEach( e => {
              const item = {
                id: e.child_id,
                name: '',
                level: e.level,
                parent: e.parent_id
              };
              childIDList.push(e.child_id);
              this.childNodesData.push(item);
            });
         // console.log(childIDList);
         // console.log(this.childNodesData);
            const ids = {
              category_ids: childIDList
            };
            this.hierarchyService.getNodeData(ids).subscribe(res => {
              if (res) {
             // console.log(res);
                res.data.forEach(e => {
                  const index = this.childNodesData.findIndex(x => x.id === e.id);
               // console.log(e);
               // console.log('Matching Index ' + index);
                  if(index >= 0){
                    this.childNodesData[index].name = e.attributes.name;
                  }
                });
              }
            });

        }  else if (this.requestType === 'category_relation_edit'){
          // Case 6 - Edit Relation
       // console.log(this.selectedDraftObj.attributes.context_data.node_to_move);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.node_to_move).subscribe(res => {
            if (res) {
              this.previousParentNodeData = res.data;
           // console.log(this.previousParentNodeData);
            }
          });
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.target_node).subscribe(res => {
            if (res) {
              this.parentNodeData = res.data;
           // console.log(this.parentNodeData);
            }
          });
        } else if (this.requestType === 'category_detach'){
          // Case 7 - Edit Relation
       // console.log(this.selectedDraftObj.attributes.context_data.node_to_detach);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.node_to_detach).subscribe(res => {
            if (res) {
              this.nodeData = res.data;
           // console.log(this.nodeData);
            }
          });
        } else if (this.requestType === 'attribute_sequence_edit'){
          // Case 7 - Edit Attribute Order
       // console.log(this.selectedDraftObj);
          // this.getNodeInfo(this.selectedDraftObj.attributes.context_data.category_id);
          this.infoService.getDraftNodeData
          (this.draftVersion, this.draftHierarchy, this.selectedDraftObj.attributes.context_data.category_id).subscribe(res => {
            if (res) {
              this.nodeData = res.data;
              this.addedAttributeListNew = this.selectedDraftObj.attributes.context_data.new_value;
              this.addedAttributeListOld = this.selectedDraftObj.attributes.context_data.original_value;
              this.convertToName(this.addedAttributeListNew, 1);
              this.convertToName(this.addedAttributeListOld, 0);
            }
          });
        } else {
          this.nodeData = null;
        }
        this.loading = false;
      }
    });
  }

  compareObject = (oldAttributeList, newAttributeList) => {
    newAttributeList.forEach(e => {
      // console.log(e.name);
      e.different = [];
    });
    oldAttributeList.forEach(e => {
      // console.log(e.name);
      e.different = [];
    });
    // console.log(oldAttributeList);
    oldAttributeList.forEach((_old, index) => {
      newAttributeList.forEach((_new, index) => {
        if (_old.attribute_id === _new.attribute_id && _old.filter_sequence !== _new.filter_sequence){
          _new.different.push('reordered');
        }
      });
    });

    // To check deleted Attributes
    const deleted = oldAttributeList.filter(o1 => !newAttributeList.some(o2 => o1.attribute_id === o2.attribute_id));
    // console.log(deleted);
    deleted.forEach(diff => {
      // console.log(diff);
      const index = oldAttributeList.indexOf(diff);
      // console.log(index);
      if (index > -1){
        oldAttributeList[index].different.push('deleted');
      }
    });
    // To check newly added Attributes
    const newAdded = newAttributeList.filter(o1 => !oldAttributeList.some(o2 => o1.attribute_id === o2.attribute_id));
    // console.log(newAdded);
    newAdded.forEach(diff1 => {
      // console.log(diff1);
      const index = newAttributeList.indexOf(diff1);
      // console.log(index);
      if (index > -1){
        newAttributeList[index].different.push('new');
      }
    });
    let referenceOld = [];
    let referenceNew = [];
    // Compare Remaining Keys
    newAttributeList.forEach((e, i) => {
      if (!e.different.includes('new') && !e.different.includes('deleted')) {
        referenceNew.push(e);
      }
    });
    oldAttributeList.forEach((e, i) => {
      if (!e.different.includes('new') && !e.different.includes('deleted')) {
        referenceOld.push(e);
      }
    });

    oldAttributeList.forEach((_old, index) => {
      newAttributeList.forEach((_new, index) => {
        if (_old.attribute_id === _new.attribute_id && _old.attribute_sequence !== _new.attribute_sequence){
          _new.different.push('seq_reordered');
        }
      });
    });
    for (let i = 0; i < referenceOld.length; i++){
      for (let j = 0; j < referenceNew.length; j++){
        if (referenceOld[i].attribute_id === referenceNew[j].attribute_id){
          console.log(referenceOld[i].attribute_id);
          console.log(referenceNew[j].attribute_id);
          console.log('Checking is active');
          console.log(referenceOld[i].is_active);
          console.log(referenceNew[j].is_active);
          console.log(referenceOld[i].is_active === referenceNew[j].is_active);
          // console.log(referenceNew[j].different);
          if (referenceOld[i].is_active !== referenceNew[j].is_active){
            {
              newAttributeList[j].different.push('is_active');
            }
          }
          if (referenceOld[i].is_closed !== referenceNew[j].is_closed){
            {
              newAttributeList[j].different.push('is_closed');
            }
          }
          if (referenceOld[i].is_differentiator !== referenceNew[j].is_differentiator){
            {
              newAttributeList[j].different.push('is_differentiator');
            }
          }
          if (referenceOld[i].is_filter !== referenceNew[j].is_filter){
            {
              newAttributeList[j].different.push('is_filter');
            }
          }
          if (referenceOld[i].is_multi !== referenceNew[j].is_multi){
            {
              newAttributeList[j].different.push('is_multi');
            }
          }
          if (referenceOld[i].is_required !== referenceNew[j].is_required){
            {
              newAttributeList[j].different.push('is_required');
            }
          }
          // For minimum Value
          if (referenceOld[i].min_value === null || referenceOld[i].min_value.length === 0){
            referenceOld[i].min_value = '';
          }
          if (referenceNew[j].min_value === null || referenceNew[j].min_value.length === 0){
            referenceNew[j].min_value = '';
          }
          // console.log(referenceOld[i].attribute_id);
          // console.log(referenceNew[j].attribute_id);
          // console.log(referenceOld[i].min_value === referenceNew[j].min_value);
          // console.log(referenceOld[i].min_value !== referenceNew[j].min_value);
          if (referenceOld[i].min_value !== referenceNew[j].min_value){
            {
              newAttributeList[j].different.push('min_value');
            }
          }
          if (referenceOld[i].uom_codeset_id !== referenceNew[j].uom_codeset_id){
            {
              newAttributeList[j].different.push('uom_codeset_id');
            }
          }
          if (referenceOld[i].value_codeset_id !== referenceNew[j].value_codeset_id){
            {
              newAttributeList[j].different.push('value_codeset_id');
            }
          }
        }
      }
    }

  }

  convertToName = (list: any, listType: number) => {
    let nameList = [];
    let attributeList = [];
    list.forEach(e => {
      attributeList.push(e.attribute_id);
    });
    const reqParam = {
      attribute_ids: attributeList
    };
 // console.log(reqParam);
    this.hierarchyService.getAttributeData(reqParam).subscribe(async res => {
      if (res) {
        // await res;
     // console.log(res);
        nameList = res.data;
        this.loading = false;
        await this.pushNames(nameList, listType);
      }
    });
    // setTimeout(function(){
    //       this.loading = false;
    //     },2000);
 // console.log(this.addedAttributeListOld);
    this.compareObject(this.addedAttributeListOld, this.addedAttributeListNew);
  }

  pushNames = (nameList, listType) => {
    if (listType === 1){
   // console.log('New attribute list');
      nameList.forEach((e) => {
     // console.log(e);
        const index = this.addedAttributeListNew.findIndex(x => x.attribute_id === e.id);
     // console.log(this.addedAttributeListNew[index]);
        if(this.addedAttributeListNew[index].uom_codeset_id){
          this.sharedService.getUomDetails(this.addedAttributeListNew[index].uom_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListNew[index].uomName = res.data.attributes.name;
            }
          });
        } else{
          this.addedAttributeListNew[index].uomName = null;
        }
        if(this.addedAttributeListNew[index].value_codeset_id){
          this.sharedService.getValidValuesDetails(this.addedAttributeListNew[index].value_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListNew[index].validValueName = res.data.attributes.name;
            }
          });
        } else{
          this.addedAttributeListNew[index].validValueName = null;
        }
        this.addedAttributeListNew[index].name = e.attributes.name;
      });
    } else if (listType === 0){
   // console.log('Old attribute list');
      nameList.forEach((e, n) => {
        const index = this.addedAttributeListOld.findIndex(x => x.attribute_id === e.id);
     // console.log(this.addedAttributeListOld[index]);
        if(this.addedAttributeListOld[index].uom_codeset_id){
          this.sharedService.getUomDetails(this.addedAttributeListOld[index].uom_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListOld[index].uomName = res.data.attributes.name;
            }
          });
        } else {
          this.addedAttributeListOld[index].uomName = null;
        }
        if(this.addedAttributeListOld[index].value_codeset_id){
          this.sharedService.getValidValuesDetails(this.addedAttributeListOld[index].value_codeset_id).subscribe(res => {
            if (res) {
           // console.log(res.data);
              this.addedAttributeListOld[index].validValueName = res.data.attributes.name;
            }
          });
        } else {
          this.addedAttributeListOld[index].validValueName = null;
        }
     // console.log(this.addedAttributeListOld[0]);
        this.addedAttributeListOld[index].name = e.attributes.name;
      });
    } else if (listType === 2){
   // console.log('Newly added attribute list');
      nameList.forEach((e, n) => {
        const index = this.addedAttributeList.findIndex(x => x.attribute_id === e.id);
     // console.log(index);
     // console.log(e);
     // console.log(this.addedAttributeList[0]);
        this.addedAttributeList[index].name = e.attributes.name;
      });
    }
 // console.log(this.addedAttributeList);
  }

  changePage = (dir) => {
    this.impact = 0;
 // console.log(dir);
    this.loading = true;
    this.draftData = [];
    this.nodeData = null;
    if (dir === 'n' && this.page < this.totalItems){
      this.page++;
   // console.log(this.page);
      const newId = this.draftList[this.page].id;
   // console.log('New ID' + newId);
      this.draftId = newId;
      this.getInfo(newId);
    } else if (dir === 'p' && this.page > 0){
      this.page--;
   // console.log(this.page);
      const newId = this.draftList[this.page].id;
   // console.log('Previous ID' + newId);
      this.draftId = newId;
      this.getInfo(newId);
    }
 // console.log('Previous ID' + this.draftId);
  }

  selectDraft = (node) => {
    // this.loading = true;
    this.selectedDraftId = null;
    this.selectedDraftObj = null;
    this.draftData = [];
    this.draftDataRaw = null;
    this.multipleRequests = false;
    this.fileList = [];
 // console.log(node);
    this.selectedDraftId = node.id;
    this.draftId = node.id;
 // console.log(this.selectedDraftId);
    this.selectedDraftObj = node;
    this.getFiles(node.id);
    this.getNodeInfo(node.id);
  }

  reject = (id) => {
    this.loading = true;
    if (id) {
      const msg = id;
      const snackBar = this.snackBar.openFromComponent(SnackBarRejectReview, {
        data: {msg, id, preClose: () => {snackBar.dismiss(); this.refresh(); }}
      });
    }
  }

  postToReview = () => {
 // console.log(this.draftId);
    this.loading = true;
    this.nodeService.postToApprove(this.draftId).subscribe(res => {
      if (res) {
        this.snackBar.open('Ticket submitted for Approval.', 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
        window.location.reload();
      }
    });
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }

  getIcon = (name) => {
    let arr = name.split(".");
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }

  goToNode = (id) => {
 // console.log(id);
    // this.homeService.selectedNodeId = id;
    // this.homeService.nodeSelection = 1;
    // this.sharedDataService.changeSideNavStatus(1);
    // this.router.navigate(['/home']);
  }

  download = (url) => {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  openAttributeOrder(id): void {
 // console.log(id);
    let reqType = this.requestType;
    let newList = this.addedAttributeListNew;
    let oldList = this.addedAttributeListOld;
    this.infoService.nodeId = id;
    // let attributeList
    if (this.requestType === 'attribute_sequence_edit'){
      const dialogRef = this.dialog.open(DialogReviewView, {
        width: '98vw',
        height: '80vh',
        data: {id, reqType, newList, oldList, }
      });
      dialogRef.afterClosed().subscribe(result => {
     // console.log(result);
        // if (result)
        // {
        //   this.snackBar.open('Marked Successfully', '', {
        //     duration: 2000,
        //   });
        // } else {
        // }
      });
    } else{
      const dialogRef = this.dialog.open(DialogReviewView, {
        width: '98vw',
        height: '80vh',
        data: {id, reqType, newList, oldList, }
      });
      dialogRef.afterClosed().subscribe(result => {
     // console.log(result);
        // if (result)
        // {
        //   this.snackBar.open('Marked Successfully', '', {
        //     duration: 2000,
        //   });
        // } else {
        // }
      });
    }
  }

  viewHierarchy = (category) => {
 // console.log(category);
    let categoryName = null;
    if (category){
      categoryName = category.attributes.category.data.attributes.name;
    }
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-hierarchy';
    dialogConfig.width = '60vw';
    dialogConfig.data = {categoryId: category.attributes.category_id, categoryName};
    const modalDialog = this.dialog.open(
      HierarchyInfoComponent, dialogConfig
      );
  }

  viewRelations = () =>{
 // console.log(this.parentNodeData);
 // console.log(this.draftData);
 // console.log(this.childNodesData);
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '90vh';
    dialogConfig.width = '95vw';
    dialogConfig.data = {parentData : this.parentNodeData, draftData : this.draftData, childData: this.childNodesData};
    const modalDialog = this.dialog.open(
      TreeDialogComponent, dialogConfig
      );
  }

  openDialog(id): void {
 // console.log(id);
    this.infoService.nodeId = id;
    const dialogRef = this.dialog.open(DialogNodeInfo, {
      width: '98vw',
      data: {id, version : this.draftVersion, hierarchy: this.draftHierarchy}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
    });
  }
}


@Component({
  selector: 'app-upload-progress-snackbar',
  styleUrls: ['./review.component.scss'],
  template: `
  <div fxLayout="row" style="width: 100%;">
    <div fxLayout="column">
      <span>Ticket submitted for approval.</span>
    </div>
    <div  fxLayout="column" fxLayoutAlign="center end">
      <button class="undo" mat-raised-button (click)="undo()">Undo ({{counter}})</button>
    </div>
  </div>
  `,

})
export class SnackBarAccept implements OnInit {
  // @Output() parentFun: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDismissClick = new EventEmitter<any>();

  subscription: Subscription;
  counter = 5;
  interval: any;
  constructor(private nodeService: NodeService,
  private router: Router,
  @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
    this.count();
  }

  count = () => {
    this.interval = setInterval(() => {
      this.counter--;
      if (this.counter <= 0){
        clearInterval(this.interval);
        this.submit(this.data.id);
        this.data.preClose();
        return false;
      }
    }, 1000);
  }

  undo(){
 // console.log('Task not submitted.');
    this.data.preClose();
  }

  // close(){
  //   // console.log(this.data.id);
  //   this.router.navigate(['/review']).then(() => {
  //     // window.location.reload();
  //   });
  //   // pass Change --------
  //   this.data.preClose();
  // }

  submit = (id) => {
    this.nodeService.postToApprove(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.nodeService.changeReviewStatus(1);
      }
    });
  }

}

@Component({
  selector: 'app-reject',
  styleUrls: ['./review.component.scss'],
  template: `
  <div fxLayout="row" style="width: 100%;">
    <div fxFlex="70" fxLayout="column">
      <span style="padding: 10px 0px;">Draft Discarded</span>
    </div>
    <div fxLayout="row" fxLayoutALign="start end">
      <button class="undo" (click)="undo()">Undo ({{counter}})</button>
      <button class="undo" (click)="submit(data.id)">Skip</button>
    </div>
  </div>
  `,

})
export class SnackBarRejectReview implements OnInit {
  // @Output() parentFun: EventEmitter<any> = new EventEmitter<any>();

  @Output() onDismissClick = new EventEmitter<any>();

  subscription: Subscription;
  counter = 5;
  interval: any;
  constructor(
    private nodeService: NodeService,
    private router: Router,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.count();
  }

  count = () => {
    this.interval = setInterval(() => {
      this.counter--;
      if (this.counter < 1){
      //  console.log(this.interval);
        clearInterval(this.interval);
      //  console.log(this.interval);
        this.submit(this.data.id);
        this.data.preClose();
      }
    }, 1000);
  }

  undo(){
  //  console.log('Task not submitted.');
    this.counter = 0;
    clearInterval(this.interval);
    this.data.preClose();
  }

  submit = (id) => {
  //  console.log('Called submitted');
    this.nodeService.rejectDraft(id).subscribe(res => {
      console.log(res);
    });
    this.data.preClose();
  }

}


// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './nodeInfo.html',
  styleUrls: ['./review.component.scss']
})
  export class DialogNodeInfo {
  constructor(
    public dialog: MatDialog,
    private infoService: InfoService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogNodeInfo>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    private nodeService: NodeService,
    private errorHandler: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
    }
  tab1Data: any = null;
  attribute: any = null;
  productType: any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  page = 0;
  pageSize = 10;
  totalItems = 0;
  editedNode;
  attachment;
  currentUserDisplayName = '';
  allDraftList = [];
  draftList = [];
  draftData;
  nodeCheckList: any = [];
  nodeCheckCount = 0;
  parentNodeData: any = [];
  previousParentNodeData: any = {
    attributes: null
  };
  attributeData: any = [];
  requestType: any = null;
  draftId;
  isLeaf = false;

  leafCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  // Edit ------
  ngOnInit(): void {
    this.selectedNodeId = this.infoService.nodeId;
 // console.log(this.selectedNodeId);
    if (this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    }
  }

  close = () => {
    this.dialogRef.close({data: null});
  }

  nodeInfo = (id) => {
    this.infoService.getDraftNodeData(this.data.version, this.data.hierarchy, id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.tab1Data = res.data;
        this.isLeaf = this.tab1Data.attributes.is_leaf;
     // console.log('IS LEAF '+ this.isLeaf);
        this.loading = false;
        this.tabLoader = false;
      }
    });
  }

  getNodeInfo = (nodeId: any): string[] => {
    this.data = null;
    this.nodeCheckCount = 0;
    this.nodeCheckList.forEach(e => {
      if (e.id === nodeId){
        this.nodeCheckCount++;
      }
    });
 // console.log('Node Check - ' + this.nodeCheckCount);
    // if(this.nodeCheckCount > 0){
    //   this.homeService.getTreeData(nodeId).subscribe(res => {
    //     if (res) {
    //       this.data = res.data;
    //    // console.log(this.data);
    //       // console.log(this.nodeData);
    //     } else{
    //    // console.log('Node Info');
    //     }
    //   });
    // }
 // console.log(this.data);
    return this.data;
  }

  getLeafNodeList = (id) => {
    this.homeService.getLeafNodes(id, this.leafCounter.page, this.leafCounter.pageSize).subscribe(res => {
      if (res) {
        this.leafNodes = res.data;
        this.tabLoader = false;
      }
    });
  }

  getLeafNodeAttributes = (i: any) => {
    this.homeService.getLeafNodeAttributes(i).subscribe({
      next: res => {
        if (res) {
          this.attributes = res.results.data;
          this.tabLoader = false;
       // console.log(this.attributes);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  tabChanged = (e) => {
    this.tabLoader = true;
    this.tabIndex = e.index;
 // console.log(this.tabIndex);
    if (this.tabIndex === 0){
      this.nodeInfo(this.selectedNodeId);
    } else if (this.tabIndex === 1){
      this.getLeafNodeAttributes(this.selectedNodeId);
    } else if (this.tabIndex === 2){
      // this.getLeafNodeList(this.selectedNodeId);
    }
  }
}




// Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-info',
  templateUrl: './attribute.html',
  styleUrls: ['./review.component.scss']
  })
  export class DialogReviewView {

  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<DialogReviewView>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.iconRegistry.addSvgIcon(
        'edit',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'filter',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/icon.svg'
        )
      );
    }
    selectedNodeId = 0;
    loading = true;
    localLoading = false;
    nodeData;
    tabLoader;
    attributes;
    leafNodes;
    tabIndex = 0;
    page = 0;
    pageSize = 10;
    totalItems = 0;
    editedNode;
    attachment;
    selectedAttributes: any = [];
    formGroupAttribute: FormGroup;
    searchedAttribute;
    attributeList: any = [];
    filteringAttributeList: any = [];
    activeTabIndex = 0;
    postAttributeObject: any = [];
    postAttributeFilterObject: any = [];
    addedAttributeList: any = [];
    type = null;
    requestType = this.data.reqType;
    newAttributeList: any = [];
    oldAttributeList: any = [];
    namedList: any = [];
    // Edit ------
    ngOnInit(): void {
      this.selectedAttributes = [];
      this.attributeList = [];
      this.filteringAttributeList = [];
      this.postAttributeObject = [];
      this.postAttributeFilterObject = [];
      this.requestType = this.data.reqType;
   // console.log(this.data);
      if (this.data.reqType === 'attribute_sequence_edit' ){
     // console.log('Editing');
        this.oldAttributeList = this.data.oldList;
        this.newAttributeList = this.data.newList;
        this.localLoading = true;
    } else {
     // console.log('Addition');
      }
      // this.nodeInfo();
      // this.initForm();
    }

    nodeInfo = () => {
      this.nodeService.getAttributeList().subscribe(res => {
        if (res) {
          // console.log(res);
          this.attributeList = res.results.data;
          // console.log(this.attributeList);
          this.tabLoader = false;
        }
      });
    }

    getname  = (id) => {
      let name = 'Attribute';
      this.namedList.forEach(e => {
        if (e.id === id){
          name = e.attributes.name;
          return name;
        }else {
          return name;
        }
      });
    }

    initForm() {
      this.formGroupAttribute = this.fb.group({
        'attribute' : ['']
      });
      this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
      distinctUntilChanged()).
      subscribe(resp => {
        // console.log(resp);
        // console.log(this.searchedAttribute);
        if (this.formGroupAttribute.get('attribute').value != ''){
          this.searchedAttribute = resp;
          this.searchAttribute(resp);
        } else {

        }
      });
    }

    searchAttribute = (searchKey) => {
      this.nodeService.searchAttribute(searchKey).subscribe(res => {
        if (res) {
          // console.log(res);
          this.attributeList = res.results.data;
          this.loading = false;
        }
      });
    }

    selectAttribute = (item) => {
      if (this.selectedAttributes.some(e => e === item)){
        let i = this.selectedAttributes.indexOf(item);
        if (i > -1) {
          this.selectedAttributes.splice(i, 1);
          this.postAttributeObject.splice(i, 1);
        }
      } else{
        this.selectedAttributes.push(item);
     // console.log(this.selectedAttributes.length);
        let index = this.selectedAttributes.length;
        let params = {
          attribute_id: item.id,
          is_filter: false,
          attribute_sequence: null,
          filter_sequence: null
        };
        this.postAttributeObject.push(params);
      }
    }

    filterCounter: any = 1;

    selectFilter = (item, i) => {
      if (this.postAttributeObject[i].is_filter === false){
        this.postAttributeObject[i].is_filter = true;
        this.postAttributeObject[i].filter_sequence = this.filterCounter;
        this.postAttributeFilterObject.push(this.postAttributeObject[i]);
        this.filterCounter++;
      } else {
        this.filterCounter--;
        this.postAttributeObject[i].is_filter = false;
        // -------------------------------------------
        let i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
        if (i1 > -1) {
          this.postAttributeFilterObject.splice(i, 1);
        }
      }
      if (this.filteringAttributeList.some(e => e === item)){
        let i = this.filteringAttributeList.indexOf(item);
        if (i > -1) {
          this.filteringAttributeList.splice(i, 1);
        }
      } else {
        this.filteringAttributeList.push(item);
      }
    }

    drop(event: CdkDragDrop<string[]>) {
      // console.log(this.selectedAttributes);
      moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
      moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
      this.mergeAttribute(this.postAttributeObject);
    }
    mergeAttribute = (obj) => {
      this.postAttributeObject.forEach((e, i) => {
        e.attribute_sequence = i + 1;
      });
   // console.log(this.postAttributeObject);
    }
    dropFilter(event: CdkDragDrop<string[]>) {
      moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
      moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
      this.mergeFilterAttribute(this.postAttributeFilterObject);
    }

    mergeFilterAttribute = (obj) => {
      this.postAttributeFilterObject.forEach((e, i) => {
        e.filter_sequence = i + 1;
      });
   // console.log(this.postAttributeFilterObject);
    }

    mergeFIlterSequence = () => {
      for (let i = 0; i <= this.postAttributeObject.length - 1; i++){
        this.postAttributeFilterObject.forEach(e => {
          if (e === this.postAttributeObject[i]){
            this.postAttributeObject[i].filter_sequence = e.filter_sequence;
          }
        });
      }
    }
    submit = () => {
      // this.mergeAttribute(this.postAttributeObject);
      // this.mergeFilterAttribute(this.postAttributeFilterObject);
      // this.mergeFIlterSequence();
      // this.nodeService.selectedAttributeList = this.selectedAttributes;
      // this.nodeService.selectedFilterAttributeList = this.filteringAttributeList;
      // console.log(this.postAttributeObject);
      this.dialogRef.close({data: this.postAttributeObject});
    }
    close = () => {
      this.dialogRef.close({data: null});
    }

  }
