import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-img-slider',
  templateUrl: './img-slider.component.html',
  styleUrls: ['./img-slider.component.scss'],
})
export class ImgSliderComponent implements OnInit {
  imgsArr: any = [];
  currentIndex = 0;
  slides;
  tag: any;
  showTag: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ImgSliderComponent, any>
  ) {
    if (data) {
      this.imgsArr = data.productImg;
    }
    this.preloadImages();
  }

  ngOnInit(): void {}

  preloadImages() {
    this.imgsArr.forEach((slide) => {
      new Image().src = slide;
    });
  }

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  prevSlide() {
    this.currentIndex =
      this.currentIndex < this.imgsArr.length - 1 ? ++this.currentIndex : 0;
  }

  nextSlide() {
    this.currentIndex =
      this.currentIndex > 0 ? --this.currentIndex : this.imgsArr.length - 1;
  }
}
