<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Draft List</span>
        </div>
        <div fxFlex="70" fxLayout="row" fxLayoutAlign="end center">
            <div fxLayout="row" *ngIf="redirectedFrom !== 'draft'">
                <span  *ngIf="publishing && userType == 'administrator'">Publishing Version</span>
                <mat-progress-bar style="width: 100px;margin:20px" mode="indeterminate" *ngIf="publishing && userType == 'administrator'"></mat-progress-bar>
                <button mat-raised-button color="primary" *ngIf="!publishing && userType == 'administrator' && !frozen" (click)="freezeVersion()">Freeze</button>
                <button mat-raised-button color="primary" *ngIf="!publishing && userType == 'administrator' && frozen" (click)="unFreezeVersion()">Unfreeze</button>
                <button mat-raised-button (click)="publish()" color="primary" *ngIf="!publishing && userType == 'administrator'">Publish Changes</button>    
            </div>
        </div>
        <div fxFlex="18" class="filter" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column">
                <mat-form-field appearance="outline">
                    <mat-select name="parent" [(ngModel)]="draftStatus" placeholder="Status" (selectionChange)="applyFilter()">
                        <mat-option [disabled]="true">Select Status</mat-option>
                        <mat-option value="all">All</mat-option>
                        <mat-option value="draft">Draft</mat-option>
                        <mat-option value="review in progress">Review in Progress</mat-option>
                        <mat-option value="waiting for approval">Waiting for Approval</mat-option>
                        <mat-option value="approved">Approved</mat-option>
                        <mat-option value="live">Live</mat-option>
                        <mat-option value="rejected">Rejected</mat-option>
                        <mat-option value="discarded">Discarded</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading && draftList"></app-loader>
        <mat-card *ngIf="!loading">
            <table style="width: 100%;" class="draftTable" border="0" cellspacing="0" cellpadding="0">
                <tr style="text-align: left;">
                    <!-- <th>Id</th> -->
                    <th fxFlex="10">Entity Name</th>
                    <th fxFlex="10">Request</th>
                    <th fxFlex="30">Existing</th>
                    <th fxFlex="30">Suggested </th>
                    <th fxFlex="10">Requested by </th>
                    <th fxFlex="10">Status</th>
                </tr>
                <tr *ngIf="draftList.length == 0">
                    <td colspan="7">
                        <span style="text-align: center;">No data Preset for current filter options.</span>
                    </td>
                </tr>
                <tr *ngFor="let item of draftList">
                    <td fxFlex="10" *ngIf="item.nodeDetails">
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_field_edit'">
                            <div *ngIf="item.nodeDetails">
                                <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)">
                                    {{item.nodeDetails.attributes.category.data.attributes.name}}
                                    <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                                </span>
                            </div>    
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_edit'">
                            <div *ngIf="item.nodeDetails">
                                <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                    {{item.nodeDetails.attributes.category.data.attributes.name}}
                                    <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                                </span>
                            </div> 
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_addition'">
                            <div *ngIf="item.nodeDetails">
                                <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                    {{item.nodeDetails.attributes.category.data.attributes.name}}
                                    <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                                </span>
                            </div> 
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_field_edit'">
                            <div *ngIf="item.nodeDetails">
                                <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                    {{item.nodeDetails.attributes.category.data.attributes.name}}
                                    <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                                </span>
                            </div> 
                        </div>
                        <!-- {{item.attributes.context_data.edit_type}} -->
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_addition'">
                            <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                {{item.nodeDetails.attributes.category.data.attributes.name}}
                                <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                            </span> 
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_detach'">
                            <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                {{item.nodeDetails.attributes.category.data.attributes.name}}
                                <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                            </span>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_sequence_edit'">
                            <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                {{item.nodeDetails.attributes.category.data.attributes.name}}
                                <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                            </span>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_add'">
                            <span style="color: #3366FF;text-decoration: none;cursor: pointer;" (click)="goToNode(item)" >
                                {{item.nodeDetails.attributes.category.data.attributes.name}}
                                <button mat-icon-button class="openInNew"><mat-icon svgIcon="newTab"></mat-icon></button>
                            </span>
                        </div>
                    </td>
                    <td fxFlex="10" *ngIf="item.nodeDetails">
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_field_edit'">Category Field Edit</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_edit'">Relation Edit</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_addition'">Attribute Addition</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_field_edit'">Attribute Field Edit</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_addition'">Category Addition</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_detach'">Delete Node</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_sequence_edit'">Attribute Sequence</div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_add'">Category Relation Addition</div>
                    </td>
                    <td fxFlex="30" *ngIf="item.nodeDetails">
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_field_edit'">
                            <div *ngIf="item.attributes.context_data.original_value==''">N/A</div>
                            <div *ngIf="item.attributes.context_data.original_value!=''">
                                {{item.attributes.context_data.original_value}}
                            </div>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_field_edit'">
                            <section *ngIf="item.attributes.context_data.field_name != 'uom_codeset' && item.attributes.context_data.field_name != 'value_codeset' && item.attributes.context_data.field_name != 'is_multi'">
                                <div *ngIf="item.attributes.context_data.original_value!=''">
                                    {{item.attributes.context_data.original_value}}
                                </div>
                                <div *ngIf="item.attributes.context_data.original_value === false">
                                    False
                                </div>
                                <div *ngIf="item.attributes.context_data.original_value=='' && item.attributes.context_data.original_value !== false">N/A</div>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'value_codeset'">
                                <span *ngIf="item.attributes.context_data.original_value">{{item.oldcodesetName}}</span>
                                <span *ngIf="!item.attributes.context_data.original_value">None</span>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'uom_codeset'">
                                <span *ngIf="item.attributes.context_data.original_value">{{item.olduomName}}</span>
                                <span *ngIf="!item.attributes.context_data.original_value">None</span>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'is_multi'">
                                <span *ngIf="item.attributes.context_data.original_value===true">
                                    False
                                </span>
                                <span *ngIf="item.attributes.context_data.original_value==false">
                                    True
                                </span>
                            </section>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_addition'">
                            <div *ngIf="item">
                                {{item.attributes.context_data.original_value}}
                            </div>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_edit'">
                            {{item.attributes.context_data.original_value}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_addition'">
                            -
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_detach'">
                            {{item.attributes.context_data.original_value}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_sequence_edit'">
                            <!-- {{item.nodeDetails.attributes.path}} -->
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_add'">
                            {{item.attributes.context_data.original_value}}
                        </div>
                    </td>
                    <td fxFlex="30" *ngIf="item.nodeDetails">
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_field_edit'">
                            {{item.attributes.context_data.new_value}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_field_edit'">
                            <section *ngIf="item.attributes.context_data.field_name != 'uom_codeset' && item.attributes.context_data.field_name != 'value_codeset' && item.attributes.context_data.field_name != 'is_multi'">
                                <div *ngIf="item.attributes.context_data.new_value==''||item.attributes.context_data.new_value==null">N/A</div>
                                <div *ngIf="item.attributes.context_data.new_value!=''">
                                    <span *ngIf="item.attributes.context_data.new_value===true">
                                        True
                                    </span>
                                    <span *ngIf="item.attributes.context_data.new_value!==true">
                                        {{item.attributes.context_data.new_value}}
                                    </span>
                                </div>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'is_multi'">
                                <span *ngIf="item.attributes.context_data.new_value===true">
                                    False
                                </span>
                                <span *ngIf="item.attributes.context_data.new_value==false">
                                    True
                                </span>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'value_codeset'">
                                <span *ngIf="item.attributes.context_data.new_value">{{item.codesetName}}</span>
                                <span *ngIf="!item.attributes.context_data.new_value">None</span>
                            </section>
                            <section *ngIf="item.attributes.context_data.field_name === 'uom_codeset'">
                                <span *ngIf="item.attributes.context_data.new_value">{{item.uomName}}</span>
                                <span *ngIf="!item.attributes.context_data.new_value">None</span>
                            </section>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_addition'">
                            <div *ngIf="item.nodeDetails">
                                {{item.attributes.context_data.suggested_value}}
                            </div>
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_edit' && item.newNodeDetails">
                            {{item.attributes.context_data.suggested_value}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_addition'">
                            {{item.attributes.context_data.attribute_name}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_detach'">
                            {{item.attributes.context_data.suggested_value}}
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'attribute_sequence_edit'">
                            <!-- {{item.nodeDetails.attributes.path}} -->
                        </div>
                        <div *ngIf="item.attributes.context_data.edit_type == 'category_relation_add'">
                            <button mat-raised-button style="cursor: pointer;width: auto;
                            height: auto;
                            padding: 0px 20px;"  (click)="viewRelations(item)">View Relation</button>
                        </div>
                    </td>
                    <td fxFlex="10" *ngIf="item.nodeDetails">
                        {{item.attributes.creator.username|titlecase}}
                    </td>
                    <td fxFlex="10" *ngIf="item.nodeDetails" style="font-weight: 600;">
                        <span style="color:orange" *ngIf="item.attributes.workflow_state=='draft'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:orange" *ngIf="item.attributes.workflow_state=='waiting for approval'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:green" *ngIf="item.attributes.workflow_state=='review in progress'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:green" *ngIf="item.attributes.workflow_state=='approved'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:green" *ngIf="item.attributes.workflow_state=='live'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:red" *ngIf="item.attributes.workflow_state=='discarded'">{{item.attributes.workflow_state|titlecase}}</span>
                        <span style="color:red" *ngIf="item.attributes.workflow_state=='rejected'">{{item.attributes.workflow_state|titlecase}}</span>
                    </td>
                </tr>
            </table>
            <mat-paginator  *ngIf="draftList && !loading" #paginator [pageIndex]="pageIndex" [length]="totalPages" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]" (page)="changePage($event)" ></mat-paginator>
        </mat-card>
    </div>
</div>
  