import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  // @Output() refresh = new EventEmitter<string>();
  constructor(
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private errorHandler: ErrorHandlerService,
    private snackBar: MatSnackBar
  ) { }
  currentVersion: any = 0;
  frozen = false;
  previous = false;
  versionList = [];
  loading = true;
  currentVersionName: any = null;
  selectedVersion: any = null;
  subscription: Subscription;
  ngOnInit(): void {
    this.getVersionInfo();
  }

  getVersionInfo = () => {
    this.versionService.getVersionList().subscribe({
      next: res => {
        if (res) {
          res.results.data.forEach(e => {
            if (e.attributes.is_available === true){
              this.versionList.push(e);
            }
            this.checkVersion();
          });
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  checkVersion = () => {
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    if (this.currentVersion !== 0 && this.currentVersion != null){
   // console.log('Version is set');
      this.currentVersionName = this.currentVersion.attributes.name;
      this.selectedVersion = this.currentVersion;
    } else {
   // console.log('Version not set');
      this.versionList.forEach(e => {
        if (e.attributes.is_current === true){
          this.currentVersion = e;
          if(this.currentVersion.attributes){
            this.currentVersionName = this.currentVersion.attributes.name;
            this.versionService.setVersion(this.selectedVersion);
          }
        }
      });
    }
  }

  changeVersion = (e: any) => {
 // console.log(e);
    this.loading = true;
    this.selectedVersion = e.value;
    this.versionService.setVersion(this.selectedVersion);
    this.hierarchyService.getHierarchyList(1, 50).subscribe({
      next: async res => {
        await console.log(res);
        this.hierarchyService.updateHierarchy(res.results.data[0]);
      }
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

}
