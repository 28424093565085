<div class="app-wrapper" fxLayout="column" fxLayoutAlign="100">
  <div>
    <!-- top nav -->
    <app-top-nav *ngIf="router.url != '/' && router.url != '/forgot-password' && router.url != '/download'  &&  location.pathname != '/reset-password'" ></app-top-nav>
    <!-- side Navigation -->
    <app-sidebar
      *ngIf="router.url != '/' && router.url != '/forgot-password'  && router.url != '/automation' && router.url != '/download'  && location.pathname != '/product-details' &&  location.pathname  != '/reset-password'">
    </app-sidebar>
    <!-- other components rendering container -->
    <div class="content-wrapper" fxLayoutAlign="center center">
      <div class="content" layout="row" fxFlex="100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>