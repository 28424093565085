<div class="infoContainer" fxLayout="column">
  <button class="close" mat-icon-button  mat-dialog-close (click)="close()"><mat-icon>close</mat-icon></button>
  <div class="tabContainer">
    <app-loader *ngIf="loading"></app-loader>
    <mat-tab-group
    class="table-tab"
    #tabGroup
    (selectedTabChange)="tabChanged($event)"
    >
          <mat-tab label="Properties" *ngIf="!loading">
            <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar>
            <div class="tab2" *ngIf="tab1Data"> 
                <ul>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Category Name</div>
                    <div class="value">{{tab1Data.attributes.category.data.attributes.name}}</div>
                    <div fxFlex="10">
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Category Code</div>
                    <div class="value">{{tab1Data.attributes.category.data.attributes.code}}</div>
                    <div fxFlex="10">
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Description</div>
                    <div class="value">{{tab1Data.attributes.category.data.attributes.description}}</div>
                    <div fxFlex="10">
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Level</div>
                    <div class="value">{{tab1Data.attributes.level}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Full Path</div>
                    <div class="value">{{tab1Data.attributes.path}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Is Leaf Node</div>
                    <div class="value">{{tab1Data.attributes.is_leaf}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Total Child Nodes</div>
                    <div class="value">{{tab1Data.attributes.child_count}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Child Nodes</div>
                    <div class="value">
                      <ul>
                        <li  *ngFor="let item of tab1Data.attributes.children; let i = index">
                          {{i+1+'.'}}<span style="margin-left: 10px;">{{item.data.attributes.category.data.attributes.name}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
            </div>
        </mat-tab>
        
        <!-- <mat-tab label="Desendents" *ngIf="!loading && isLeaf">
          <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar>
          <div class="tab1" *ngIf="leafNodes && attributes">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" style="width:100%">
                <div fxFlex="100" *ngIf="attributes">
                  <div class="listHeaderFixed">
                    <div class="listHeader">Attributes ({{attributes.length}})</div>
                  </div>
                  <div  class="leaf-node-list">
                    <ul>
                      <li *ngFor="let item of attributes;let i = index">
                        {{item.attributes.name}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </mat-tab> -->

        <mat-tab label="Attributes" *ngIf="!loading && isLeaf">
            <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar>
            <div>
              <app-attribute-list [attributeList]="attributes" ></app-attribute-list>
            </div> 
        </mat-tab>
    </mat-tab-group>
  </div>
</div>
  