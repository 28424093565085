import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  module_slug;
  batch_id;
  urlTree;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.urlTree = this.router.parseUrl(this.router.url);
    this.module_slug = this.route.snapshot.queryParams['module_slug'];
    this.batch_id = this.route.snapshot.queryParams['batch_id'];

    // console.log(this.urlTree,this.module_slug,this.batch_id);

    this.userService.me().subscribe({
      next: (resp) => {
        window.open(
          '/api/modules/' +
            this.module_slug +
            '/batches/' +
            this.batch_id +
            '/download_output'
        );
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
        localStorage.clear();
      },
    });
  }
}
