import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringClearner'
})
export class StringClearnerPipe implements PipeTransform {

  transform(value): unknown {
    let result;
 // console.log(value);
    result = value.replace('_',' ');
    result = result.replace('_',' ');
    result = result.replace('_',' ');
    result = result.replace('_',' ');
    result = this.capitalizeFirstLetter(result);
 // console.log(result);
    if(result === 'Is multi'){
      result = 'Is single';
    }
    return result;
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
