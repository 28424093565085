    <div class="dataContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card *ngIf="!loading">
            <div fxLayout="row" style="width:100%;">
                <div fxFlex="50" class="left">
                    <h3></h3>
                    <div class="list" (scroll)="onCategoryScroll($event)">
                        <ul class="categoryList" *ngIf="conflistList.length > 0">
                            <li *ngFor="let item of conflistList">
                                <div class="item" fxLayout="row" (click)="getInfo(item)" [ngClass]="{'active': item.id == selectedNode.id}">
                                    <div fxFlex="100" fxLayoutAlign="start end" class="caption">{{item.attributes.category.data.attributes.name}} | {{item.attributes.attribute.data.attributes.name}}</div>
                                </div>
                            </li>
                        </ul>  
                    </div>          
                </div>
                <div fxFlex="50" class="right">
                    <h3>Category Details</h3>
                    <div class="list">
                        <ul *ngIf="selectedNode" class="categoryDetails">
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Category Name</div>
                                    <div fxFlex="75" class="caption">{{selectedNode.attributes.category.data.attributes.name}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Attribtue Name</div>
                                    <div fxFlex="75" class="caption">{{selectedNode.attributes.attribute.data.attributes.name}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Is Filter</div>
                                    <div fxFlex="75" class="caption">{{(selectedNode.attributes.is_filter).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Is Closed</div>
                                    <div fxFlex="75" class="caption">{{(selectedNode.attributes.is_closed).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Is Active</div>
                                    <div fxFlex="75" class="caption">{{(selectedNode.attributes.is_active).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Is Differentiator</div>
                                    <div fxFlex="75" class="caption">{{(selectedNode.attributes.is_differentiator).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="25" class="caption">Attribute Sequence</div>
                                    <div fxFlex="75" class="caption">{{selectedNode.attributes.attribute_sequence}}</div>
                                </div>
                            </li>

                        </ul>
                    </div>                    
                </div>
            </div>
        </mat-card>
    </div>
  

