import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { NodeService } from 'src/app/services/node.service';

@Component({
  selector: 'app-relation-misc-dialog',
  templateUrl: './relation-misc-dialog.component.html',
  styleUrls: ['./relation-misc-dialog.component.scss']
})
export class RelationMiscDialogComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private hierarchyService: HierarchyService,
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private errorHandler: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RelationMiscDialogComponent>,
  ) {

    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'hierarchy',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/hierarchy.svg'
      ),
    );
   }
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = false;
  formSubmitted = false;
  selectedNodeId: any = null;
  ticketID;
  selectedHierarchy: any = null;
  draftId = 0;
  ngOnInit(): void {
 // console.log(this.data);
    this.selectedHierarchy = this.hierarchyService.checkSetHierarchy();
  }
  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  getIcon = (name) => {
    let arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }


  // save = () => {
  //   this.loading = true;
  //   const data = {
  //     edit_type: 'category_relation_add',
  //     target_node: "0aafb6af-db32-4e27-8c84-868c82e2dfe3",
  //     relation_to_add: '',
  //     hierarchy_id: this.selectedHierarchy.id,
  //     note: this.notes,
  //     internal_ticket_id: this.ticketID
  //   };
  //// console.log(data);
  //   this.nodeService.addCategory(this.selectedId, data).subscribe({
  //     next: (resp: any) => {
  //    // console.log(resp);
  //       this.draftId = resp.data.id;
  //       if (this.draftId > 0 && this.files.length > 0){
  //         setTimeout(() => {
  //           const file = {
  //             attachments: this.files
  //           };
  //           let form = new FormData();
  //           this.files.forEach(e => {
  //             form.append('attachments', e);
  //           });
  //        // console.log(form);
  //           this.nodeService.uploadFile(this.draftId, form).subscribe({
  //             next: resp => {
  //               this.formSubmitted = false;
  //               this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
  //                 duration: 4000,
  //                 panelClass: ['error-snackbar']
  //               });
  //             },
  //             error: (HttpResponse: HttpErrorResponse) => {
  //               this.formSubmitted = false;
  //               this.loading = false;
  //            // console.log(HttpResponse.error. detail);
  //               const error = HttpResponse.error. detail;
  //               const errorList = [];
  //               for (const p in error) {
  //              // console.log (p, error[p]);
  //                 const x = {
  //                   title: p,
  //                   value: error[p]
  //                 };
  //                 errorList.push(x);
  //               }
  //            // console.log(errorList);
  //               errorList.forEach(element => {
  //                 this.snackBar.open(element.title.toUpperCase() + ' - ' + element.value, 'OK', {
  //                   duration: 4000,
  //                   panelClass: ['error-snackbar']
  //                 });

  //               });
  //             }
  //           });
  //         }, 2000);
  //       } else {
  //         this.loading = false;
  //         this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
  //           duration: 4000,
  //           panelClass: ['success-snackbar']
  //         });
  //       }
  //     },
  //     error: (HttpResponse: HttpErrorResponse) => {
  //       this.loading = false;
  //    // console.log(HttpResponse.error. detail);
  //       const error = HttpResponse.error. detail;
  //       const errorList = [];
  //       for (const p in error) {
  //    // console.log (p, error[p]);
  //       const x = {
  //         title: p,
  //         value: error[p]
  //       };
  //       errorList.push(x);
  //     }
  //    // console.log(errorList);
  //       errorList.forEach(element => {
  //       this.snackBar.open(element.title.toUpperCase() + ' - ' + element.value, 'OK', {
  //         duration: 4000,
  //         panelClass: ['error-snackbar']
  //       });

  //     });
  //     }
  //   });
  // }

  cancel = () => {
    this.dialogRef.close(null);
  }

  submit = () => {
    const miscData = {
      files: this.files,
      internalTicketID: this.ticketID,
      notes: this.notes
    }
    this.dialogRef.close(miscData);
  } 

}
