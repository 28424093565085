import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { MatPaginator } from '@angular/material/paginator';
import { T } from '@angular/cdk/keycodes';
import { VersionService } from 'src/app/services/version.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TreeDialogComponent } from '../_shared/tree-dialog/tree-dialog.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';

@Component({
  selector: 'app-draft-list',
  templateUrl: './draft-list.component.html',
  styleUrls: ['./draft-list.component.scss']
})
export class DraftListComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator?: MatPaginator;
  subscription: Subscription;
  currentVersionName = null;
  publishing = false;
  constructor(
    public dialog: MatDialog,
    private infoService: InfoService,
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private homeService: HomeService,
    private sharedDataService: SharedDataService,
    private versionService: VersionService,
    private errorHandler: ErrorHandlerService,
    private hierarchyService: HierarchyService

  ) {
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );
  }

  draftList: any = null;
  draftData: any = null;
  totalItems: any = null;
  nodeData: any = null;
  page = 1;
  pageSize = 10;
  pageIndex = 0;
  totalPages = 0;
  loading = true;
  userData;
  userDisplayName;
  userGroup = null;
  userType = null;
  draftStatus = 'all';
  versionFromService: any = null;
  currentVersion: any = null;
  frozen = false;
  versionList: any = [];
  childNodesData;
  parentNodeData;
  redirectedFrom = 'draft';
  creatorId = '';
  ngOnInit(): void {
    this.redirectedFrom = this.sharedDataService.draftListSource;
 // console.log(this.redirectedFrom);
    if(this.redirectedFrom === 'draft'){
      this.draftStatus = 'draft';
    } 
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    if (this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
      this.currentVersionName = this.currentVersion.attributes.name;
    }
 // console.log(this.userData);
    this.userDisplayName = this.userData.username;
    this.userGroup = this.userData.groups;
  // console.log(this.userGroup);
    this.userGroup.forEach(e => {
      if (e.name === 'administrator'){
        this.userType = 'administrator';
      } else if (e.name === 'reviewer'){
        this.userType = 'reviewer';
      } else if (e.name === 'editor'){
        this.userType = 'editor';
      }
    });
    this.getDraftData();
    this.getPublishStatus();
  }

  goBack = () => {
    if (this.userType === 'editor'){
      this.router.navigate(['/draft']);
    } else if (this.userType === 'reviewer'){
      this.router.navigate(['/review']);
    } else if (this.userType === 'administrator'){
      this.router.navigate(['/approve']);
    }
  }

  getDraftData = () => {
    if(this.redirectedFrom === 'draft' && this.userType !== 'editor'){
      this.creatorId = this.userData.id;
    } else {
      this.creatorId = '';
    }
    this.nodeService.getDraftList(this.page, this.pageSize, this.draftStatus, this.creatorId).subscribe(res => {
      if (res) {
        let i = 0;
        this.draftList = res.results.data;
        this.page = res.page;
        this.totalPages = res.total_count;
        this.pageSize = res.page_size;
        this.totalItems = this.draftList.length;
     // console.log(this.draftList);
        this.draftList.forEach(e => {
          i++;
          this.nodeService.getDraft(e.id).subscribe(res => {
            if (res) {
              this.draftData = res.data;
              const hID = this.draftData.attributes.context_data.hierarchy_id;
           // console.log(hID);
              if (this.draftData.attributes.context_data.field_name === 'uom_codeset'
              && this.draftData.attributes.context_data.edit_type === 'attribute_field_edit'){
              // console.log(this.draftData.attributes.context_data.field_name);
                if(this.draftData.attributes.context_data.new_value !== null){
                  this.sharedDataService.getUomDetails(this.draftData.attributes.context_data.new_value).subscribe(res => {
                    if (res) {
                    // console.log(res.data);
                      e.uomName = res.data.attributes.name;
                    // console.log(this.draftData.attributes);
                    }
                  });
                }
                if(this.draftData.attributes.context_data.original_value !== null){
                  this.sharedDataService.getUomDetails(this.draftData.attributes.context_data.original_value).subscribe(res => {
                    if (res) {
                    // console.log(res.data);
                      e.olduomName = res.data.attributes.name;
                    // console.log(this.draftData.attributes);
                    }
                  });
                }
              }
              if ( this.draftData.attributes.context_data.field_name === 'value_codeset'
              && this.draftData.attributes.context_data.edit_type === 'attribute_field_edit'){
              // console.log(this.draftData.attributes.context_data.field_name);
                if(this.draftData.attributes.context_data.new_value !== null){
                  this.sharedDataService.getValidValuesDetails(this.draftData.attributes.context_data.new_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.codesetName = res.data.attributes.name;
                    // console.log(this.draftData.attributes);
                    }
                  });
                }
                if(this.draftData.attributes.context_data.original_value !== null){
                  this.sharedDataService.getValidValuesDetails(this.draftData.attributes.context_data.original_value).subscribe(res => {
                    if (res) {
                   // console.log(res.data);
                      e.oldcodesetName = res.data.attributes.name;
                    // console.log(this.draftData.attributes);
                    }
                  });
                }
              }
              
              if (this.draftData.attributes.context_data.target_node){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.target_node,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                  // console.log(this.nodeData);
                    if (this.nodeData.length > 1){
                      e.newNodeDetails = null;
                    // console.log(13);

                    } else {
                      e.newNodeDetails = this.nodeData;
                    // console.log(23);
                    }
                  }
                });
              }
              if (this.draftData.attributes.context_data.category_id){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.category_id,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                    // console.log(11);

                    } else {
                      e.nodeDetails = this.nodeData;
                    // console.log(21);
                    }
                  }
                });
              } else if (this.draftData.attributes.context_data.node_to_move){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.node_to_move,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                    // console.log(12);

                    } else {
                      e.nodeDetails = this.nodeData;
                    // console.log(22);
                    }
                  }
                });
              } else if (this.draftData.attributes.context_data.target_node){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.target_node,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                  // console.log(this.nodeData);
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                      e.newNodeDetails = null;
                    // console.log(13);

                    } else {
                      e.nodeDetails = this.nodeData;
                      e.newNodeDetails = this.nodeData;
                    // console.log(23);
                    }
                  }
                });
              } else if (this.draftData.attributes.context_data.node_to_detach){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.node_to_detach,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                    // console.log(14);

                    } else {
                      e.nodeDetails = this.nodeData;
                    // console.log(24);
                    }
                  }
                });
              }  else if (this.draftData.attributes.context_data.category_id){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.node_to_move,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                    // console.log(15);

                    } else {
                      e.nodeDetails = this.nodeData;
                    // console.log(25);
                    }
                  }
                });

              }   else if (this.draftData.attributes.context_data.node_to_move){
                this.homeService.getNodeDataDraftList(this.draftData.attributes.context_data.node_to_move,
                  hID).subscribe(res => {
                  if (res) {
                    this.nodeData = res.data;
                    if (this.nodeData.length > 1){
                      e.nodeDetails = null;
                    // console.log(16);

                    } else {
                      e.nodeDetails = this.nodeData;
                    // console.log(26);
                    }
                  }
                });
              }
            }
          });
        });
      // console.log(this.draftList);
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      }
    });
  }

  publish = () => {
    this.snackBar.open('Publishing Changes. Current Taxonomy version will be frozen and a new version will be created.', 'OK', {
      duration: 4000,
      panelClass: ['error-snackbar']
    });
    // this.nodeService.publish().subscribe({
    //     next: resp => {
    //         this.snackBar.open('Publishing Changes. Current Taxonomy version will be frozen and a new version will be created.', 'OK', {
    //           duration: 4000,
    //           panelClass: ['error-snackbar']
    //         });
    //         this.getPublishStatus();
    //     },
    //     error: (HttpResponse: HttpErrorResponse) => {
    //       this.loading = false
    //       this.errorHandler.pass(HttpResponse.error);
    //     }
    // });
  }
  // getInfo = (id:any,index) =>{
  // // console.log(id);
  //   this.nodeService.getDraft(id).subscribe(res => {
  //     if (res) {
  //       this.draftData = res.data;
  //     // console.log(this.draftData);
  //       this.getNodeInfo(this.draftData.attributes.edit_context.properties.category_id);
  //     }
  //   });
  // }

  getNodeInfo = (nodeId: any) : any => {
    if (nodeId){
      this.homeService.getNodeDataDraftList(nodeId).subscribe(res => {
        if (res) {
          this.nodeData = res.data;
        // console.log(this.nodeData);
        }
      });
    }
    return this.nodeData;
  }

  changePage(ev: any){
  // console.log();
    this.loading = true;
    this.loading = true;
    this.pageSize = ev.pageSize;
    this.page = ev.pageIndex + 1;
    this.pageIndex = this.page - 1;
    this.getDraftData();
  }

  goToNode = (node) => {
  // console.log(node);
    this.sharedDataService.draftListRedirection = true;
    this.sharedDataService.draftNodeStatus = node.attributes.workflow_state;
    this.nodeService.selectedDraft = node.id;
  // console.log(this.userType);
    if(this.redirectedFrom === 'draft'){
      this.router.navigate(['/draft']);
    } else {
      if (this.userType === 'editor'){
        this.router.navigate(['/draft']);
      } else if (this.userType === 'reviewer'){
        this.router.navigate(['/review']);
      } else if (this.userType === 'administrator'){
        this.router.navigate(['/approve']);
      }
    }
  }

  freezeVersion = () => {
    this.nodeService.freeze().subscribe({
      next: resp => {
        if (resp) {
          this.frozen = true;
          this.currentVersion.attributes.is_frozen = true;
          if (this.currentVersion){
            this.versionService.setVersion(this.currentVersion);
            this.snackBar.open('Current version in now Frozen.', 'OK', {
              duration: 4000,
              panelClass: ['error-snackbar']
            });
            this.loading = false;

          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  unFreezeVersion = () => {
    this.nodeService.unFreeze().subscribe({
      next: resp => {
        if (resp) {
          this.frozen = false;
          this.currentVersion.attributes.is_frozen = false;
          this.versionService.setVersion(this.currentVersion);
          this.snackBar.open('Current version in now active.', 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
          this.loading = false;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  getPublishStatus = () => {
    this.nodeService.publishStatus().subscribe({
      next: (resp: any) => {
        if (resp) {
        // console.log(resp);
          this.publishing = resp.data.attributes.status;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  changeVersion = () => {

  }

  applyFilter = () => {
  // console.log(this.draftStatus);
    this.loading = true;
    this.getDraftData();
    // this.nodeService.filterDraftList(this.draftStatus).subscribe(res => {
    //   if (res) {
    //   // console.log(this.nodeData);
    //   }
    // });
  }
  viewRelations = (item) => {
 // console.log(item);
    this.parentNodeData = item.nodeDetails;
    this.draftData = item;
    this.childNodesData = [];
    let childIDList = [];
    this.draftData.attributes.context_data.relation_to_add.forEach( e => {
      const item = {
        id: e.child_id,
        name: '',
        level: e.level,
        parent: e.parent_id
      };
      childIDList.push(e.child_id);
      this.childNodesData.push(item);
    });
    const ids = {
      category_ids: childIDList
    };
    this.hierarchyService.getNodeData(ids).subscribe(res => {
      if (res) {
     // console.log(res);
        res.data.forEach(e => {
          const index = this.childNodesData.findIndex(x => x.id === e.id);
       // console.log(e);
       // console.log('Matching Index ' + index);
          if (index >= 0){
            this.childNodesData[index].name = e.attributes.name;
          }
        });
      }
    });
 // console.log(this.parentNodeData);
 // console.log(this.draftData);
 // console.log(this.childNodesData);
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '90vh';
    dialogConfig.width = '90vw';
    dialogConfig.data = {parentData : this.parentNodeData, draftData : this.draftData, childData: this.childNodesData};
    const modalDialog = this.dialog.open(
      TreeDialogComponent, dialogConfig
      );
  }


}
