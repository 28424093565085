import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { ENDPOINTS } from '../_globals/endpoints';
import { Globals } from '../_globals/endpoints.global';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  currentHierarchy: any = null;
  currentHierarchyName = 0;
  currentVersion:any = null;
  currentVersionName:any = null;
  private httpOptions: HttpHeaders;
  private subscription: Subscription;
  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private versionService: VersionService
    ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      if (this.currentVersion === 0 || this.currentVersion == null) {
     // console.log(this.currentVersion);
        this.currentVersion = JSON.parse(localStorage.getItem('version'));
      } else {
        this.currentVersionName = this.currentVersion.id;
      }
    });
  }

  private Hierarchy = new Subject<any>();

  getVersion = () => {
    this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      if (this.currentVersion === 0 || this.currentVersion == null) {
     // console.log(this.currentVersion);
        this.currentVersion = JSON.parse(localStorage.getItem('version'));
      } else {
        this.currentVersionName = this.currentVersion.id;
      }
    });
  }
  
  getHierarchyList = (page?: number, pageSize?: number): Observable<any> => {
    let endPoint = this.globals.urlJoin('hierarchy', 'hierarchyList');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get(endPoint, {
      headers: this.httpOptions,
      params: {
        page: page.toString(),
        page_size: pageSize.toString(),
      }
    }).pipe(
      map((response: any) => {
        return response;
      }),
      // catchError((error) => throwError(error))
    );
  }

  getFreeNodes = (page: number, pageSize: number, isLeaf: any, searchKey): Observable<any> => {
    const endPoint = this.globals.urlJoin('hierarchy', 'free_nodes');
    this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
 // console.log(endPoint);
    return this.http.get(endPoint + this.currentHierarchy.id + '/free_nodes/', {
      headers: this.httpOptions,
      params: {
        page: page.toString(),
        page_size: pageSize.toString(),
        is_leaf: isLeaf.toString(),
        q: searchKey
      }
    }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  updateHierarchy = (Hierarchy: any) => {
    localStorage.setItem('hierarchyInfo', JSON.stringify(Hierarchy));
    this.Hierarchy.next(Hierarchy);
  }

  checkSetHierarchy = () =>{
    this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    return this.currentHierarchy;
  }

  clearHierarchy = () => {
    this.Hierarchy.next();
  }

  getUpdatedHierarchy(): Observable<any> {
    return this.Hierarchy.asObservable();
  }


  getNodeInfo = (id) => {
 // console.log(id);
    const endPoint = this.globals.urlJoin('hierarchy', 'categoryDetails');
    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getNodeData = (data: any) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'get_node_data');
    return this.http.post<any>(endPoint, data, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  getAttributeData = (data: any) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'get_attributes_data');
    return this.http.post<any>(endPoint, data, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

  postRelationData = (data: any) => {
    const endPoint = this.globals.urlJoin('hierarchy', 'add_relation');
    return this.http.post<any>(endPoint, data, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
  }

}
