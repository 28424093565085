import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { TreeDialogComponent } from '../../_shared/tree-dialog/tree-dialog.component';
import { EditFreeCategoryComponent } from '../../_shared/edit-free-category/edit-free-category.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-search-category',
  templateUrl: './search-category.component.html',
  styleUrls: ['./search-category.component.scss']
})
export class SearchCategoryComponent implements OnInit {
  constructor(
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private infoService: InfoService,
    private multiCategoryService: MultiCategoryService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'review',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'trash',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Trash.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'doc',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/doc.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'send',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/send.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'export',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/export.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'info',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/info-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
  }
  searchFormGroup: FormGroup;
  selectedNode = {
    id : 0
  };
  selectedNodeData;
  nodeData: any = null;
  loading = true;
  isLeaf = false;
  selectedCategory: any = null;
  categoryList = [];
  searchKey = '';
  categoryCounter = {
    page : 1,
    pageSize : 50,
    totalPages : 0,
    totalValues: 0
  };
  isFree = false;
  nodeType = 'cnln'
  getLeafNodes = false;
  levelForm = new FormGroup({
    level: new FormControl(1)
  });
  hierarchies = [];

  ngOnInit(): void {
    this.initForm();
    this.getCategoryList(this.searchKey);
  }

  initForm = () => {
    this.searchFormGroup = this.fb.group({
      searchField : ['']
    });
    this.searchFormGroup.get('searchField').valueChanges.pipe(debounceTime(1000),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.searchFormGroup.get('searchField').value != ''){
        this.searchKey = resp;
        this.getCategoryList(this.searchKey);
      } else {
        this.searchKey = '';
        this.getCategoryList(this.searchKey);
      }
    });
  }

  changeNodeType = (e) => {
    console.log(e.value);
    if(e.value==='cln'){
      this.isFree = false;
      this.getLeafNodes = true;
    }else if(e.value==='cnln'){
      this.isFree = false;
      this.getLeafNodes = false;

    }else if(e.value==='fln'){
      this.isFree = true;
      this.getLeafNodes = true;

    }else if(e.value==='fnln'){
      this.isFree = true;
      this.getLeafNodes = false;
    }
    console.log(this.isFree);
    console.log(this.getLeafNodes);
    this.getCategoryList(this.searchKey);
  }

  goToAddCategory = () => {
    this.router.navigate(['/category']);
  }

  goToAbandonedNodes = () => {
    this.router.navigate(['/empty-nodes']);
  }
  
  getCategoryList = (searchKey: string) => {
    this.categoryCounter.page = 1;
    this.multiCategoryService.getCategoryList(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.getLeafNodes,
      searchKey,
      this.isFree
      ).subscribe(res => {
      if (res) {
     // console.log(res);
        this.categoryList = res.results.data;
        this.categoryCounter.totalPages = res.total_pages;
        this.categoryCounter.totalValues = res.total_count;
        this.categoryCounter.page = res.page;
        this.selectedNode = this.categoryList[0];
        if (this.categoryList.length > 0 && this.selectedNode.id !== 0){
       // console.log(this.selectedNode);
          this.getCategoryInfo(this.selectedNode);
        } else {
          this.loading = false;
        }
      }
    });
  }
  attributeList: any = null;
  attributeLength = 0;

  getCategoryInfo = (node: any) => {
    this.selectedNode = node;
    this.selectedNodeData = null;
 // console.log(node);
    const id = node.id;
    this.multiCategoryService.getCategoryDetail(id).subscribe({
      next: res => {
        if (res) {
       // console.log(res.data);
          if (res.data.attributes !== undefined){
            res.data.attributes.hierarchies.data.forEach(e => {
              if (e.attributes.name.includes('Master') && this.hierarchies.length === 0){
                this.hierarchies.push(e);
              }
            });
            this.selectedNodeData = res.data;
            this.attributeLength = this.selectedNodeData.attributes.attributes.data.length;
            this.attributeList = this.selectedNodeData.attributes.attributes.data;
            console.log(this.hierarchies);
          } else {
            this.selectedNodeData = res.data[0].attributes.category.data;
         // console.log(this.selectedNodeData);
            this.attributeLength = res.data.length;
            this.attributeList = res.data;
         // console.log(this.attributeList);
          }
       // console.log(this.selectedNodeData);
          this.loading = false;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  onCategoryScroll = (event: any) => {
    if (this.categoryCounter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
     // console.log('scrolling category');
        if (this.categoryCounter.page < this.categoryCounter.totalPages){
          this.categoryCounter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.categoryCounter.page);
        }else{
       // console.log('Cant Load' + this.categoryCounter.page);
        }
      }
    }
  }

  leafNodeActivate = (e) => {
 // console.log(e);
    if (e.checked === true) {
      this.getLeafNodes = true;
    } else {
      this.getLeafNodes = false;
    }
    this.getCategoryList(this.searchKey);
  }

  patchCategoryList = (i) => {
    this.multiCategoryService.getCategoryList(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.getLeafNodes,
      this.searchKey,
      this.isFree
      ).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.categoryList = this.categoryList.concat(res.results.data);
            this.categoryCounter.totalPages = res.total_pages;
            this.categoryCounter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }

  editCategory = () => {
 // console.log(this.attributeList);
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '90vh';
    dialogConfig.width = '85vw';
    dialogConfig.data = {category: this.selectedNodeData, attribute: this.attributeList};
    const modalDialog = this.dialog.open(
      EditFreeCategoryComponent, dialogConfig
    );
    modalDialog.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  };
}
