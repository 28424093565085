import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-add-category-multi',
  templateUrl: './add-category-multi.component.html',
  styleUrls: ['./add-category-multi.component.scss']
})
export class AddCategoryMultiComponent implements OnInit {
  constructor(
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private infoService: InfoService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private multiCategoryService: MultiCategoryService,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'doc',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/doc.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'send',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/send.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'export',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/export.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icon.svg'
      )
    );
  }
  formGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  loading = true;
  formSubmitted = false;
  draftId = 0;
  selectedNodeId: any;
  selectedNodeData: any;
  isLeaf = false;
  isFree = true;
  name = '';
  description = '';
  parent;
  notes = '';
  categoryName: any;
  categoriesList: any = null;
  newCategoryId = null;
  attributeList: any = [];
  ticketId = null;
  formGroupCategory: FormGroup;
  existingCode = false;
  existingName = false;
  categoryCounter = {
    page : 1,
    pageSize : 50,
    totalPages : 0,
    totalValues: 0
  };
  getLeafNodes = false;
  attributeToShow = [];
  ngOnInit(): void {
    this.initForm();
    this.nodeService.selectedAttributeList = [];
    this.nodeService.selectedFilterAttributeList = [];
 // console.log(this.infoService.nodeId);
    this.selectedNodeId = this.infoService.nodeId;
    this.loading = false;
  }

  initForm = () => {
    this.formGroupCategory = this.fb.group({
      category : ['']
    });
    this.formGroupCategory.get('category').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupCategory.get('category').value !== ''){
        this.checkName(resp);
      } else {

      }
    });
  }

  checkName = (name: any) => {
 // console.log(name);
    this.multiCategoryService.getCategoryList(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.getLeafNodes,
      name,
      this.isFree,
      ).subscribe(res => {
      if (res) {
     // console.log(res.results);
        if (res.results.data.length > 0){
       // console.log(res.results.data.length);
          this.existingName = true;
        }
      }
    });
  }

  checkCode = (name: any) => {
 // console.log(name);
    this.multiCategoryService.getCategoryList(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.getLeafNodes,
      name,
      this.isFree
      ).subscribe(res => {
      if (res) {
     // console.log(res);
        if (res.results.length > 0){
          this.existingCode = true;
        }
      }
    });
  }

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.loading = false;
      }
    });
  }

  nodeList = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
        this.categoriesList = res.data;
        // console.log(res.data);
        this.loading = false;
      }
    });
  }

  getIcon = (name) => {
    const arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'doc' || arr[1] === 'docx'){
      return 'doc';
    } else if (arr[1] === 'pdf'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }

  onSelect = (event) => {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove = (event) => {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save = () => {
    if(this.categoryName && this.newCategoryId){
      const data = {
        name: this.categoryName,
        code: this.newCategoryId,
        description: this.description,
        is_leaf: this.isLeaf,
        attributes: this.attributeList
      };
      this.loading = true;
      this.multiCategoryService.addCategory(data).subscribe({
        next: (resp: any) => {
       // console.log(resp);
          this.formSubmitted = false;
          this.loading = false;
          this.snackBar.open(`Category addition request submitted successfully.`, 'OK', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/category-list']);
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.errorHandler.pass(HttpResponse.error);
        }
      });  
    } else {
      this.snackBar.open(`Please fill the values properly .`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }
 // console.log(data);
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }

  back = () => {
    this.router.navigate(['/category-list']);
  }
  
  openDialog = (): void => {
    const list = this.attributeList;
    const dialogRef = this.dialog.open(DialogNodeAttributesMulti, {
      width: '98vw',
      data: {list}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      if (result.data != null)
      {
        this.attributeToShow = result.allList;
        this.attributeList = JSON.parse(JSON.stringify(result.data));
        if (this.attributeList.length > 0){
          this.attributeList.forEach(element => {
            delete element['name'];
            delete element['uomValue'];
            delete element['validValue'];
          });
        }
     // console.log(this.attributeList);
      } else {
        this.snackBar.open('No attributes selected', 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
        this.attributeList = [];
      }
   // console.log(this.attributeToShow);
    });
  }

}

// Dialog ---------------------------------------------------
@Component({
selector: 'dialog-node-info',
templateUrl: './attribute.html',
styleUrls: ['./add-category-multi.component.scss']
})
export class DialogNodeAttributesMulti {
@ViewChild(MatAutocompleteTrigger) attributeValuesAutoComplete: MatAutocompleteTrigger;

  tab1Data: any = null;
  attribute: any = null;
  productType: any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  page = 0;
  pageSize = 10;
  totalItems = 0;
  editedNode;
  attachment;
  data;
  selectedAttributes: any = [];
  formGroupAttribute: FormGroup;
  searchedAttribute;
  attributeList: any = [];
  filteringAttributeList: any = [];
  activeTabIndex = 0;
  postAttributeObject: any = [];
  postAttributeFilterObject: any = [];
  attributeCounter = {
    page : 1,
    pageSize : 200,
    totalPages: 0
  };
  filterCounter: any = 1;
  isLeaf = false;
  isSingle = true;
  isActive = false;
  isRequired = null;
  minValue = '';
  isDifferentiator = false;
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  searchKeyUoM = '';
  searchKeyValidValues = '';
  searchKey = '';
  selectedValidValue: any = null;
  selecteduom: any = null;
  uomValueList = [];
  validValueList = [];
  selectedTabIndex = 0;
  isRequiredList = [
    {
      value : null,
      view: 'NULL'
    },
    {
      value : false,
      view: 'False'
    },
    {
      value : true,
      view: 'True'
    }
  ]
  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<DialogNodeAttributesMulti>,
    private fb: FormBuilder,
    private multiCategoryService: MultiCategoryService,
    private homeService: HomeService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
  }

  ngOnInit(): void {
    this.initFormValidValue();
    this.initFormUoM();
    this.selectedAttributes = [];
    this.attributeList = [];
    this.filteringAttributeList = [];
    this.postAttributeObject = [];
    this.postAttributeFilterObject = [];
    // if(this.nodeService.selectedAttributeList.length>0){
    //   // this.selectedAttributes = this.nodeService.selectedAttributeList;
    //   this.nodeService.selectedAttributeList.forEach(e=>{
    //     this.selectAttribute(e);
    //   });
    // }
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.nodeInfo();
    this.initForm();
  }

  nodeInfo = () => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributeList = res.results.data;
        this.attributeCounter.totalPages = res.total_pages;
        this.attributeCounter.page = res.page;
        this.tabLoader = false;
      }
    });
  }


  initForm = () => {
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      // console.log(resp);
      // console.log(this.searchedAttribute);
      if (this.formGroupAttribute.get('attribute').value != ''){
        this.searchKey = resp;
        this.attributeCounter.page = 1;
        this.searchAttribute(resp);
      } else {
        this.searchKey = resp;
        this.attributeCounter.page = 1;
        this.searchAttribute('');
      }
    });
  }

  next = () => {
    this.selectedTabIndex++;
  }

  onTabChanged = (e: any) => {
 // console.log(e);
 // console.log(this.postAttributeObject);
    this.selectedTabIndex = e.index;
  }

  searchAttribute = (searchKey) => {
    this.attributeCounter.page = 1;
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, searchKey).subscribe(res => {
      if (res) {
        this.attributeList = res.results.data;
        this.attributeCounter.totalPages = res.total_pages;
        this.attributeCounter.page = res.page;
        this.loading = false;
      }
    });
  }

  checkForValidValues = (id) => {
    this.multiCategoryService.getAttributeValidValue(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('Valid Values');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  checkForUOM = (id) => {
    this.multiCategoryService.getAttributeUOM(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('UOM');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  selectAttribute = (item, e) => {
 // console.log(e);
    if (e.checked){
      this.initFormValidValue();
      this.initFormUoM();
   // console.log(item);
      if (this.selectedAttributes.some(e => e.id === item.id)){
     // console.log('Already Selected');
        // let i = this.selectedAttributes.indexOf(item);
        // if (i > -1) {
        //   this.selectedAttributes.splice(i, 1);
        //   this.postAttributeObject.splice(i, 1);
        // }
      } else{
        let validValue = null;
        let uom = null;
     // console.log(item);
        this.selectedAttributes.push(item);
     // console.log(this.selectedAttributes);
        const index = this.selectedAttributes.length;
        const params = {
          name: item.attributes.name,
          attribute_id: item.id,
          attribute_sequence: null,
          filter_sequence: null,
          is_multi:  false,
          is_active: true,
          is_required: null,
          is_filter: false,
          min_value: '',
          is_differentiator: false,
          value_codeset_id: null,
          uom_codeset_id: null,
          validValue: null,
          uomValue: null
        };
     // console.log(params);
        this.postAttributeObject.push(params);
      }
    }
  }

  selectFilter = (item, i) => {
    if (this.postAttributeObject[i].is_filter === false){
      this.postAttributeObject[i].is_filter = true;
      this.postAttributeObject[i].filter_sequence = this.filterCounter;
      this.postAttributeFilterObject.push(this.postAttributeObject[i]);
      this.filterCounter++;
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
      } else {
   // console.log(this.postAttributeFilterObject);
      this.postAttributeObject[i].is_filter = false;
      const i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
      if (i1 > -1) {
        this.postAttributeFilterObject.splice(i, 1);
      }
      this.filterCounter--;
    }
    if (this.filteringAttributeList.some(e => e === item)){
      const i = this.filteringAttributeList.indexOf(item);
      if (i > -1) {
        this.filteringAttributeList.splice(i, 1);
      }
    } else {
      this.filteringAttributeList.push(item);
    }
 // console.log(this.postAttributeObject);
  }

  drop = (event: CdkDragDrop<string[]>) => {
    // console.log(this.selectedAttributes);
    moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
    this.mergeAttribute(this.postAttributeObject);
  }
  mergeAttribute = (obj) => {
    this.postAttributeObject.forEach((e, i) => {
      e.attribute_sequence = i + 1;
    });
 // console.log(this.postAttributeObject);
  }
  dropFilter = (event: CdkDragDrop<string[]>) => {
    moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
  }

  mergeFilterAttribute = (obj) => {
    this.postAttributeFilterObject.forEach((e, i) => {
      e.filter_sequence = i + 1;
    });
 // console.log(this.postAttributeFilterObject);
  }

  mergeFIlterSequence = () => {
    for (let i = 0; i <= this.postAttributeObject.length - 1; i++){
      this.postAttributeFilterObject.forEach(e => {
        if (e === this.postAttributeObject[i]){
          this.postAttributeObject[i].filter_sequence = e.filter_sequence;
        }
      });
    }
  }
  
  removeAttribute = (item, i) => {
 // console.log(item);
 // console.log(this.postAttributeFilterObject[0]);
 // console.log(this.postAttributeObject[0]);
 // console.log(this.selectedAttributes[0]);
 // console.log(this.filteringAttributeList[0]);
    const index3 = this.postAttributeObject.findIndex(i => i.attribute_id === item.id);
    const index4 = this.postAttributeFilterObject.findIndex(i => i.attribute_id === item.id);
    const index5 = this.selectedAttributes.findIndex(i => i.id === item.id);
    const index6 = this.filteringAttributeList.findIndex(i => i.id === item.id);
    if (index3 > -1){
      this.postAttributeObject.splice(index3, 1);
    }
    if (index4 > -1){
      this.postAttributeFilterObject.splice(index4, 1);
    }
    if (index5 > -1){
      this.selectedAttributes.splice(index5, 1);
    }
    if (index6 > -1){
      this.filteringAttributeList.splice(index6, 1);
    }
    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
  }

  submit = () => {
    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
    this.nodeService.selectedAttributeList = this.selectedAttributes;
    this.nodeService.selectedFilterAttributeList = this.filteringAttributeList;
 // console.log(this.postAttributeObject);
    if (this.postAttributeObject.length > 0){
      this.snackBar.open('Attributes and properties updated to the category', 'OK', {
        duration: 4000,
        panelClass: ['success-snackbar']
      });
      this.dialogRef.close({data: this.postAttributeObject, allList: this.postAttributeObject});
    } else {
      this.snackBar.open('No attribute Selected', 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }
  }
  close = () => {
    this.dialogRef.close({data: null});
  }

  initFormUoM = () => {
    this.searchKeyUoM = '';
    this.selecteduom = '';
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {
       this.searchKeyUoM = '';
       this.searchUoM('');
      }
    });
  }

  searchUoM = (searchKey: any) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }
  selectUoMValue = (e: any, i: any) => {
    this.selecteduom = e;
 // console.log(e);
    this.postAttributeObject[i].uom_codeset_id = e.id;
    this.postAttributeObject[i].uomValue = e.attributes.name;
    if (this.postAttributeObject[i].is_filter === true) {
    //  this.postAttributeFilterObject[i].uom_codeset_id = e.id;
    }
    this.selecteduom = null;
    this.formGroupUoM.reset();
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

  initFormValidValue = () => {
    this.searchKeyValidValues = '';
    this.selectedValidValue = '';
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {
       this.searchKeyValidValues = '';
       this.searchValidValue('');
      }
    });
  }

  searchValidValue = (searchKey: any) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.validValueList = res.results.data;
      }
    });
  }
  selectValidValue = (e: any, i: any) => {
 // console.log(e);
    this.selectedValidValue = e;
    this.postAttributeObject[i].value_codeset_id = e.id;
    this.postAttributeObject[i].validValue = e.attributes.name;
    if (this.postAttributeObject[i].is_filter === true) {
    //  this.postAttributeFilterObject[i].value_codeset_id = e.id;
    }
    this.selectedValidValue = null;
    this.formGroupValidValues.reset();
  }

  singleSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_multi = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_multi = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_multi = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_multi = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  activeSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_active = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_active = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_active = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_active = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  requiredSelection = (e, i) => {
    if (e.checked === true){
      this.postAttributeObject[i].is_required = true;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = true;
      }
    }else {
      this.postAttributeObject[i].is_required = false;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = false;
      }
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

  diffSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_differentiator = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_differentiator = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_differentiator = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_differentiator = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  setMinValue = (i, value) => {
    this.postAttributeObject[i].min_value = value;
    if (this.postAttributeObject[i].is_filter === true) {
      // this.postAttributeFilterObject[i].min_value = value;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

  onScroll = (event: any) => {
 // console.log('Here');
    if (this.attributeCounter.totalPages > 1){
   // console.log(event.target.offsetHeight + event.target.scrollTop);
   // console.log(event.target.scrollHeight);
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
     // console.log('scrolling category');
        if (this.attributeCounter.page < this.attributeCounter.totalPages){
          this.attributeCounter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.attributeCounter.page);
        }else{
       // console.log('Cant Load' + this.attributeCounter.page);
        }
      }
    }
  }

  patchCategoryList = (i) => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey)
    .subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.attributeList = this.attributeList.concat(res.results.data);
            this.attributeCounter.totalPages = res.total_pages;
            this.attributeCounter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }


}
