<div class="value" fxLayout="row" style="width: 100%;">
    <!-- Name Edit -->
    <div *ngIf="data.editField=='name'" class="editPanel" fxFlex="100">
      <mat-card fxLayout="row" class="editDialog">
        <div fxFlex="50">
            <div>
                <mat-label>Name</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="editedValue">
                </mat-form-field>
            </div>
            <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
            </div>
            <div class="textArea">
                <mat-label>Notes</mat-label>
                <mat-form-field appearance="outline">
                    <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="50">
            <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                <ngx-dropzone-label>
                    <mat-icon svgIcon="upload"></mat-icon>
                    <p>Drag or Drop your files here</p>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    
                    <ngx-dropzone-label fxLayout="row">
                        <div fxFlex="50">
                            <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                            </mat-icon>    
                        </div>
                        <div fxFlex="50">
                            <span style="font-size: 10px;">{{ f.name }}</span>
                        </div>
                    </ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>
      </mat-card>
      <div mat-dialog-actions style="float: right;">
        <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitName(editedValue)" >SAVE</button>
        <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
      </div>      
    </div>

    <!-- description Edit -->

    <div *ngIf="data.editField=='description'" class="editPanel" fxFlex="100">
        <mat-card fxLayout="row" class="editDialog">
          <div fxFlex="50">
              <div>
                  <mat-label>Description</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="8" [(ngModel)]="editedValue"></textarea>
                  </mat-form-field>
              </div>
              <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
              </div>
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex="50">
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      
                      <ngx-dropzone-label fxLayout="row">
                          <div fxFlex="50">
                              <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                              </mat-icon>    
                          </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitDesc(editedValue)" >SAVE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
    </div>

    <!-- Closed Edit -->
    
    <div *ngIf="data.editField=='is_closed'" class="editPanel" fxFlex="100">
        <div><h2>Is closed</h2></div>
        <mat-card fxLayout="row" class="editDialog">
          <div fxFlex="50">
              <div>
                <section class="example-section">
                    <mat-checkbox class="example-margin" [(ngModel)]="isClosed" >Closed</mat-checkbox>
                </section>
              </div>
              <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
            </div>
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex="50">
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      
                      <ngx-dropzone-label fxLayout="row">
                          <div fxFlex="50">
                              <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                              </mat-icon>    
                          </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitisClosed(isClosed)" >SAVE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
    </div>

    <!-- Multi Edit -->

    <div *ngIf="data.editField=='is_multi'" class="editPanel" fxFlex="100">
        <div><h2>Is Single</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                  <section class="example-section">
                      <mat-checkbox class="example-margin" [(ngModel)]="isSingle" >Single</mat-checkbox>
                  </section>
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitMulti(isSingle)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>
    <!-- // has_uom: true
    // is_active: true
    // is_closed: false
    // is_differentiator: false
    // is_filter: true
    // is_multi: false -->

    <!-- is_active -->
    <div *ngIf="data.editField=='has_uom'" class="editPanel" fxFlex="100">
        <div><h2>Has UOM</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                    <section class="example-section">
                        <mat-checkbox class="example-margin" [(ngModel)]="hasUOM" >Has UOM</mat-checkbox>
                    </section>
                  </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitHasUom(hasUOM)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>

    <!-- is_active -->
    <div *ngIf="data.editField=='is_active'" class="editPanel" fxFlex="100">
        <div><h2>Is Active</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                    <section class="example-section">
                        <mat-checkbox class="example-margin" [(ngModel)]="isActive" >is Active</mat-checkbox>
                    </section>
                  </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitActive(isActive)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>

    <!-- Is Required -->

    <div *ngIf="data.editField=='is_required'" class="editPanel" fxFlex="100">
        <div><h2>Is Required</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                    <section class="example-section">
                        <mat-checkbox class="example-margin" [(ngModel)]="isRequired" >Is Required</mat-checkbox>
                    </section>
                  </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitIsRequired(isRequired)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>
    <!-- Is Min Value -->

    <div *ngIf="data.editField=='min_value'" class="editPanel" fxFlex="100">
        <div><h2>Minimun Value</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                    <mat-label>Minimun Value</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="minValue">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitMinValue(minValue)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>
    <!-- Is Differentiator -->

    <div *ngIf="data.editField=='is_differentiator'" class="editPanel" fxFlex="100">
        <div><h2>Is Differentiator</h2></div>
        <mat-card fxLayout="row" class="editDialog">
            <div fxFlex="50">
                <div>
                    <section class="example-section">
                        <mat-checkbox class="example-margin" [(ngModel)]="isDifferentiator" >Is Differentiator</mat-checkbox>
                    </section>
                </div>
                <div>
                    <mat-label>Internal Ticket ID</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="ticketId">
                    </mat-form-field>
                </div>
                <div class="textArea">
                    <mat-label>Notes</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
                <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                    <ngx-dropzone-label>
                        <mat-icon svgIcon="upload"></mat-icon>
                        <p>Drag or Drop your files here</p>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label fxLayout="row">
                            <div fxFlex="50">
                                <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                </mat-icon>    
                            </div>
                            <div fxFlex="50">
                                <span style="font-size: 10px;">{{ f.name }}</span>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
            </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
          <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitDifferentiator(isDifferentiator)" >SAVE</button>
          <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>      
    </div>
    
    <!-- UOM Codeset -->

    <div *ngIf="data.editField=='uom_codeset'" class="editPanel" fxFlex="100">
        <mat-card fxLayout="row" class="editDialog">
          <div fxFlex="50">
              <div>
                  <mat-label>Existing UoM Codeset</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput *ngIf="data.value==null" value="{{data.value}}" readonly placeholder="Not Available">
                    <input matInput *ngIf="data.value!=null" value="{{data.value.data.attributes.name}}" readonly placeholder="Not Available">
                  </mat-form-field>
              </div>
              <div>
                <form class="example-form" [formGroup]="formGroupUoM" style="width: 100%;">
                    <mat-label>UoM Codeset Suggestion</mat-label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text"
                              id="searchKey"
                              placeholder="Search UoM Codeset"
                              matInput
                              formControlName="uom"
                              [matAutocomplete]="uomAutoComplete">        
                        <mat-autocomplete #uomAutoComplete="matAutocomplete" style="width: 90%;">
                          <mat-option (click)="selectUoMValue(option)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of uomValueList" [value]="option.attributes.name">
                            {{option.attributes.name}}
                          </mat-option>
                          <mat-option value="None" (click)="selectUoMValue('None')">None</mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </form>
              </div>
              <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
              </div>
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex="50">
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      
                      <ngx-dropzone-label fxLayout="row">
                          <div fxFlex="50">
                              <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                              </mat-icon>    
                          </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitUom(this.uomValue)" >SAVE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
    </div>

    <!-- Valid Value -->

    <div *ngIf="data.editField=='valid_values'" class="editPanel" fxFlex="100">
        <mat-card fxLayout="row" class="editDialog">
          <div fxFlex="50">
              <div>
                  <mat-label><b></b>Current Mapping Name</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput *ngIf="data.value==null" value="{{data.value}}" readonly placeholder="Not Available">
                    <input matInput *ngIf="data.value!=null" value="{{data.value.data.attributes.name}}" readonly placeholder="Not Available">
                  </mat-form-field>
              </div>
              <div>
                <form class="example-form" [formGroup]="formGroupValidValues" style="width: 100%;">
                    <mat-label>Valid Values</mat-label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-icon matPrefix>search</mat-icon>
                      <input type="text"
                            id="searchKey"
                            placeholder="Search Valid Values"
                            matInput
                            formControlName="validValue"
                            [matAutocomplete]="validValuesAutoComplete">        
                      <mat-autocomplete #validValuesAutoComplete="matAutocomplete" style="width: 90%;">
                        <mat-option (click)="selectValidValue(values)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of validValueList" [value]="values.attributes.name">
                          {{values.attributes.name}}
                        </mat-option>
                        <mat-option value="None" (click)="selectValidValue('None')">None</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
              </div>
              <div style="margin-top: -10px;margin-bottom: 20px;">
                <span *ngIf="SelectedvalidValue!=null" ><button mat-icon-button><mat-icon>info</mat-icon></button> The 'Is closed' property will be set to TRUE on approval.</span>
              </div>
              <div>
                <mat-label>Internal Ticket ID</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="ticketId">
                </mat-form-field>
              </div>
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
          </div>
          <div fxFlex="50">
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      
                      <ngx-dropzone-label fxLayout="row">
                          <div fxFlex="50">
                              <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                              </mat-icon>    
                          </div>
                          <div fxFlex="50">
                              <span style="font-size: 10px;">{{ f.name }}</span>
                          </div>
                      </ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
          </div>
        </mat-card>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitValidValues(this.SelectedvalidValue)" >SAVE</button>
            <button mat-raised-button class="close-btn-style" (click)="onCancel()" >CLOSE</button>
        </div>
    </div>
  </div>