import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reference-url-dialog',
  templateUrl: './reference-url-dialog.component.html',
  styleUrls: ['./reference-url-dialog.component.scss'],
})
export class ReferenceUrlDialogComponent implements OnInit {
  urlData;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    // console.log(this.data);
    this.urlData = this.data.value;
  }
}
