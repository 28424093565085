<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/category-list'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Add Category</span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button (click)="save()" class='buttonSuccess'>Save</button>
            </div>
            <div>
                <button mat-raised-button (click)="cancel()" class="buttonCancel">Cancel</button>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" >
            <div fxFlex="50">
                <div>
                    <mat-label>New Category Name <span style="color:red">*</span></mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="categoryName" (change)="checkName(categoryName)">
                        <mat-error *ngIf="existingName">Name Already Exists</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>New Category Code <span style="color:red">*</span></mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="newCategoryId" (change)="checkCode(newCategoryId)">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Description</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput placeholder="" rows="4" [(ngModel)]="description"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" style="width: 100%;height: 50px;">
                    <section class="example-section" fxFlex="30">
                        <mat-checkbox class="example-margin" [(ngModel)]="isLeaf" >Is Leaf Node</mat-checkbox>
                    </section>
                    <div fxFlex="70" style="padding:10px;" fxLayout="column" fxLayoutAlign="center end"><button *ngIf="isLeaf" (click)="openDialog()" mat-raised-button>Select Attributes</button></div>
                </div>
            </div>
            <div fxFlex="50">
                <section *ngIf="attributeToShow.length > 0">
                    <h3>Selected Attributes</h3>
                    <div class="list">
                        <ul class="categoryDetails">
                            <li *ngFor="let item of attributeToShow">
                                <div class="item" fxLayout="row">
                                    <div fxFlex="85" class="caption">{{item.name}} 
                                    </div>
                                    <div *ngIf="item.is_filter" FxLayoutAlign="end center">
                                        <button mat-icon-button><mat-icon svgIcon="filter"></mat-icon></button>({{item.filter_sequence}})
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </mat-card>
    </div>
</div>
