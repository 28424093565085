import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value,key): unknown {
    let result;
    let arr;
    let output = '';
 // console.log('In pipe');
    arr = value.split(">>");
 // console.log(arr);
    arr.forEach(e => {
      if(e==key){
        result =  `<b>`+e+`</b>`;
        output = output.concat(result.toString())
      } else{
        result = `<i>`+e+`</i> >> `;
        output = output.concat(result.toString())
      }
    });
    return output;
  }

}
