import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[Hotkeys]'
})
export class HotkeysDirective {

  constructor(private router: Router) { }
  
  @HostListener('window:keydown.control.shift.h', ['$event'])
  home(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigate(['/home'])
  }
  @HostListener('window:keydown.control.shift.p', ['$event'])
  prod(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigate(['/products'])
  }
  @HostListener('window:keydown.control.shift.r', ['$event'])
  review(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigate(['/review'])
  }

  // @HostListener('keydown', ['$event']) home(e) {
  //   if (e.keyCode == 16 && e.keyCode == 72) {
  //     this.router.navigate(['/home'])
  //   }
  // }
  // @HostListener('keydown', ['$event']) review(e) {
  //   if (e.keyCode == 71 && e.keyCode == 82) {
  //     this.router.navigate(['/review'])
  //   }
  // }
  // @HostListener('keydown', ['$event']) products(e) {
  //   if (e.keyCode == 71 && e.keyCode ==  80 ) {
  //     this.router.navigate(['/products'])
  //   }
  // }

}
