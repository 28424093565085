import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'recolor'
})
export class RecolorPipe implements PipeTransform {
  constructor(
    private _sanitizer:DomSanitizer
  ) {
    
  }
  transform(value): unknown {
    let result;
    let arr;
    let output:string = '';
 // console.log('In pipe');
    arr = value.split("[");
 // console.log(arr);
    arr.forEach(e => {
      e = e.replace('[', '');
      e = e.replace(']', '');
   // console.log(e);      
      e = e.replace(',', '');
      e = e.replace('"', '');
      // e = e.replace(' ', '');
      e = e.replace('[', '');
      e = e.replace(']', '');
      e = e.replace('(', '');
      e = e.replace(')', '');
      e = '<span style="color:red;">'+e+'</span>'
      output = output.concat(e.toString())
   // console.log(output);      
    });

    // output = '<span style="color:red;">Hi</span>'
 // console.log(output);      
    return this._sanitizer.bypassSecurityTrustHtml(output);
  }


}
