import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'app-hierarchy-info',
  templateUrl: './hierarchy-info.component.html',
  styleUrls: ['./hierarchy-info.component.scss']
})
export class HierarchyInfoComponent implements OnInit {
  hierarchyInfo:any = null;
  categoryName = null;
  hierarchyName = null;
  constructor(
    private infoService: InfoService,
    private snackBar: MatSnackBar,
    private errorHandler: ErrorHandlerService,
    public dialogRef: MatDialogRef<HierarchyInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit(): void {
 // console.log(this.data);
    this.nodeInfo(this.data.categoryId);
  }

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.hierarchyName = res.data.attributes.hierarchy_name;
        this.categoryName = res.data.attributes.category.data.attributes.name;
        this.getHierarchyInfo();
      }
    });
  }

  getHierarchyInfo = () => {
    this.infoService.getNodeHierarchyInfo(this.data.categoryId).subscribe({
      next: res => {
        if (res) {
          console.log(res);
          this.hierarchyInfo = res.data;
          const index = this.hierarchyInfo.findIndex(i => i.attributes.hierarchy.data.attributes.name === this.hierarchyName);
          if (index > -1) {
            this.hierarchyInfo.splice(index, 1);
          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }
}
