import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmedValidator} from './confirmed.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  formSubmitted: boolean;
  loading: boolean;
  uidb64: any;
  token: any;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public router: Router,
    private errorHandler: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[$@$!%*?&])/
            ),
          ],
        ],
        confirm_password: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'confirm_password'),
      }
    );

    this.uidb64 = this.route.snapshot.queryParams['uidb64'];
    this.token = this.route.snapshot.queryParams['token'];
  }
  get f() {
    return this.resetPasswordForm.controls;
  }

  get newPwd() {
    return this.resetPasswordForm.get('password');
  }

  get confirmPwd() {
    return this.resetPasswordForm.get('confirm_password');
  }

  emailValidation = () => {
    this.userService.getEmailValidation(this.uidb64, this.token).subscribe({
      next: (resp) => {},
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      },
    });
  };

  resetComplete = () => {
    if (this.resetPasswordForm.invalid) {
      return false;
    } else {
      this.userService
        .patchResetPassword(
          this.resetPasswordForm.value,
          this.uidb64,
          this.token
        )
        .subscribe({
          next: (resp) => {
            this.snackBar.open('Password changed successfully.', 'OK', {
              duration: 4000,
            });
            this.logoutUser();
          },
          error: (HttpResponse: HttpErrorResponse) => {
            this.errorHandler.pass(HttpResponse.error);
          },
        });
    }
  };

  //Logout after password reset
  logoutUser = () => {
    this.router.navigate(['/']);
    localStorage.removeItem('currentUser');
    localStorage.clear();
  };
}
