<div fxLayout="column" class="mainContainer">
    <div fxLayout="row" class="topBar">
      <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
        <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;" *ngIf="selectedNodeData">Add Relation ({{selectedNodeData.attributes.category.data.attributes.name}}) - {{selectedHierarchy.attributes.name | textCorrection}}</span>
      </div>
      <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
          <div>
              <button mat-raised-button (click)="dialogForAttachments()" [disabled]="finalObject.relation_to_add.length==0" class='buttonSuccess'>Proceed</button>
          </div>
      </div>
    </div>
    <div style="margin-top: 5px; margin-left:35px">
      <span><i>Select a category to add nodes under it.</i></span>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div style="padding:5px;padding-bottom: 0px;" fxLayoutGap="0px" class="mainTable" *ngIf="selectedLevel">
      <div fxLayout="row" style="padding:20px;" fxLayoutGap="0px">
        <div style="width: 240px;" class="listContainer" *ngFor="let l of levelList;let mainIndex = index">
          <div class="listHeaderFixed">
            <div class="listHeader">{{l.name}}</div>
          </div>
          <ul>
            <li fxLayout="row" style="width: 100%;" *ngFor="let item of l.value; let i = index" [ngClass]="{'active': item.id == selectedNodeId}">
              <!-- {{item|json}} -->
              <div style="cursor: pointer;" *ngIf="item.category === undefined" fxLayout="column" (click)="getNodeInfo(item,mainIndex)" fxLayoutAlign="center start" fxFlex="80"><span>{{item.attributes.category.data.attributes.name}} ({{item.attributes.child_count}})</span></div>
              <div style="cursor: pointer;" *ngIf="item.category === 'new'" fxLayout="column" (click)="getNodeInfo(item,mainIndex)" fxLayoutAlign="center start" fxFlex="80"><span>{{item.attributes.name}}</span></div>
              <!-- <div class="info" fxFlex="20" fxLayout="column" fxLayoutAlign="center center"><button mat-icon-button class="infoButton" matTooltip="{{item.full_path}}" matTooltipPosition="after"><mat-icon svgIcon="info"></mat-icon></button></div> -->
            </li>
          </ul> 
    
        </div>
        <div *ngIf="rightPanelActive" _ngcontent-djb-c140="" class="listContainer ng-star-inserted" style="width: 850px; margin-right: 0px;">
          <div _ngcontent-djb-c140="" class="listHeaderFixed" style="width: 850px;">
      </div>
    </div>
  </div>

  <!-- Right Panel -->
  
  <div class="flyingDiv l2r" #rightPanel [ngClass]="{'flyingDivActivated': rightPanelActive == true}">
    <button class="trigger" mat-icon-button (click)="avtivateRightPanel()">
      <mat-icon *ngIf="!rightPanelActive">arrow_left</mat-icon>
      <mat-icon *ngIf="rightPanelActive">arrow_right</mat-icon>
    </button>
    <div *ngIf="rightPanelActive" class="internalContainer" fxLayout="row">
        <div fxFlex="50" fxLayout="column">
            <div class="searchContainer">
                <form class="example-form" [formGroup]="searchFormGroup" style="width: 100%;">
                    <mat-form-field class="example-full-width" appearance="outline" style="width:100%;">
                      <mat-icon matPrefix>search</mat-icon>
                      <input type="text"
                            id="searchKey"
                            placeholder="Search"
                            matInput
                            formControlName="searchField"
                            >        
                      <mat-autocomplete #auto="matAutocomplete" style="width: 90%;">
                        <mat-option style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of selectedCategory" [value]="option.name">
                          {{option.attributes.name}}
                          <button *ngIf="option.attributes.category_code" button style="background: #E6E8F0; border: none;margin-left: 20px; height: 20px;border-radius: 4px;">{{option.attributes.category_code}}</button>
                          <button style="background: #52BD94; border: none; height: 20px;border-radius: 4px; float: right;margin-top: 5px;" *ngIf="option.type=='categories'">(Level {{option.attributes.level}})</button>
                          <br />
                          <span button style="background: transparent; border: none; height: 20px;border-radius: 4px;font-size: 12px;">{{option.attributes.path}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
            <div class="toggleContainer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                <button mat-raised-button (click)="enableLeafNodes()" [ngClass]="isLeaf ? 'buttonSuccess' : 'buttonCancel' " >Leaf Nodes</button>
                <!-- <button mat-raised-button (click)="enableFreeNodes()" [ngClass]="isFree ? 'buttonSuccess' : 'buttonCancel' " >Free Nodes</button> -->
            </div>
            <div class="rightListContainer" (scroll)="onCategoryScroll($event)">
                <div class="list">
                    <ul class="categoryList" *ngIf="categoryList.length > 0 && currentParent!=null">
                        <li *ngFor="let item of categoryList" (click)="selectNode(item)">
                            <div class="item" fxLayout="row">
                                <div fxFlex="100" fxLayoutAlign="start end" class="caption">
                                    {{item.attributes.name}}
                                </div>
                            </div>
                        </li>
                    </ul>  
                </div> 
            </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div class="searchContainer">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Levels</mat-label>
              <input type="text" matInput [(ngModel)]="'Level ' + levelForAddition" readonly="true">  
            </mat-form-field>
            <!-- <form class="example-form" [formGroup]="searchFormGroup" style="width: 100%;">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Levels</mat-label>
                <mat-select>
                  <mat-option *ngFor="let level of levelList" [value]="level" (onSelectionChange)="selectLevel(level)">{{level.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </form> -->
          </div>
          <div class="rightListContainer" (scroll)="onCategoryScroll($event)">
            <div class="list">
              <ul class="categoryList" *ngIf="categoryList.length > 0">
                  <li *ngFor="let item of selectedNodes" (click)="selectNode(item)">
                      <div class="item" fxLayout="row">
                          <div fxFlex="100" fxLayoutAlign="start end" class="caption">
                              {{item.attributes.name}}
                          </div>
                          <div fxLayoutAlign="end end">
                              <!-- <button mat-icon-button (click)="getCategoryInfo(item)">
                                  <mat-icon svgIcon="info" class="infoButton"></mat-icon>
                              </button> -->
                              <button mat-icon-button (click)="removeNode(item)">
                                  <mat-icon class="">delete</mat-icon>
                              </button>
                          </div>
                      </div>
                  </li>
              </ul>
              <div style="text-align:right; padding:5px" *ngIf="selectedNodes.length > 0">
                <button mat-raised-button class='buttonSuccess' (click)="appendNodes()">Add</button>
              </div>  
            </div> 
          </div>
        </div>
  </div>