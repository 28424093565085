import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../_globals/endpoints.global';
import { Router, RouterModule } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppPermissionsService {
  private permissionObject: any;
  private httpOptions: HttpHeaders;
  constructor(
    private globals: Globals, private http: HttpClient, private router: Router, private snackBar: MatSnackBar,

  ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
   }


  getAppPermissions = () => {
    const getPermissionEndpoint = this.globals.urlJoin('permissions', 'get_user_permission');
    return this.http
      .get(getPermissionEndpoint, {
        headers: this.httpOptions,
      })
      .pipe (
        map((response: any) => {
          this.permissionObject = response;
          return response;
        }),
        catchError((error) => throwError(error))
      );
  }

  get permissions(): any {
    return this.permissionObject;
  }

  set permissions(val) {
    this.permissionObject = val;
  }

}
