import { Component, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'datax';
  location: any;

  constructor(
    public router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {}
  @HostListener("window:onbeforeunload",["$event"])
  clearLocalStorage(event){
      localStorage.clear();
  }
  ngOnInit() {
    this.location = window.location;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          // tslint:disable-next-line:whitespace
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(event['title']));

    this.iconRegistry.addSvgIcon(
      'Check-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/Check.svg')
    );

    this.iconRegistry.addSvgIcon(
      'trash-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/trash.svg')
    );

    this.iconRegistry.addSvgIcon(
      'DownloadAsset',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/asset-download.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'Refresh-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/Refresh.svg')
    );

    this.iconRegistry.addSvgIcon(
      'Close-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/close.svg')
    );

    this.iconRegistry.addSvgIcon(
      'Approved-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/completed-green.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'RejectSuggestion-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/disabled_by_default_black_24dp.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'Processed-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/completed-grey.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'In_Progress-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/inProgress.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'In_Queue-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/inQueue.svg')
    );
    this.iconRegistry.addSvgIcon(
      'Cancelled-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/cancelled.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'Download-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/file_download.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'Info',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/info.svg')
    );

    this.iconRegistry.addSvgIcon(
      'PDF',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/pdf-input.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'CSV',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/CSV-input.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'AUDIO',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/MP3.svg')
    );
    this.iconRegistry.addSvgIcon(
      'VIDEO',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/MP4.svg')
    );

    this.iconRegistry.addSvgIcon(
      'AcceptSuggestion-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/check_box_black_24dp.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'RejectSuggestion-btn',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/disabled_by_default_black_24dp.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'chevronLeft',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/chevron_left.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'chevronRight',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/chevron_right.svg'
      )
    );


    this.iconRegistry.addSvgIcon(
      'Next',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/forward.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'Back',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/back.svg'
      )
    );

   
    this.iconRegistry.addSvgIcon(
      'ProductCSV',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/productCSV.svg'
      )
    );
     
  }
}
