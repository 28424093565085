import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { InfoService } from 'src/app/services/info.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { NodeService } from 'src/app/services/node.service';
import { VersionService } from 'src/app/services/version.service';
import { RelationMiscDialogComponent } from '../../_shared/relation-misc-dialog/relation-misc-dialog.component';

@Component({
  selector: 'app-add-relation-multi',
  templateUrl: './add-relation-multi.component.html',
  styleUrls: ['./add-relation-multi.component.scss']
})
export class AddRelationMultiComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private infoService: InfoService,
    private scrollDispatcher: ScrollDispatcher,
    private multiCategoryService: MultiCategoryService,
    private hierarchyService: HierarchyService,
    private versionService: VersionService,
    private nodeService: NodeService,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'empty',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/empty-state.svg'
      )
    );
  }
  loading = true;
  rightPanelActive = false;
  levelList = [];
  levels = 6;
  searchFormGroup: FormGroup;
  searchKey = '';
  selectedCategory = null;
  selectedLevel = 0;
  parentLevel = 0;
  categoryCounter = {
    page : 1,
    pageSize : 50,
    totalPages : 0,
    totalValues: 0
  };
  draftId = 0;
  isFree = true;
  isLeaf = false;
  categoryList = [];
  selectedNode = null;
  selectedNodes = [];
  selectedNodeId = null;
  selectedNodeData = null;
  nodeList = [];
  selectedHierarchy: any = null;
  selectedVersion = this.versionService.checkSetVersion();
  levelForAddition = 0;
  currentIndex = 0;
  activeIndex = 0;
  universalNodes = [];
  currentParent = null;
  finalObject = null;
  selectedNodeByNode = [];
  files: any = [];
  ngOnInit(): void {
    this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    this.loading = false;
 // console.log(this.infoService.nodeId);
    this.selectedNodeId = this.infoService.nodeId;
    // this.selectedNodeId = 'd45c312c-1a83-4a3a-9a2e-3081f7235280';
    if (this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    } else {
      this.snackBar.open('No node ID is found.', '', {
        duration: 2000,
      });
      this.router.navigate(['/home']);
    }
    this.initForm();
    this.getCategoryList(this.searchKey);
    this.finalObject = {
      edit_type: 'category_relation_add',
      hierarchy_id: this.selectedHierarchy.id,
      version_id: this.selectedVersion.id,
      target_node: this.selectedNodeId,
      relation_to_add: [],

    };
  }

  rawLevel = () => {
    this.levelList = [];
 // console.log(this.selectedLevel);
    this.currentIndex = 0;
    for (let i = this.selectedLevel; i <= this.levels; i++){
      const l = {
        index: i,
        name: 'Level ' + i,
        value: []
      };
      this.levelList.push(l);
    }
 // console.log(this.levelList);
  }

  nodeInfo = (id) => {
    this.universalNodes.push(id);
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.selectedLevel = this.selectedNodeData.attributes.level;
        this.parentLevel = this.selectedNodeData.attributes.level;
        this.levelForAddition = this.selectedLevel + 1;
        this.rawLevel();
        this.generateLevels();
        this.loading = false;
      }
    });
  }

  generateLevels = () => {
    this.levelList.forEach(l => {
      const list = [];
      this.nodeList[this.selectedLevel] = this.selectedNodeData;
      this.nodeList.forEach(e => {
     // console.log(e);
        if (e.attributes.level === l.index){
          list.push(e);
        }else{
        }
      });
      l.value = list;
    });
 // console.log(this.levelList);
  }

  avtivateRightPanel = () => {
    if (this.rightPanelActive){
      this.rightPanelActive = false;
    } else {
      this.rightPanelActive = true;
    }
  }

  initForm = () => {
    this.searchFormGroup = this.fb.group({
      searchField : ['']
    });
    this.searchFormGroup.get('searchField').valueChanges.pipe(debounceTime(1000),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.searchFormGroup.get('searchField').value != ''){
        this.searchKey = resp;
        this.getCategoryList(this.searchKey);
      } else {
        this.searchKey = '';
        this.getCategoryList(this.searchKey);
      }
    });
  }

  getCategoryList = (searchKey: string) => {
    this.categoryCounter.page = 1;
    this.categoryList = [];
    this.hierarchyService.getFreeNodes(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.isLeaf,
      this.searchKey
      ).subscribe(res => {
      if (res) {
     // console.log(res);
        this.categoryList = res.results.data;
        this.categoryCounter.totalPages = res.total_pages;
        this.categoryCounter.totalValues = res.total_count;
        this.categoryCounter.page = res.page;
      }
    });
  }

  onCategoryScroll = (event: any) => {
    if (this.categoryCounter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
        // console.log('scrolling category');
        if (this.categoryCounter.page < this.categoryCounter.totalPages){
          this.categoryCounter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.categoryCounter.page);
        }else{
       // console.log('Cant Load' + this.categoryCounter.page);
        }
      }
    }
  }

  patchCategoryList = (i) => {
    this.hierarchyService.getFreeNodes(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.isLeaf,
      this.searchKey
      ).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.categoryList = this.categoryList.concat(res.results.data);
            this.categoryCounter.totalPages = res.total_pages;
            this.categoryCounter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }

  enableLeafNodes = () => {
 // console.log(this.isLeaf);
    this.isLeaf = this.isLeaf === true ? false : true;
 // console.log(this.isLeaf);
    this.getCategoryList(this.searchKey);
  }

  calculateChilds = (id) => {
 // console.log(id);
    return 10;
  }

  selectNode = (item: any) => {
 // console.log(this.parentLevel);
 // console.log(this.selectedNodes);
 // console.log(this.activeIndex);
 // console.log(this.currentIndex);
 // console.log(item);
    if (this.currentIndex === 0){
   // console.log(this.selectedNodes);
      if (this.universalNodes.length == 1) {
        let st = 0;
        this.universalNodes.forEach(e => {
          if (e === item.id){
            st++;
         // console.log(e);
          } 
        });
        this.selectedNodes.forEach(e => {
          if (e.id === item.id){
            st++;
          } 
        });
     // console.log(this.universalNodes);
     // console.log(this.selectedNodes);
     // console.log(st);
        if (st === 0){
          this.universalNodes.push(item.id);
          item.category = 'new';
          const data = {
            category_ids: [item.id]
          }
          this.hierarchyService.getNodeData(data).subscribe(res => {
            if (res) {
           // console.log(res.data);
              res.data[0].category = 'new';
              this.selectedNodes.push(res.data[0]);
            }
          });
        } else {
          this.snackBar.open('Duplicate node.', '', {
            duration: 2000,
          });
        }
      } else {
        this.snackBar.open('You can add only one node.', '', {
          duration: 2000,
        });
      }
    } else {
      let st = 0;
      this.universalNodes.forEach(e => {
        if (e === item.id){
          st++;
        } 
      });
      this.selectedNodes.forEach(e => {
        if (e.id === item.id){
          st++;
        } 
      });
   // console.log(this.universalNodes);
   // console.log(this.selectedNodes);
   // console.log(st);
      if (st === 0){
        this.universalNodes.push(item.id);
        item.category = 'new';
        const data = {
          category_ids: [item.id]
        }
        this.hierarchyService.getNodeData(data).subscribe(res => {
          if (res) {
         // console.log(res.data);
            res.data[0].category = 'new';
            this.selectedNodes.push(res.data[0]);
          }
        });
      } else{
        this.snackBar.open('Duplicate node.', '', {
          duration: 2000,
        });
      }
   // console.log(this.selectedNodes);
    }

  }

  getNodeInfo = (item: any, index) => {
 // console.log(item);
    if (item.attributes.is_leaf){
      this.snackBar.open("Can't add nodes under leaf node.", '', {
        duration: 2000,
      });
    } else {
      this.rightPanelActive = true;
      this.currentParent = item;
   // console.log('currentIndex - ' + index);
   // console.log('Current Level - ');
   // console.log(this.levelList[index]);
      this.finalObject.relation_to_add.forEach(node => {
     // console.log(node);
        if (this.currentParent.type === 'categories'){
          if (this.currentParent.id === node.parent_id){
            this.selectedNodeByNode.push(node);
          }
        }
      });
   // console.log(this.currentParent);
   // console.log(this.selectedNodeByNode);
   // console.log(this.activeIndex);
      this.currentIndex = index;
      this.activeIndex = index + 1;
      this.selectedLevel = this.levelList[index].index;
      this.levelForAddition = this.levelList[index].index + 1;
   // console.log('this.levelForAddition');
   // console.log(this.levelForAddition);
      this.selectedNodeId = item.attributes.category_id;
      if (item.id !== undefined){
        this.selectedNodeId = item.id;
      }
      this.selectedNodeByNode = [];
    }
  }

  removeNode = (item: any) => {
    const index1 = this.selectedNodes.findIndex(e => e.id === item.id);
 // console.log(index1);
    if (index1 >= 0){
      this.selectedNodes.splice(index1, 1);
    }
    const index2 = this.universalNodes.findIndex(e => e === item.id);
 // console.log(index2);
    if (index2 >= 0){
      this.universalNodes.splice(index2, 1);
    }
  }

  checkName = (item) => {
 // console.log(item);
    return 'Parent';
  }



  checkSelection = (item): boolean => {
    this.selectedNodes.forEach(e => {
      if (e.id === item.id){
     // console.log(e);
        return true;
      } else {
        return false;
      }
    });
    return false;
  }

  enableFreeNodes = () => {
 // console.log(this.isFree);
    this.isFree = this.isFree === true ? false : true;
 // console.log(this.isFree);
    this.getCategoryList(this.searchKey);
  }

  appendNodes = () => {
      if (this.levels < this.levelForAddition){
        this.levels++;
        const l = {
          index: this.levelForAddition,
          name: 'Level ' + this.levelForAddition,
          value: []
        };
        this.levelList.push(l);
      }
      this.selectedNodes.forEach(element => {
        this.levelList[this.activeIndex].value.push(element); // Addition to level nodes
        let parentID = 0;
        if (this.currentParent.category !== undefined && this.currentParent.category === 'new'){
          parentID = this.currentParent.id
        } else {
          parentID = this.currentParent.attributes.category_id;
        }
        const items = {
          parent_id: parentID,
          child_id: element.id,
          level: this.levelForAddition,
          is_root: false,
          // original_value: this.selectedNodeData.attributes.path,
          is_leaf: element.attributes.is_leaf
        };
        this.finalObject.relation_to_add.push(items);
      });
   // console.log(this.finalObject);
      // this.currentParent = null;
      this.selectedNodes = [];
  }

  save = () => {
    if (this.finalObject.relation_to_add.length > 0){
      this.hierarchyService.postRelationData(this.finalObject).subscribe({
        next: res => {
          if (res) {
         // console.log(res.data);
            this.snackBar.open('Relation Addition request submitted.', '', {
              duration: 4000,
            });
            this.router.navigate(['/home']);
            this.draftId = res.data.id;
            if (this.draftId > 0 && this.files.length > 0){
              setTimeout(() => {
                const file = {
                  attachments: this.files
                };
                let form = new FormData();
                this.files.forEach(e => {
                  form.append('attachments', e);
                });
             // console.log(form);
                this.nodeService.uploadFile(this.draftId, form).subscribe({
                  next: resp => {
                    this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
                      duration: 4000,
                      panelClass: ['error-snackbar']
                    });
                    this.router.navigate(['/home']);
                  },
                  error: (HttpResponse: HttpErrorResponse) => {
                    this.loading = false;
                    this.errorHandler.pass(HttpResponse.error);
                 // console.log(HttpResponse.error. detail);
                  }
                });
              }, 2000);
            } else {
              this.loading = false;
              this.snackBar.open(`Relation edit request submitted successfully.`, 'OK', {
                duration: 4000,
                panelClass: ['success-snackbar']
              });
              this.router.navigate(['/home']);
            }
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    }
  }

  cancel = () => {
 // console.log('Cancelled');
  }

  // Dialog for Notes and MISC

  dialogForAttachments = () => {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '70vh';
    dialogConfig.width = '85vw';
    dialogConfig.data = {category: this.selectedNodeData, data: this.finalObject};
    const modalDialog = this.dialog.open(
      RelationMiscDialogComponent, dialogConfig
    );
    modalDialog.afterClosed().subscribe(result => {
   // console.log(result);
      this.finalObject.note = result.notes;
      this.finalObject.internal_ticket_id = result.internalTicketID;
      this.files = result.files;
   // console.log(this.finalObject);      
      this.save();
    });
  }
}


