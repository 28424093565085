import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attribute-suggestion',
  templateUrl: './attribute-suggestion.component.html',
  styleUrls: ['./attribute-suggestion.component.scss']
})
export class AttributeSuggestionComponent implements OnInit {

  constructor() { }
  loading = false;
  list = [
      {
          "id": 58,
          "name": "Abrading Application",
          "is_global_attribute": false
      },
      {
          "id": 201,
          "name": "Abrasive Depth",
          "is_global_attribute": false
      },
      {
          "id": 199,
          "name": "Abrasive Length",
          "is_global_attribute": false
      },
      {
          "id": 28,
          "name": "Abrasive Material",
          "is_global_attribute": false
      },
      {
          "id": 200,
          "name": "Abrasive Width",
          "is_global_attribute": false
      },
      {
          "id": 197,
          "name": "Additive Compatibility",
          "is_global_attribute": false
      },
      {
          "id": 217,
          "name": "Air Requirement",
          "is_global_attribute": false
      },
      {
          "id": 233,
          "name": "Air Volume",
          "is_global_attribute": false
      },
      {
          "id": 42,
          "name": "Applicable Materials",
          "is_global_attribute": false
      },
      {
          "id": 221,
          "name": "Approximate Weight",
          "is_global_attribute": false
      },
      {
          "id": 4,
          "name": "Arbor Hole Size",
          "is_global_attribute": false
      },
      {
          "id": 173,
          "name": "Arbor/Shank Size",
          "is_global_attribute": false
      },
      {
          "id": 21,
          "name": "Attachment Type",
          "is_global_attribute": false
      },
      {
          "id": 97,
          "name": "Backing Material",
          "is_global_attribute": false
      },
      {
          "id": 98,
          "name": "Backing Weight",
          "is_global_attribute": false
      },
      {
          "id": 119,
          "name": "Band Diameter",
          "is_global_attribute": false
      },
      {
          "id": 120,
          "name": "Band Length",
          "is_global_attribute": false
      },
      {
          "id": 99,
          "name": "Belt Length",
          "is_global_attribute": false
      },
      {
          "id": 100,
          "name": "Belt Width",
          "is_global_attribute": false
      },
      {
          "id": 176,
          "name": "Blade Cutting Width",
          "is_global_attribute": false
      },
      {
          "id": 175,
          "name": "Blade Diameter",
          "is_global_attribute": false
      },
      {
          "id": 169,
          "name": "Blade Length",
          "is_global_attribute": false
      },
      {
          "id": 161,
          "name": "Blade Material",
          "is_global_attribute": false
      },
      {
          "id": 162,
          "name": "Blade Orientation",
          "is_global_attribute": false
      },
      {
          "id": 156,
          "name": "Blade Size",
          "is_global_attribute": false
      }
  ]
  ngOnInit(): void {
  }

}
