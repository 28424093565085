import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NodeService } from 'src/app/services/node.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() selectedDraftId: number;
  commentLoader = true;
  comment;
  commentList;
  currentUserDisplayName;
  userId;
  userData;
  userDisplayPicture: any = 'assets/images/user.jpg';
  constructor(
    private nodeService: NodeService,
    private snackBar: MatSnackBar
  ) { }
  ngOnInit(): void {
    this.getComments();
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
 // console.log(this.userData);
    this.userId = this.userData.id;
    if (this.userData.data){
      this.currentUserDisplayName = this.userData.data.attributes.username;
   // console.log(this.currentUserDisplayName);
    }
  }

  getComments = () => {
    this.comment = '';
    this.nodeService.viewComments(this.selectedDraftId).subscribe(res => {
      if (res) {
     // console.log(res);
        this.commentList = res;
        this.commentLoader = false;
      }
    });
  }

  postComment = () => {
    if (this.comment.length > 0){
      const data = {
        body: this.comment,
        ticket_id: this.selectedDraftId,
        author_id: this.userId
      };
      this.nodeService.addComment(this.selectedDraftId, data).subscribe(res => {
        if (res) {
       // console.log(res);
          this.commentLoader = true;
          this.snackBar.open('Comment added.', 'OK', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });
          this.getComments();
          // this.loading = false;
        }
      });
    } else {
      this.snackBar.open('Please add a comment.', 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });

    }

  }

}
