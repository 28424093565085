import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ENDPOINTS } from '../_globals/endpoints';
import { Globals } from '../_globals/endpoints.global';
import { HierarchyService } from './hierarchy.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private httpOptions: HttpHeaders;
  private endpoints: any = ENDPOINTS;
  selectedNodeId = 0;
  nodeSelection = 0;
  currentVersionName: any = null;
  currentVersion: any = null;
  draftListSource = null;
  draftListRedirection = false;
  draftNodeStatus = null;
  subscription: Subscription;
  currentHierarchy: any = null;
  subscriptionHeirarchy: Subscription;

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private versionService: VersionService,
    private hierarchyService: HierarchyService
    ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.subscription = this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      if (this.currentVersion === 0 || this.currentVersion == null) {
     // console.log(this.currentVersion);
        this.currentVersion = JSON.parse(localStorage.getItem('version'));
      } else {
       this.currentVersionName = this.currentVersion.id;
      }
    });
    this.subscriptionHeirarchy = this.hierarchyService.getUpdatedHierarchy().subscribe(hierarchy => {
      this.currentHierarchy = hierarchy;
      if (this.currentHierarchy === 0 || this.currentHierarchy == null) {
     // console.log(this.currentHierarchy);
        this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
      } else {
     // console.log(this.currentHierarchy);
      }
    });

  }

  private sideNavstate = new BehaviorSubject(1);
  changeStatus = this.sideNavstate.asObservable();
  changeSideNavStatus = (value: any) => {
    this.sideNavstate.next(value);
  }
  getUomDetails = (id: any) => {

    let endPoint = this.globals.urlJoin('attribute', 'uomDetails');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions,
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getValidValuesDetails = (id: any) => {
    let endPoint = this.globals.urlJoin('attribute', 'validValueDetails');
    endPoint = endPoint.replace('api', 'api/versions/' + this.currentVersionName);
    return this.http.get<any>(endPoint + id, {
      headers: this.httpOptions,
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

  getVersionList = () => {
    const endPoint = this.globals.urlJoin('version', 'versionList');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    })
      .pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
      );
  }

}
