  <div fxLayout="row" style="padding:20px;
  margin-left: 76px;" class="mainTable">
    <div fxFlex="20" class="listContainer">
      <div class="listHeaderFixed">
        <div class="listHeader">Level 1</div>
      </div>
      <ul>
        <li>
          <button></button>
        </li>
      </ul> 
    </div>
    <div fxFlex="20" class="listContainer">
      <div class="listHeaderFixed">
        <div class="listHeader">Level 2</div>
      </div>
      <ul>
        <li>
          <button></button>
        </li>
      </ul> 
    </div>
    <div fxFlex="20" class="listContainer">
      <div class="listHeaderFixed">
        <div class="listHeader">Level 3</div>
      </div>
      <ul>
        <li>
          <button></button>
        </li>
      </ul> 
    </div>
    
    <div fxFlex="20" class="listContainer">
      <div class="listHeaderFixed">
        <div class="listHeader">Level 4</div>
      </div>
      <ul>
        <li>
          <button></button>
        </li>
      </ul> 
    </div>
    <div fxFlex="20" class="listContainer">
      <div class="listHeaderFixed">
        <div class="listHeader">Level 5</div>
      </div>
      <ul>
        <li>
          <button></button>
        </li>
      </ul> 
    </div>
    
  </div>