<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="row" fxLayoutAlign="center start">
            <div fxFlex="60">
                <form class="example-form" [formGroup]="searchFormGroup" style="width: 100%;">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-icon matPrefix>search</mat-icon>
                      <input type="text"
                            id="searchKey"
                            placeholder="Search"
                            matInput
                            formControlName="searchField"
                            >        
                      <mat-autocomplete #auto="matAutocomplete" style="width: 90%;">
                        <mat-option style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of selectedCategory" [value]="option.name">
                          {{option.attributes.name}}
                          <button *ngIf="option.attributes.category_code" button style="background: #E6E8F0; border: none;margin-left: 20px; height: 20px;border-radius: 4px;">{{option.attributes.category_code}}</button>
                          <button style="background: #52BD94; border: none; height: 20px;border-radius: 4px; float: right;margin-top: 5px;" *ngIf="option.type=='categories'">(Level {{option.attributes.level}})</button>
                          <br />
                          <span button style="background: transparent; border: none; height: 20px;border-radius: 4px;font-size: 12px;">{{option.attributes.path}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
            <div fxFlex="30">
                <mat-form-field  appearance="fill" (mouseenter)="select.open()">
                  <mat-label>Category Type</mat-label>
                  <mat-select #select (selectionChange)="changeNodeType($event);select.close()" [(value)]="nodeType">
                    <mat-option value="cln">Connected Leaf Nodes</mat-option>
                    <mat-option value="cnln">Connected Non-Leaf Nodes</mat-option>
                    <mat-option value="fln">Free Leaf Nodes</mat-option>
                    <mat-option value="fnln">Free Non-Leaf Nodes</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- <div fxFlex="5" fxLayoutAlign="center end">
            <section class="example-section">
                <mat-checkbox class="example-margin" (change)="leafNodeActivate($event)" [(ngModel)]="isLeaf">Leaf Nodes</mat-checkbox>
            </section>
        </div> -->
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button (click)="goToAbandonedNodes()" class='buttonSuccess'>Empty Nodes</button>
            </div>

            <div>
                <button mat-raised-button (click)="goToAddCategory()" class='buttonSuccess'>Add Category</button>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" class="content">
            <div fxFlex="row">
                <div fxFlex="50" class="left">
                    <h3>Category List</h3>
                    <div class="list" (scroll)="onCategoryScroll($event)">
                        <ul class="categoryList" *ngIf="categoryList.length > 0">
                            <li *ngFor="let item of categoryList">
                                <div class="item" fxLayout="row" (click)="getCategoryInfo(item)" [ngClass]="{'active': item.id == selectedNode.id}">
                                    <div fxFlex="100" fxLayoutAlign="start end" class="caption">{{item.attributes.name}}</div>
                                    <div fxLayoutAlign="end end">
                                        <!-- <button mat-icon-button *ngIf="item.is_free">
                                            <mat-icon svgIcon="review" class="infoButton"></mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="categoryList" *ngIf="categoryList.length == 0">
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="100" fxLayoutAlign="start end" class="caption">No data present.</div>
                                    <div fxLayoutAlign="end end">
                                        <!-- <button mat-icon-button *ngIf="item.is_free">
                                            <mat-icon svgIcon="review" class="infoButton"></mat-icon>
                                        </button> -->
                                    </div>
                                </div>
                            </li>
                        </ul>  
                    </div>          
                </div>
                <div fxFlex="50" class="right" style="padding:14px;">
                    <div style="width:100%;text-align:right" *ngIf="isFree && categoryList.length > 0">
                        <button mat-raised-button (click)="editCategory()">
                            Edit Category <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                    </div>
                    <mat-tab-group *ngIf="categoryList.length > 0">
                        <mat-tab label="Category Details">
                            <div class="list">
                                <ul *ngIf="selectedNodeData" class="categoryDetails">
                                    <li>
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Name</div>
                                            <div fxFlex="75" class="caption">{{selectedNodeData.attributes.name}}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Category Code</div>
                                            <div fxFlex="75" class="caption">{{selectedNodeData.attributes.code}}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Description</div>
                                            <div fxFlex="75" class="caption">{{selectedNodeData.attributes.description}}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Is Leaf</div>
                                            <div fxFlex="75" class="caption">{{(selectedNodeData.attributes.is_leaf).toString() | titlecase}}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Is Free</div>
                                            <div fxFlex="75" class="caption">{{(selectedNodeData.attributes.is_free).toString() | titlecase}}</div>
                                        </div>
                                    </li>
                                    <li *ngIf="hierarchies.length > 0">
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="25" class="caption">Connected Hierarchies</div>
                                            <div fxFlex="75" class="caption">
                                                <ul style="padding:0px; margin:0px">
                                                    <li *ngFor="let item of hierarchies">
                                                        {{item.attributes.name}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>   
                        </mat-tab>
                        <mat-tab label="Attributes" *ngIf="attributeLength > 0 && selectedNodeData">
                            <app-attribute-list [attributeList]="attributeList" ></app-attribute-list>
                            <!-- <div class="list">
                                <ul class="categoryDetails">
                                    <li *ngFor="let item of attributeList">
                                        <div class="item" fxLayout="row">
                                            <div fxFlex="100" class="caption">{{item.attributes.attribute.data.attributes.name}}</div>
                                            <button mat-icon-button>
                                                <mat-icon *ngIf="item.attributes.is_filter" svgIcon="filter"></mat-icon>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
                        </mat-tab>    
                    </mat-tab-group>                 
                    <!-- {{selectedNodeData|json}} -->
                </div>
            </div>
        </mat-card>        
    </div>
</div>
