import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HomeService } from 'src/app/services/home.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { NodeService } from 'src/app/services/node.service';
import { DialogNodeAttributesMulti } from '../../category/add-category-multi/add-category-multi.component';

@Component({
  selector: 'app-edit-free-category',
  templateUrl: './edit-free-category.component.html',
  styleUrls: ['./edit-free-category.component.scss']
})
export class EditFreeCategoryComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) attributeValuesAutoComplete: MatAutocompleteTrigger;

  tab1Data: any = null;
  attribute: any = null;
  productType: any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  page = 0;
  pageSize = 10;
  totalItems = 0;
  editedNode;
  attachment;
  selectedAttributes: any = [];
  formGroupAttribute: FormGroup;
  formGroupName: FormGroup;
  searchedAttribute;
  attributeList: any = [];
  filteringAttributeList: any = [];
  activeTabIndex = 0;
  postAttributeObject: any = [];
  postAttributeFilterObject: any = [];
  attributeCounter = {
    page : 1,
    pageSize : 200,
    totalPages: 0
  };
  filterCounter: any = 1;
  isLeaf = false;
  isSingle = true;
  isActive = false;
  isRequired = false;
  minValue = '';
  isDifferentiator = false;
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  searchKeyUoM = '';
  searchKeyValidValues = '';
  searchKey = '';
  selectedValidValue: any = null;
  selecteduom: any = null;
  uomValueList = [];
  validValueList = [];
  selectedTabIndex = 0;
  attributeCount = 0;
  categoryCounter = {
    page : 1,
    pageSize : 50,
    totalPages : 0,
    totalValues: 0
  };
  dataCopy: any = null;
  isFree = false;
  getLeafNodes = false;
  validatedCategoryName = true;
  validatedCategoryCode = true;
  attributesList: any = null;
  isRequiredList = [
      {
        value : null,
        view: 'NULL'
      },
      {
        value : false,
        view: 'False'
      },
      {
        value : true,
        view: 'True'
      }
    ];
  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    private fb: FormBuilder,
    private multiCategoryService: MultiCategoryService,
    private homeService: HomeService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditFreeCategoryComponent>,
    private errorHandler: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
  }

  ngOnInit(): void {
    this.dataCopy = JSON.parse(JSON.stringify(this.data));
 // console.log(this.data);
    this.attributeCount = this.data.attribute.length;
    this.initFormValidValue();
    this.initFormUoM();
    this.attributesList = this.data.attribute;
    this.selectedAttributes = [];
    this.attributeList = [];
    this.filteringAttributeList = [];
    this.postAttributeObject = [];
    this.postAttributeFilterObject = [];
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupName = this.fb.group({
      name : [this.data.category.attributes.name],
      code : [this.data.category.attributes.code]
    });

    this.nodeInfo();
    this.initForm();
 // console.log(this.data.category.attributes.name);
    this.attributeMap(this.attributesList);
    this.initNameForm();
  }

  nodeInfo = () => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributeList = res.results.data;
        this.attributeCounter.totalPages = res.total_pages;
        this.attributeCounter.page = res.page;
        this.tabLoader = false;
      }
    });
  }

  initForm = () => {
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      // console.log(resp);
      // console.log(this.searchedAttribute);
      if (this.formGroupAttribute.get('attribute').value !== ''){
        this.searchKey = resp;
        this.attributeCounter.page = 1;
        this.searchAttribute(resp);
      } else {
        this.searchKey = resp;
        this.attributeCounter.page = 1;
        this.searchAttribute('');
      }
    });
  }

  next = () => {
    this.selectedTabIndex++;
  }

  onTabChanged = (e: any) => {
 // console.log(e);
    this.selectedTabIndex = e.index;
  }

  searchAttribute = (searchKey) => {
    this.attributeCounter.page = 1;
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, searchKey).subscribe(res => {
      if (res) {
        this.attributeList = res.results.data;
        this.attributeCounter.totalPages = res.total_pages;
        this.attributeCounter.page = res.page;
        this.loading = false;
      }
    });
  }

  checkForValidValues = (id) => {
    this.multiCategoryService.getAttributeValidValue(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('Valid Values');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  checkForUOM = (id) => {
    this.multiCategoryService.getAttributeUOM(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('UOM');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  attributeMap = (attributes) => {
    let validValueName = null;
    let uomName = null;
    let validValueId = null;
    let uomId = null;
 // console.log(this.data.attribute);
    attributes.forEach(e => {
      if (e.attributes.uom_codeset){
        uomId = e.attributes.uom_codeset.data.id;
        uomName = e.attributes.uom_codeset.data.attributes.name;
      }
      if (e.attributes.value_codeset){
        validValueId = e.attributes.value_codeset.data.id;
        validValueName = e.attributes.value_codeset.data.attributes.name;
      }
      const params = {
        name: e.attributes.attribute.data.attributes.name,
        attribute_id: e.attributes.attribute_id,
        attribute_sequence: e.attributes.attribute_sequence,
        filter_sequence: e.attributes.filter_sequence,
        is_multi:  e.attributes.is_multi,
        is_active: e.attributes.is_active,
        is_required: e.attributes.is_required,
        is_filter: e.attributes.is_filter,
        min_value: e.attributes.min_value,
        is_differentiator: e.attributes.is_differentiator,
        value_codeset_id: validValueId,
        uom_codeset_id: uomId,
        validValue: validValueName,
        uomValue: uomName
      };
      validValueName = null;
      uomName = null;
      validValueId = null;
      uomId = null;
      const rawParam = {
        attributes: {name: e.attributes.attribute.data.attributes.name},
        id: e.attributes.attribute_id,
        is_filter: e.attributes.is_filter,
        filter_sequence : e.attributes.filter_sequence,
        type: 'attributes'
      };

      // For Adding Filter -------------
      if (e.attributes.is_filter === true){
        this.filterCounter++;
     // console.log(params);
        this.filteringAttributeList.push(rawParam);
        this.postAttributeFilterObject.push(params);
      }
      // -----------------------------------
      this.selectedAttributes.push(rawParam);
      this.postAttributeObject.push(params);
      this.filteringAttributeList.sort((a,b) => a.attributes.filter_sequence - b.attributes.filter_sequence);
      this.postAttributeFilterObject.sort((a,b) => a.filter_sequence - b.filter_sequence);
  
  });
 // console.log(this.selectedAttributes);
    this.postAttributeFilterObject.sort(function(a, b) {
      let keyA = a.filter_sequence;
      let keyB = b.filter_sequence;
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });

    this.filteringAttributeList.sort(function(a, b) {
      let keyA = a.filter_sequence;
      let keyB = b.filter_sequence;
      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }
      return 0;
    });
  }

  selectAttribute = (item, e?) => {
 // console.log(e);
    if (e === undefined || e.checked === true) {
   // console.log(e);
      this.initFormValidValue();
      this.initFormUoM();
   // console.log(item);
      if (this.selectedAttributes.some(e => e.id === item.id)){
     // console.log('Already Selected');
        // const i = this.selectedAttributes.indexOf(item);
        // if (i > -1) {
        //   this.selectedAttributes.splice(i, 1);
        //   this.postAttributeObject.splice(i, 1);
        // }
      } else{
     // console.log(item);
        this.selectedAttributes.push(item);
     // console.log(this.selectedAttributes);
        const index = this.selectedAttributes.length;
        const params = {
          name: item.attributes.name,
          attribute_id: item.id,
          attribute_sequence: null,
          filter_sequence: null,
          is_multi:  false,
          is_active: false,
          is_required: false,
          is_filter: false,
          min_value: '',
          is_differentiator: false,
          value_codeset_id: null,
          uom_codeset_id: null,
          validValue: null,
          uomValue: null
        };
     // console.log(params);
        this.postAttributeObject.push(params);
      }
    }
  }

  removeAttribute = (item, i) => {
 // console.log(item);
    const idToMatch = item.id;
    const index1 = this.postAttributeObject.findIndex(i1 => i1.attribute_id === idToMatch);
    const index2 = this.selectedAttributes.findIndex(i2 => i2.id === idToMatch);
    const index3 = this.postAttributeFilterObject.findIndex(i3 => i3.attribute_id === idToMatch);
    const index4 = this.filteringAttributeList.findIndex(i4 => i4.id === idToMatch);
    if (index1 > -1){
      this.postAttributeObject.splice(index1, 1);
    }
    if (index2 > -1){
      this.selectedAttributes.splice(index2, 1);
    }
    if (index3 > -1){
      this.postAttributeFilterObject.splice(index3, 1);
      this.filterCounter--;
    }
    if (index4 > -1){
      this.filteringAttributeList.splice(index4, 1);
    }

    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
  }

  selectFilter = (item, i) => {
    if (this.postAttributeObject[i].is_filter === false){
      this.postAttributeObject[i].is_filter = true;
      this.postAttributeObject[i].filter_sequence = this.filterCounter;
      this.postAttributeFilterObject.push(this.postAttributeObject[i]);
      this.filterCounter++;
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
      } else {
   // console.log(this.postAttributeFilterObject);
      this.postAttributeObject[i].is_filter = false;
      const i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
      if (i1 > -1) {
        this.postAttributeFilterObject.splice(i, 1);
      }
      this.filterCounter--;
    }
    if (this.filteringAttributeList.some(e => e === item)){
      const i = this.filteringAttributeList.indexOf(item);
      if (i > -1) {
        this.filteringAttributeList.splice(i, 1);
      }
    } else {
      this.filteringAttributeList.push(item);
    }
 // console.log(this.postAttributeObject);
  }

  drop = (event: CdkDragDrop<string[]>) => {
    // console.log(this.selectedAttributes);
    moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
    this.mergeAttribute(this.postAttributeObject);
  }
  mergeAttribute = (obj) => {
    this.postAttributeObject.forEach((e, i) => {
      e.attribute_sequence = i + 1;
    });
 // console.log(this.postAttributeObject);
  }
  dropFilter = (event: CdkDragDrop<string[]>) => {
    moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
  }

  mergeFilterAttribute = (obj) => {
    this.postAttributeFilterObject.forEach((e, i) => {
      e.filter_sequence = i + 1;
    });
 // console.log(this.postAttributeFilterObject);
  }

  mergeFIlterSequence = () => {
    for (let i = 0; i <= this.postAttributeObject.length - 1; i++){
      this.postAttributeFilterObject.forEach(e => {
        if (e === this.postAttributeObject[i]){
          this.postAttributeObject[i].filter_sequence = e.filter_sequence;
        }
      });
    }
  }
  submit = (data) => {
    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
    this.nodeService.selectedAttributeList = this.selectedAttributes;
    this.nodeService.selectedFilterAttributeList = this.filteringAttributeList;
 // console.log(this.postAttributeObject);
    if (this.postAttributeObject.length > 0){
      this.postAttributeObject.forEach(element => {
        delete element.name;
        delete element.uomValue;
        delete element.validValue;
      });
      const newObj = {
        attributes : this.postAttributeObject,
        name: data.category.attributes.name,
        code: data.category.attributes.code,
        description: data.category.attributes.description,
        is_leaf: data.category.attributes.is_leaf,
      };
      if (this.validatedCategoryCode){
        if (this.validatedCategoryName){
          this.multiCategoryService.editCategory(data.category.id, newObj).subscribe({
            next: res => {
              if (res) {
             // console.log(res);
                this.snackBar.open(`Edit request submitted successfully`, 'OK', {
                  duration: 4000,
                  panelClass: ['error-snackbar']
                });
                this.dialogRef.close();
              }
            },
            error: (HttpResponse: HttpErrorResponse) => {
              this.loading = false;
              this.snackBar.open(`${HttpResponse.error. detail}`, 'OK', {
                duration: 4000,
                panelClass: ['error-snackbar']
              });
            }
          });
        } else {
          this.snackBar.open(`Category Name is already present.`, 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
        }
      } else {
        this.snackBar.open(`Category Code is already present.`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    } else {
      this.snackBar.open('No attribute Selected', 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }

  }
  close = () => {
    this.dialogRef.close({data: null});
  }

  initFormUoM = () => {
    this.searchKeyUoM = '';
    this.selecteduom = '';
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {
       this.searchKeyUoM = '';
       this.searchUoM('');
      }
    });
  }

  searchUoM = (searchKey: any) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }
  selectUoMValue = (e: any, i: any) => {
 // console.log(e);
    if (e == null){
      this.postAttributeObject[i].uom_codeset_id = null;
      this.postAttributeObject[i].uomValue = null;
      if (this.postAttributeObject[i].is_filter === true) {
        const index = this.postAttributeObject.findIndex(i => i.attribute_id === i.attribute_id);
        this.postAttributeFilterObject[index].uom_codeset_id = null;
        this.postAttributeObject[index].uomValue = null;
      }
     } else {
      this.selecteduom = e;
   // console.log(e);
      this.postAttributeObject[i].uom_codeset_id = e.id;
      this.postAttributeObject[i].uomValue = e.attributes.name;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].uom_codeset_id = e.id;
      }
      this.selecteduom = null;
      this.formGroupUoM.reset();
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }

  }

  initFormValidValue = () => {
    this.searchKeyValidValues = '';
    this.selectedValidValue = '';
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {
       this.searchKeyValidValues = '';
       this.searchValidValue('');
      }
    });
  }

  searchValidValue = (searchKey: any) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.validValueList = res.results.data;
      }
    });
  }
  selectValidValue = (e: any, i: any) => {
 // console.log(e);
    if (e == null){
      this.postAttributeObject[i].value_codeset_id = null;
      this.postAttributeObject[i].validValue = null;
      if (this.postAttributeObject[i].is_filter === true) {
        const index = this.postAttributeObject.findIndex(i => i.attribute_id === i.attribute_id);
        this.postAttributeFilterObject[index].value_codeset_id = null;
        this.postAttributeObject[index].uomValue = null;
      }
     } else {
      this.selectedValidValue = e;
      this.postAttributeObject[i].value_codeset_id = e.id;
      this.postAttributeObject[i].validValue = e.attributes.name;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].value_codeset_id = e.id;
      }
      this.selectedValidValue = null;
      this.formGroupValidValues.reset();
    }
  }

  singleSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_multi = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_multi = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_multi = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_multi = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  activeSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_active = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_active = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_active = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_active = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  requiredSelection = (e, i) => {
    if (e.checked === true){
      this.postAttributeObject[i].is_required = true;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = true;
      }
    }else {
      this.postAttributeObject[i].is_required = false;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = false;
      }
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

  diffSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_differentiator = true;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_differentiator = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_differentiator = false;
      if (this.postAttributeObject[i].is_filter === true) {
      //  this.postAttributeFilterObject[i].is_differentiator = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  setMinValue = (i, value) => {
    this.postAttributeObject[i].min_value = value;
    if (this.postAttributeObject[i].is_filter === true) {
      // this.postAttributeFilterObject[i].min_value = value;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

  onScroll = (event: any) => {
 // console.log('Here');
    if (this.attributeCounter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
     // console.log('scrolling category');
        if (this.attributeCounter.page < this.attributeCounter.totalPages){
          this.attributeCounter.page++;
          // this.leafNodeLocalLoading = true;
          this.patchCategoryList(this.attributeCounter.page);
        }else{
       // console.log('Cant Load' + this.attributeCounter.page);
        }
      }
    }
  }

  patchCategoryList = (i) => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, this.searchKey)
    .subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.attributeList = this.attributeList.concat(res.results.data);
            this.attributeCounter.totalPages = res.total_pages;
            this.attributeCounter.page = res.page;
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
    });
  }

  submitDetails = (data) => {
 // console.log(data.category.id);
 // console.log(data);
 // console.log(this.dataCopy);
    if (data.category.attributes.is_leaf === false){
      data.attribute = [];
    }
    const newObj = {
      attributes : data.attribute,
      name: data.category.attributes.name,
      code: data.category.attributes.code,
      description: data.category.attributes.description,
      is_leaf: data.category.attributes.is_leaf,
    };
    if (JSON.stringify(data) !== JSON.stringify(this.dataCopy)){
      if (this.validatedCategoryCode){
        if (this.validatedCategoryName){
          this.multiCategoryService.editCategory(data.category.id, newObj).subscribe({
            next: res => {
              if (res) {
             // console.log(res);
                this.snackBar.open(`Edit request submitted successfully`, 'OK', {
                  duration: 4000,
                  panelClass: ['error-snackbar']
                });
                this.dialogRef.close();
              }
            },
            error: (HttpResponse: HttpErrorResponse) => {
              this.loading = false;
              this.errorHandler.pass(HttpResponse.error);
            }
          });
        } else {
          this.snackBar.open(`Category Name is already present.`, 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
        }
      } else {
        this.snackBar.open(`Category Code is already present.`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    } else {
      this.snackBar.open(`No Change.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }
  }

  getCategoryList = (searchKey: string) => {
    this.multiCategoryService.getCategoryList(
      this.categoryCounter.page,
      this.categoryCounter.pageSize,
      this.getLeafNodes,
      searchKey,
      this.isFree
      ).subscribe(res => {
      if (res) {
     // console.log(res);
      }
    });
  }

  validateCategoryName = (name) => {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {
       this.searchKeyValidValues = '';
       this.searchValidValue('');
      }
    });
    if (name !== this.dataCopy.category.attributes.name){
   // console.log('Checking Name');
      this.multiCategoryService.validateCategoryName(name).subscribe(res => {
     // console.log(res);
        if (res.exists) {
          this.validatedCategoryName = false;
        } else {
          this.validatedCategoryName = true;
        }
      });
    }
  }

  validateCategoryCode = (code) => {
    if (code !== this.dataCopy.category.attributes.code){
   // console.log('Checking Name');
      this.multiCategoryService.validateCategoryCode(code).subscribe(res => {
     // console.log(res);
        if (res.exists) {
          this.validatedCategoryCode = false;
        } else {
          this.validatedCategoryCode = true;
        }
      });
    }
  }

  initNameForm = () => {
    this.formGroupName.get('name').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      // console.log(resp);
      // console.log(this.searchedAttribute);
      if (this.formGroupName.get('name').value !== ''){
        this.data.category.attributes.name = resp;
        this.searchKey = resp;
        this.validateCategoryName(resp);
      } else {
        this.searchKey = resp;
      }
    });
    this.formGroupName.get('code').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      if (this.formGroupName.get('code').value !== ''){
        this.data.category.attributes.code = resp;
        this.searchKey = resp;
        this.validateCategoryCode(resp);
      } else {
        this.searchKey = resp;
      }
    });
  }

}
