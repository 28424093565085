import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HomeService } from '../../services/home.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe, NgIf } from '@angular/common';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VersionService } from 'src/app/services/version.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { HierarchyInfoComponent } from '../_shared/hierarchy-info/hierarchy-info.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
interface StatusList {
  value: string;
  viewValue: string;
}

interface TagList {
  value: string;
  viewValue: string;
}

export class Test {}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private homeService: HomeService,
    private infoService: InfoService,
    private fb: FormBuilder,
    private nodeService: NodeService,
    private scrollDispatcher: ScrollDispatcher,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private sharedDataService: SharedDataService,
    private errorHandler: ErrorHandlerService
  ) {
    this.iconRegistry.addSvgIcon(
      'search',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/search.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'filters',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/filters.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'info',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/info-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'refresh',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/refresh.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'empty',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/empty-state.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'search-reset',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/search-reset-icon.svg'
      )
    );
    this.searchFormGroup = this.fb.group({
      searchField : ['']
    });
  }
  @ViewChild('rightPanel') divToMeasureElement: ElementRef;
  // @ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  matAutocomplete: MatAutocompleteTrigger;
  searchOption: any = null;
  options: string[] = [];
  rightPanelActive = false;
  selectedLeafNodeId = 0;
  leafNodeData: any = null;
  selectedLeafNode = null;
  activeCell = 0;
  nodes: any = null;
  rightPanelData: any = null;
  leafNode: any;
  attributesList: any;
  selectedNode: any;
  searchKey: any = '';
  selectedNodeID = 0;
  childNodeList = [];
  searchFormGroup: FormGroup;
  filterData: any;
  searchDepth: any = [];
  searchAttribute = false;
  nodeList: any = [];
  attributeSearch = false;
  currentLevel: any = 0;
  selectedAttributeID: any = 0;
  selectedAttribute: any = null;
  selectedLeafID = 0;
  formCtrlSub: Subscription;
  loading = true;
  searchResult = false;
  levelForm;
  selectedLevels: any = [];
  markedLevel: any;
  searchAttCount = 0;
  levels: any = 6;
  levelList: any = [];
  top = 0;
  left = 0;
  bottom = 0;
  floatMenu = false;
  searchAttributeOpen = true;
  processed = false;
  searchNodeSelected = false;
  leafCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  attributeCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  categoryCounter = {
    page : 1,
    pageSize : 100,
    totalPages : 0,
    totalValues: 0
  };
  leafNodeList = [] ;
  leafNodeListCount = 0;
  attributesListCount = 0;
  scroll = false;
  allNodes: any;
  page = 1;
  pageSize = 10;
  versionList: any = [];
  selectedVersion: any = 0;
  currentVersion: any = null;
  currentVersionName: any = null;
  subscription: Subscription;
  frozen = false;
  previous = false;
  searchPage = 0;
  searchPageSize = 0;
  searchTotalPage = 0;
  totalCategoryCount = 0;
  overlay = false;
  isLeaf = false;

  i = 1;
  attributeLocalLoading = false;
  leafNodeLocalLoading = false;
  subscriptionHeirarchy: Subscription;
  currentHierarchy: any = null;
  currentNodeLevel = 0;
  subscription1;
  userData;
  userDisplayName;
  userGroup = null;
  userType = null;
  currentHierarchyName = null;
  preselectedNode = false;
  preselectedNodeData = null;

  ngOnInit () {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.userDisplayName = this.userData.username;
    this.userGroup = this.userData.groups;
 // console.log(this.userGroup);
    this.userGroup.forEach(e => {
      if (e.name === 'administrator'){
        this.userType = 'administrator';
      } else if (e.name === 'reviewer'){
        this.userType = 'reviewer';
      } else if (e.name === 'editor'){
        this.userType = 'editor';
      }
    });
    // Version Check Live -----------------------------
    this.currentVersion = this.versionService.checkSetVersion();
    this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
      console.log(this.currentVersion);
      if (this.currentVersion){
        this.frozen = this.currentVersion.attributes.is_frozen;
        this.previous = !version.attributes.is_current;
      }
    });

    // + For sticky Back Button +
    // Check if already have a category in storage -------------
    this.preselectedNodeData = this.infoService.selectedCategory;
    if (this.preselectedNodeData){
      this.preselectedNode = true;
      console.log('Category is already there');
      console.log(this.preselectedNodeData);
      setTimeout(() => {
        this.activeTableCell(this.preselectedNodeData);
      }, 1000);
    } else {
      console.log('Fresh Load');
    }

    this.subscriptionHeirarchy = this.hierarchyService.getUpdatedHierarchy().subscribe(hierarchy => {
      this.currentHierarchy = hierarchy;
      if (this.currentHierarchy){
        this.currentHierarchyName = this.currentHierarchy.attributes.name;
        this.selectedNodeID = null;
        console.log(1);
        this.getTreeData();
      }
    });

    this.currentHierarchy = this.hierarchyService.checkSetHierarchy();
    if (this.currentHierarchy && !this.preselectedNodeData){
      this.currentHierarchyName = this.currentHierarchy.attributes.name;
      this.selectedNodeID = null;
      console.log(2);
      this.getTreeData();
    }

    window.addEventListener('scroll', this.scrollSearch, true);
    this.initForm();
    this.loading = true;
  }

  resetFormInputs = () => {
    this.levelForm = new FormGroup({
      level: this.addLevel(this.levelList)
    });
  }


  createFormInputs = () => {
    this.levelForm = new FormGroup({
      level: this.addLevel(this.levelList)
    });
  }

  getSelectedLevel = (e, i: number) => {
    e.stopPropagation();
    this.attributeSearch = false;
    if (this.selectedLevels.includes(i) === false){
      this.selectedLevels.push(i);
    } else {
      const index = this.selectedLevels.indexOf(i);
      if (index > -1) {
        this.selectedLevels.splice(index, 1);
      }
    }
 // console.log(this.selectedLevels);
    this.getSelectedHobbiesName();
    this.filterByLevel();
  }

  filterByLevel = () => {
    if (this.selectedLevels.length > 0){
      this.getSearchSuggestions(this.searchKey);
    } else {
      this.getSearchSuggestions(this.searchKey);
    }
  }

  addLevel = (level) => {
    const arr = level.map(l => {
      return new FormControl(l.selected || false);
    });
    return new FormArray(arr);
  }

  download = (file) => {
    console.log(this.currentVersion);
    if (!this.currentVersion){
      this.currentVersion = this.versionService.checkSetVersion();
      console.log(this.currentVersion);
    }
    if (file === 'delta') {
      if (this.currentVersion.attributes.delta_url){
     // console.log(this.currentVersion.attributes.delta_url);
        window.open(this.currentVersion.attributes.delta_url, '_blank');
      } else {
        this.snackBar.open(`Delta not available for this version`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    } else {
      if (this.currentVersion.attributes.export_url){
     // console.log(this.currentVersion.attributes.export_url);
        window.open(this.currentVersion.attributes.export_url, '_blank');
      } else {
        this.snackBar.open(`Version build in progress`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }
    }

  }


  getSelectedHobbiesName = () => {
    this.selectedLevels = _.filter(
      this.selectedLevels,
      function(level) {
        if (level !== false) {
          return level;
        }
      }
    );
  }

  activeTableCell = (item) => {
    console.log(item);
    if (item.attributes.category.data.id){
      console.log('1');
      this.activeCell = item.id;
      this.getNodeData(item.attributes.category.data.id);
      this.selectedNodeID = item.attributes.category.data.id;
      this.attributesList = [];
      this.attributesListCount = 0;
      this.rightPanelActive = false;
      this.rightPanelData = null;
      this.selectedLeafNodeId = null;
      this.selectedNode = item;
      this.searchResult = false;
      this.currentLevel = item.attributes.level;
      this.nodeService.selectedNode = item;
      if (item.attributes.child_count === 0){
          this.isLeaf = true;
          this.selectLeafNode(this.selectedNodeID);
        } else {
          this.isLeaf = false;
        }
      if (this.rightPanelActive && this.selectedNodeID){
        this.leafNodes(this.selectedNodeID);
        this.getLeafNodeAttributes(this.selectedNodeID);
      }
    } else {
      console.log('2');
      if (this.selectedNodeID === item.attributes.category_id){
        this.selectedNodeID = null;
        this.rightPanelData = null;
        this.leafNode = null;
        this.selectedNode = null;
        this.leafNodeData = null;
        this.selectedLeafNode = null;
        this.attributesList = null;
        this.activeCell = 0;
      } else {
        this.activeCell = item.id;
        this.getNodeData(item.attributes.category_id);
        this.selectedNodeID = item.attributes.category_id;
        this.attributesList = [];
        this.attributesListCount = 0;
        this.rightPanelActive = false;
        this.rightPanelData = null;
        this.selectedLeafNodeId = null;
        this.selectedNode = item;
        this.searchResult = false;
        this.currentLevel = item.attributes.level;
        this.nodeService.selectedNode = item;
        if (item.attributes.child_count === 0){
            this.isLeaf = true;
            this.selectLeafNode(this.selectedNodeID);
          } else {
            this.isLeaf = false;
          }
        if (this.rightPanelActive && this.selectedNodeID){
          this.leafNodes(this.selectedNodeID);
          this.getLeafNodeAttributes(this.selectedNodeID);
        }
      }
    }
  }

  leafNodes = (id: number) => {
    this.leafCounter = {
      page : 1,
      pageSize : 100,
      totalPages : 0,
      totalValues: 0
    };
    this.homeService.getLeafNodes(id, this.leafCounter.page, this.leafCounter.pageSize).subscribe({
      next: res => {
        if (res) {
          this.leafNodeList = res.results.data;
          this.leafNodeListCount = res.total_count;
          this.leafCounter.page = res.page;
          this.leafCounter.totalPages = res.total_pages;
       // console.log(this.leafNodeList);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  avtivateRightPanel = () => {
 // console.log(this.selectedNodeID);
    const width = this.divToMeasureElement.nativeElement.offsetWidth;
 // console.log(width);
    if (this.rightPanelActive){
      this.rightPanelActive = false;
    } else {
      if (this.selectedNodeID){
        this.leafNodes(this.selectedNodeID);
        this.getLeafNodeAttributes(this.selectedNodeID);
      }
      this.rightPanelActive = true;
    }
  }

  getInitialCount = () => {
    this.homeService.getTreeData(this.selectedNodeID, this.categoryCounter.page, this.categoryCounter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.totalCategoryCount = res.total_count;
          this.categoryCounter.pageSize = this.totalCategoryCount;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  updateHeirarchy = () => {
    const old = this.currentHierarchy;
  }

  getTreeData = () => {
    let pageSize = 0;
 // console.log('I am being Called');
    // this.levelList = [];
    this.loading = true;
    this.options = [];
 // console.log(this.currentHierarchy);
    // Get Count and then Call it again to load all
    this.homeService.getTreeData(this.selectedNodeID, this.categoryCounter.page, this.categoryCounter.pageSize).subscribe({
      next: async res => {
        if (res) {
       // console.log(res);
          this.totalCategoryCount = res.total_count;
          pageSize = await this.totalCategoryCount;
          this.categoryCounter.pageSize = this.totalCategoryCount;
          this.homeService.getTreeData(this.selectedNodeID, this.categoryCounter.page, pageSize).subscribe({
            next: res => {
              if (res) {
             // console.log('###################### Calling get Tree Data');
             // console.log(res);
                this.nodeList = res.results;
                console.log(this.nodeList);
                this.loading = false;
                this.rightPanelActive = false;
                this.leafNodeList = [];
                this.rawLevel();
                this.generateLevels();
              }
            },
            error: (HttpResponse: HttpErrorResponse) => {
              this.loading = false;
              this.errorHandler.pass(HttpResponse.error);
            }
          });
        }
      },
    });
  }

  rawLevel = () => {
    // this.levelList = [];
    for (let i = 1; i <= this.levels; i++){
      const l = {
        index: i,
        name: 'Level ' + i,
        value: []
      };
      this.levelList.push(l);
    }
  }

  generateLevels = () => {
 // console.log('calling Me');
    if (this.levelList.length > 0){
      this.levelList.forEach(l => {
        const list = [];
        this.nodeList.data.forEach(e => {
          // console.log(e);
          if (e.attributes.level === l.index){
            list.push(e);
          }else{
          }
        });
        l.value = list;
      });
    }
    this.levelList = this.levelList.slice(0, this.levels);
  }

  getNodeData = (nodeId: number) => {
    this.loading = true;
    this.selectedLeafNodeId = 0;
    this.selectedNodeID = nodeId;
    console.log(nodeId);
    // this.getChildNodesData(nodeId);
    this.homeService.getTreeData(nodeId, this.categoryCounter.page, this.categoryCounter.pageSize).subscribe({
      next: res => {
        if (res) {
          console.log(res);
          this.allNodes = res;
          this.rawLevel();
          this.putNodes();
          this.loading = false;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });

  }

  putNodes = () => {
    console.log(this.allNodes);
    this.nodeList = [];
    this.nodeList = this.nodeList.concat(this.allNodes.data.attributes.ancestors);
    this.nodeList = this.nodeList.concat(this.allNodes.data.attributes.children);
    this.nodeList = this.nodeList.concat(this.allNodes.data.attributes.siblings);
    console.log(this.preselectedNodeData);
    
  //  console.log(this.allNodes.data.attributes.siblings);
    this.levelList.forEach(l => {
      if (this.currentLevel !== l.index || l.value.length === 1 ||
        (this.preselectedNode && this.currentLevel === this.preselectedNodeData.attributes.level)){
        console.log('Condition - 1');
        const list = [];
        this.nodeList.forEach(e => {
          if (e.data.attributes.level === l.index){
            list.push(e.data);
          }else{
          }
        });
        l.value = list;
      } else {
        console.log('Condition - 2');
      }
    });
    this.levelList = this.levelList.slice(0, this.levels);
    console.log(this.levelList);
}

  getLeafNodeData = (nodeId: number) => {
    this.loading = true;
    // this.rightPanelData = null;
    this.attributesList = null;
    this.homeService.getNodeData(this.selectedNodeID, nodeId, this.attributeCounter.page, this.attributeCounter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.rightPanelData = res;
          if (this.searchResult){
            this.selectedLeafNode = this.rightPanelData.detail.attributes.names[0];
            // this.attributesList = this.rightPanelData.detail.attributes.names;
            this.loading = false;
          } else {
            // this.attributesList = this.rightPanelData.detail.attributes;
            this.loading = false;

          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  selectLeafNode = (id: any) => {
 // console.log(id);
    this.selectedLeafNodeId = id;
 // console.log(this.selectedLeafNodeId);
    this.getLeafNodeAttributes(this.selectedLeafNodeId);
  }

  getLeafNodeAttributes = (i: any) => {
    this.loading = true;
    this.selectedLeafNodeId = i;
    this.homeService.getLeafNodeAttributes(i).subscribe({
      next: res => {
        if (res) {
          this.attributesList = res.results.data;
       // console.log(this.attributesList);
          this.attributesListCount = res.total_count;
          this.loading = false;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  selectAttribute = (i: any) => {
    // console.log(i);
    if (this.attributeSearch === false){
      // this.rightPanelActive = true;
    }
    if (this.searchResult){
      if (i.attribute_id){
        this.selectedAttributeID = i.attribute_id;
        // this.getAttributeData(this.selectedAttributeID,this.leafCounter.page,this.leafCounter.pageSize);
      } else {
        this.selectedAttributeID = i.id;
        // this.getAttributeData(this.selectedAttributeID,this.leafCounter.page,this.leafCounter.pageSize);
      }
    }
  }
  //

  initForm = () => {
    this.searchNodeSelected = false;
    this.searchFormGroup = this.fb.group({
      searchField : ['']
    });
    this.searchFormGroup.get('searchField').valueChanges.pipe(debounceTime(1000),
    distinctUntilChanged()).
    subscribe(resp => {
      if (this.searchFormGroup.get('searchField').value != ''){
     // console.log(resp);
        this.searchKey = resp;
        this.searchPageSize = 1;
        this.getSearchSuggestions(resp);
      } else {
        this.getTreeData();
      }
    });
  }

  getSearchSuggestions = (searchKey: any) => {
    if (this.searchKey !== 0){
   // console.log(searchKey);
      this.searchKey = searchKey;
   // console.log(this.selectedLevels);
      this.homeService.searchNode(searchKey, this.selectedLevels, this.page).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            this.options = res.results.data;
            this.searchPage = res.page;
            this.searchPageSize = res.page_size;
            this.searchTotalPage = res.total_pages;
         // console.log(this.options);
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    }
  }

  enterSearch = (e) => {
    // console.log(e);
    if (e.keyCode === 13){
      const input = this.searchFormGroup.get('searchField').value;
      // console.log(input);
      if (input){
        // this.getSearchResults(input, this.selectedLevels, this.attributeSearch);
        // this.matAutocomplete.closePanel();
      }
    }
  }

  resetSearch = () => {
    this.selectedNode = null;
    this.leafNodeListCount = 0;
    this.leafNodeListCount = 0;
    this.selectedLevels = [];
    this.attributesList = null;
    this.leafNodeList = [];
    this.searchKey = '';
    this.attributesListCount = 0;
    this.attributeSearch = false;
    this.rightPanelActive = false;
    this.leafNode = null;
    this.attributesList  = null;
    this.rightPanelData = false;
    this.searchResult = null;
    this.loading = true;
    this.homeService.selectedNodeId = 0;
    this.ngOnInit();
  }

  checkIndex = (i) => {
    return this.selectedLevels.includes(i);
  }

  getSearchData = (op) => {
    // console.log('Selected SearchKey as -- '+this.searchKey);
    // console.log(op);
    // this.rightPanelActive = true;
    // if(this.attributeSearch==true){
    //   this.rightPanelActive = true;
    // }
  }

  getSearchParam = (ob) => {
 // console.log(ob);
    this.searchResult = true;
    if (ob.type === 'categories_to_hierarchies'){
      this.searchNodeSelected = true;
      this.selectedNodeID = ob.attributes.category.data.id;
      this.selectedNode = ob;
   // console.log(this.selectedNode);
      this.homeService.selectedNodeId = this.selectedNodeID;
      // this.getTreeData();
      this.getNodeData(this.selectedNodeID);
    } else {
      this.attributesList = [];
      this.attributesList.push(ob);
   // console.log(this.attributesList);
      this.searchNodeSelected = true;
      this.selectedAttributeID = ob.id;
      // this.getAttributeData(this.selectedAttributeID,this.attributeCounter.page,this.attributeCounter.pageSize);
      this.rightPanelActive = true;
    }
  }

  nextPageLeafNode = (dir) => {
 // console.log(dir);
  }

  nextPageAttribute = (dir) => {
 // console.log(dir);
    if (dir === '1' && this.attributeCounter.totalPages < this.attributeCounter.page){
      this.attributeCounter.page++;
      this.getNodeData(this.selectedNodeID);
    } else if (dir === '0' && this.attributeCounter.totalPages > this.attributeCounter.page){
      this.attributeCounter.page--;
      this.getNodeData(this.selectedNodeID);
    }
  }

  getSearchResults = (searchKey: any, depth: any, attribute: any) => {
    if (this.searchNodeSelected === false){
      this.loading = true;
      this.searchResult = true;
   // console.log('Calling search Details');
      this.homeService.getSearchResults(searchKey, depth).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            if (attribute === true){
              this.loading = false;
           // console.log('In attribute Search >>>>>>');
              this.attributesList = res.detail.results;
              this.searchAttCount = res.detail.total;
              if (this.attributesList.length > 0){
                this.selectAttribute(this.attributesList[0]);
                // console.log(this.attributesList[0]);
              } else {
                // console.log(this.attributesList);
              }
            } else {
              // console.log(res);
              this.loading = false;
              this.rightPanelActive = false;
              this.nodes = res.detail;
              this.nodeList = res.detail.results;
              this.selectedLeafNode = this.options;
              // console.log(this.nodeList);
              this.nodeList.forEach(e => {
                if (e.nodes.length === 1 && this.nodes.total === 1){
                  // console.log(e);
                  this.getNodeData(e.nodes[0].node_id);

                }
              });
            }
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    }
  }

  addSearchDepth = (i) => {
    this.attributeSearch = false;
    let st = 0;
    this.selectedLevels.forEach(e => {
      if (e === i){
        st ++;
        const index = this.searchDepth.indexOf(i);
        this.searchDepth.splice(index, 1);
      }
    });
    if (st === 0){
      this.searchDepth.push(i);
    }
    // console.log(this.attributeSearch);
    // console.log(this.searchDepth);
  }

  checkActiveSearchLevel = (i: number) => {
    this.selectedLevels.forEach(e => {
      if (e === i){
        return true;
      }else {
        return false;
      }
    });
  }

  goToInfo = (node: any) => {
    console.log(node.attributes.category.data.id);
    this.infoService.nodeId = node.attributes.category.data.id;
    this.infoService.selectedCategory = node;
    this.router.navigate(['/info']);
  }

  goToAttributeInfo = (attribute) => {
 // console.log(attribute);
    this.infoService.nodeId = this.selectedLeafNodeId;
    this.infoService.attribute = attribute;
    this.infoService.attributeId = attribute.attributes.attribute_id;
    this.infoService.selectedCategory = this.selectedNode;
    this.router.navigate(['/attribute-info']);
  }

  checkNode = (node) => {
 // console.log(node);
    this.homeService.getTreeData(node.attributes.category.data.id, this.categoryCounter.page, this.categoryCounter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.isLeaf = res.data.attributes.is_leaf;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });

  }

  scrollSearch = (event) => {
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
      if (this.i < this.searchTotalPage){
        this.i = this.i + 1;
     // console.log('Can Load' + this.i);
        this.attributeLocalLoading = true;
     // console.log(this.searchKey);
        this.patchSearch(this.i);
      }else{
        this.i = this.searchTotalPage;
     // console.log('Cant Load' + this.i);
      }
    } else if (event.target.scrollTop === 0) {
   // console.log('Start');
    }
  }

  patchSearch = (i) => {
    const page = i;
 // console.log(page);
 // console.log(this.searchKey);
    if (this.searchKey !== undefined){
   // console.log(this.selectedLevels);
      this.homeService.searchNode(this.searchKey, this.selectedLevels, i).subscribe({
        next: res => {
          if (res) {
         // console.log(res);
            // this.options = res.results.data;
            this.options = this.options.concat(res.results.data);
            this.searchPage = res.page;
            this.searchPageSize = res.page_size;
            this.searchTotalPage = res.total_pages;
         // console.log(this.options);
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    }
  }

  rightClick = (e, node) => {
      if (!this.previous && this.frozen){
        e.preventDefault();
        this.snackBar.open(`Current Taxonomy is Frozen.`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      }else if (this.previous){
        e.preventDefault();
        this.snackBar.open(`Edit is disabled for old Taxonomy versions.`, 'OK', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      } else {
        e.preventDefault();
     // console.log(e);
     // console.log(e.view.innerHeight);
        this.top = e.view.innerHeight - e.clientY;
        this.left = e.clientX;
        // this.selectedNode = node;
        this.currentNodeLevel = node.attributes.level;
        this.selectedNodeID = node.attributes.category.data.id;
        this.nodeService.selectedNode = node;
        this.infoService.selectedCategory = node;
        this.infoService.nodeId = node.attributes.category.data.id;
     // console.log(node);
        this.overlay = true;
        this.floatMenu = true;
      }
  }


  selectedOption = (i) => {
    if (i === 1){
   // console.log(i);
      // this.infoService.nodeId = ;
      this.router.navigate(['/info']);
    } else if (i === 2){
   // console.log(i);
      this.router.navigate(['/edit-relation']);
    } else if (i === 3){
   // console.log(i);
      this.router.navigate(['/add-relation']);
    }
    else if (i === 4){
   // console.log(i);
      this.router.navigate(['/add-attribute']);
    }
    else if (i === 5){
      this.deleteNode();
    }
    this.floatMenu = false;
    this.overlay = false;
  }

  overlayClick = () => {
    this.overlay = false;
    this.floatMenu = false;
  }

  onAttributeScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
      if (this.i < this.attributeCounter.totalPages){
        this.i = this.i + 1;
     // console.log('Can Load' + this.i);
        this.attributeLocalLoading = true;
        this.patchAttributeValues(this.i);
      }else{
        this.i = this.attributeCounter.totalPages;
     // console.log('Cant Load' + this.i);
      }
   // console.log('End');
    } else if (event.target.scrollTop === 0) {
   // console.log('Start');
    }
  }

  patchAttributeValues = (i) => {
    const page = i;
    this.homeService.getNodeData(this.selectedNodeID, 0, page, this.attributeCounter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.rightPanelData = res;
          this.leafNode = this.rightPanelData.detail.product_types.nodes;
          if (this.leafNode.length === 1){
            this.selectLeafNode(this.leafNode[0]);
          }
          this.attributesList.names = this.attributesList.names.concat(this.rightPanelData.detail.attributes.names);
          this.attributeLocalLoading = false;
          if (this.searchResult){
            this.getLeafNodeData(this.leafNode[0].node_id);
          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  onLeafNodeScroll(event: any) {
    if (this.leafCounter.totalPages > 1){
      if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
     // console.log('scrolling leaf');
        if (this.leafCounter.page < this.leafCounter.totalPages){
          this.leafCounter.page++;
          this.leafNodeLocalLoading = true;
          this.patchLeafNodeValues(this.leafCounter.page);
        }else{
       // console.log('Cant Load' + this.leafCounter.page);
        }
      }
    }
  }

  patchLeafNodeValues = (i) => {
    this.homeService.getLeafNodes(this.selectedNodeID, i, this.leafCounter.pageSize).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
          this.leafNodeList = this.leafNodeList.concat(res.results.data);
          this.leafNodeLocalLoading = false;
          this.leafCounter.page = res.page;
          this.leafCounter.totalPages = res.total_pages;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  deleteNode = () => {
 // console.log('Delete');
 // console.log(this.selectedNodeID);
    this.openDialog(this.selectedNodeID);
    // let data = {
    //   edit_type: "category_detach",
    //   node_to_detach: this.selectedNodeID
    // }
    // this.nodeService.addCategory(this.selectedAttributeID,data).subscribe({
    //   next: (resp:any) => {
    //  // console.log(resp);
    //     this.snackBar.open(`Category delete requested submitted successfully.`, 'OK', {
    //       duration: 4000,
    //       panelClass: ['success-snackbar']
    //     });
    //   },
    //   error: (HttpResponse: HttpErrorResponse) => {
    //     this.loading = false
    //     this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
    //       duration: 4000,
    //       panelClass: ['error-snackbar']
    //     });
    //   }
    // });
  }

  openDialog(id): void {
 // console.log(id);
    this.infoService.nodeId = id;
    const dialogRef = this.dialog.open(DialogNodeDelete, {
      width: '98vw',
      data: {id}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      // if(result) {
      //   let data = {
      //     edit_type: "category_detach",
      //     node_to_detach: this.selectedNodeID,
      //     note: "Category delete example",
      //     internal_ticket_id: "123CategoryDelete"
      //   }
      //   this.nodeService.delCategory(this.selectedNodeID,data).subscribe({
      //     next: (resp:any) => {
      //    // console.log(resp);
      //       this.snackBar.open(`Category delete requested submitted successfully.`, 'OK', {
      //         duration: 4000,
      //         panelClass: ['success-snackbar']
      //       });
      //     },
      //     error: (HttpResponse: HttpErrorResponse) => {
      //       this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
      //         duration: 4000,
      //         panelClass: ['error-snackbar']
      //       });
      //     }
      //   });
      // } else {
      // }
    });
  }

}


// ---------------------------------------- Dialog ---------------------------------------------------
@Component({
  selector: 'dialog-node-delete',
  templateUrl: './delete-dialog.html',
  styleUrls: ['./home.component.scss']
})
  export class DialogNodeDelete implements OnInit{
    nodeData;
    loader = true;
    ticketId = '';
    notes;
    files: any = [];
    selectedNodeID;
    uploadForm;
    draftId;
    isLeaf = false;
    selectedVersion = this.versionService.checkSetVersion();
    constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<DialogNodeDelete>,
      private iconRegistry: MatIconRegistry,
      private sanitizer: DomSanitizer,
      private snackBar: MatSnackBar,
      private infoService: InfoService,
      private nodeService: NodeService,
      private formBuilder: FormBuilder,
      private errorHandler: ErrorHandlerService,
      private versionService: VersionService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.iconRegistry.addSvgIcon(
        'upload',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/addFolder.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'zip',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/zip.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'csv',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/csv.svg'
        ),
      );
      this.iconRegistry.addSvgIcon(
        'pdf',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/pdf.svg'
        ),
      );
    }
    selectedHierarchy = null;
    // Edit ------
    ngOnInit(): void {
   // console.log(this.data);
      this.nodeInfo(this.data.id);
      this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    }
    nodeInfo = (id) => {
      this.infoService.getNodeData(id).subscribe(res => {
        if (res) {
       // console.log(res);
          this.nodeData = res.data;
          this.isLeaf = this.nodeData.attributes.is_leaf;
          this.loader = false;
        }
      });
    }

    viewHierarchy = (category) => {
   // console.log(category);
      let categoryName = null;
      if (category){
        categoryName = category.attributes.category.data.attributes.name;
      }
      const dialogConfig = new MatDialogConfig();
      // dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-hierarchy';
      dialogConfig.width = '60vw';
      dialogConfig.data = {categoryId: category.attributes.category_id, categoryName};
      const modalDialog = this.dialog.open(
        HierarchyInfoComponent, dialogConfig
        );
    }
    onCancel(): void {
      this.dialogRef.close(null);
    }
    onSelect = (event) => {
   // console.log(event);
      this.files.push(...event.addedFiles);
    }

    onRemove = (event) => {
   // console.log(event);
      this.files.splice(this.files.indexOf(event), 1);
    }

    submit = () => {
      this.uploadForm = this.formBuilder.group({
        attachment: ['']
      });
      const data = {
          edit_type: 'category_detach',
          node_to_detach: this.data.id,
          hierarchy_id : this.selectedHierarchy.id,
          version_id: this.selectedVersion.id,
          is_leaf: this.isLeaf,
          note: this.notes,
          internal_ticket_id: this.ticketId
        };
   // console.log(data);
      this.nodeService.nodeEdit(data).subscribe({
          next: (resp: any) => {
         // console.log(resp);
            if (resp.data.id > 0){
              this.draftId = resp.data.id;
           // console.log(this.draftId);
              if (this.draftId > 0 && this.files.length > 0){
                setTimeout(() => {
                  const file = {
                    attachments: this.files
                  };
                  const form = new FormData();
                  this.files.forEach(e => {
                    form.append('attachments', e);
                  });
               // console.log(form);
                  this.nodeService.uploadFile(this.draftId, form).subscribe({
                    next: resp => {
                   // console.log('resp');
                      this.snackBar.open(`Draft ticket for deletion request created successfully.`, 'OK', {
                        duration: 4000,
                        panelClass: ['error-snackbar']
                      });
                      this.dialogRef.close({});
                    }
                  });
                }, 2000);
              } else {
                this.snackBar.open(`Draft ticket for deletion request created successfully.`, 'OK', {
                  duration: 4000,
                  panelClass: ['error-snackbar']
                });
                this.dialogRef.close();
              }

            }
          },
          error: (HttpResponse: HttpErrorResponse) => {
            this.errorHandler.pass(HttpResponse.error);
            this.dialogRef.close({});
          }
        });
      this.dialogRef.close(this.data);
    }

    getIcon = (name) => {
      const arr = name.split('.');
      // console.log(arr);
      if (arr[1] === 'csv'){
        return 'csv';
      } else if (arr[1] === 'zip'){
        return 'zip';
      } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
        return 'pdf';
      } else {
        return 'zip';
      }
    }
}
