import { Component, Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private snackBar: MatSnackBar,

  ) { }
  pass = (e) => {
 // console.log(e);
    if (e.conflict){
      const draftDetails = e.drafts;
      const snackBar = this.snackBar.openFromComponent(ConflictSnackBar, {
        data: e,
        duration: 5000,
        panelClass: ['error-snackbar']
      });
    } else if (e){
      if (typeof e === 'object' && e.detail === undefined){
        const error = e;
        const errorList = [];
        for (const p in error) {
       // console.log (p, error[p]);
          const x = {
            title: p,
            value: error[p]
          };
          errorList.push(x);
        }
     // console.log(errorList);
        const snackBar = this.snackBar.openFromComponent(MultipleErrors, {
          data: errorList,
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      } else {
        if (e.detail){
          const error = e.detail;
          const errorList = [];
          if(typeof(error) === 'string'){
            this.snackBar.open(error, 'OK', {
              duration: 5000,
              panelClass: ['error-snackbar']
            });
          } else {
            for (const p in error) {
           // console.log (p, error[p]);
              const x = {
                title: p,
                value: error[p]
              };
              errorList.push(x);
            }
         // console.log(errorList);
            const snackBar = this.snackBar.openFromComponent(MultipleErrors, {
              data: errorList,
              duration: 5000,
              panelClass: ['error-snackbar']
            });  
          }
        } else {
          this.snackBar.open(e.detail, 'OK', {
            duration: 5000,
            panelClass: ['error-snackbar']
          });
        }
      }
      
    }
  }
}

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: './snackBar.html',
})
export class ConflictSnackBar {
  tickets = [];
  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<ConflictSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  view = () => {

 // console.log('View This Id');
 // console.log(this.data);
    this.data.drafts.forEach(e => {
      this.tickets.push(e.id);
    });
 // console.log(this.tickets);
    const ticketParam= this.tickets.join('&');
    const url = 'draft-view/' + ticketParam;
 // console.log(url);
    window.open(url, '_blank');
    this.snackBar.dismiss();
    // this.tickets.forEach(e => {
    //   const url = 'draft-view/' + e;
    //// console.log(url);
    //   window.open(url, '_blank');
    // });

    // this.router.navigate([]).then(result => {  window.open('', '_blank'); });
  }
}

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: './snackBarMultipleError.html',
})
export class MultipleErrors {
  tickets = [];
  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<MultipleErrors>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ok = () => {
    this.snackBar.dismiss();
    }
}
