<mat-dialog-content>
    <div fxLayout="column" class="mainContainer">
        <div fxLayout="row" flexLayoutAlign="center center">
            <h2><b>{{categoryName}}</b> in other Hierarchies</h2>
        </div>
        <div fxLayout="row">
            <ul>
                <li *ngFor="let item of hierarchyInfo" fxLayout="column" fxLayoutAlign="center start">
                    <div fxLayout="row" style="width: 100%;" fxLayoutAlign="start center">
                        <div fxFlex="90">{{item.attributes.path}} (L{{item.attributes.level}})</div>
                        <div fxFlex="10" flexLayoutAlign="end center">{{item.attributes.hierarchy.data.attributes.name}}</div>
                    </div>
                </li>
            </ul>
        </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>