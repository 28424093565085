<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Add Attribute <span *ngIf="selectedNodeData">({{selectedNodeData.attributes.category.data.attributes.name}})</span></span>
        </div>
        <div fxFlex="30" fxLayoutAlign="end center" fxLayout="row">
            <div>
                <button mat-raised-button (click)="save()" class='buttonSuccess'>Proceed</button>
            </div>
            <div>
                <button mat-raised-button (click)="cancel()" class="buttonCancel">Cancel</button>
            </div>
        </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-card fxLayout="row" *ngIf="!loading">
            <div fxFlex="50">
                <div style="float: right;padding-right: 20px;">
                  <button [ngClass]="{'activeButton': !existingAttibure}" (click)="selectExistingAttribute()" mat-raised-button>Select Existing</button>
                </div>
                <div *ngIf="existingAttibure" fxLayout="column" fxLayoutAlign="center start" style="margin-top: 20px;">
                  <form class="example-form" [formGroup]="formGroupAttribute" style="width: 100%;">
                    <mat-label>Select Attribute Name</mat-label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text"
                              id="attribute"
                              placeholder="Search Attribute Name"
                              matInput
                              formControlName="attribute"
                              [matAutocomplete]="attributeAutoComplete">        
                        <mat-autocomplete #attributeAutoComplete="matAutocomplete" style="width: 90%;">
                          <mat-option (click)="selectAttributeValue(option)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of attributeList" [value]="option.attributes.name">
                            {{option.attributes.name}}
                          </mat-option>
                          <mat-option *ngIf="validValueList.length==0">No data present.</mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </form>
                </div>
                <div>
                    <mat-label>Attribute Name</mat-label>
                    <mat-form-field appearance="outline">
                        <input [disabled]="existingAttibure" matInput [(ngModel)]="selectedAttribute">
                    </mat-form-field>
                </div>
                <!-- <div>
                  <mat-label>Description</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="description"></textarea>
                  </mat-form-field>
                </div> -->
                <section>
                  <section class="example-section">
                    <mat-checkbox class="example-margin" [(ngModel)]="isSingle">Is Single</mat-checkbox>
                </section>
                <section class="example-section">
                    <mat-checkbox class="example-margin" [(ngModel)]="isFilter" >Filtering Attribute</mat-checkbox>
                </section>

                <div fxLayout="column" fxLayoutAlign="center start" style="margin-top: 20px;">
                  <form class="example-form" [formGroup]="formGroupUoM" style="width: 100%;">
                    <mat-label>UoM Codeset</mat-label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text"
                              id="searchKey"
                              placeholder="Search UoM Codeset"
                              matInput
                              formControlName="uom"
                              [matAutocomplete]="uomAutoComplete">        
                        <mat-autocomplete #uomAutoComplete="matAutocomplete" style="width: 90%;">
                          <mat-option (click)="selectUoMValue(option)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of uomValueList" [value]="option.attributes.name">
                            {{option.attributes.name}}
                          </mat-option>
                          <mat-option *ngIf="validValueList.length==0">No data present.</mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </form>
                </div>

                <div fxLayout="column" fxLayoutAlign="center start" >
                  <form class="example-form" [formGroup]="formGroupValidValues" style="width: 100%;">
                      <mat-label>Valid Values</mat-label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text"
                              id="searchKey"
                              placeholder="Search Valid Values"
                              matInput
                              formControlName="validValue"
                              [matAutocomplete]="validValuesAutoComplete">        
                        <mat-autocomplete #validValuesAutoComplete="matAutocomplete" style="width: 90%;">
                          <mat-option (click)="selectValidValue(values)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of validValueList" [value]="values.attributes.name">
                            {{values.attributes.name}}
                          </mat-option>
                          <mat-option *ngIf="validValueList.length==0">No Valid Values are found.</mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </form>
                </div>
                </section>
                
                <div>
                  <mat-label>Internal Ticket ID</mat-label>
                  <mat-form-field appearance="outline">
                      <input matInput [(ngModel)]="ticketId">
                  </mat-form-field>
                </div>
            </div>
            <div fxFlex="50">
              
              <div class="textArea">
                  <mat-label>Notes</mat-label>
                  <mat-form-field appearance="outline">
                      <textarea matInput placeholder="" rows="4" [(ngModel)]="notes"></textarea>
                  </mat-form-field>
              </div>
              <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                      <mat-icon svgIcon="upload"></mat-icon>
                      <p>Drag or Drop your files here</p>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
        </mat-card>
    </div>
</div>
  