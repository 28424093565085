<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
      <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
        <button mat-icon-button routerLink='/category-list'><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Empty Nodes</span>
      </div>
      <div fxFlex="44" fxLayoutAlign="end center">
      </div>
    </div>
    <div class="dataContainer">
      <app-loader *ngIf="loading"></app-loader>
      <mat-card fxLayout="row" *ngIf="!loading">
          <div class="list" (scroll)="onCategoryScroll($event)">
                <ul *ngIf="list.length > 0">
                    <li *ngFor="let item of list">
                        <div fxLayout="row">
                            <div fxFlex="80">
                                {{(item.attributes.path).replace(item.attributes.category.data.attributes.name,' ')}} <span style="color:#000; font-weight: 600;">{{item.attributes.category.data.attributes.name}}</span>
                            </div>
                            <div fxFlex="20" fxLayout="column">
                                <span>Version : <b>{{item.attributes.version.data.attributes.display_name}}</b></span>
                                <span>Hierarchy : <b>{{item.attributes.hierarchy_name}}</b></span>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul *ngIf="list.length == 0">
                    <li>
                        <div fxLayout="row">
                        <div fxFlex="80">
                            No Empty Nodes
                        </div>
                        </div>
                    </li>
              </ul>
          </div>
      </mat-card>
  </div>


