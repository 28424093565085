const ENDPOINTS: any = {
  users: {
    login: '/api/users/login/',
    logout: '/api/users/logout/',
    me: '/api/users/profile/',
    test: '/images/process/prediction',
  },

  version : {
    versionList: '/api/versions/'
  },

  hierarchy : {
    hierarchyList: '/api/hierarchies/',
    categories: '/api/taxonomies/categories/',
    categoryDetails: '/api/taxonomies/categories/',
    attributes: '/api/taxonomies/attributes/',
    valid_values: '/api/taxonomies/attributes/',
    uom_values: '/api/taxonomies/attributes/',
    free_nodes: '/api/taxonomies/hierarchies/',
    add_relation: '/api/drafts/',
    get_attributes_data: '/api/taxonomies/attributes/list/',
    get_node_data: '/api/taxonomies/categories/list/',
  },

  home : {
    tree_data: '/api/categories/',
    node_details: '/api/categories/',
    search: '/api/categories/search/',
    abandoned_nodes : '/api/versions/categories/abandoned/',
    uom_conflict : '/api/versions/1/uom_codesets/conflict_nodes/'
  },

  node_info: {
    all_info: '/api/tree/view/node/properties/'
  },

  taxonomy: {
    export : '/api/export/',
  },

  attribute: {
    list: '/api/attributes/',
    selectiveList: '/api/categories/',
    validValue: '/api/value_codesets/search/',
    uom : '/api/uom_codesets/search/',
    details: '/api/categories/',
    uomDetails : '/api/uom_codesets/',
    validValueDetails : '/api/value_codesets/',
    search: '/api/attributes/search/',
    datas: '/api/attributes/list/'
  },


  data: {
    attributes: '/api/attributes/list/',
    categories: '/api/categories/list/',
    datas: '/api/categories/list/'
  },

  validate : {
    category_name: '/api/taxonomies/categories/validate_name/',
    category_code: '/api/taxonomies/categories/validate_name/',
  },

  node: {
    edit: '/api/drafts/',
    add: '/api/drafts/',
    hierarchy: '/api/taxonomies/categories/'
  },
  draft: {
    list: '/api/drafts/',
    submit: '/api/drafts/',
    publish: '/api/drafts/publish/',
    comment: '/api/tickets/',
    freeze: '/api/drafts/freeze/',
    unfreeze: '/api/drafts/unfreeze/',
    status: '/api/drafts/publish/status/',
    clear: '/api/drafts/clear/'
  }, 

  file: {
    uomUpload: '/api/codesets/upload/',
    exportUoM: '/api/uom_codesets/export/',
    exportValidValues: '/api/value_codesets/export/',
    codesetUpload: '/api/codesets/upload/'
  },


  node_properties: {
    properties: 'api/tree/view/node/edit/',
    attachment_upload: 'api/tree/view/node/edit/upload/',
    draft: '/api/tree/view/node/edit/draft/',
    review: '/api/tree/view/node/edit/review/',
    reject: '/api/tree/view/node/edit/review/reject/',
    accept: '/api/tree/view/node/edit/review/accept/',
    submitStatus: '/api/tree/view/node/edit/review/status/'
  },
};

export { ENDPOINTS };


