<!-- login, forgotpassword page carousel  -->
<div class="content-right">
  <!-- carousel starts  -->
  <mat-carousel
    timings="20ms ease-in"
    [autoplay]="true"
    interval="4000"
    proportion="30"
    slides="7"
    [loop]="true"
    [hideArrows]="true"
    [hideIndicators]="false"
    [useKeyboard]="true"
    [useMouseWheel]="true"
    orientation="ltr"

  >
    <mat-carousel-slide
      #matCarouselSlide
      *ngFor="let slide of slides"
      overlayColor="#f9f9f9"
      [hideOverlay]="false"
    >
      <img src="{{ slide.imgSrc }}" alt="logo" />
      <p class="header">
        {{ slide.heading }}
      </p>
      <p class="info">{{ slide.content }}</p>
    </mat-carousel-slide>
  </mat-carousel>

  <br />
  <!-- contact -->
  <!-- <p>Schedule a demo? &nbsp; <a href=""> Contact US</a></p> -->
</div>
