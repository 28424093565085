import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCorrection'
})
export class TextCorrectionPipe implements PipeTransform {

  transform(value): unknown {
    let result;
    result = value.replace('AD', '');
    return result;
  }
  
  capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
