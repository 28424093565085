import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';

@Component({
  selector: 'app-tree-dialog',
  templateUrl: './tree-dialog.component.html',
  styleUrls: ['./tree-dialog.component.scss']
})
export class TreeDialogComponent implements OnInit {

  constructor(
    private multiCategoryService: MultiCategoryService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TreeDialogComponent>,
    private errorHandler: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.iconRegistry.addSvgIcon(
      'filter',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icon.svg'
      )
    );
  }
  levelList: any = [];
  parentData = this.data.parentData;
  childData = this.data.childData;
  minLevel = this.parentData.attributes.level;
  maxLevel = 0;
  selectedNode = null;
  parentCount = 0;
  childCountList = [];
  selectedNodeData;
  nodeData: any = null;
  loading = true;
  isLeaf = false;
  selectedCategory: any = null;
  categoryList = [];
  searchKey = '';
  hierarchies = [];
  attributeLength = 0;
  attributeList:any =  null;

  ngOnInit(): void {
 // console.log(this.data);
 // console.log(this.childData);
    this.parentCount = this.childData.length;
    this.maxLevel = Math.max.apply(Math, this.childData.map(function(o) { return o.level; }));
    this.rawLevel();
    this.getCategoryInfo(this.parentData);
  }

  rawLevel = () => {
    const diff = this.maxLevel - this.minLevel;
 // console.log(this.minLevel);
 // console.log(this.maxLevel);
    for (let i = this.minLevel + 1; i <= this.maxLevel; i++){
      this.levelList.push({level: i, values: []});
    }
    this.generateLevel(1);
 // console.log(this.levelList);
  }

  generateLevel = (index) => {
    // this.levelList.forEach(level => {
    //   const childs = [];
    //   this.childData.forEach(child => {
    //     if (child.level === level.level){
    //       const index = this.childData.findIndex(e => e.id === child.parent);
    //       if (index >= 0){
    //      // console.log(this.childData[index].name);
    //         child.parentName = this.childData[index].name;
    //       } else {
    //         child.parentName = '';
    //       }
    //       childs.push(child);
    //     }
    //   });
    //   level.values = childs;
    // });
  }

  close = () => {
    this.dialogRef.close({data: null});
  }
  
  selectNode = (item: any, index) => {
    this.selectedNode = item;
    this.getCategoryInfo(this.selectedNode);
    if (this.selectedNode.type === undefined){
      this.getChilds2(this.selectedNode.level, index);
    } else {
      this.getChilds1(this.selectedNode.attributes.level, index);
    }
  }

  getCategoryInfo = (node: any) => {
 // console.log(node);
    this.selectedNode = node;
    this.selectedNodeData = null;
    let id = 0;
    if(this.selectedNode.parentName === undefined){
      id = node.attributes.category_id;
    } else {
      id = node.id;
    }
    this.hierarchies = [];
    this.multiCategoryService.getCategoryDetail(id).subscribe({
      next: res => {
        if (res) {
       // console.log(res.data);
          if(res.data.attributes !== undefined){
            this.hierarchies = res.data.attributes.hierarchies.data;
            this.selectedNodeData = res.data;
            this.attributeLength = this.selectedNodeData.attributes.attributes.data.length;
            this.attributeList = this.selectedNodeData.attributes.attributes.data;
          } else {
            this.selectedNodeData = res.data[0].attributes.category.data;
            this.attributeLength = res.data.length;
            this.attributeList = res.data;
            // console.log(this.attributeList);
          }
       // console.log(this.selectedNodeData);
          this.loading = false;
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  calculateChilds = (id) => {
    // console.log(id);
    let count = 0;
    this.childData.forEach(e => {
      // console.log(e);
      if (e.parent === id){
        count ++;
      }
    });
    return count;
  }


  getChilds1 = (level, index) => {
    // console.log(index);
    const selectedLevel = level + 1;
    const childs = [];
    this.childData.forEach(child => {
      // console.log(this.selectedNode);
      // console.log(child);
      if (child.level === selectedLevel){
        const index = this.childData.findIndex(e => e.id === child.parent);
        if (index >= 0){
       // console.log(this.childData[index].name);
          child.parentName = this.childData[index].name;
        } else {
          child.parentName = '';
        }
        childs.push(child);
      } else {
      }
    });
    // console.log(this.levelList);
    this.levelList[index + 1].values = childs;
  }

  getChilds2 = (level, index) => {
    // console.log(index);
    const selectedLevel = level + 1;
    const childs = [];
    this.childData.forEach(child => {
      // console.log(this.selectedNode);
      // console.log(child);
      if (child.level === selectedLevel && child.parent === this.selectedNode.id){
        const index = this.childData.findIndex(e => e.id === child.parent);
        if (index >= 0){
          // console.log(this.childData[index].name);
          child.parentName = this.childData[index].name;
        } else {
          child.parentName = '';
        }
        childs.push(child);
      } else {
      }
    });
    // console.log(this.levelList);
    // console.log(this.levelList.length);
    // console.log(index);
    if ((index + 1) < this.levelList.length){
      this.levelList[index + 1].values = childs;
    }
  }

  
  getNodesInfo = () => {
    this.levelList.forEach(level => {
      const childs = [];
      this.childData.forEach(child => {
        if (child.level === level.level){
          const index = this.childData.findIndex(e => e.id === child.parent);
          if (index >= 0){
         // console.log(this.childData[index].name);
            child.parentName = this.childData[index].name;
          } else {
            child.parentName = '';
          }
          childs.push(child);
        }
      });
      level.values = childs;
    });
  }

}
