import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { HomeService } from '../../services/home.service';
import { Chart, TimeScale } from 'chart.js';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss']
})
export class AutomationComponent implements OnInit {
  @Output()
  //tabe change
  selectedTabChange: EventEmitter<MatTabChangeEvent>
  //progress circle
  mode: ProgressSpinnerMode = 'determinate';
  //usage data
  start_date;
  end_date;
  //data
  data;
  //percentage
  automationPercentage;
  //graph xy
  date;
  usage;
  //spinner
  pageload: boolean;
  errorOccurs: boolean;
  //line chart data
  chart: any = [];
  lineChartData = [];
  timeFormat: TimeScale = {
    unit: 'day'
  };
  // automation chart tab's data
  automationChartIntervals = [
    { span: 7, title: 'Weekly', id: 'canvas0' },
    { span: 30, title: 'Monthly', id: 'canvas1' },
    { span: 365, title: 'Yearly', id: 'canvas2' }
  ]
  lastUpdated: Date;
  constructor(
    private homeService: HomeService,
    private matSnackbar: MatSnackBar
  ) { }

  /**
   * 
   * @param str 
   * @returns date
   */
  public convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  }

  ngOnInit() {
    this.pageload = true;
    this.calculateDate(this.automationChartIntervals[0]);
  }

  calculateDate(interval) {
    const d = new Date();
    this.start_date = this.convert(d.setDate(d.getDate() - interval.span));
    this.end_date = this.convert(new Date());
    this.getData(
      this.start_date,
      this.end_date,
      interval
    )
  }

  /**
   * Usage stats API
   * @param start_date 
   * @param end_date 
   * @param interval 
   */
  getData = (start_date: string, end_date: string, interval: any) => {

  }

  // generate line chart based on data received
  generateLineChart = (data, id) => {
    // x axis ticks for yearly data
    if (data.length > 31) {
      this.timeFormat = { unit: 'month' };
    } else {
      this.timeFormat = { unit: 'day' };
    }
    // map data relevat to axis
    this.date = data.map(t => t.date);
    this.usage = data.map(t => t.usage);
    this.chart = new Chart(id, {
      type: 'line',
      data: {
        labels: this.date,
        datasets: [
          {
            data: this.usage,
            borderColor: '#2c83ed',
            fill: false
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 20
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time',
            time: this.timeFormat
          }],
          yAxes: [{
            display: true
          }]
        }
      }
    })
    this.chart.canvas.parentNode.style.height = '350px';
  }

  /**
   * @param changeEvent 
   */
  myTabSelectedTabChange(changeEvent: MatTabChangeEvent) {
    this.pageload = true;
    this.calculateDate(this.automationChartIntervals[changeEvent.index]);
  }
}
