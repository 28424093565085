
<!-- side bar main wrapper -->
<div class="side-nav-bar" [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }">

  <!-- overlay -->
  <div class="side-nav-bar-overlay"
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }" (click)="onSidebarClose()"></div>

  <!-- side bar-->
  <div class="side-nav-bar-menu-container" [ngStyle]="getSideNavBarStyle((showSideNav | async))">

    <!-- close button -->
    <div><span class="material-icons side-nav-bar-close" (click)="onSidebarClose()">clear</span></div>
    <div fxLayout="column" class="aside-section">
      <h1 class="__head">Upload New Batch</h1>

    </div>
    <!-- side bar content -->
    <div class="side-nav-bar-content-container">
      <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
    </div>
  </div>
  
</div>