import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-info-content',
  templateUrl: './login-info-content.component.html',
  styleUrls: ['./login-info-content.component.scss'],
})
export class LoginInfoContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  slides = [
    {
      imgSrc: 'assets/images/taxonomy.svg',
      heading: 'Taxonomy Audit & Optimization',
      content:
        'Define taxonomy, attribute names, and List of Attribute Values; align with consumer intent regularly.',
    },
    {
      imgSrc: 'assets/images/itemSetup.svg',
      heading: 'Item Setup & Onboarding',
      content:
        'Classify products and extract attributes in your taxonomy based on pdfs, technical specs, images, titles, and descriptions provided by suppliers and/or Content Service Providers.',
    },
    {
      imgSrc: 'assets/images/customerExperience.svg',
      heading: 'Customer Experience Optimization',
      content:
        'Benchmark against competitor websites; keyword analytics from internal search and external sources to improve search, recommendations and navigation.',
    },
    {
      imgSrc: 'assets/images/ItemValidation.svg',
      heading: 'Item Governance & Validation',
      content:
        'Monitor completeness and accuracy of attributes, product names and digital assets; align attributes with consumer search.',
    },
    {
      imgSrc: 'assets/images/productMatching.svg',
      heading: 'Product Matching & Recommendations',
      content:
        'Match products based on attributes to normalize data across different versions; leverage models to provide attribute-based product recommendations.',
    },
    {
      imgSrc: 'assets/images/titleDescription.svg',
      heading: 'Title & Description Creation',
      content:
        'Automatically create product title & descriptions to optimize the customer experience; make information consistent across SKUs and ensure that key product attributes are always represented in titles and descriptions.',
    },
    {
      imgSrc: 'assets/images/AssetLibraryCreation.svg',
      heading: 'Asset Library Creation',
      content:
        'Source digital content - data sheets, manuals, technical specifications, warranty policy and others from your partners directly, to make your asset library as complete as possible.',
    },
  ];
}
