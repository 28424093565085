<mat-dialog-content>
    <div class="dialog" fxLayout="column">
        <div>
            <div style="text-align: center;"><h3>Edit Properties</h3></div>
            <app-loader *ngIf="tabLoader"></app-loader>
            <mat-tab-group mat-align-tabs="start"  [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)">
                <mat-tab label="Edit Category Properties">
                    <div fxLayout="column" fxFlex="60" style="padding: 40px 10px;">
                        <form class="example-form" [formGroup]="formGroupName">
                            <div fxLayout="column">
                                <mat-label>Category Name</mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput formControlName="name" >
                                </mat-form-field>
                            </div>
                            <div fxLayout="column">
                                <mat-label>Category Code</mat-label>
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input matInput formControlName="code" >
                                </mat-form-field>
                            </div>
                        </form>
                        <div fxLayout="column">    
                            <mat-label>Description</mat-label>
                            <mat-form-field appearance="outline">
                                <textarea matInput placeholder="" [(ngModel)]="data.category.attributes.description" rows="4"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" style="width: 100%;height: 50px;">
                            <section class="example-section" fxFlex="30">
                                <mat-checkbox class="example-margin" [(ngModel)]="data.category.attributes.is_leaf" >Is Leaf Node</mat-checkbox>
                            </section>
                        </div>
                    </div>
                </mat-tab> 
                <mat-tab label="Add New Attribute" *ngIf="data.category.attributes.is_leaf">
                    <app-add-attribute-multi></app-add-attribute-multi>
                </mat-tab> 
                <mat-tab label="Select Attribute" *ngIf="data.category.attributes.is_leaf">
                    <div fxLayout="row" >
                        <div fxFlex="40">
                            <div style="padding: 10px;">
                                <div fxLayout="column" fxLayoutAlign="center start" >
                                    <form class="example-form" [formGroup]="formGroupAttribute" style="width: 100%;">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-icon matPrefix>search</mat-icon>
                                          <input type="text"
                                                id="searchKey"
                                                placeholder="Search Attribute"
                                                matInput
                                                formControlName="attribute"
                                                [matAutocomplete]="attributeValuesAutoComplete">        
                                          <mat-autocomplete #attributeValuesAutoComplete="matAutocomplete" style="width: 90%;">
                                            <!-- <mat-option style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of attributeList" [value]="values.attributes.name">
                                              {{values.attributes.name}}
                                            </mat-option> -->
                                          </mat-autocomplete>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div style="height:40vh; overflow:auto" (scroll)="onScroll($event)">
                                    <ul *ngIf="!tabLoader" style="margin-top: 0px;height: auto;">
                                        <li *ngFor="let item of attributeList" style="padding: 10px;border: 1px solid lightgrey;">
                                            <div fxLayout="row" style="width: 100%;">
                                                <div fxFlex="100">
                                                    <section class="example-section">
                                                        <mat-checkbox class="example-margin" (change)="selectAttribute(item, $event)">
                                                            {{item.attributes.name}} 
                                                        </mat-checkbox>
                                                    </section>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="60" style="margin:0px" fxLayout="column">
                            <div style="padding: 0px 20px;" class="example-container" *ngIf="selectedAttributes.length > 0">
                                <div style="width: 90%;padding: 10px 0px; text-align: right;">Filtering Attribute</div>
                                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of selectedAttributes;let i = index" cdkDrag>
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="5">{{i+1}}</div>
                                            <div fxFlex="70">{{item.attributes.name}}</div>
                                            <div fxFlex="15">
                                                <section class="example-section">
                                                    <mat-checkbox class="example-margin" [checked]="item.is_filter" (click)="selectFilter(item,i)">
                                                    </mat-checkbox>
                                                </section>
                                            </div>
                                            <div fxFlex="10">
                                                <button mat-icon-button class="removeButton" (click)="removeAttribute(item,i)"><mat-icon>delete</mat-icon></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Add Attribute Properties" *ngIf="data.category.attributes.is_leaf">
                    <div fxLayout="row" style="margin:20px 0px">
                        <div fxFlex="100" style="margin:20px 20px">
                            <div *ngIf="selectedAttributes.length == 0">
                                <span>No Attribute Selected.</span>
                            </div>
                            <div class="listContainer" *ngIf="selectedAttributes.length > 0">
                                <div fxLayout="column" *ngFor="let item of postAttributeObject;let i = index" class="listItem">
                                    <div fxLayout="row" style="width: 100%;">
                                        <div fxFlex="2">{{i+1}}.</div>
                                        <div fxFlex="98" style="font-weight: 600;">{{item.name}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div fxFlex="row">
                                            <!-- <section class="example-section" fxFlex="30">
                                                <mat-checkbox class="example-margin" [(ngModel)]="item.is_filter" >Is Filter</mat-checkbox>
                                            </section> -->
                                            <section class="example-section" fxFlex="30">
                                                <mat-checkbox class="example-margin" (change)="singleSelection($event,i)" [(ngModel)]="!item.is_multi" >Is Single</mat-checkbox>
                                            </section>
                                            <section class="example-section" fxFlex="30">
                                                <mat-checkbox class="example-margin" (change)="activeSelection($event,i)" [(ngModel)]="item.is_active" >Is Active</mat-checkbox>
                                            </section>
                                            <section class="example-section" fxFlex="30">
                                                <mat-checkbox class="example-margin" (change)="diffSelection($event,i)" [(ngModel)]="item.is_differentiator" >Is Differentiator</mat-checkbox>
                                            </section>
                                            <section class="example-section" fxFlex="30">
                                                <mat-checkbox class="example-margin" (change)="requiredSelection($event,i)" [(ngModel)]="item.is_required" >Is Required</mat-checkbox>
                                            </section>
                                        </div>
                                        <div fxFlex="row" style="margin-top:10px">
                                            <div fxFlex="33">
                                                <div fxLayout="column" fxLayoutAlign="center start">
                                                    <mat-label>Minimum Value</mat-label>
                                                    <mat-form-field appearance="outline">
                                                        <input matInput [(ngModel)]="item.min_value" (change)="setMinValue(i,item.min_value)">
                                                    </mat-form-field>    
                                                </div>
                                            </div>
                                            <div fxFlex="33">
                                                <form class="example-form" [formGroup]="formGroupUoM" style="width: 100%;">
                                                    <div fxLayout="column" fxLayoutAlign="center start">
                                                        <mat-label>UoM Codeset</mat-label>
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                            <mat-icon matPrefix>search</mat-icon>
                                                            <input type="text"
                                                                    id="searchKey"
                                                                    placeholder="Search UoM Codeset"
                                                                    matInput
                                                                    formControlName="uom"
                                                                    [value]="item.uom"
                                                                    [matAutocomplete]="uomAutoComplete">        
                                                            <mat-autocomplete #uomAutoComplete="matAutocomplete" style="width: 90%;">
                                                                <mat-option (click)="selectUoMValue(option,i)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of uomValueList" [value]="option.attributes.name">
                                                                {{option.attributes.name}}
                                                                </mat-option>
                                                                <mat-option value="None" (click)="selectUoMValue(null,i)">None</mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                        <input readonly type="text" matInput [value]="item.uomValue" [matAutocomplete]="uomAutoComplete">
                                                    </div>                                            
                                                </form>
                                            </div>
                                            <div fxFlex="33">
                                                <form class="example-form" [formGroup]="formGroupValidValues" style="width: 100%;">
                                                    <div fxLayout="column" fxLayoutAlign="center start" >
                                                        <mat-label>Valid Values</mat-label>
                                                        <mat-form-field class="example-full-width" appearance="outline">
                                                        <mat-icon matPrefix>search</mat-icon>
                                                        <input type="text"
                                                                id="searchKey"
                                                                placeholder="Search Valid Values"
                                                                matInput
                                                                formControlName="validValue"
                                                                [value]="item.validValue"
                                                                [matAutocomplete]="validValuesAutoComplete">        
                                                        <mat-autocomplete #validValuesAutoComplete="matAutocomplete" style="width: 90%;">
                                                            <mat-option (click)="selectValidValue(values,i)" style="line-height: 30px;height: 40px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of validValueList" [value]="values.attributes.name">
                                                            {{values.attributes.name}}
                                                            </mat-option>
                                                            <mat-option value="None" (click)="selectValidValue(null,i)">None</mat-option>
                                                        </mat-autocomplete>
                                                        </mat-form-field>
                                                        <input readonly type="text" matInput [value]="item.validValue" [matAutocomplete]="uomAutoComplete">
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Filtering Attribute Order" *ngIf="data.category.attributes.is_leaf">
                    <div fxLayout="row" style="margin:20px 0px">
                        <div fxFlex="50" style="margin:20px 0px">
                            <div *ngIf="filteringAttributeList.length == 0">
                                <span>No filtering Attribute Selected.</span>
                            </div>
                            <div style="padding: 0px 20px;" class="example-container" *ngIf="filteringAttributeList.length > 0">
                                <!-- <div style="width: 90%;padding: 10px 0px; text-align: right;">Filtering Attribute</div> -->
                                <div cdkDropList class="example-list" (cdkDropListDropped)="dropFilter($event)">
                                    <div class="example-box" *ngFor="let item of filteringAttributeList;let i = index" cdkDrag>
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="4">{{i+1}}</div>
                                            <div fxFlex="100">{{item.attributes.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button style="position:absolute;bottom:10px;right:0px" class='buttonSuccess' mat-raised-button (click)="submit(data)" >Submit</button>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="!data.category.attributes.is_leaf" class='buttonSuccess' mat-raised-button (click)="submitDetails(data)" >Submit</button>                        
    <button mat-raised-button cdkFocusInitial *ngIf="selectedTabIndex<4 && data.category.attributes.is_leaf" (click)="next()" >Next</button>
    <button mat-raised-button mat-dialog-close (click)="close()" >Cancel</button>
</mat-dialog-actions>