import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HomeService } from 'src/app/services/home.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { NodeService } from 'src/app/services/node.service';
import { DialogNodeAttributesMulti } from '../../category/add-category-multi/add-category-multi.component';

@Component({
  selector: 'app-attribute-order',
  templateUrl: './attribute-order.component.html',
  styleUrls: ['./attribute-order.component.scss']
})
export class AttributeOrderComponent implements OnInit {

  attribute: any = null;
  productType: any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  page = 0;
  pageSize = 10;
  totalItems = 0;
  editedNode;
  attachment;
  selectedAttributes: any = [];
  formGroupAttribute: FormGroup;
  searchedAttribute;
  attributeList: any = [];
  filteringAttributeList: any = [];
  activeTabIndex = 0;
  postAttributeObject: any = [];
  postAttributeFilterObject: any = [];
  attributeCounter = {
    page : 1,
    pageSize : 50
  };
  filterCounter: any = 1;
  isLeaf = false;
  isSingle = true;
  isActive = false;
  isRequired = null;
  minValue = '';
  isDifferentiator = false;
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  searchKeyUoM = '';
  searchKeyValidValues = '';
  selectedValidValue: any = null;
  selecteduom: any = null;
  uomValueList = [];
  validValueList = [];
  selectedTabIndex = 0;
  rawSelectedAttributeList = [];
  cleanSelectedAttributeList = [];
  isRequiredList = [
    {
      value : null,
      view: 'NULL'
    },
    {
      value : false,
      view: 'False'
    },
    {
      value : true,
      view: 'True'
    }
  ]
  constructor(
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private nodeService: NodeService,
    public dialogRef: MatDialogRef<AttributeOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private multiCategoryService: MultiCategoryService,
    private homeService: HomeService,
    private snackBar: MatSnackBar,
  ) {
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
  }

  ngOnInit(): void {
    this.initFormValidValue();
    this.initFormUoM();
    this.rawSelectedAttributeList = this.data.attributes;
    this.selectedAttributes = [];
    this.attributeList = [];
    this.filteringAttributeList = [];
    this.postAttributeObject = [];
    this.postAttributeFilterObject = [];
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
 // console.log(this.data.attributes);
    this.getAttributes();
    this.initForm();
    if (this.rawSelectedAttributeList.length > 0){
      this.mapAttributes();
    }
  }

  mapAttributes = () => {
    this.rawSelectedAttributeList.forEach(e => {
   // console.log(e);
      this.mapSelectedAttribute(e);
    });
  }

  getAttributes = () => {
    const searchKey = '';
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributeList = res.results.data;
        this.tabLoader = false;
      }
    });
  }

  mapSelectedAttribute = (item) => {
    if (this.selectedAttributes.some(e => e.id === item.id)){
   // console.log('Already Selected');
      let i = this.selectedAttributes.indexOf(item);
      if (i > -1) {
        this.selectedAttributes.splice(i, 1);
        this.postAttributeObject.splice(i, 1);
      }
    } else{
      let validValue = null;
      let uom = null;
      this.multiCategoryService.getAttributeUOM(item.id).subscribe({
        next: res => {
       // console.log(res);
          if (res) {
            if (res.data.length === 1){
              uom = res.data[0];
            } else {
              uom = '123';
            }
          }
        }
      });
      this.multiCategoryService.getAttributeValidValue(item.id).subscribe({
        next: res => {
       // console.log(res);
          if (res) {
            if (res.data.length === 1){
              validValue = res.data[0];
            } else {
              validValue = '123';
            }
          }
        }
      });
   // console.log(item);
      this.selectedAttributes.push(item);
   // console.log(this.selectedAttributes.length);
      const index = this.selectedAttributes.length;
      const params = {
        name: item.attributes.attribute.data.attributes.name,
        attribute_id: item.attributes.attribute_id,
        attribute_sequence: null,
        filter_sequence: null,
        is_multi:  false,
        is_active: false,
        is_required: null,
        is_filter: false,
        min_value: '',
        is_differentiator: false,
        value_codeset_id: null,
        uom_codeset_id: null,
      };
   // console.log(params);
      this.postAttributeObject.push(params);
    }
  }

  removeAttribute = (index: number) => {
    
  }


  initForm = () => {
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      // console.log(resp);
      // console.log(this.searchedAttribute);
      if (this.formGroupAttribute.get('attribute').value != ''){
        this.searchedAttribute = resp;
        this.searchAttribute(resp);
      } else {
        this.searchedAttribute = resp;
        this.searchAttribute('');
      }
    });
  }

  next = () => {
    this.selectedTabIndex++;
  }

  onTabChanged = (e: any) => {
 // console.log(e);
    this.selectedTabIndex = e.index;
  }

  searchAttribute = (searchKey) => {
    this.multiCategoryService.getAttributes(this.attributeCounter.page, this.attributeCounter.pageSize, searchKey).subscribe(res => {
      if (res) {
        this.attributeList = res.results.data;
        this.loading = false;
      }
    });
  }

  checkForValidValues = (id) => {
    this.multiCategoryService.getAttributeValidValue(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('Valid Values');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  checkForUOM = (id) => {
    this.multiCategoryService.getAttributeUOM(id).subscribe({
      next: res => {
        if (res) {
          if (res.data.length === 1){
         // console.log('UOM');
         // console.log(res.data[0]);
            return res.data[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    });
  }

  selectAttribute = (item) => {
 // console.log(item);
    if (this.selectedAttributes.some(e => e.id === item.id)){
   // console.log('Already Selected');
      let i = this.selectedAttributes.indexOf(item);
      if (i > -1) {
        this.selectedAttributes.splice(i, 1);
        this.postAttributeObject.splice(i, 1);
      }
    } else{
      let validValue = null;
      let uom = null;
      this.multiCategoryService.getAttributeUOM(item.id).subscribe({
        next: res => {
       // console.log(res);
          if (res) {
            if (res.data.length === 1){
              uom = res.data[0];
            } else {
              uom = '123';
            }
          }
        }
      });
      this.multiCategoryService.getAttributeValidValue(item.id).subscribe({
        next: res => {
       // console.log(res);
          if (res) {
            if (res.data.length === 1){
              validValue = res.data[0];
            } else {
              validValue = '123';
            }
          }
        }
      });
   // console.log(item);
      this.selectedAttributes.push(item);
   // console.log(this.selectedAttributes.length);
      const index = this.selectedAttributes.length;
      const params = {
        name: item.attributes.attribute.data.attributes.name,
        attribute_id: item.attributes.attribute_id,
        attribute_sequence: null,
        filter_sequence: null,
        is_multi:  false,
        is_active: false,
        is_required: null,
        is_filter: false,
        min_value: '',
        is_differentiator: false,
        value_codeset_id: null,
        uom_codeset_id: null,
      };
   // console.log(params);
      this.postAttributeObject.push(params);
    }
  }

  selectFilter = (item, i) => {
    if (this.postAttributeObject[i].is_filter === false){
      this.postAttributeObject[i].is_filter = true;
      this.postAttributeObject[i].filter_sequence = this.filterCounter;
      this.postAttributeFilterObject.push(this.postAttributeObject[i]);
      this.filterCounter++;
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
      } else {
   // console.log(this.postAttributeFilterObject);
      this.postAttributeObject[i].is_filter = false;
      const i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
      if (i1 > -1) {
        this.postAttributeFilterObject.splice(i, 1);
      }
      this.filterCounter--;
    }
    if (this.filteringAttributeList.some(e => e === item)){
      const i = this.filteringAttributeList.indexOf(item);
      if (i > -1) {
        this.filteringAttributeList.splice(i, 1);
      }
    } else {
      this.filteringAttributeList.push(item);
    }
 // console.log(this.postAttributeObject);
  }

  drop = (event: CdkDragDrop<string[]>) => {
    // console.log(this.selectedAttributes);
    moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
    this.mergeAttribute(this.postAttributeObject);
  }
  mergeAttribute = (obj) => {
    this.postAttributeObject.forEach((e, i) => {
      e.attribute_sequence = i + 1;
    });
 // console.log(this.postAttributeObject);
  }
  dropFilter = (event: CdkDragDrop<string[]>) => {
    moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
    this.mergeFilterAttribute(this.postAttributeFilterObject);    
  }

  mergeFilterAttribute = (obj) => {
    this.postAttributeFilterObject.forEach((e, i) => {
      e.filter_sequence = i + 1;
    });
 // console.log(this.postAttributeFilterObject);
  }

  mergeFIlterSequence = () => {
    for (let i = 0; i <= this.postAttributeObject.length - 1; i++){
      this.postAttributeFilterObject.forEach(e => {
        if (e === this.postAttributeObject[i]){
          this.postAttributeObject[i].filter_sequence = e.filter_sequence;
        }
      });
    }
  }
  submit = () => {
    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
    this.nodeService.selectedAttributeList = this.selectedAttributes;
    this.nodeService.selectedFilterAttributeList = this.filteringAttributeList;
 // console.log(this.postAttributeObject);
    if (this.postAttributeObject.length > 0){
      this.snackBar.open('Attributes and properties updated to the category', 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
      this.dialogRef.close({data: this.postAttributeObject});
    } else {
      this.snackBar.open('No attribute Selected', 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    }
  }
  close = () => {
    this.dialogRef.close({data: null});
  }

  initFormUoM = () => {
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {
       this.searchKeyUoM = '';
       this.searchUoM('');
      }
    });
  }

  searchUoM = (searchKey: any) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }
  selectUoMValue = (e: any, i: any) => {
    this.selecteduom = e;
    this.postAttributeObject[i].uom_codeset_id = e.id;
    if (this.postAttributeObject[i].is_filter === true) {
     this.postAttributeFilterObject[i].uom_codeset_id = e.id;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
    this.uomValueList = [];
  }

  initFormValidValue = () => {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {
       this.searchKeyValidValues = '';
       this.searchValidValue('');
      }
    });
  }

  searchValidValue = (searchKey: any) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.validValueList = res.results.data;
      }
    });
  }
  selectValidValue = (e: any, i: any) => {
 // console.log(e);
    this.selectedValidValue = e;
    this.postAttributeObject[i].value_codeset_id = e.id;
    if (this.postAttributeObject[i].is_filter === true) {
     this.postAttributeFilterObject[i].value_codeset_id = e.id;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
    this.validValueList = [];
  }

  singleSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_multi = true;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].is_multi = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_multi = false;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].is_multi = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  activeSelection = (e, i) => {
    if (e.checked === true){
   // console.log(e);
      this.postAttributeObject[i].is_active = true;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].is_active = true;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    } else {
   // console.log(e);
      this.postAttributeObject[i].is_active = false;
      if (this.postAttributeObject[i].is_filter === true) {
       this.postAttributeFilterObject[i].is_active = false;
      }
   // console.log(this.postAttributeObject);
   // console.log(this.postAttributeFilterObject);
    }
  }

  requiredSelection = (e, i) => {
    if (e.checked === true){
      this.postAttributeObject[i].is_required = true;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = true;
      }
    }else {
      this.postAttributeObject[i].is_required = false;
      if (this.postAttributeObject[i].is_filter === true) {
        this.postAttributeFilterObject[i].is_required = false;
      }
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }

diffSelection = (e, i) => {
  if (e.checked === true){
 // console.log(e);
    this.postAttributeObject[i].is_differentiator = true;
    if (this.postAttributeObject[i].is_filter === true) {
     this.postAttributeFilterObject[i].is_differentiator = true;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  } else {
 // console.log(e);
    this.postAttributeObject[i].is_differentiator = false;
    if (this.postAttributeObject[i].is_filter === true) {
     this.postAttributeFilterObject[i].is_differentiator = false;
    }
 // console.log(this.postAttributeObject);
 // console.log(this.postAttributeFilterObject);
  }
}

setMinValue = (i, value) => {
  this.postAttributeObject[i].min_value = value;
  // if (this.postAttributeObject[i].is_filter === true) {
  //   this.postAttributeFilterObject[i].min_value = value;
  // }
  console.log(this.postAttributeObject);
  console.log(this.postAttributeFilterObject);
}


}
