import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Me } from '../../../_models/me';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../../../_dialogs/contact-dialog/contact-dialog.component';
import { LegendsComponent } from 'src/app/_dialogs/legends/legends.component';
import { HomeService } from 'src/app/services/home.service';
import { VersionService } from 'src/app/services/version.service';
import { Subscription } from 'rxjs';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { __await } from 'tslib';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { TicketResetComponent } from '../../_shared/ticket-reset/ticket-reset.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  userData;
  userDisplayName;

  userRole;
  userDisplayPicture: any = 'assets/images/user.jpg';
  document;
  usage;
  versionList: any = [];
  hierarchyList: any = [];
  selectedVersion;
  versionFromService;
  currentVersion: any = null;
  currentHierarchy: any = null;
  frozen = false;
  previous = false;
  version: any = null;
  page = 1;
  pageSize = 50;
  totalPages = 0;
  totalItems = 0;
  isAdmin = false;
  subscription: Subscription;
  subscriptionHeirarchy: Subscription;
  constructor(
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public homeService: HomeService,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private errorHandler: ErrorHandlerService
  ) {}
  ngOnDestroy = () => {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  // LOGOUT SHORTCUT KEY
  @HostListener('window:keydown.control.q', ['$event'])
  logout = (event: KeyboardEvent) => {
    event.preventDefault();
    this.logOut();
  }

  ngOnInit(): void {
    this.currentVersion = JSON.parse(localStorage.getItem('version'));
    this.currentHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
 // console.log(this.currentVersion);
 // console.log(this.currentHierarchy);
    this.getVersionList();
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userData.first_name && this.userData.last_name){
      this.userDisplayName = this.userData.first_name + ' ' + this.userData.last_name;
    } else {
      this.userDisplayName = this.userData.username;
    }
    this.userRole = this.userData.groups;
    this.getFrozenStatus();
    this.userRole.forEach(element => {
      if(element.name=='administrator'){
        this.isAdmin = true;
      }
    });

  }

  getVersionList = async () => {
    console.log(this.currentVersion);
    if (this.currentVersion === null || this.currentVersion === ''){
      this.versionService.getVersionList().subscribe(async res => {
        if (res) {
          res.results.data.forEach(e => {
            if (e.attributes.is_available === true){
              this.versionList.push(e);
            }
          });
          this.versionList.forEach(e => {
            if (e.attributes.is_current === true){
              this.currentVersion = e;
            }
          });
          this.selectedVersion = await this.currentVersion;
          this.versionService.setVersion(this.selectedVersion);
          this.frozen = this.selectedVersion.attributes.is_frozen;
        }
      });
    } else {
      this.versionService.setVersion(this.currentVersion);
      this.getHierarchyList();
    }
  }

  getHierarchyList = async() => {
    if(this.currentHierarchy === null){
      this.hierarchyService.getHierarchyList(this.page, this.pageSize).subscribe({
        next: async res => {
          this.hierarchyList = res.results.data;
          this.currentHierarchy = this.hierarchyList[0];
          await this.hierarchyService.updateHierarchy(this.currentHierarchy);
       console.log(this.currentHierarchy);
        },
        // error: (HttpResponse: HttpErrorResponse) => {
        //   this.snackBar.open(`${HttpResponse.error. detail}`, 'OK', {
        //     duration: 4000,
        //     panelClass: ['error-snackbar']
        //   });
        // }
      });
    } else {
      this.hierarchyService.updateHierarchy(this.currentHierarchy);
    }
  }

  getFrozenStatus = () => {
    this.versionService.getUpdatedVersion().subscribe(version => {
      this.currentVersion = version;
   // console.log(this.currentVersion);
      if (this.currentVersion){
        this.frozen = this.currentVersion.attributes.is_frozen;
        this.previous = !version.attributes.is_current;
      }
   // console.log(this.frozen);
    });
  }



  // logout topnav
  logOut = () => {
    this.userService.logOut().subscribe({
      next: (resp) => {
        this.homeService.selectedNodeId = 0;
        this.router.navigate(['/']);
        localStorage.clear();
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
        this.router.navigate(['/']);
        localStorage.clear();
      },
    });
  }

  resetTicket = () => {
    console.log('Reset');
    const dialogRef = this.dialog.open(TicketResetComponent, {
      data: 'app',
      width: '300px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === true){
        console.log('Cleared');
      } else {
        console.log('Not Yet');
      }
    });
  }

  openDialog = () => {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      data: 'app',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openLegendDialog = () => {
    const legendDialogRef = this.dialog.open(LegendsComponent, {});
    legendDialogRef.afterClosed().subscribe((result) => {});
  }
}
