<div fxLayout="column" class="attributeDialog">
    <mat-dialog-content>
        <div class="dialog" fxLayout="column">
            <div *ngIf="requestType!='attribute_sequence_edit'">
                <div style="text-align: center;"><h3>Selected Attributes</h3></div>    
                <div fxLayout="row">
                    <div fxFlex="50" style="margin:0px" fxLayout="column">
                        <div><h3>Selected Attribute List</h3></div>
                        <div style="padding: 0px 20px;" class="example-container">
                            <ul style="margin: 0px;padding: 0px;">
                                <li *ngFor="let item of newAttributeList;let i = index" style="padding: 10px;border: 1px solid lightgrey;max-height: 20px;">
                                    <div fxLayout="row" style="width: 100%;">
                                        <div fxFlex="90" class="tab">
                                            <div fxFlex="10">{{i+1}}</div>
                                            <div fxFlex="90">{{item.name}}</div>
                                        </div>
                                        <div fxFlex="10">
                                            <div class="tab" fxLayout="row" *ngIf="item.is_filter==true">
                                                <mat-icon *ngIf="item.is_filter==true" svgIcon="filter"></mat-icon>
                                                <span style="margin: 4px;">({{item.filter_sequence}})</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>            
            </div>
            <div *ngIf="requestType=='attribute_sequence_edit'">
                <div style="text-align: center;"><h3>Selected Attributes</h3></div>    
                <div fxLayout="row">
                    <div fxFlex="50" style="margin:0px" fxLayout="column">
                        <div><h3>Previous Selected Attribute List</h3></div>
                        <div style="padding: 0px 10px;" class="example-container">
                            <ul style="margin: 0px;padding: 0px;" class="oldList">
                                <li *ngFor="let item of oldAttributeList;let i = index" style="padding: 10px;border: 1px solid lightgrey;" [ngClass]="{'deleted': item.different.includes('deleted')}">
                                    <div fxLayout="column" style="width: 100%;">
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="85" class="tab">
                                                <div fxFlex="10">{{i+1}}</div>
                                                <div fxFlex="90"><b>{{item.name}}</b>
                                                    <button *ngIf="item.different.includes('deleted')" class="delButton">Deleted</button>
                                                </div>
                                            </div>
                                            <div fxFlex="15" fxLayoutAlign="end center">
                                                <div class="tab" fxLayout="row" *ngIf="item.is_filter==true">
                                                    <mat-icon class="filter" *ngIf="item.is_filter==true" svgIcon="filter"></mat-icon>
                                                    <span style="margin: 0px 5px">({{item.filter_sequence}})</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Is Active  <span class="value" *ngIf="item.is_active!==null"> {{ (item.is_active).toString() | titlecase}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Is Required  <span class="value" *ngIf="item.is_required!==null"> {{ (item.is_required).toString() | titlecase}}</span>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Is Single  <span class="value" *ngIf="!item.is_multi!==null"> {{ (!item.is_multi).toString() | titlecase}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Is Differentiator  <span class="value" *ngIf="item.is_differentiator!==null"> {{ (item.is_differentiator).toString() | titlecase}}</span>
                                            </div>    
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Minumum Value  <span class="value">{{item.min_value}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row">
                                                Is closed  <span class="value" *ngIf="item.is_closed">{{ (item.is_closed).toString() | titlecase}}</span>
                                                <span class="value" *ngIf="!item.is_closed || item.is_closed==null">False</span>
                                        </div> 
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="100" class="tab" fxLayout="row">
                                                UOM  <span class="value">{{item.uomName}}</span>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="100" class="tab" fxLayout="row">
                                                Valid Value  <span class="value">{{item.validValueName}}</span>
                                            </div>    
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div fxFlex="50" style="margin:0px" fxLayout="column">
                        <div><h3>New Selected Attribute List</h3></div>
                        <div style="padding: 0px 10px;" class="example-container">
                            <ul style="margin: 0px;padding: 0px;" class="newList">
                                <li *ngFor="let item of newAttributeList;let i = index" style="padding: 10px;border: 1px solid lightgrey;" [ngClass]="{'new': item.different.includes('new')}">
                                    <div fxLayout="column" style="width: 100%;">
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="85" fxLayout="row" class="tab">
                                                <div fxFlex="10">{{i+1}}</div>
                                                <div fxFlex="90"><b>{{item.name}}</b>
                                                    <button *ngIf="item.different.includes('new')" class="newButton">New</button>
                                                </div>
                                            </div>
                                            <div fxFlex="15" fxLayoutAlign="end center">
                                                <div fxLayout="row" class="tab" *ngIf="item.is_filter==true" [ngClass]="{'different': item.different.includes('reordered')}">
                                                    <mat-icon class="filter" *ngIf="item.is_filter==true" svgIcon="filter"></mat-icon>
                                                    <span style="margin: 0px 5px;">({{item.filter_sequence}})</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('is_active')}">
                                                Is Active  <span class="value" *ngIf="item.is_active!==null"> {{ (item.is_active).toString() | titlecase}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('is_required')}">
                                                Is Required  <span class="value" *ngIf="item.is_required!==null"> {{ (item.is_required).toString() | titlecase}}</span>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('is_multi')}">
                                                Is Single  <span class="value" *ngIf="!item.is_multi!==null"> {{ (!item.is_multi).toString() | titlecase}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('is_differentiator')}">
                                                Is Differentiator  <span class="value" *ngIf="item.is_differentiator!==null"> {{ (item.is_differentiator).toString() | titlecase}}</span>
                                            </div>    
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('min_value')}">
                                                Minumum Value  <span class="value">{{item.min_value}}</span>
                                            </div>
                                            <div fxFlex="50" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('is_closed')}">
                                                Is closed  <span class="value" *ngIf="item.is_closed">{{ (item.is_closed).toString() | titlecase}}</span>
                                                <span class="value" *ngIf="!item.is_closed || item.is_closed==null">False</span>
                                            </div> 
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="100" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('uom_codeset_id')}">
                                                UOM  <span class="value">{{item.uomName}}</span>
                                            </div>
                                        </div>
                                        <div fxLayout="row">
                                            <div fxFlex="100" class="tab" fxLayout="row" [ngClass]="{'different': item.different.includes('value_codeset_id')}">
                                                Valid Value  <span class="value">{{item.validValueName}}</span>
                                            </div>    
                                        </div>
                                        
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>            
            </div>
        </div>    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close (click)="close()" >Close</button>
    </mat-dialog-actions>
</div>