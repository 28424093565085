import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent implements OnInit {
  @Output() refresh = new EventEmitter<string>();

  page = 1;
  pageSize = 50;
  totalPages = 0;
  totalItems = 0;
  localLoading = true;
  selectedHierarchy: any = null;
  hierarchyList = [];
  subscription: Subscription;
  subscriptionHeirarchy: Subscription;
  currentVersion: any = null;
  i = 0;
  constructor(
    private hierarchyService: HierarchyService,
    private snackBar: MatSnackBar,
    private versionService: VersionService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.currentVersion = this.versionService.checkSetVersion();
    this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
    if (this.currentVersion){
      this.getHierarchy();
    } else {
      this.versionService.getUpdatedVersion().subscribe({
        next: async version => {
          this.currentVersion = version;
       // console.log('Version Changed');
       // console.log(this.selectedHierarchy);
          if (this.currentVersion){
            await this.getHierarchy();
          }
        }
      });
    }
    this.hierarchyService.getUpdatedHierarchy().subscribe(hierarchy => {
   // console.log(this.selectedHierarchy);
      this.selectedHierarchy = hierarchy;
   // console.log('Hierarchy Changed');
      this.getHierarchy();
    });


  }


  getHierarchy = () => {
    this.hierarchyService.getHierarchyList(this.page, this.pageSize).subscribe({
      next: async res => {
        // await console.log(res);
        this.page = res.page;
        this.pageSize = res.page_size;
        this.totalPages = res.total_pages;
        this.totalItems = res.total_count;
        // this.hierarchyList =  res.results.data;
        res.results.data.forEach(e => {
          if(e.attributes.name.includes('Master') && this.hierarchyList.length == 0){
            this.hierarchyList.push(e);
          }
        });
        let index = 0;
        if (this.hierarchyList.length > 0){
          this.hierarchyList.forEach((e,i) => {
            if(e.attributes.name.includes('Master')){
              index = i;
            }
          });
          let t = this.hierarchyList[0];
          this.hierarchyList[0] = this.hierarchyList[index];
          this.hierarchyList[index] = t;
          if (this.selectedHierarchy != null) {
            const selectedIndex = this.hierarchyList.findIndex(x => x.id === this.selectedHierarchy.id);
            if (selectedIndex < 0){
              this.selectedHierarchy = this.hierarchyList[0];
              this.hierarchyService.updateHierarchy(this.selectedHierarchy);
            } else {
              this.selectedHierarchy = this.hierarchyList[selectedIndex];
              // this.hierarchyService.updateHierarchy(this.selectedHierarchy);
            }
          } else {
         // console.log('Setting');
            this.selectedHierarchy = this.hierarchyList[0];
            this.hierarchyService.updateHierarchy(this.selectedHierarchy);
          }
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  changeHierarchy = (e: any) => {
 // console.log(e);
    this.selectedHierarchy = e.value;
    this.refresh.next('refresh');
    this.hierarchyService.updateHierarchy(this.selectedHierarchy);
  }

  scrollSearch = (event) => {
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
      if (this.i < this.totalPages){
        this.i = this.i + 1;
     // console.log('Can Load' + this.i);
        this.localLoading = true;
        this.patchSearch(this.i);
      }else{
        this.i = this.totalPages;
     // console.log('Cant Load' + this.i);
      }
    } else if (event.target.scrollTop === 0) {
   // console.log('Start');
    }
  }

  patchSearch = (i) => {
 // console.log(i);
    this.hierarchyService.getHierarchyList(i, this.totalItems).subscribe({
      next: res => {
        if (res) {
       // console.log(res);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.localLoading = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }
}
