import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material';
import { LoginComponent } from './components/login/login.component';
import { DialogNodeDelete, HomeComponent } from './components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginInfoContentComponent } from './components/login-info-content/login-info-content.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DialogComponent } from './_dialogs/dialog/dialog.component';
import { TopNavComponent } from './components/header/top-nav/top-nav.component';
import { SidebarComponent } from './components/header/sidebar/sidebar.component';
import { TableComponent } from './components/table/table.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AutomationComponent } from './components/automation/automation.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule } from 'ng2-charts';
import { DialogNodeInfo, DialogReviewView, ReviewComponent, SnackBarAccept, SnackBarRejectReview } from './components/review/review.component';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { LeftSidePanelComponent } from './components/left-side-panel/left-side-panel.component';

import { Daterangepicker } from 'ng2-daterangepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpConfigInterceptor } from './_interceptors/http-config.interceptor';
import { LoadingspinnerComponent } from './components/loadingspinner/loadingspinner.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { AppPermissionsService } from './services/app-permissions.service';
import { UnderscoreAsSpacePipe } from './_pipe/underscore-as-space.pipe';
import { TruncatePipe } from './_pipe/truncate.pipe';
import { SafeContentPipe } from './_pipe/safe-content.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImgSliderComponent } from './_dialogs/img-slider/img-slider.component';
import { ApproveConformationComponent } from './_dialogs/approve-conformation/approve-conformation.component';
import { ContactDialogComponent } from './_dialogs/contact-dialog/contact-dialog.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { ReferenceUrlDialogComponent } from './_dialogs/reference-url-dialog/reference-url-dialog.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LabelDialogComponent } from './_dialogs/label-dialog/label-dialog.component';
import { HotkeysDirective } from './_directive/hotkeys.directive';
import { LegendsComponent } from './_dialogs/legends/legends.component';
import { DownloadComponent } from './components/download/download.component';
import { CommmaSeperatorPipe } from './_pipe/commma-seperator.pipe';
import { HomeTableComponent } from './_shared/loader/home-table/home-table.component';
import { RightListComponent } from './_shared/loader/right-list/right-list.component';
import { LoaderComponent } from './_shared/loader/loader/loader.component';
import { DialogEdit, InfoComponent, DialogNodeAttributesOrder } from './components/info/info.component';
import { AttributeInfoComponent, DialogEditAttribute } from './components/attribute-info/attribute-info.component';
import { HighlightPipe } from './highlight.pipe';
import { RecolorPipe } from './recolor.pipe';
import { EditRelationComponent } from './components/edit-relation/edit-relation.component';
import { DialogDraftView, DialogNodeInfoDraft, DraftComponent, SnackBarRejectDraft } from './components/draft/draft.component';
import { AttributeSuggestionComponent } from './_shared/attribute-suggestion/attribute-suggestion.component';
import { DraftListComponent } from './components/draft-list/draft-list.component';
import { AddAttributeComponent } from './components/add-attribute/add-attribute.component';
import { AddCategoryComponent, DialogNodeAttributes } from './components/add-category/add-category.component';
import { ApproveComponent, DialogApproveView, DialogNodeInfoAdmin, SnackBarRejectApprove } from './components/approve/approve.component';
import { FileOptionsComponent } from './components/file-options/file-options.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DraftDetailsComponent } from './components/draft/draft-details/draft-details.component';
import { HloaderComponent } from './_shared/loader/hloader/hloader.component';
import { CommentsComponent } from './components/_draftTemplates/comments/comments.component';
import { RequestsComponent } from './components/_draftTemplates/requests/requests.component';
import { NodeInfoComponent } from './components/_draftTemplates/node-info/node-info.component';
import { DialogUploadUOM, MappedValuesComponent } from './components/mapped-values/mapped-values.component';
import { UploadComponent } from './components/upload/upload.component';
import { StringClearnerPipe } from './string-clearner.pipe';
import { AddCategoryMultiComponent, DialogNodeAttributesMulti } from './components/category/add-category-multi/add-category-multi.component';
import { SearchCategoryComponent } from './components/category/search-category/search-category.component';
import { AddRelationMultiComponent } from './components/category/add-relation-multi/add-relation-multi.component';
import { HierarchyComponent } from './components/_shared/hierarchy/hierarchy.component';
import { VersionComponent } from './components/_shared/version/version.component';
import { AddAttributeMultiComponent } from './components/category/add-attribute-multi/add-attribute-multi.component';
import { TreeDialogComponent } from './components/_shared/tree-dialog/tree-dialog.component';
import { AttributeOrderComponent } from './components/_shared/attribute-order/attribute-order.component';
import { UomConflictComponent } from './components/uom-conflict/uom-conflict.component';
import { AbandondNodesComponent } from './components/_shared/abandond-nodes/abandond-nodes.component';
import { TextCorrectionPipe } from './components/text-correction.pipe';
import { UomSetPipe } from './components/uom-set.pipe';
import { ValueSetPipe } from './components/value-set.pipe';
import { HierarchyInfoComponent } from './components/_shared/hierarchy-info/hierarchy-info.component';
import { EditFreeCategoryComponent } from './components/_shared/edit-free-category/edit-free-category.component';
import { MultiToSinglePipe } from './multi-to-single.pipe';
import { HierarchyNameComponent } from './components/_draftTemplates/hierarchy-name/hierarchy-name.component';
import { AttributeListComponent } from './components/_shared/attribute-list/attribute-list.component';
import { RelationMiscDialogComponent } from './components/_shared/relation-misc-dialog/relation-misc-dialog.component';
import { DeleteCategoryPipe } from './delete-category.pipe';
import { ConflictSnackBar, MultipleErrors } from './services/error-handler.service';
import { DialogDraftViewSingle, DialogNodeInfoDraftView, DraftViewComponent } from './components/_shared/draft-view/draft-view.component';
import { TicketResetComponent } from './components/_shared/ticket-reset/ticket-reset.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LoginInfoContentComponent,
    ForgotPasswordComponent,
    DialogComponent,
    TopNavComponent,
    SidebarComponent,
    TableComponent,
    AutomationComponent,
    ReviewComponent,
    LeftSidePanelComponent,
    LoadingspinnerComponent,
    ErrorMessageComponent,
    UnderscoreAsSpacePipe,
    TruncatePipe,
    SafeContentPipe,
    ImgSliderComponent,
    ApproveConformationComponent,
    ContactDialogComponent,
    ReferenceUrlDialogComponent,
    ResetPasswordComponent,
    LabelDialogComponent,
    HotkeysDirective,
    LegendsComponent,
    DownloadComponent,
    CommmaSeperatorPipe,
    HomeTableComponent,
    RightListComponent,
    LoaderComponent,
    InfoComponent,
    AttributeInfoComponent,
    HighlightPipe,
    RecolorPipe,
    EditRelationComponent,
    DraftComponent,
    DialogEdit,
    DialogNodeInfo,
    DialogNodeInfoDraft,
    SnackBarRejectDraft,
    SnackBarAccept,
    SnackBarRejectReview,
    SnackBarRejectApprove,
    AttributeSuggestionComponent,
    DraftListComponent,
    AddAttributeComponent,
    AddCategoryComponent,
    DialogEditAttribute,
    ApproveComponent,
    DialogNodeInfoAdmin,
    DialogNodeAttributes,
    FileOptionsComponent,
    DraftDetailsComponent,
    DialogNodeDelete,
    HloaderComponent,
    CommentsComponent,
    RequestsComponent,
    NodeInfoComponent,
    MappedValuesComponent,
    UploadComponent,
    DialogNodeAttributesOrder,
    DialogDraftView,
    StringClearnerPipe,
    DialogReviewView,
    DialogApproveView,
    DialogUploadUOM,
    AddCategoryMultiComponent,
    SearchCategoryComponent,
    AddRelationMultiComponent,
    HierarchyComponent,
    VersionComponent,
    DialogNodeAttributesMulti,
    AddAttributeMultiComponent,
    TreeDialogComponent,
    AttributeOrderComponent,
    AbandondNodesComponent,
    UomConflictComponent,
    TextCorrectionPipe,
    UomSetPipe,
    ValueSetPipe,
    HierarchyInfoComponent,
    EditFreeCategoryComponent,
    MultiToSinglePipe,
    HierarchyNameComponent,
    AttributeListComponent,
    RelationMiscDialogComponent,
    DeleteCategoryPipe,
    ConflictSnackBar,
    DraftViewComponent,
    DialogDraftViewSingle,
    DialogNodeInfoDraftView,
    MultipleErrors,
    TicketResetComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    ChartsModule,
    Daterangepicker,
    InfiniteScrollModule,
    PinchZoomModule,
    MatCarouselModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'x-csrf',
    }),
    DragDropModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    AppPermissionsService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  entryComponents: [
    DialogEdit,
    DialogNodeInfo,
    DialogNodeInfoDraft,
    DialogEditAttribute,
    DialogNodeInfoAdmin,
    DialogNodeAttributes,
    DialogNodeDelete,
    DialogNodeAttributesOrder,
    DialogDraftView,
    DialogReviewView,
    DialogApproveView,
    DialogUploadUOM,
    DialogNodeAttributesMulti,
    TreeDialogComponent,
    AbandondNodesComponent,
    AttributeOrderComponent,
    HierarchyInfoComponent,
    EditFreeCategoryComponent,
    ConflictSnackBar,
    DialogNodeInfoDraftView,
    DialogDraftViewSingle,
    MultipleErrors,
    TicketResetComponent,
    SnackBarRejectApprove,
    SnackBarRejectDraft,
    SnackBarRejectReview
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
