import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';

@Component({
  selector: 'app-add-attribute',
  templateUrl: './add-attribute.component.html',
  styleUrls: ['./add-attribute.component.scss']
})
export class AddAttributeComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) validValuesAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) uomAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) attributeAutoComplete: MatAutocompleteTrigger;

  constructor(
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private infoService: InfoService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private errorHandler: ErrorHandlerService
  ) {

    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      )
    );
  }
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  formGroupAttribute: FormGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = true;
  formSubmitted = false;
  draftId = 0;
  leafNode = false;
  selectedNodeId: any;
  selectedNodeData: any;
  isSingle = true;
  isFilter = false;
  isClosed = false;
  validValues;
  levelForm: any;
  searchKeyValidValues;
  searchKeyUoM;
  searchKeyAttribute;
  selectedLevel: any = 0;
  levelList = [1, 2, 3, 4, 5, 6];
  uomValueList: any = [];
  validValueList: any = [];
  attributeList: any = [];
  selectedCodeSet;
  selectedAttribute;
  selectedAttributeObj;
  ticketId = '';
  existingAttibure = false;

  ngOnInit(): void {
 // console.log(this.infoService.nodeId);
    this.selectedNodeId = this.infoService.nodeId;
    // this.selectedNodeId = 'dd63ae6a-270d-460b-a6b1-ada856064279';
    if (this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    } else {
      this.snackBar.open('No node ID is found.', '', {
        duration: 2000,
      });
      this.router.navigate(['/home']);
    }
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });

    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.initFormUoM();
    this.initFormValidValue();
    this.initAttributeForm();
  }

  selectExistingAttribute = () => {
    if (!this.existingAttibure){
      this.existingAttibure = true;
      this.selectedAttribute = '';
    } else {
      this.existingAttibure = false;
      this.selectedAttribute = '';
    }
  }

  initAttributeForm = () => {
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyAttribute);
      if (this.formGroupAttribute.get('attribute').value !== ''){
        this.searchKeyAttribute = resp;
        this.searchAttribute(resp);
      } else {

      }
    });
  }

  searchAttribute = (searchKey) => {
    this.nodeService.searchAttribute(searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributeList = res.results.data;
        // this.loading = false;
      }
    });
  }

  selectAttributeValue = (e: any) => {
 // console.log(e);
    this.selectedAttribute = e.attributes.name;
    this.selectedAttributeObj = e;
    this.nodeService.getAttributeDetails(e.id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.selectedAttributeObj = res.data;
        this.loading = false;
      }
    });
  }

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.loading = false;
      }
    });
  }

  getSearchParam = (ob) => {
 // console.log(ob);
  }

  initFormUoM() {
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {

      }
    });
  }

  searchUoM = (searchKey) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }
  selecteduom: any = null;
  selectUoMValue = (e: any) => {
    this.selecteduom = e;
 // console.log(e);
  }

  initFormValidValue() {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {

      }
    });
  }

  searchValidValue = (searchKey) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.validValueList = res.results.data;
      }
    });
  }
  selectedValidValue: any = null;
  selectValidValue = (e: any) => {
 // console.log(e);
    this.selectedValidValue = e;
    this.validValueList = [];
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save = () => {
    this.loading = true;
 // console.log(this.leafNode);
    // if(this.leafNode){
    //   this.router.navigate(['/attribute-suggestion']);
    // }
    let data = {};
    if (this.selectedAttribute){
      if (!this.selectedValidValue && !this.selecteduom){
        this.selectedValidValue = {id : ''}; // In case no VV selected
        this.selecteduom = {id : ''}; // In case no UOM selected
      }
      if (this.existingAttibure){
     // console.log(this.selectedAttributeObj);
        data = {
          edit_type: 'attribute_addition',
          category_id: this.selectedNodeId,
          attribute_id: this.selectedAttributeObj.id,
          attribute_name: '',
          is_multi: this.isSingle,
          is_filter: this.isFilter,
          value_codeset_id: this.selectedValidValue.id,
          uom_codeset_id: this.selecteduom.id,
          note: this.notes,
          internal_ticket_id: this.ticketId,
          // description:this.description
        };
      } else {
        data = {
          edit_type: 'attribute_addition',
          attribute_id: '',
          category_id: this.selectedNodeId,
          attribute_name: this.selectedAttribute,
          is_multi: !this.isSingle,
          is_filter: this.isFilter,
          value_codeset_id: this.selectedValidValue.id,
          uom_codeset_id: this.selecteduom.id,
          note: this.notes,
          internal_ticket_id: this.ticketId,
          // description: this.description
        };
      }
   // console.log(data);
      this.nodeService.addCategory(this.selectedId, data).subscribe({
        next: (resp: any) => {
       // console.log(resp);
          this.loading = false;
          if (this.draftId > 0 && this.files.length > 0){
            setTimeout(() => {
              const form = new FormData();
              this.files.forEach(e => {
                form.append('attachments', e);
              });
           // console.log(form);
              this.nodeService.uploadFile(this.draftId, form).subscribe({
                next: resp => {
                  this.loading = false;
               // console.log('resp');
                  this.snackBar.open(`Attribute addition Request Submitted Successfully.`, 'OK', {
                    duration: 4000,
                    panelClass: ['error-snackbar']
                  });
                },
                error: (HttpResponse: HttpErrorResponse) => {
                  this.loading = false;
                  this.errorHandler.pass(HttpResponse.error);
                }
              });
            }, 2000);
          } else {
            this.loading = false;
            this.snackBar.open(`Attribute addition request submitted successfully.`, 'OK', {
              duration: 4000,
              panelClass: ['success-snackbar']
            });
          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    } else {
      this.snackBar.open(`Please select all options properly.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
      this.loading = false;
    }
 // console.log(this.files);
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }


}
