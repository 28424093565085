import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiToSingle'
})
export class MultiToSinglePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
