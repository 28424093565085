import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLogin } from '../_models/user';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Me } from '../_models/me';
import { Globals } from '../_globals/endpoints.global';
import { map, catchError, tap } from 'rxjs/operators';
import { Reset } from '../_models/reset';
import { AppPermissionsService } from '../services/app-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private httpOptions: HttpHeaders;
  public userData: UserLogin;
  activeUser: any;
  permissionObject;
  /* behaviour Subject to maintain session */
  public userSubject = new BehaviorSubject([]);
  currentUser: any;
  currentUserSubject = new BehaviorSubject<Me>(null);

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private permissionService: AppPermissionsService
  ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // ME endpoint call
  me = (): Observable<Me> => {
    const meEndPoint = this.globals.urlJoin('users', 'me');
    return this.http
      .get(meEndPoint, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response: Me) => {
          this.activeUser = response;
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };

  // LOGIN endpoint call
  login = (userData: UserLogin): Observable<any> => {
    const loginEndpoint = this.globals.urlJoin('users', 'login');
    return this.http
      .post(loginEndpoint, userData, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };

  // LOGOUT endpoint call
  logOut = () => {
    const logOutEndpoint = this.globals.urlJoin('users', 'logout');
    return this.http.post<any>(logOutEndpoint, {
      headers: this.httpOptions,
    }).pipe(
        tap(resp => {
            return resp;
        }),
        catchError(error => throwError(error))
    );
    // const test = null;
    // const logOutEndpoint = this.globals.urlJoin('users', 'logout');
    // return this.http.post(logOutEndpoint,test).pipe(
    //   map((response) => {
    //     return response;
    //   }),
    //   catchError((error) => throwError(error))
    // // );
  };

  emailToVerify = (emailVerify): Observable<any> => {
    const emailToVerifyEndpoint = this.globals.urlJoin(
      'users',
      'email_recovery'
    );
    return this.http.post(emailToVerifyEndpoint, emailVerify).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  };

  /**
   * To reset password
   */

  postResetPassword = (resetData: Reset): Observable<Reset> => {
    const resetEndpoint = this.globals.urlJoin('users', 'reset');

    return this.http
      .post(resetEndpoint, resetData, {
        headers: this.httpOptions,
      })
      .pipe(
        map((response: Reset) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };
  /**
   * Validation email
   */

  getEmailValidation = (uidb64, token) => {
    const passwordValidationEndpoint = this.globals.urlJoin(
      'users',
      'email_rest_validation'
    );
    return this.http
      .get(passwordValidationEndpoint + '/' + uidb64 + '/' + token)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };

  patchResetPassword = (resetData: Reset, uidb64, token) => {
    const resetPasswordEndpoint = this.globals.urlJoin(
      'users',
      'email_rest_validation'
    );
    return this.http
      .patch(
        resetPasswordEndpoint + '/' + uidb64 + '/' + token,
        resetData,
        {
          headers: this.httpOptions,
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };
}
