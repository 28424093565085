<div fxLayout class="head">
  <h2 mat-dialog-title>Add Label</h2>
</div>

<!-- Labels -->
<div class="label" fxLayout>
  <mat-chip-list [multiple]="true" selectable>
    <mat-chip 
      class="chip"
      *ngFor="let item of labelList"
      [ngStyle]="{ 'background-color': item.colour }"
      fxLayoutAlign="center center"
      (click)="toggleChip(item.name,item.id)"
      [selected]="chip.has(item.name)"
     
    >
    <!-- selected chip icon -->
    <mat-icon  class="iccon"
    >done</mat-icon>
    <span > {{ item.name }}</span>
    </mat-chip>
  </mat-chip-list>

</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button primary [mat-dialog-close]="true" cdkFocusInitial (click)="onSubmit(chipControl.value)">Confirm</button> -->
</mat-dialog-actions>
