import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deleteCategory'
})
export class DeleteCategoryPipe implements PipeTransform {

  transform(value: any, name: string): any {
    let result = '';
    const stringArray = value.split('>');
    stringArray.forEach(e => {
      e = e.trim();
      name = name.trim();
      if(e==name){
      } else {
        result = result.concat(e + ' > ');
      }
    });
 // console.log(result);
    return (result);
  }

}
