<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
      <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
        <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div fxFlex="21" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Information Page</span>
      </div>
      <div fxFlex="10" fxLayoutAlign="end center">
      </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="50">
                <mat-card class="card">
                    <mat-card-title style="text-align: center;">
                        Upload <button mat-icon-button><mat-icon>file_upload</mat-icon></button>
                    </mat-card-title>
                    <mat-card-content fxlayoutAlign="center center">
                        <div>
                            <ngx-dropzone class="dropZone" (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                    <mat-icon svgIcon="upload"></mat-icon>
                                    <p>Drag or Drop your files here</p>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                    
                                    <ngx-dropzone-label fxLayout="row">
                                        <div fxFlex="50">
                                            <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                            </mat-icon>    
                                        </div>
                                        <div fxFlex="50">
                                            <span style="font-size: 10px;">{{ f.name }}</span>
                                        </div>
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div fxFlex="50">
                <mat-card class="card">
                    <mat-card-title style="text-align: center;">
                        Download <button mat-icon-button><mat-icon>file_download</mat-icon></button>
                    </mat-card-title>
                    <mat-card-content>
                        <div>
                            <div class="download" fxLayout="column" fxLayoutAlign="center center">
                                <button mat-icon-button (click)="download()">
                                    <mat-icon>download</mat-icon>
                                    
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
  

