<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
        <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
          <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start" *ngIf="selectedAttribute">
            <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Attribute Information Page ({{selectedAttribute.attributes.attribute.data.attributes.name}})</span>
            <div>Leaf Node : {{nodeName}}</div>
        </div>
        <div fxFlex="10" fxLayoutAlign="end center">
        </div>
    </div>
    <div class="tabContainer">
        <mat-tab-group
        class="table-tab"
        #tabGroup
        (selectedTabChange)="tabChanged($event)"
        >
            <mat-tab label="Properties">
                <app-loader *ngIf="loading"></app-loader>
                <div class="tab" *ngIf="tabData">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <ul>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Name</b></div>
                                <div class="value">{{tabData.attributes.attribute.data.attributes.name}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('name',tabData.attributes.attribute.data.attributes.name)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                  </div>
                            </li> 
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Description</b></div>
                                <div class="value">{{tabData.attributes.attribute.data.attributes.description}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('description',tabData.attributes.attribute.data.attributes.description)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Closed</b></div>
                                <div class="value">{{(tabData.attributes.is_closed).toString() | titlecase}}</div>
                                
                            </li> 

                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Filter</b></div>
                                <div class="value">{{(tabData.attributes.is_filter).toString() | titlecase}}</div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Single</b></div>
                                <div class="value">{{(!tabData.attributes.is_multi).toString() | titlecase}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('is_multi',tabData.attributes.is_multi)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Is Active</b></div>
                                <div class="value">{{(tabData.attributes.is_active).toString() | titlecase}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('is_active',tabData.attributes.is_active)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Is Differentiator</b></div>
                                <div class="value">{{(tabData.attributes.is_differentiator).toString() | titlecase}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('is_differentiator',tabData.attributes.is_differentiator)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Is Required</b></div>
                                <div class="value">
                                    <span *ngIf="tabData.attributes.is_required">
                                        {{(tabData.attributes.is_required).toString() | titlecase}}
                                    </span>
                                    <span *ngIf="!tabData.attributes.is_required">
                                        N/A
                                    </span>
                                </div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('is_required',tabData.attributes.is_required)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Minimum Value</b></div>
                                <div class="value">{{tabData.attributes.min_value}}</div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('min_value',tabData.attributes.min_value)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Has UOM</b></div>
                                <div class="value">{{(tabData.attributes.has_uom).toString() | titlecase}}</div>
                                <div fxFlex="10">
                                    <!-- <button *ngIf="!frozen" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('has_uom',tabData.attributes.has_uom)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button> -->
                                </div>
                            </li> 
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>UoM Codeset</b></div>
                                <div class="value"><span *ngIf="tabData.attributes.uom_codeset">{{tabData.attributes.uom_codeset.data.attributes.name}}</span></div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit"  mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('uom_codeset',tabData.attributes.uom_codeset)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>UoM Values</b></div>
                                <div class="value">
                                    <span *ngIf="tabData.attributes.uom_codeset">
                                        <div fxLayout="row wrap">
                                            <div *ngFor="let item of tabData.attributes.uom_codeset.data.attributes.units.data;let isLast=last" style="margin: 1px; margin-right: 4px;margin-left: 0px;">
                                                {{item.attributes.name}}{{isLast ? '' : ' , '}}
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div fxFlex="10">
                                    <!-- <button mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('valid_values',tabData.attributes.approved_value_list)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button> -->
                                </div>
                            </li> 
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Approved Value</b></div>
                                <div class="value"><span *ngIf="tabData.attributes.value_codeset">{{tabData.attributes.value_codeset.data.attributes.name}}</span></div>
                                <div fxFlex="10">
                                    <button *ngIf="!frozen  && !disableEdit"  mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('valid_values',tabData.attributes.value_codeset)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                                </div>
                            </li>  
                            <li fxLayout="row">
                                <div fxFlex="25" class="key"><b>Approved Value List</b></div>
                                <div class="value">
                                    <span *ngIf="tabData.attributes.value_codeset">
                                        <div fxLayout="row wrap">
                                            <div *ngFor="let item of tabData.attributes.value_codeset.data.attributes.values.data;let isLast=last" style="margin: 1px; margin-right: 4px;margin-left: 0px;">
                                                {{item.attributes.name}}{{isLast ? '' : ' , '}}
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div fxFlex="10">
                                    <!-- <button mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('valid_values',tabData.attributes.approved_value_list)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button> -->
                                </div>
                            </li>  
                        </ul>     
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>