<section fxLayout="column" fxLayoutAlign="center center">
  <h3 mat-dialog-title fxFlexLayout="center center">Approve batch {{batch_id}}?</h3>

  <!-- <div style="display: inline; text-align: center"> -->
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <button mat-button (click)="approveDialogRef.close(true)" class="approve-button">
      Approve
    </button>
    <button mat-button (click)="approveDialogRef.close(false)" class="cancle-button">
      Cancel
    </button>
  </div>
  <!-- <div fxLayout="row" fxFlex="40" fxLayoutAlign="start center">
      
    </div> -->
  <!-- </div> -->
</section>