import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ENDPOINTS } from '../_globals/endpoints';
import { Globals } from '../_globals/endpoints.global';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private httpOptions: HttpHeaders;
  selectedNodeId = 0;
  nodeSelection = 0;
  selectedVersion = 0;

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  private version$: BehaviorSubject<any> = new BehaviorSubject(null);

  setVersion = (version: any) => {
 // console.log('Updating Version');
    localStorage.setItem('version', JSON.stringify(version));
    this.version$.next(version);
  }

  clearVersion = () => {
    localStorage.removeItem('version');
    this.version$.next('');
  }

  getUpdatedVersion(): Observable<any> {
    return this.version$.asObservable();
  }

  checkSetVersion = () =>{
    const currentVersion = JSON.parse(localStorage.getItem('version'));
    return currentVersion;
  }


  getVersionList = () => {
    const endPoint = this.globals.urlJoin('version', 'versionList');
    return this.http.get<any>(endPoint, {
      headers: this.httpOptions,
    }).pipe(
        tap( resp => {
          return resp;
        }),
        catchError(error => throwError(error))
      );
  }

}
