import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DialogComponent } from '../../_dialogs/dialog/dialog.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public emailVerify: FormGroup;
  formSubmitted: boolean;
  loader: boolean;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  openDialog() {
    this.dialog.open(DialogComponent);
  }

  goToResetPassword = () => {
    this.router.navigate(['/reset-password']);
  };
  ngOnInit() {
    this.emailVerify = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });
  }

  get e() {
    return this.emailVerify.controls;
  }
  emailVerification = () => {
    this.loader = true;
    this.userService.emailToVerify(this.emailVerify.value).subscribe({
      next: (resp) => {
        this.loader = false;
        if (resp.detail) {
          this.dialog.open(DialogComponent);
        }
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
          duration: 8000,
        });
      },
    });
  };
}
