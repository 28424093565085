<h2 mat-dialog-title>Need Help?</h2>
<form name="form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()" >
<!-- <mat-dialog-content class="mat-typography"> -->
<mat-form-field appearance="outline" class="form-field" >
  <textarea class="text-field" matInput formControlName="dialogField"></textarea>
</mat-form-field>
<!-- </mat-dialog-content> -->
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="submit" (click)="onSubmit()" [disabled]='!form.dirty'>
    Submit
  </button>
</mat-dialog-actions>
</form>