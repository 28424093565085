import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoService } from 'src/app/services/info.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VersionService } from 'src/app/services/version.service';
// import { saveAs } from 'file-saver';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  activeTab = 1;
  userData;
  userType;
  userRole;
  review = false;
  draft = true;
  navSub: Subscription;
  finalSubmit = false;
  currentVersion: any = null;
  previous = false;
  subscription: Subscription;
  frozen = false;
  constructor(
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private sharedDataService: SharedDataService,
    private infoService: InfoService,
    private versionService: VersionService
    ) {
      this.iconRegistry.addSvgIcon(
        'home',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Home.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'review',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/review-icon.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'draft',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'approve',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/approve-icon.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'review-active1',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/review-active-icon.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'draft-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'approve-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/approve-active-icon.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'templates',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Templates.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'settings',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Settings.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'help',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Message.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'home-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Home1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'review-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Edit1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'templates-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Templates1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'settings-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Settings1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'help-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/Message1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'info1',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/info1.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'attribute',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/attribute.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'attribute1',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/attribute-active.svg'
        )
      );

      this.iconRegistry.addSvgIcon(
        'category',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/category.svg'
        )
      );
      this.iconRegistry.addSvgIcon(
        'category-active',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/datax/category-active.svg'
        )
      );
      this.versionService.getUpdatedVersion().subscribe({
        next: version => {
          this.currentVersion = version;
          if (this.currentVersion){
            this.frozen = this.currentVersion.attributes.is_frozen;
            this.previous = !this.currentVersion.attributes.is_current;
          }
        }
      });
  }

  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }


  ngOnDestroy(): void{
    this.navSub ? this.navSub.unsubscribe() : "";
  }
  // checkPrevious = () =>{
  //   let version=null;
  //   this.versionService.getVersion().subscribe(version => version = version);
  //   if(version){
  //     this.previous=!version.attributes.is_current;
  //   }
  // }
  ngOnInit(): void {
    this.navSub = this.sharedDataService.changeStatus.subscribe(value => {
       // console.log(value);
      this.activeTab = value;
    })
    if (window.location.pathname === '/draft'){
      this.sharedDataService.changeSideNavStatus(2);
    } else if (window.location.pathname === '/review'){
      this.sharedDataService.changeSideNavStatus(3);
    } else if (window.location.pathname === '/home'){
       // console.log('Changing');
      this.sharedDataService.changeSideNavStatus(1);
    }
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
     // console.log(this.userData);
    this.userType = this.userData.groups;
     // console.log(this.userType);
    this.checkGroup();

  }
  checkGroup = () => {
    this.userType.forEach(e => {
      if (e.name === 'editor'){
        this.draft = true;
      } else if (e.name === 'reviewer'){
        this.review = true;
      } else if (e.name === 'administrator'){
        this.finalSubmit = true;
      }
    });
 // console.log(this.review);
 // console.log(this.finalSubmit);

    // this.userRole.forEach(e => {
    //   if(e=='Editor'){
        // this.draft = true;
    //   }
    //   if(e=='Reviewer'){
        // this.review = true;
    //   }
    // });
  }
  activateTab = (i: number) => {
    this.activeTab = i;
     // console.log(i);

    if (this.activeTab === 1){
      this.sharedDataService.changeSideNavStatus(1);
      // this.router.navigateByUrl('/home');
      window.location.href = 'home';
    } else if (this.activeTab === 2){
   // console.log(this.activeTab);
      this.sharedDataService.changeSideNavStatus(2);
      this.router.navigate(['/draft']);
    } else if (this.activeTab === 3){
   // console.log(this.activeTab);
      this.sharedDataService.changeSideNavStatus(3);
      this.router.navigate(['/review']);
    } else if (this.activeTab === 4){
   // console.log(this.activeTab);
      this.sharedDataService.changeSideNavStatus(3);
      this.router.navigate(['/approve']);
    } else if (this.activeTab === 4){
     // console.log('Here');
      this.export();
    }else if (this.activeTab === 5){
      this.sharedDataService.changeSideNavStatus(5);
      this.router.navigate(['/mapping']);
    } else if (this.activeTab === 6){
      this.sharedDataService.changeSideNavStatus(6);
      this.router.navigate(['/upload']);
    } else if (this.activeTab === 7){
      this.sharedDataService.changeSideNavStatus(6);
      this.router.navigate(['/upload']);
    }
  }

  export = () => {
    this.infoService.export().subscribe(res => {
   // console.log('loader');
      // const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      // const header = Object.keys(data[0]);
      // const csv = data.map((row) =>
      //   header
      //     .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      //     .join(',')
      // );
      // csv.unshift(header.join(','));
      // const csvArray = csv.join('\r\n');

      // const a = document.createElement('a');
      // const blob = new Blob([csvArray], { type: 'text/csv' });
      // const url = window.URL.createObjectURL(blob);

      // a.href = url;
      // a.download = 'myFile.csv';
      // a.click();
      // window.URL.revokeObjectURL(url);
      // a.remove();
    });
  }


  downloadMyFile = () => {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://help.datax.ai/content-governance/secrets/hY-7ie0V-pNUhnIuuiI9lQ');
    // link.setAttribute('download', `Taxonomy_App_User_Manual-11082022.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
}
