import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-attribute-info',
  templateUrl: './attribute-info.component.html',
  styleUrls: ['./attribute-info.component.scss']
})
export class AttributeInfoComponent implements OnInit {

  constructor(
    private infoService: InfoService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private nodeService: NodeService,
    private versionService: VersionService,
    private hierarchyService: HierarchyService,
    private errorHandler: ErrorHandlerService,
  ) {
    this.iconRegistry.addSvgIcon(
      'filters',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/filters.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'edit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/Edit.svg'
      )
    );
   }
  subscription: Subscription;
  tabData: any = null;
  attribute: any = null;
  productType: any = null;
  selectedAttributeId = null;
  properties: any = null;
  loading = true;
  propertyKeys: any = null;
  mappedNodes: any = null;
  selectedAttribute: any = null;
  selectedNodeId;
  nameEdit = false;
  descriptionEdit = false;
  currentVersion = null;
  frozen =  false;
  previous = false;
  userData;
  userDisplayName;
  userGroup = null;
  userType = null;
  currentHierarchy:any = null;
  isLeaf = true;
  disableEdit = false;
  currentHierarchyName = null;
  nodeData:any = null;
  nodeName:any = null;

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.userDisplayName = this.userData.username;
    this.userGroup = this.userData.groups;
 // console.log(this.userGroup);
    this.userGroup.forEach(e => {
      if (e.name === 'administrator'){
        this.userType = 'administrator';
      } else if (e.name === 'reviewer'){
        this.userType = 'reviewer';
      } else if (e.name === 'editor'){
        this.userType = 'editor';
      }
    });
    this.selectedNodeId = this.infoService.nodeId;
    this.nodeInfo(this.selectedNodeId);
    this.selectedAttributeId = this.infoService.attributeId;
    this.currentHierarchy = this.hierarchyService.checkSetHierarchy();
 // console.log(this.currentHierarchy);
    if(this.currentHierarchy){
      this.currentHierarchyName = this.currentHierarchy.attributes.name;
    }
    this.selectedAttribute = this.infoService.attribute;
    if (this.selectedAttributeId){
      this.getInfo(this.selectedAttributeId);
    } else {
      this.router.navigate(['/home']);
    }
    this.currentVersion = this.versionService.checkSetVersion();
    if(this.currentVersion){
      this.frozen = this.currentVersion.attributes.is_frozen;
      this.previous = !(this.currentVersion.attributes.is_current);
    }
  }

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.nodeData = res.data;
        if(this.nodeData){
          this.nodeName = this.nodeData.attributes.category.data.attributes.name;
        }
      }
    });
  }

  getInfo = (nodeId: number) => {
    this.nodeService.attributeDetails(this.selectedNodeId, this.selectedAttributeId).subscribe(res => {
      if (res) {
     // console.log(res);
        this.tabData = res.data;
        this.disableEdit = this.isLeaf && (this.currentHierarchyName.indexOf('Master') === -1);
     // console.log(this.disableEdit);
        this.loading = false;
      }
    });
  }

  tabChanged = (e) => {
    this.loading = true;
 // console.log(e);
    this.getInfo(this.selectedAttributeId);
  }

  editPanel = (column, value) => {
 // console.log(column);
 // console.log(value);
 // console.log(this.selectedAttribute);
    this.openDialog(column, this.selectedNodeId, value, this.selectedAttribute);
  }

  openDialog(editField, id, value, selectedAttribute): void {
    const dialogRef = this.dialog.open(DialogEditAttribute, {
      width: '80vw',
      height : '75vh',
      data: {editField, id, value, selectedAttribute}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      if (result)
      {
        this.snackBar.open('Marked Successfully', '', {
          duration: 2000,
        });
      } else {
      }
    });
  }


}

@Component({
  selector: 'dialog-confirm-final',
  templateUrl: './edit.html',
  styleUrls: ['./attribute-info.component.scss']
})
export class DialogEditAttribute implements OnInit{
  constructor(
  private snackBar: MatSnackBar,
  private nodeService: NodeService,
  private iconRegistry: MatIconRegistry,
  private sanitizer: DomSanitizer,
  public dialogRef: MatDialogRef<DialogEditAttribute>,
  private formBuilder: FormBuilder,
  private homeService: HomeService,
  private errorHandler: ErrorHandlerService,
  private versionService: VersionService,
  private fb: FormBuilder,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'refresh',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/refresh.svg'
      )
    );
  }
  @ViewChild(MatAutocompleteTrigger) validValuesAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) uomAutoComplete: MatAutocompleteTrigger;

  selectedNode;
  selectedId = this.data.id;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  formSubmitted = false;
  draftId = 0;
  editedValue = this.data.value;
  value = this.data.value;
  uploadForm;
  isClosed;
  isSingle;
  ticketId = '';
  isfilter;
  searchKeyUoM;
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  uomValueList = [];
  searchKeyValidValues;
  validValueList = [];
  uomValue: any = null;
  validValue: any = null;
  SelectedvalidValue: any = null;
  oldValue: any = null;
  hasUOM = null;
  isActive = null;
  isDifferentiator = null;
  isRequired = null;
  minValue = null;
  selectedHierarchy = null;
  selectedVersion = this.versionService.checkSetVersion();

  
  ngOnInit(): void {
    this.selectedHierarchy = JSON.parse(localStorage.getItem('hierarchyInfo'));
 // console.log(this.data);
    this.isfilter = this.data.value;
    this.isSingle = !(this.data.value);
    this.isClosed = this.data.value;
    this.hasUOM = this.data.value;
    this.isActive = this.data.value;
    this.isDifferentiator = this.data.value;
    this.isRequired = this.data.value;
    this.minValue = this.data.value;
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });

    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.initFormUoM();
    this.initFormValidValue();
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  selectUoMValue = (value) => {
 // console.log(value);
    if(value !== 'None') {
      this.uomValue = value.id;
    } else {
      this.uomValue = null;
    }
  }


  submitName = (value) => {
    this.saveDraft('name', value, 'string');
  }
  submitDesc = (value) => {
    this.saveDraft('description', value, 'string');
  }
  submitisClosed = (value) => {
    this.saveDraft('is_closed', value, 'string');
  }
  submitMulti = (value) => {
    this.saveDraft('is_multi', !(value), 'boolean');
  }
  submitHasUom = (value) => {
    this.saveDraft('has_uom', (value), 'boolean');
  }
  submitActive = (value) => {
    this.saveDraft('is_active', (value), 'boolean');
  }
  submitDifferentiator = (value) => {
 // console.log(this.isDifferentiator);
    this.saveDraft('is_differentiator', (value), 'boolean');
  }
  submitIsRequired = (value) => {
    if(value==true){
      this.saveDraft('is_required', (value), 'boolean');
    } else {
      this.saveDraft('is_required', ('null'), 'boolean');
    }
  }
  submitMinValue = (value) => {
    this.saveDraft('min_value', (value), 'string');
  }
  submitUom = (value) => {
 // console.log(value);
    this.saveDraft('uom_codeset', value, 'id');
  }
  submitValidValues = (value) => {
    this.saveDraft('value_codeset', value, 'id');
  }
  // oldValue: any = null;
  // hadUOM = false;
  // isActive = false;
  // isDifferentiator = false;
  // isRequired = false;
  // minValue = null;

  initFormUoM() {
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {
        this.uomValueList = [];
      }
    });
  }

  searchUoM = (searchKey) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.uomValueList = res.results.data;
      }
    });
  }

  initFormValidValue() {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {
        this.validValueList = [];
        this.SelectedvalidValue = null;
      }
    });
  }

  toProperCase = (string): String => {
    return string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  }

  searchValidValue = (searchKey) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.validValueList = res.results.data;
      }
    });
  }

  selectValidValue = (e: any) => {
 // console.log(e);
    if(e !== 'None') {
      this.SelectedvalidValue = e.id;
      this.validValueList = [];
      } else {
      this.SelectedvalidValue = null;
    }
  }

  resetValidValue = () => {
    this.validValueList = [];
    this.SelectedvalidValue = '';
  }

  saveDraft = (field, value, dataType) => {
 // console.log(this.data);
    this.uploadForm = this.formBuilder.group({
      attachment: ['']
    });
    if(this.data.value!== undefined && this.data.value !== null){
      if(dataType === 'id') {
        this.oldValue = this.data.value.data.id;
      } else {
        this.oldValue = this.data.value;
      }
    } else{
      this.oldValue = '';
    }
 // console.log(this.data);
 // console.log(this.data.value);
 // console.log(this.oldValue);
 // console.log(value);
    if (this.oldValue === value) {
      this.snackBar.open(`Value is unchanged.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    } else {
      if(dataType === 'string'){

      } else if(dataType === 'boolean'){
        if(value === 'true' || value === true){
          value = true;
        } else {
          value = false;
        }
      } else {

      }
        // this.snackBar.open(`Please fill the values properly.`, 'OK', {
        //   duration: 4000,
        //   panelClass: ['error-snackbar']
        // });
      const data = {
        edit_type: 'attribute_field_edit',
        category_id: this.data.id,
        attribute_id: this.data.selectedAttribute.attributes.attribute_id,
        field_name: field,
        new_value: value,
        original_value: this.oldValue,
        note: this.notes,
        hierarchy_id : this.selectedHierarchy.id,
        version_id: this.selectedVersion.id,
        internal_ticket_id: this.ticketId
      };
   // console.log(data);
      this.nodeService.nodeEdit(data).subscribe({
        next: (resp: any) => {
       // console.log(resp);
          if (resp.data.id > 0){
            this.formSubmitted = false;
            this.draftId = resp.data.id;
         // console.log(this.draftId);
            if (this.draftId > 0 && this.files.length > 0){
              setTimeout(() => {
                const file = {
                  attachments: this.files
                };
                const form = new FormData();
                this.files.forEach(e => {
                  form.append('attachments', e);
                });
             // console.log(form);
                this.nodeService.uploadFile(this.draftId, form).subscribe({
                  next: resp => {
                 // console.log('resp');
                    this.snackBar.open(`Draft ticket for attribute edit created Successfully.`, 'OK', {
                      duration: 4000,
                      panelClass: ['error-snackbar']
                    });
                  },
                  error: (HttpResponse: HttpErrorResponse) => {
                    this.formSubmitted = false;
                    this.snackBar.open(`${HttpResponse.error. detail}`, 'OK', {
                      duration: 4000,
                      panelClass: ['error-snackbar']
                    });
                  }
                });
              }, 2000);
            } else {
              this.snackBar.open(`Draft ticket for attribute edit created Successfully.`, 'OK', {
                duration: 4000,
                panelClass: ['error-snackbar']
              });
            }

          }
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.formSubmitted = false;
       // console.log(HttpResponse.error. detail);
          this.errorHandler.pass(HttpResponse.error);
        }
      });
   // console.log(this.files);
      this.dialogRef.close(null);
    }
  }

  getIcon = (name) => {
    const arr = name.split('.');
    // console.log(arr);
    if (arr[1] === 'csv'){
      return 'csv';
    } else if (arr[1] === 'zip'){
      return 'zip';
    } else if (arr[1] === 'pdf' || arr[1] === 'docx'){
      return 'pdf';
    } else {
      return 'zip';
    }

  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
