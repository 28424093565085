import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { MultiCategoryService } from 'src/app/services/multi-category.service';
import { NodeService } from 'src/app/services/node.service';

@Component({
  selector: 'app-add-attribute-multi',
  templateUrl: './add-attribute-multi.component.html',
  styleUrls: ['./add-attribute-multi.component.scss']
})
export class AddAttributeMultiComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) validValuesAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) uomAutoComplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) attributeAutoComplete: MatAutocompleteTrigger;

  constructor(
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private infoService: InfoService,
    private fb: FormBuilder,
    private homeService: HomeService,
    private multiCategoryService: MultiCategoryService,
    private errorHandler: ErrorHandlerService,
  ) {

    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      )
    );
  }
  formGroupUoM: FormGroup;
  formGroupValidValues: FormGroup;
  formGroupAttribute: FormGroup;
  selectedNode: any;
  selectedId = 0;
  files: File[] = [];
  nodeData: any = null;
  name = '';
  description = '';
  notes = '';
  loading = true;
  formSubmitted = false;
  draftId = 0;
  leafNode = false;
  selectedNodeId: any;
  selectedNodeData: any;
  isSingle = true;
  isFilter = false;
  isClosed = false;
  validValues: any;
  levelForm: any;
  searchKeyValidValues: any;
  searchKeyUoM: any;
  searchKeyAttribute: any;
  selectedLevel: any = 0;
  levelList = [1, 2, 3, 4, 5, 6];
  uomValueList: any = [];
  validValueList: any = [];
  attributeList: any = [];
  selectedCodeSet: any;
  selectedAttribute: string;
  selectedAttributeObj: { id: any; };
  ticketId = '';
  existingAttibure = false;
  selectedValidValue: any = null;
  selecteduom: any = null;

  ngOnInit(): void {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.loading = false;
    this.initFormUoM();
    this.initFormValidValue();
    this.initAttributeForm();
  }

  selectExistingAttribute = () => {
    if (!this.existingAttibure){
      this.existingAttibure = true;
      this.selectedAttribute = '';
    } else {
      this.existingAttibure = false;
      this.selectedAttribute = '';
    }
  }

  initAttributeForm = () => {
    this.formGroupAttribute = this.fb.group({
      attribute : ['']
    });
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyAttribute);
      if (this.formGroupAttribute.get('attribute').value !== ''){
        this.searchKeyAttribute = resp;
        this.searchAttribute(resp);
      } else {

      }
    });
  }

  searchAttribute = (searchKey: any) => {
    this.nodeService.searchAttribute(searchKey).subscribe(res => {
      if (res) {
     // console.log(res);
        this.attributeList = res.results.data;
        // this.loading = false;
      }
    });
  }

  selectAttributeValue = (e: any) => {
 // console.log(e);
    this.selectedAttribute = e.attributes.name;
    this.selectedAttributeObj = e;
    this.nodeService.getAttributeDetails(e.id).subscribe(res => {
      if (res) {
     // console.log(res);
        this.selectedAttributeObj = res.data;
        this.loading = false;
      }
    });
  }

  nodeInfo = (id: any) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.loading = false;
      }
    });
  }

  getSearchParam = (ob: any) => {
 // console.log(ob);
  }

  initFormUoM = () => {
    this.formGroupUoM = this.fb.group({
      uom : ['']
    });
    this.formGroupUoM.get('uom').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
   // console.log(this.searchKeyUoM);
      if (this.formGroupUoM.get('uom').value != ''){
        this.searchKeyUoM = resp;
        this.searchUoM(resp);
      } else {

      }
    });
  }

  searchUoM = (searchKey: any) => {
    this.homeService.getCodeset(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.uomValueList = res.results.data;
        this.loading = false;
      }
    });
  }
  selectUoMValue = (e: any) => {
    this.selecteduom = e;
 // console.log(e);
  }

  initFormValidValue = () => {
    this.formGroupValidValues = this.fb.group({
      validValue : ['']
    });
    this.formGroupValidValues.get('validValue').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
   // console.log(resp);
      if (this.formGroupValidValues.get('validValue').value != ''){
        this.searchKeyValidValues = resp;
     // console.log(this.searchKeyValidValues);
        this.searchValidValue(this.searchKeyValidValues);
      } else {

      }
    });
  }

  searchValidValue = (searchKey: any) => {
    this.homeService.getValidValues(searchKey).subscribe(res => {
      if (res) {
     // console.log(res.results.data);
        this.validValueList = res.results.data;
      }
    });
  }
  selectValidValue = (e: any) => {
 // console.log(e);
    this.selectedValidValue = e;
    this.validValueList = [];
  }

  onSelect = (event: { addedFiles: any; }) => {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove = (event: File) => {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save = () => {
    const data = {
      name: this.name
    };
    if (this.name){
      this.multiCategoryService.addAttribute(data).subscribe({
        next: resp => {
          this.loading = false;
       // console.log('resp');
          this.snackBar.open(`Attribute added Successfully.`, 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandler.pass(HttpResponse.error);
        }
      });
    } else {
      this.snackBar.open(`Please select all options properly.`, 'OK', {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
      this.loading = false;
    }
 // console.log(this.files);
  }

  cancel = () => {

  }


}
