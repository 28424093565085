<div class="content" fxLayout="column">
    <div style="overflow: auto; max-height: 50vh;">
        <!-- <app-hloader *ngIf="commentLoader"></app-hloader> -->
        <div *ngIf="commentList">
            <div class="comment" fxLayout="column" *ngFor="let item of commentList.results">
                <div fxLayout="row" style="width: 100%;">
                    <div fxFlex="12"><img [src]="userDisplayPicture" class="avatar"></div>
                    <div fxFlex="88" fxLayout="column">
                        <span style="line-height: 20px;margin: 0px 5px;">{{item.author.username | titlecase}}</span>
                        <span style="line-height: 10px;margin: 0px 5px; font-size: 10px;">{{item.modified | date:'medium'}}</span>
                    </div>
                </div>
                <div fxLayout="row">
                    {{item.body}}
                </div>
            </div>
        </div>        
    </div>
    <div class="comment" fxLayout="column">
        <div fxLayout="row">
            <mat-form-field class="example-form-field" appearance="outline">
                <mat-label>Add your comment</mat-label>
                <input matInput type="text" [(ngModel)]="comment">
                <button (click)="postComment()" matSuffix mat-icon-button aria-label="Clear" >
                    <mat-icon svgIcon="send"></mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</div>