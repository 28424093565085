<!-- side navigation -->
<div class="sidebar">
  <div class="sidebar__nav" fxLayout="column">
    <div fxLayout="column" fxflex="100" class="menuContainer" fxLayoutAlign="start center">
      <div class="tab" (click)="activateTab(1)" [routerLinkActive]="'activeTab'" #home ="routerLinkActive">
        <mat-icon svgIcon="home"  *ngIf="!home.isActive"></mat-icon>
        <mat-icon svgIcon="home-active" *ngIf="home.isActive"></mat-icon>
        <p style="text-align: center;">Home</p>
      </div>
      <div class="tab" (click)="activateTab(2)" *ngIf="draft && !previous" [routerLink]="['/draft']" [routerLinkActive]="'activeTab'" #draft="routerLinkActive">
        <mat-icon svgIcon="draft" *ngIf="!draft.isActive"></mat-icon>
        <mat-icon svgIcon="draft-active" *ngIf="draft.isActive"></mat-icon>
        <p style="text-align: center;">Draft</p>
      </div>
      <div class="tab" (click)="activateTab(3)" *ngIf="review && !previous"  [routerLink]="['/review']" [routerLinkActive]="'activeTab'" #review="routerLinkActive">
        <mat-icon svgIcon="review" *ngIf="!review.isActive"></mat-icon>
        <mat-icon svgIcon="review-active1" *ngIf="review.isActive"></mat-icon>
        <p style="text-align: center;">Review</p>
      </div>
      <div class="tab" (click)="activateTab(4)" *ngIf="finalSubmit && !previous"  [routerLink]="['/approve']" [routerLinkActive]="'activeTab'" #approve="routerLinkActive">
        <mat-icon svgIcon="approve" *ngIf="!approve.isActive"></mat-icon>
        <mat-icon svgIcon="approve-active" *ngIf="approve.isActive"></mat-icon>
        <p style="text-align: center;">Approve</p>
      </div>
      <div class="tab" (click)="activateTab(5)" [routerLink]="['/mapping']" [routerLinkActive]="'activeTab'" #mapping="routerLinkActive">
        <mat-icon svgIcon="attribute" *ngIf="!mapping.isActive"></mat-icon>
        <mat-icon svgIcon="attribute1" *ngIf="mapping.isActive"></mat-icon>
        <p style="text-align: center;line-height: 10px;
        margin-top: 0px;">Valid Values</p>
      </div>
      <div class="tab" [routerLinkActive]="'activeTab'" *ngIf="!previous" [routerLink]="['/category-list']"  #category ="routerLinkActive">
        <mat-icon svgIcon="category" *ngIf="!category.isActive"></mat-icon>
        <mat-icon svgIcon="category-active" *ngIf="category.isActive"></mat-icon>
        <p style="text-align: center;">Category</p>
      </div>
      <!-- <div class="tab" [routerLinkActive]="'activeTab'"  #settings ="routerLinkActive">
        <mat-icon svgIcon="settings" *ngIf="!settings.isActive"></mat-icon>
        <mat-icon svgIcon="settings-active" *ngIf="settings.isActive"></mat-icon>
        <p style="text-align: center;">Settings</p>
      </div> -->
      <!-- <div class="tab">
        <a target="_self" href="https://storage.googleapis.com/taxonomy-v2-data/taxonomy.zip">
          <mat-icon style="color:#3C3E48;">download</mat-icon> 
          <p style="text-align: center;color:#3C3E48;">Download</p>
        </a>
      </div> -->

      
      <div class="tab" style="bottom: 10px; position: fixed" (click)="downloadMyFile()" [routerLinkActive]="'activeTab'" #help ="routerLinkActive">
        <mat-icon svgIcon="help" *ngIf="!help.isActive"></mat-icon>
        <mat-icon svgIcon="help-active" *ngIf="help.isActive"></mat-icon>
        <p style="text-align: center;">Help</p>
      </div>
    </div>

  </div>
</div>
