<div class="mainDialogContainer" fxLayout="column" fxLayoutAlign="start start">
    <button class="close" mat-icon-button style="background: #F4F6FA;"  mat-dialog-close (click)="close()"><mat-icon>close</mat-icon></button>
    <h3>Added Nodes</h3>
    <div fxLayout="row" style="width:100%; overflow: auto;">
        <div fxLayout="row" style="padding-right: 10px;">
            <div class="nodeContainer">
                <div class="header">
                    Level {{minLevel}}
                </div>
                <div class="node" (click)="selectNode(parentData,-1)" [ngClass]="{'active': parentData == selectedNode}">
                    {{parentData.attributes.category.data.attributes.name}} ({{parentCount}})
                </div>
            </div>
            <div *ngFor="let item of levelList;let i = index" class="nodeContainer">
                <div class="header">
                    Level {{item.level}}
                </div>
                <div fxLayout="column">
                    <div *ngFor="let node of item.values" (click)="selectNode(node,i)" class="node" [ngClass]="{'active': node.id == selectedNode.id}">
                        {{node.name}} ({{calculateChilds(node.id)}})
                    </div>
                </div>
            </div>
        </div>
        <div class="detailContainer">
            <mat-tab-group>
                <mat-tab label="Category Details">
                    <!-- <h3>Category Details</h3> -->
                    <div class="list1">
                        <ul *ngIf="selectedNodeData" class="categoryDetails">
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Name</div>
                                    <div fxFlex="70" class="caption">{{selectedNodeData.attributes.name}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Category Code</div>
                                    <div fxFlex="70" class="caption">{{selectedNodeData.attributes.code}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Description</div>
                                    <div fxFlex="70" class="caption">{{selectedNodeData.attributes.description}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Is Leaf</div>
                                    <div fxFlex="70" class="caption">{{(selectedNodeData.attributes.is_leaf).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li>
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Is Free</div>
                                    <div fxFlex="70" class="caption">{{(selectedNodeData.attributes.is_free).toString() | titlecase}}</div>
                                </div>
                            </li>
                            <li *ngIf="hierarchies.length > 0">
                                <div class="item" fxLayout="row">
                                    <div fxFlex="30" class="caption">Connected Hierarchies</div>
                                    <div fxFlex="70" class="caption">
                                        <ul style="padding:0px; margin:0px">
                                            <li *ngFor="let item of hierarchies">
                                                {{item.attributes.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>   
                </mat-tab>
                <mat-tab label="Attributes" *ngIf="attributeLength > 0 && selectedNodeData">
                    <div class="list2">
                        <ul class="categoryDetails">
                            <li *ngFor="let item of attributeList">
                                <div class="item" fxLayout="column">
                                    <div fxLayout="row">
                                        <div fxFlex="85" class="caption"> ({{item.attributes.attribute_sequence}}) <b>{{item.attributes.attribute.data.attributes.name}}</b></div>
                                        <button mat-icon-button *ngIf="item.attributes.is_filter">
                                            <mat-icon  svgIcon="filter"></mat-icon>({{item.attributes.filter_sequence}})
                                        </button>    
                                    </div>
                                    <div fxLayout="column">
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.is_closed !== null">Closed : {{(item.attributes.is_closed).toString() | titlecase}}</div>
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.is_multi !== null">Single : {{(!item.attributes.is_multi).toString() | titlecase}}</div>    
                                        </div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.is_active !== null">Is Active : {{(item.attributes.is_active).toString() | titlecase}}</div>
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.has_uom !== null">Has UOM : {{(item.attributes.has_uom).toString() | titlecase}}</div>
                                        </div>    
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.is_required !== null">Is Required : {{(item.attributes.is_required).toString() | titlecase}}</div>
                                            <div fxFlex="50" class="caption" *ngIf="item.attributes.is_differentiator !== null">Is Differentiator : {{(item.attributes.is_differentiator).toString() | titlecase}}</div>
                                        </div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="50" class="caption">Minimum Value : {{item.attributes.min_value}}</div>
                                        </div>
                                        <div class="caption" *ngIf="item.attributes.uom_codeset!==undefined && item.attributes.uom_codeset!==null">UoM Codeset : {{item.attributes.uom_codeset.data.attributes.name}}</div>
                                        <div class="caption" *ngIf="item.attributes.value_codeset!==undefined && item.attributes.value_codeset!==null">Approved Value: {{item.attributes.value_codeset.data.attributes.name}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </mat-tab>    
            </mat-tab-group>
        </div>
    </div>
</div>