import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commmaSeperator'
})
export class CommmaSeperatorPipe implements PipeTransform {

  transform(value: any,): any {
    return value? value.replace(/,/g, ", ") : value;
  }

}
