import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import { InfoService } from 'src/app/services/info.service';
import { NodeService } from 'src/app/services/node.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

  constructor(
    private nodeService: NodeService,
    private router: Router,
    private iconRegistry:MatIconRegistry,
    private sanitizer: DomSanitizer,
    private snackBar:MatSnackBar,
    private infoService: InfoService,
    private dialog:MatDialog,
    private errorHandler: ErrorHandlerService
  ) { 
    this.iconRegistry.addSvgIcon(
      'upload',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/addFolder.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'zip',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/zip.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'csv',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/csv.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'doc',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/doc.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/pdf.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'send',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/send.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'export',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/export.svg'
      ),
    );
    this.iconRegistry.addSvgIcon(
      'newTab',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/datax/newTab.svg'
      ),
    );
  }
  formGroup;
  selectedNode;
  selectedId = 0;
  files: File[] = [];
  nodeData:any = null;
  loading = true;
  formSubmitted = false;
  draftId= 0;
  selectedNodeId:any;
  selectedNodeData:any;
  isLeaf;
  name = '';
  description = '';
  parent;
  notes = '';
  categoryName:any;
  categoriesList:any = null;
  newCategoryId = null;
  attributeList:any = [];
  ticketId = '';
  ngOnInit(): void {
    this.nodeService.selectedAttributeList = [];
    this.nodeService.selectedFilterAttributeList = [];
 // console.log(this.infoService.nodeId);    
    this.selectedNodeId = this.infoService.nodeId;
    // this.selectedNodeId = 'dd63ae6a-270d-460b-a6b1-ada856064279';
    if(this.selectedNodeId){
      this.nodeInfo(this.selectedNodeId);
    } else {
      this.snackBar.open('No node ID is found.', '', {
        duration: 2000,
      });
      this.router.navigate(['/home']);
    }
    this.nodeList(0);
  }
  

  nodeInfo = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
     // console.log(res.data);
        this.selectedNodeData = res.data;
        this.loading = false;
      }
    });
  }

  nodeList = (id) => {
    this.infoService.getNodeData(id).subscribe(res => {
      if (res) {
        this.categoriesList = res.data;
        // console.log(res.data);
        this.loading = false;
      }
    });
  }

  getIcon = (name) => {
    let arr = name.split('.');
    // console.log(arr);
    if(arr[1]=='csv'){
      return 'csv';
    } else if(arr[1]=='zip'){
      return 'zip';
    } else if(arr[1]=='doc'||arr[1]=='docx'){
      return 'doc';
    } else if(arr[1]=='pdf'){
      return 'pdf';
    } else {
      return 'zip';
    }
  }

  onSelect(event) {
 // console.log(event);
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
 // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save =()=>{
    this.loading = true;
 // console.log(this.categoryName); 
    // if(this.leafNode){
    //   this.router.navigate(['/attribute-suggestion']);
    // }
    let data = {
      edit_type: 'category_addition',
      target_node: this.selectedNodeId,
      category_name: this.categoryName,
      is_leaf: this.isLeaf,
      note: this.notes,
      attributes: this.attributeList,
      internal_ticket_id: this.ticketId,
      description: this.description,
      category_code:this.newCategoryId
    }
 // console.log(data);    
    this.nodeService.addCategory(this.selectedId,data).subscribe({
      next: (resp:any) => {
     // console.log(resp);        
        this.formSubmitted = false;
        this.draftId = resp.data.id;
        if(this.draftId > 0 && this.files.length > 0){
          // this.loading = false
          var form = new FormData();
          this.files.forEach(e => {
            form.append('attachments', e);
          });
          this.nodeService.uploadFile(this.draftId,form).subscribe({
            next: resp => {
              this.loading = false
              this.snackBar.open(`Category addition request submitted successfully.`, 'OK', {
                duration: 4000,
                panelClass: ['success-snackbar']
              });
            },
            error: (HttpResponse: HttpErrorResponse) => {
              this.loading = false
              this.errorHandler.pass(HttpResponse.error);
            }
          });
        } else {
          this.loading = false
          this.snackBar.open(`Category addition request submitted successfully.`, 'OK', {
            duration: 4000,
            panelClass: ['success-snackbar']
          });
        }      
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  cancel = () => {
    this.router.navigate(['/home']);
  }
  openDialog(): void {
    var list = this.attributeList;
    const dialogRef = this.dialog.open(DialogNodeAttributes, {
      width: '98vw',
      data: {list}
    });
    dialogRef.afterClosed().subscribe(result => {
   // console.log(result);
      if (result)
      {
        this.attributeList = result.data;
        // this.snackBar.open('Marked Successfully', '', {
        //   duration: 2000,
        // });
      } else {
      }
    }); 
  }

}

// Dialog ---------------------------------------------------
@Component({
selector: 'dialog-node-info',
templateUrl: './attribute.html',
styleUrls: ['./add-category.component.scss']
})
export class DialogNodeAttributes {
@ViewChild(MatAutocompleteTrigger) attributeValuesAutoComplete: MatAutocompleteTrigger;

constructor(
  public dialog: MatDialog,
  private iconRegistry:MatIconRegistry,
  private sanitizer: DomSanitizer,
  private nodeService: NodeService,
  public dialogRef: MatDialogRef<DialogNodeAttributes>,
  private fb:FormBuilder
) {
  this.iconRegistry.addSvgIcon(
    'edit',
    this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/images/datax/Edit.svg'
    )
  );
  }
  tab1Data:any = null;
  attribute:any = null;
  productType:any = null;
  selectedNodeId = 0;
  loading = true;
  nodeData;
  tabLoader;
  attributes;
  leafNodes;
  tabIndex = 0;
  page = 0;
  pageSize = 10;
  totalItems = 0;
  editedNode;
  attachment;
  data;
  selectedAttributes:any = [];
  formGroupAttribute: FormGroup;
  searchedAttribute;
  attributeList:any = [];
  filteringAttributeList:any = [];
  activeTabIndex = 0;
  postAttributeObject:any = [];
  postAttributeFilterObject:any = [];
  // Edit ------
  ngOnInit(): void {
    this.selectedAttributes = [];
    this.attributeList = [];
    this.filteringAttributeList = [];
    this.postAttributeObject = [];
    this.postAttributeFilterObject = [];
    // if(this.nodeService.selectedAttributeList.length>0){
    //   // this.selectedAttributes = this.nodeService.selectedAttributeList;
    //   this.nodeService.selectedAttributeList.forEach(e=>{
    //     this.selectAttribute(e);
    //   });
    // }
    this.formGroupAttribute = this.fb.group({
      'attribute' :['']
    });
    this.nodeInfo();  
    this.initForm();
  }

  nodeInfo = () => {
    this.nodeService.getAttributeList().subscribe(res => {
      if (res) {
        // console.log(res);
        this.attributeList = res.results.data;
        // console.log(this.attributeList);
        this.tabLoader = false;
      }
    });
  }

  initForm() {
    this.formGroupAttribute = this.fb.group({
      'attribute' :['']
    })
    this.formGroupAttribute.get('attribute').valueChanges.pipe(debounceTime(100),
    distinctUntilChanged()).
    subscribe(resp => {
      // console.log(resp);
      // console.log(this.searchedAttribute);      
      if(this.formGroupAttribute.get('attribute').value != ''){
        this.searchedAttribute = resp;
        this.searchAttribute(resp);
      } else {
       
      }
    })
  }

  searchAttribute = (searchKey) => {
    this.nodeService.searchAttribute(searchKey).subscribe(res => {
      if (res) {
        // console.log(res);
        this.attributeList = res.results.data;
        this.loading = false;
      }
    });
  }

  selectAttribute = (item) => {
    if(this.selectedAttributes.some(e => e === item)){
      var i = this.selectedAttributes.indexOf(item);
      if (i > -1) {
        this.selectedAttributes.splice(i, 1);
        this.postAttributeObject.splice(i, 1);
      }
    } else{
      this.selectedAttributes.push(item)
   // console.log(this.selectedAttributes.length);      
      let index = this.selectedAttributes.length;
      let params = {
        attribute_id: item.id,
        is_filter: false,
        attribute_sequence: null,
        filter_sequence:null
      }
      this.postAttributeObject.push(params);
    }
  }

  filterCounter:any= 1;
  
  selectFilter = (item,i) => {
    if(this.postAttributeObject[i].is_filter == false){
      this.postAttributeObject[i].is_filter = true;
      this.postAttributeObject[i].filter_sequence = this.filterCounter;
      this.postAttributeFilterObject.push(this.postAttributeObject[i]);
      this.filterCounter++;
    } else {
      this.filterCounter--;
      this.postAttributeObject[i].is_filter = false;
      // -------------------------------------------
      var i1 = this.postAttributeFilterObject.indexOf(this.postAttributeObject[i]);
      if (i1 > -1) {
        this.postAttributeFilterObject.splice(i, 1);
      }
    }
    if(this.filteringAttributeList.some(e => e === item)){
      var i = this.filteringAttributeList.indexOf(item);
      if (i > -1) {
        this.filteringAttributeList.splice(i, 1);
      }
    } else {
      this.filteringAttributeList.push(item);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    // console.log(this.selectedAttributes);
    moveItemInArray(this.selectedAttributes, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeObject, event.previousIndex, event.currentIndex);
    this.mergeAttribute(this.postAttributeObject);
  }
  mergeAttribute = (obj)=>{
    this.postAttributeObject.forEach((e, i) => {
      e.attribute_sequence = i+1;
    });
 // console.log(this.postAttributeObject);
  }
  dropFilter(event: CdkDragDrop<string[]>) {    
    moveItemInArray(this.filteringAttributeList, event.previousIndex, event.currentIndex);
    moveItemInArray(this.postAttributeFilterObject, event.previousIndex, event.currentIndex);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
  }
  
  mergeFilterAttribute = (obj)=>{
    this.postAttributeFilterObject.forEach((e, i) => {
      e.filter_sequence = i+1;
    });
 // console.log(this.postAttributeFilterObject);
  }

  mergeFIlterSequence = () => {
    for(let i=0;i<=this.postAttributeObject.length-1;i++){
      this.postAttributeFilterObject.forEach(e => {
        if(e==this.postAttributeObject[i]){
          this.postAttributeObject[i].filter_sequence=e.filter_sequence;
        }
      });
    }
  }
  submit = () =>{
    this.mergeAttribute(this.postAttributeObject);
    this.mergeFilterAttribute(this.postAttributeFilterObject);
    this.mergeFIlterSequence();
    this.nodeService.selectedAttributeList = this.selectedAttributes;
    this.nodeService.selectedFilterAttributeList = this.filteringAttributeList;
 // console.log(this.postAttributeObject);   
    this.dialogRef.close({data:this.postAttributeObject});
  } 
  close = () => {
    this.dialogRef.close({data:null});    
  } 

  // req =
  // { 
  //   new_category_id:'110101',
  //   new_category_name:'Test Category',
  //   description:'Test Description',
  //   is_leaf: true,
  //   connect_to:'ca3fbc83-ff96-4505-845e-63c5c12e7243',
  //   internal_ticker_id:'1123344',
  //   notes:'test notes'
  // }

}
