import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AppPermissionsService } from '../../services/app-permissions.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../../_dialogs/contact-dialog/contact-dialog.component';
import { HomeService } from 'src/app/services/home.service';
import { VersionService } from 'src/app/services/version.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  show: boolean
  hide = true;
  isDisabled: true
  public loginForm: FormGroup;
  formSubmitted: boolean;
  loading: boolean;
  currentYear;
  versionList:any = [];
  selectedVersion:any = null;
  currentVersion:any = null;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private permissionService: AppPermissionsService,
    public dialog: MatDialog,
    private errorHandler: ErrorHandlerService
  ) {}

  navigateTo() {
    this.router.navigate(['/forgot-password'])
  }

  public caxLogo;

  ngOnInit() {
    this.loading = false;
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
      // rememberme: [false]
    });
    // cax logo
    this.show = true;
    this.iconRegistry.addSvgIcon(
      'cax',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/Logo2.svg'
      )
    );
    this.caxLogo = 'cax';
    // if `me` returns 200, login user directly
    this.userService.me().subscribe({
      next: resp => {
        this.router.navigate(["home"]);
      }
    });

    this.currentYear = new Date().getFullYear()
  }

  // Login function
  login = () => {
    this.loading = true;
    this.formSubmitted = true;
    this.userService.login(this.loginForm.value).subscribe({
      next: resp => {
        this.formSubmitted = false;
        this.userService.me().subscribe({
          next: resp => {
         // console.log('resp');
            // this.setVersion();
            localStorage.setItem('currentUser', JSON.stringify(resp));
            this.router.navigate(['/home']);
          }
        })
      },
      error: (HttpResponse: HttpErrorResponse) => {
        this.loading = false
        this.formSubmitted = false;
        this.errorHandler.pass(HttpResponse.error);
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactDialogComponent,{
      data: 'login',});

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

