<div fxLayout="column" class="mainContainer">
  <div fxLayout="column" style="border-bottom: 1px solid #f0f0f0;z-index: 9; padding: 0px 0px 0px 20px;" class="topBar">
    <div fxLayout="row">
      <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start">
        <form class="example-form" [formGroup]="searchFormGroup" style="width: 100%;">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <input type="text"
                  id="searchKey"
                  placeholder="Search"
                  matInput
                  formControlName="searchField"
                  [matAutocomplete]="auto" (keydown)="enterSearch($event)">        
            <mat-autocomplete (optionSelected)="getSearchData($event)" #auto="matAutocomplete" style="width: 90%;">
              <mat-option (scroll)="scrollSearch($event)" (click)="getSearchParam(option)" style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let option of options" [value]="option.name">
                {{option.attributes.category.data.attributes.name}}
                <button *ngIf="option.attributes.category.data.attributes.code" button style="background: #E6E8F0; border: none;margin-left: 20px; height: 20px;border-radius: 4px;">{{option.attributes.category.data.attributes.code}}</button>
                <button style="background: #52BD94; border: none; height: 20px;border-radius: 4px; float: right;margin-top: 5px;" *ngIf="option.type=='categories_to_hierarchies'">(Level {{option.attributes.level}})</button>
                <br />
                <span button style="background: transparent; border: none; height: 20px;border-radius: 4px;font-size: 12px;">{{option.attributes.path}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
      <div style="padding:20px 10px">
        <mat-icon matSuffix svgIcon="filters" [matMenuTriggerFor]="levels" #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()"></mat-icon>
      </div>
      <div style="padding:10px 10px">
        <button (click)="resetSearch()" mat-icon-button>
          <mat-icon svgIcon="search-reset"></mat-icon>
        </button>
      </div>
      <mat-menu #selector="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="levels">Levels</button>  
      </mat-menu>
  
      <mat-menu #levels="matMenu"  [overlapTrigger]="false">
        <span (mouseleave)="menuTrigger.closeMenu()">
          <div mat-menu-item (click)="getSelectedLevel($event,i+1);" *ngFor="let level of levelList; let i=index;"
          class="flex-container  align-center multi-checkbox">
          <input type="checkbox" [checked]="checkIndex(i+1)">
          {{levelList[i].name}}
        </div>
        </span>
      </mat-menu>

      <app-version></app-version>
      <app-hierarchy></app-hierarchy>
      <div fxLayoutAlign="center center">
        <div class="menu-drop" style="cursor:pointer">
          <mat-icon mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger1="matMenuTrigger" (mouseenter)="menuTrigger1.openMenu()">
            download</mat-icon>
          <mat-menu #menu="matMenu" class="" [overlapTrigger]="false">
            <span (mouseleave)="menuTrigger1.closeMenu()">
              <button mat-menu-item (click)="download('full')">
                <mat-icon>download</mat-icon>
              <span class="menu-item">Taxonomy</span>
              </button>
              <button mat-menu-item (click)="download('delta')">
                <mat-icon>download</mat-icon>
              <span class="menu-item">Delta</span>
              </button>
            </span>
          </mat-menu>
        </div>
      </div>
      <!-- <div class="menu-drop" fxLayoutAlign="center center">
        <mat-icon mat-icon-button [matMenuTriggerFor]="menu" style="color:#3C3E48;">download</mat-icon>
        <mat-menu #menu="matMenu" class="">
          <button mat-icon-button (click)="download()">
            <mat-icon>arrow_drop_down</mat-icon>
            <span class="menu-item">Taxonomy</span>
          </button>
          <button mat-icon-button (click)="download()">
            <mat-icon>arrow_drop_down</mat-icon>
            <span class="menu-item">Delta</span>
          </button>
        </mat-menu>
        </div>
      </div>-->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div style="margin: 0px 5px;" *ngFor="let item of selectedLevels">
        <button mat-raised-button>
          Level {{item}}
        </button>
      </div>
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <!-- <app-home-table *ngIf="!nodeList"></app-home-table> -->
  <!-- {{nodeList|json}} -->
  <div style="padding:20px 10px; padding-bottom: 0px;" fxLayoutGap="0px" class="mainTable">
    <div fxLayout="row" style="padding:20px 10px;" fxLayoutGap="0px">
      <div style="width: 240px;" class="listContainer" *ngFor="let l of levelList;let level=index">
        <div class="listHeaderFixed">
          <div class="listHeader">{{l.name}}</div>
        </div>
        <ul>
          <li fxLayout="row" style="width: 100%;" *ngFor="let item of l.value; let i = index" [ngClass]="{'active': item.attributes.category.data.id == selectedNodeID}">
            <div style="cursor: pointer;" fxLayout="column" (click)="activeTableCell(item)" fxLayoutAlign="center start" fxFlex="80"><span>{{item.attributes.category.data.attributes.name}} ({{item.attributes.child_count}})</span></div>
            <div class="info" fxFlex="20" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
              <button mat-icon-button (click)="goToInfo(item)" class="infoButton" matTooltip="{{item.full_path}}" matTooltipPosition="after">
                <mat-icon svgIcon="info"></mat-icon>
              </button>
              <button mat-icon-button (click)="rightClick($event,item)" class="infoButton" *ngIf="!frozen">
                <mat-icon svgIcon="edit"></mat-icon>
              </button>
            </div>
          </li>
        </ul> 
  
      </div>
      <div *ngIf="rightPanelActive" _ngcontent-djb-c140="" class="listContainer ng-star-inserted" style="width: 850px; margin-right: 0px;">
        <div _ngcontent-djb-c140="" class="listHeaderFixed" style="width: 850px;">
    </div>
  </div>
</div>

<div class="flyingDiv l2r" #rightPanel [ngClass]="{'flyingDivActivated': rightPanelActive == true}">
  <button class="trigger" mat-icon-button (click)="avtivateRightPanel()">
    <mat-icon *ngIf="!rightPanelActive">arrow_left</mat-icon>
    <mat-icon *ngIf="rightPanelActive">arrow_right</mat-icon>
  </button>
  <div class="flyingDivTitle" *ngIf="!rightPanelActive">
    <span>{{'Attributes' | uppercase}}</span>
  </div>
  <div *ngIf="rightPanelActive" style="height: 100%;">
    <div style="padding: 60px;" fxLayout="column" fxLayoutAlign="center center" *ngIf="!leafNodeList&&!attributesList">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon svgIcon="empty" style="width: 100%;height: auto;"></mat-icon>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <p style="font-size: 20px;">Select something to View</p> <h2>Leaf node & Attribute</h2>
      </div>
    </div>
    <div></div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="leafNodeList">
      <div style="text-align: left;width: 95%; padding: 10px;">
        <span class="rightPanelHeader" *ngIf="selectedNode && !searchResult">{{selectedNode.attributes.category.data.attributes.name}} ({{selectedNode.attributes.child_count}})</span>
      </div>
      <div fxLayout="row" style="width:95%">
        <div fxFlex="50" *ngIf="leafNodeList">
          <div class="listHeaderFixed">
            <div class="listHeader" *ngIf="!searchResult" fxLayout="row" >
              <span>Leaf Nodes({{leafNodeListCount}})</span>
            </div>
            <div class="listHeader" *ngIf="searchResult" fxLayout="row">
              <span>Leaf Nodes ({{leafNodeListCount}})</span>
            </div>
            
          </div>
          <div  class="leaf-node-list" (scroll)="onLeafNodeScroll($event)">
            <ul *ngIf="searchResult">
              <li *ngFor="let item of leafNodeList;let i = index" (click)='selectLeafNode(item.attributes.category_id)' [ngClass]="{'active': item.attributes.category_id == selectedLeafNodeId}">
                <div fxLayout='row' style="width:100%">
                  <div fxFlex="100">
                    <span [matTooltip]="item.attributes.name">{{(item.attributes.category.data.attributes.name.length>30)? (item.attributes.category.data.attributes.name | slice:0:30)+'..':(item.attributes.category.data.attributes.name)}} (L{{item.attributes.level}})</span>
                  </div>
                </div>
              </li>
            </ul>
            <ul *ngIf="!searchResult">
              <li *ngFor="let item of leafNodeList;let i = index" (click)='selectLeafNode(item.attributes.category_id)' [ngClass]="{'active': item.attributes.category_id == selectedLeafNodeId}">
                <div fxLayout='row' style="width:100%">
                  <div fxFlex="100">
                    <span [matTooltip]="item.attributes.name">{{(item.attributes.category.data.attributes.name.length>30)? (item.attributes.category.data.attributes.name | slice:0:30)+'..':(item.attributes.category.data.attributes.name)}} (L{{item.attributes.level}})</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div fxFlex="50">
          <div class="listHeaderFixed">
            <div class="listHeader" fxLayout="row">
              <div fxFlex="100">Attributes <span *ngIf="!searchResult">({{attributesListCount}})</span><span *ngIf="searchResult">({{attributesListCount}})</span></div>            
            </div>
          </div>
          <div class="attribute-list" (scroll)="onAttributeScroll($event)">
            <ul *ngIf="searchResult && selectedLeafNodeId">
              <li *ngFor="let item of attributesList" [ngClass]="{'active': item.id == selectedAttributeID}" fxLayout="row" style="width: 100%;">
                <div *ngIf="item.attributes.attribute" fxLayout='row' style="width:100%">
                  <div fxFlex="70" >{{(item.attributes.attribute.data.attributes.name.length>30)? (item.attributes.attribute.data.attributes.name | slice:0:30)+'..':(item.attributes.attribute.data.attributes.name)}}</div>
                  <div fxFlex="10" *ngIf="isLeaf  || selectedLeafNodeId">
                    <button mat-icon-button class="info" [matTooltip]="item.attributes.attribute.data.attributes.name" (click)="goToAttributeInfo(item)"><mat-icon svgIcon="info"></mat-icon></button>
                  </div>
                  <div fxFlex="20" fxLayout="row" *ngIf="item.attributes.is_filter==true">
                    <mat-icon *ngIf="item.attributes.is_filter==true "svgIcon="filter"></mat-icon>
                    <span>({{item.attributes.filter_sequence}})</span>
                  </div>
                </div>  
              </li>
            </ul>
            <ul *ngIf="!searchResult && !selectedLeafNodeId">
              <li *ngFor="let item of attributesList" [ngClass]="{'active': item.id == selectedAttributeID}" fxLayout="row" style="width: 100%;">
                <div fxLayout='row' *ngIf="item.attributes" style="width:100%">
                  <div fxFlex="70">{{(item.attributes.name.length>30)? (item.attributes.name | slice:0:30)+'..':(item.attributes.name)}}</div>
                  <div fxFlex="10" *ngIf="isLeaf || selectedLeafNodeId">
                    <button mat-icon-button class="info" [matTooltip]="item.attributes.name" (click)="goToAttributeInfo(item)"><mat-icon svgIcon="info"></mat-icon></button>
                  </div>
                  <div fxFlex="20" fxLayout="row" *ngIf="item.attributes.is_filter==true">
                    <mat-icon *ngIf="item.attributes.is_filter==true "svgIcon="filter"></mat-icon>
                    <span>({{item.attributes.filter_sequence}})</span>
                  </div>

                </div>  
              </li>
            </ul>
            <ul *ngIf="!searchResult && selectedLeafNodeId">
              <li *ngFor="let item of attributesList" [ngClass]="{'active': item.id == selectedAttributeID}" fxLayout="row" style="width: 100%;">
                <div *ngIf="item.attributes.attribute" fxLayout='row' style="width:100%">
                  <div fxFlex="70" >{{(item.attributes.attribute.data.attributes.name.length>30)? (item.attributes.attribute.data.attributes.name | slice:0:30)+'..':(item.attributes.attribute.data.attributes.name)}}</div>
                  <div fxFlex="10" *ngIf="isLeaf  || selectedLeafNodeId">
                    <button mat-icon-button class="info" [matTooltip]="item.attributes.attribute.data.attributes.name" (click)="goToAttributeInfo(item)"><mat-icon svgIcon="info"></mat-icon></button>
                  </div>
                  <div fxFlex="20" fxLayout="row" *ngIf="item.attributes.is_filter==true">
                    <div fxFlex="50"><mat-icon *ngIf="item.attributes.is_filter==true "svgIcon="filter"></mat-icon></div>
                    <div fxFlex="50"><span>({{item.attributes.filter_sequence}})</span></div>
                  </div>
                </div>  
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button class="trigger-right" mat-icon-button (click)="avtivateRightPanel()">
      <mat-icon *ngIf="rightPanelActive">close</mat-icon>
    </button>
  </div>

</div>
<div class="floatingMenu" style="position: fixed;" [style.bottom.px]="top" [style.left.px]="left" *ngIf="floatMenu">
  <ul>
    <li (click)="selectedOption(1)">Edit Properties</li>
    <li (click)="selectedOption(2)">Edit Relation</li>
    <li (click)="selectedOption(3)" *ngIf="!isLeaf">Add Relation</li>
    <!-- <li (click)="selectedOption(4)" *ngIf="isLeaf">Add Attribute</li> -->
    <li (click)="selectedOption(5)" *ngIf="currentNodeLevel > 1">Disconnect</li>
  </ul>  
</div>
<div class="overlay" *ngIf="overlay" (click)="overlayClick()">
</div>