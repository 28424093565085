<div class="dialogContainer">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex="50">
            <mat-card class="card">
                <mat-card-title style="text-align: center;">
                    Upload Codeset File <button mat-icon-button><mat-icon>file_upload</mat-icon></button>
                </mat-card-title>
                <mat-card-content fxlayoutAlign="center center">
                    <div>
                        <ngx-dropzone class="dropZone" (change)="onSelectUOM($event)">
                            <ngx-dropzone-label>
                                <mat-icon svgIcon="upload"></mat-icon>
                                <p>Drag or Drop your files here</p>
                                <p style="color: red;">* xlsx file</p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of uomFiles" [removable]="true" (removed)="onRemoveUOM(f)">
                                
                                <ngx-dropzone-label fxLayout="row">
                                    <div fxFlex="50">
                                        <mat-icon [svgIcon]="getIcon(f.name)" style="width: 40px;height: 40px;">
                                        </mat-icon>    
                                    </div>
                                    <div fxFlex="50">
                                        <span style="font-size: 10px;">{{ f.name }}</span>
                                    </div>
                                </ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div mat-dialog-actions style="float: right;">
        <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submitUpload()" >Submit</button>
        <button mat-raised-button class="close-btn-style" (click)="close()" >Close</button>
    </div>
</div>