import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  form = new FormGroup({
    dialogField: new FormControl(
      ` 
    -------------------------------------------------------------------
    Contact information
    Name:
    Email:
    '--------------------------------------------------------------------
    The type and version of browser used.
    　　Example: Chrome 71.0.3578.98
    -------------------------------------------------------------------
    ▼ Impact & Frequency of events --- (Please select the applicable one)
    　1 (Extreme): Occurs with high frequency regardless of usage 
    　2 (High): Always occurs under specific conditions that are used often
    　3 (Medium): Occurs in specific conditions that occur occasionally
    　4 (Low): Occurs rarely during operation.
    ------------------------------------------------------------------------
    Details of the event
    　Please provide the details of the event.
    　
    　(Example)
    　In the case of a failure
    　　- When did the event occur?
    　　- Please provide any details of the configuration changes that were made before the event occurred.
    `,
      Validators.required
    ),
  });
  
  constructor(
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {}

  ngOnInit() {
    // console.log(this.data)
  }

  onSubmit() {
      this.loginService.fromLogin(this.form.controls['dialogField'].value,this.data).subscribe({
        next: (resp) => {
          //  console.log(resp)
          this.snackBar.open(resp.detail, 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar'],
          });
        },
        error: (HttpResponse: HttpErrorResponse) => {
          this.snackBar.open(`${HttpResponse.error.detail}`, 'OK', {
            duration: 4000,
            panelClass: ['error-snackbar'],
          });
        },
      });
    } 
  }

