<div class="row">
  <div class="column-left">
    <!-- Login page left column -->
    <div class="content-left">
      <div class="logo" fxLayoutAlign="center center">
        <img src="assets/images/datax_logo.svg" class="svg_icon" />
      </div>

      <h1>Forgot Password?</h1>
      <p>We’ll send a recovery link to</p>
      <!-- login form -->
      <div class="input-fields">
        <form
          class="form"
          fxLayout="column"
          [formGroup]="emailVerify"
          (submit)="emailVerification()"
        >
         <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error *ngIf="emailVerify.controls.email.hasError('pattern')">
              Please provide a appropriate email.
            </mat-error>
                 <mat-error
              *ngIf="
                e.email.touched &&
                e.email.invalid &&
                e.email.errors.required
              "
            >
              Email is requried.
            </mat-error>
            
            </mat-form-field
          >
          <button
            mat-raised-button
            class="recovery_button"
           
          >
            Send Recovery Link
          </button>
          <br />
        </form>
      </div>
      <div class="hr_line">Or</div>

      <button mat-raised-button class="return_login_button" routerLink="/">
        Return to Log In
      </button>
    </div>
    <!-- Copyrights -->
    <p class="login__copyright">&copy; 2021 Crowd<a href="#">ANALYTIX</a></p>
  </div>

  <!-- Login page Right column Info -->
  <div class="column-right">
    <app-login-info-content></app-login-info-content>
  </div>
</div>
