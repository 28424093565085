<div class="mainContainer" fxLayout="column">
    <div fxLayout="row" class="topBar">
      <div fxFlex="4" fxLayout="column" fxLayoutAlign="center start">
        <button mat-icon-button routerLink='/home'><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div fxFlex="21" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 24px;color: #3B3E48;">Information Page</span>
      </div>
      <div fxFlex="10" fxLayoutAlign="end center">
      </div>
    </div>
    <div class="tabContainer">
        <app-loader *ngIf="loading"></app-loader>
        <mat-tab-group
        class="table-tab"
        #tabGroup
        (selectedTabChange)="tabChanged($event)"
        >
          <mat-tab label="Properties" *ngIf="!loading">
            <mat-progress-bar *ngIf="tabLoader" mode="indeterminate"></mat-progress-bar>
            <div class="tab2" *ngIf="tab1Data"> 
                <ul>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Category Name</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.category.data.attributes.name}}</div>
                    <div fxFlex="10">
                      <button *ngIf="!frozen" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('Name',tab1Data.attributes.category.data.attributes.name)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Category Code</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.category.data.attributes.code}}</div>
                    <div fxFlex="10">
                      <!-- <button *ngIf="!frozen" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px;" (click)="editPanel('Name',tab1Data.attributes.category.data.attributes.name)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button> -->
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Description</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.category.data.attributes.description}}</div>
                    <div fxFlex="10">
                      <button *ngIf="!frozen" mat-icon-button style="width: auto;height: 20px;line-height: 15px;border-radius: 0px" (click)="editPanel('Node Description',tab1Data.attributes.category.data.attributes.description)"><mat-icon style="width: 15px; height: 15px;" svgIcon="edit"></mat-icon></button>
                    </div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Level</div>
                    <div class="value">{{tab1Data.attributes.level}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Full Path</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.path}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Is Leaf Node</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.is_leaf}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Total Child Nodes</div>
                    <div fxFlex="65" class="value">{{tab1Data.attributes.child_count}}</div>
                  </li>
                  <li fxLayout="row">
                    <div fxFlex="25" class="key">Child Nodes</div>
                    <div fxFlex="65" class="value">
                      <ul>
                        <li  *ngFor="let item of tab1Data.attributes.children; let i = index">
                          {{i+1+'.'}}<span style="margin-left: 10px;">{{item.data.attributes.category.data.attributes.name}}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
            </div>
          </mat-tab>
            <!-- Leaf Node Attributes view -->
          <mat-tab label="Attributes" *ngIf="!loading && isLeaf">
            <div fxLayout="column">
              <div fxLayoutAlign="end end" style="padding: 20px;">
                <button mat-icon-button style="
                  width: auto;
                  height: 20px;
                  line-height: 15px;
                  border-radius: 0px;
                  margin-right: 20px;"
                  *ngIf="!frozen && !disableEdit" 
                  (click)="editAttributes()"><mat-icon style="width: 15px; height: 15px;line-height: 15px;" svgIcon="edit"></mat-icon></button>
              </div>
              <div>
                <app-attribute-list [attributeList]="attributes" ></app-attribute-list>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
    </div>
</div>
