<div class="dialog" fxLayout="column">
    <div class="editPanel" fxFlex="100">
        <div fxLayout="row">
            <div fxFlex="100">
                <h2>Reset all the tickets</h2>
                <br>
                <h3>Please confirm your action</h3>
            </div>
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button class="save-btn-style" cdkFocusInitial (click)="submit()" >Yes</button>
            <button mat-raised-button class="close-btn-style" (click)="cancel()" >Cancel</button>
        </div>
    </div>  
</div>
    