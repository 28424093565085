import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-approve-conformation',
  templateUrl: './approve-conformation.component.html',
  styleUrls: ['./approve-conformation.component.scss'],
})
export class ApproveConformationComponent implements OnInit {
  batch_id: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public approveDialogRef: MatDialogRef<ApproveConformationComponent, any>
  ) {

    if (data) {
      this.batch_id = data.id
    }
  }

  ngOnInit(): void {}
}
