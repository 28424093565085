<mat-dialog-content>
    <div class="dialog" fxLayout="column">
        <div>
            <div style="text-align: center;"><h3>Select Attributes</h3></div>    
            <app-loader *ngIf="tabLoader"></app-loader>
            <mat-tab-group mat-align-tabs="start" >
                <mat-tab label="Add Attribute">
                    <div fxLayout="row">
                        <div fxFlex="40">
                            <div style="padding: 20px;">
                                <div fxLayout="column" fxLayoutAlign="center start" >
                                    <form class="example-form" [formGroup]="formGroupAttribute" style="width: 100%;">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                          <mat-icon matPrefix>search</mat-icon>
                                          <input type="text"
                                                id="searchKey"
                                                placeholder="Search Attribute"
                                                matInput
                                                formControlName="attribute"
                                                [matAutocomplete]="attributeValuesAutoComplete">        
                                          <mat-autocomplete #attributeValuesAutoComplete="matAutocomplete" style="width: 90%;">
                                            <!-- <mat-option style="line-height: 30px;height: 80px;padding-top: 10px;padding-bottom: 10px;border-bottom: 1px solid #E6E8F0;" *ngFor="let values of attributeList" [value]="values.attributes.name">
                                              {{values.attributes.name}}
                                            </mat-option> -->
                                          </mat-autocomplete>
                                        </mat-form-field>
                                    </form>
                                </div>
                                <div>
                                    <ul *ngIf="!tabLoader" style="margin-top: 0px;height: auto;">
                                        <li *ngFor="let item of attributeList" style="padding: 10px;border: 1px solid lightgrey;">
                                            <div fxLayout="row" style="width: 100%;">
                                                <div fxFlex="100">
                                                    <section class="example-section">
                                                        <mat-checkbox class="example-margin" (click)="selectAttribute(item)">
                                                            {{item.attributes.name}} 
                                                        </mat-checkbox>
                                                    </section>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="60" style="margin:0px" fxLayout="column">
                            <div style="padding: 0px 20px;" class="example-container" *ngIf="selectedAttributes.length > 0">
                                <div style="width: 90%;padding: 10px 0px; text-align: right;">Filtering Attribute</div>
                                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of selectedAttributes;let i = index" cdkDrag>
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="4">{{i+1}}</div>
                                            <div fxFlex="80">{{item.attributes.name}}</div>
                                            <div fxFlex="16">
                                                <section class="example-section">
                                                    <mat-checkbox class="example-margin" (click)="selectFilter(item,i)">
                                                    </mat-checkbox>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Filtering Attribute Order">
                    <div fxLayout="row" style="margin:20px 0px">
                        <div fxFlex="50" style="margin:20px 0px">
                            <div style="padding: 0px 20px;" class="example-container" *ngIf="filteringAttributeList.length > 0">
                                <!-- <div style="width: 90%;padding: 10px 0px; text-align: right;">Filtering Attribute</div> -->
                                <div cdkDropList class="example-list" (cdkDropListDropped)="dropFilter($event)">
                                    <div class="example-box" *ngFor="let item of filteringAttributeList;let i = index" cdkDrag>
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                        <div fxLayout="row" style="width: 100%;">
                                            <div fxFlex="4">{{i+1}}</div>
                                            <div fxFlex="100">{{item.attributes.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            
        </div>
    </div>    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button cdkFocusInitial (click)="submit()" >Submit</button>
    <button mat-raised-button mat-dialog-close (click)="close()" >Cancel</button>
</mat-dialog-actions>