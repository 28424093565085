<h2 mat-dialog-title fxLayoutAlign="center center">References</h2>
<ul class="product-url" *ngFor="let url of urlData">
  <li *ngIf="url.value && url.value != ''">
    <a
      class="product-url"
      href="{{ url.value }}"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ url.name }}
    </a>
  </li>
</ul>
