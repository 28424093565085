import { Component, Input, OnInit } from '@angular/core';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-hierarchy-name',
  templateUrl: './hierarchy-name.component.html',
  styleUrls: ['./hierarchy-name.component.scss']
})
export class HierarchyNameComponent implements OnInit {
  @Input() draftData: any;
  hierarchyId = null;
  versionId = null;
  page = 1;
  pageSize = 50;
  hierarchyName = null;
  versionName = null;
  constructor(
    private hierarchyService: HierarchyService,
    private versionService: VersionService
  ) { }

  ngOnInit(): void {
 // console.log(this.draftData);
    if(this.draftData){
      this.hierarchyId = this.draftData.attributes.context_data.hierarchy_id;
      this.versionId = this.draftData.attributes.context_data.version_id;
    }
    this.getVersion();
    this.getHierarchyList();
  }

  getVersion = () => {
    this.versionService.getVersionList().subscribe({
      next: res => {
        const list = res.results.data;
        const index = list.findIndex(i => i.id === parseInt(this.versionId));
        this.versionName = list[index].attributes.display_name;
     // console.log(this.versionName);
      }
    });
  }

  getHierarchyList = () => {
    this.hierarchyService.getHierarchyList(this.page, this.pageSize).subscribe({
      next: res => {
     // console.log(res);
        const list = res.results.data;
        const index = list.findIndex(i => i.id === this.hierarchyId);
        this.hierarchyName = list[index].attributes.name;
      }
    });
  }


}
