<div class="listHeaderFixed" fxLayout="row">
    <div class="list">
        <ul class="categoryDetails">
            <li *ngFor="let item of attributeList">
                <div class="item" fxLayout="column">
                    <div fxLayout="row">
                        <div fxFlex="100" class="caption" fxLayout="row">
                            <div fxFlex="85" fxLayoutAlign="start center">
                                ({{item.attributes.attribute_sequence}}) &nbsp;<b>{{item.attributes.attribute.data.attributes.name}}</b>
                            </div>
                            <div fxFlex="15" fxLayoutAlign="center center">
                                <button mat-icon-button *ngIf="item.attributes.is_filter">
                                    <mat-icon  svgIcon="filter"></mat-icon>({{item.attributes.filter_sequence}})
                                </button>      
                            </div>
                        </div>  
                    </div>
                    <div fxLayout="column">
                        <div fxLayout="row" style="width: 100%;">
                            <div fxFlex="50" class="caption">Closed : <span  *ngIf="item.attributes.is_closed !== null"></span>{{(item.attributes.is_closed).toString() | titlecase}}</div>
                            <div fxFlex="50" class="caption">Single : <span *ngIf="item.attributes.is_multi !== null">{{(!item.attributes.is_multi).toString() | titlecase}}</span></div>    
                        </div>
                        <div fxLayout="row" style="width: 100%;">
                            <div fxFlex="50" class="caption">Is Active : <span *ngIf="item.attributes.is_active !== null">{{(item.attributes.is_active).toString() | titlecase}}</span></div>
                            <div fxFlex="50" class="caption">Has UOM : <span *ngIf="item.attributes.has_uom !== null">{{(item.attributes.has_uom).toString() | titlecase}}</span></div>
                        </div>    
                        <div fxLayout="row" style="width: 100%;">
                            <div fxFlex="50" class="caption">Is Required : <span  *ngIf="item.attributes.is_required !== null">{{(item.attributes.is_required).toString() | titlecase}}</span></div>
                            <div fxFlex="50" class="caption">Is Differentiator : <span  *ngIf="item.attributes.is_differentiator !== null">{{(item.attributes.is_differentiator).toString() | titlecase}}</span></div>
                        </div>
                        <div fxLayout="row" style="width: 100%;">
                            <div fxFlex="100" class="caption">Minimum Value : <span>{{item.attributes.min_value}}</span></div>
                        </div>
                        <div class="caption">UoM Codeset : <span *ngIf="item.attributes.uom_codeset!==undefined && item.attributes.uom_codeset!==null">{{item.attributes.uom_codeset.data.attributes.name}}</span></div>
                        <div class="caption">Approved Value: <span   *ngIf="item.attributes.value_codeset!==undefined && item.attributes.value_codeset!==null">{{item.attributes.value_codeset.data.attributes.name}}</span></div>
                    </div>
                </div>
            </li>
        </ul>
    </div>